import { bosslist, getScaleByNameAndKey } from "./datasourcegenerator";
export const QueryBy = {
    INSTRUMENTS: 'INSTRUMENTS',
    TEXT: 'TEXT'
};

export function notesOverlap(note1, note2) {
    return !((note1.ticks + note1.durationTicks) <= note2.ticks ||
        note1.ticks >= (note2.ticks + note2.durationTicks))
}

export const RelativeChordName = {};
export const RelativeChordValue = {};
(() => {
    [{ name: 'I', value: 0 }, { name: 'ii', value: 1 }, { name: 'II', value: 2 }, { name: 'iii', value: 3 }, { name: 'III', value: 4 },
    { name: 'iv', value: 5 }, { name: 'IV', value: 6 }, { name: 'v', value: 7 }, { name: 'V', value: 8 }, { name: 'vi', value: 9 },
    { name: 'VI', value: 10 }, { name: 'vii', value: 11 }, { name: 'VII', value: 12 }].map(v => {
        RelativeChordName[v.name] = v.value;
        RelativeChordValue[v.value] = v.name;
    })
})();
export const createScaleLib = function () {
    var scalelib = [];
    var _temp = "BCDEFGA".split("");
    for (var j = 0; j < 10; j++)
        for (var i = 0; i < _temp.length; i++) {
            scalelib.push(_temp[i]);
        }
    return scalelib;
}
export function getaccidentalsvalue(library, melodylist) {
    var lowest = 1000;
    var base = null;
    for (var i = 0; i < melodylist.length; i++) {
        var temp = library[melodylist[i].key + melodylist[i].accidental] + melodylist[i].octave * 12;
        if (temp < lowest) {
            base = melodylist[i].accidental;
            lowest = temp;
        }
    }
    return base;
}
Array.prototype.convertToMetric = function () {
    var result = [];
    for (var i = 0; i < this.length; i++) {
        result.push(getMetric(this[i]));
    }
    return result;
}
if (!String.prototype.trim) {

    String.prototype.trim = function () { return this.replace(/^\s\s*/, '').replace(/\s\s*$/, ''); };

    String.prototype.ltrim = function () { return this.replace(/^\s+/, ''); };

    String.prototype.rtrim = function () { return this.replace(/\s+$/, ''); };

    String.prototype.fulltrim = function () { return this.replace(/(?:(?:^|\n)\s+|\s+(?:$|\n))/g, '').replace(/\s+/g, ' '); };
    //code for trim
}
Array.prototype.clean = function (deleteValue) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] == deleteValue) {
            this.splice(i, 1);
            i--;
        }
    }
    return this;
};
Array.prototype.trim = function () {
    for (var i = 0; i < this.length; i++) {
        this[i] = this[i].trim();
    }
    return this;
}
Array.prototype.toLower = function () {
    for (var i = 0; i < this.length; i++) {
        this[i] = this[i].toLowerCase();
    }
    return this;
}

Array.prototype.replace = function (old, new_, nd) {
    var result = [];
    for (var i = 0; i < this.length; i++) {
        if (nd) {
            result.push(this[i].replace(old, new_));
        }
        else this[i] = this[i].replace(old, new_);
    }
    if (nd)
        return result;
    return this;
}


Array.prototype.appendString = function (op1, op2, array) {
    let old = '';
    for (var i = 0; i < this.length; i++) {
        this[i] = this[i] + old;
    }
    return this;
}
Array.prototype.clean = function (deleteValue) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] == deleteValue) {
            this.splice(i, 1);
            i--;
        }
    }
    return this;
};
export const key = ["A", "B", "C", "D", "E", "F", "G"];
export const flat = htmlDecode('&#x266d;');
export const sharp = htmlDecode('&#x266F;');
export const natural = htmlDecode('&#x266E;');
export const library = {};
library["C"] = 0;
library["B" + sharp] = 12;
library["C" + sharp] = 1;
library["D" + flat] = 1;
library["D"] = 2;
library["D" + sharp] = 3;
library["E" + flat] = 3;
library["E"] = 4;
library["F" + flat] = 4;
library["E" + sharp] = 5;
library["F"] = 5;
library["F" + sharp] = 6;
library["G" + flat] = 6;
library["G"] = 7;
library["G" + sharp] = 8;
library["A" + flat] = 8;
library["A"] = 9;
library["A" + sharp] = 10;
library["B" + flat] = 10;
library["B"] = 11;
library["C" + flat] = -1;

export const metricscale = {};
metricscale["1"] = 0;
metricscale["2"] = 2;
metricscale["3"] = 4;
metricscale["4"] = 5;
metricscale["5"] = 7;
metricscale["6"] = 9;
metricscale["7"] = 11;

export const getKey = function (value, accidental) {
    for (var i in library) {
        if (library[i] == value) {
            if (i.indexOf(flat) == -1 && i.indexOf(sharp) == -1) {
                return i;
            }
            else if (i.indexOf(sharp) != -1 && accidental == sharp) {
                return i;
            }
            else if (i.indexOf(flat) != -1 && accidental == flat) {
                return i;
            }


        }
    }
}
export const getrootoctave = function (melodylist) {
    var lowest = 1000;
    var base = null;
    for (var i = 0; i < melodylist.length; i++) {
        var temp = library[melodylist[i].key + melodylist[i].accidental] + melodylist[i].octave * 12;
        if (temp < lowest) {
            base = melodylist[i].octave;
            lowest = temp;
        }
    }
    return base;
    // return output;
}
export function getrootbase(library, melodylist) {
    var lowest = 1000;
    var base = null;
    for (var i = 0; i < melodylist.length; i++) {
        var temp = library[melodylist[i].key + melodylist[i].accidental] + melodylist[i].octave * 12;
        if (temp < lowest) {
            base = melodylist[i].key;
            lowest = temp;
        }
    }
    return base;
    // return output;
}

export const generateSigpoints = function () {
    var signaturePoints = {};
    for (var i = 0; i < 11; i++) {
        var key = getKey((library["C"] + i * 7) % 12, sharp);
        if (signaturePoints[key] == undefined) {
            signaturePoints[key] = { sharps: i };
        }
        else {
            signaturePoints[key].sharps = i;
        }
        var temp = (library["C"] - i * 7) % 12;
        if (temp < 0) {
            temp += 12;
        }
        key = getKey(temp, flat);
        if (signaturePoints[key] == undefined) {
            signaturePoints[key] = { flats: i };
        }
        else {
            signaturePoints[key].flats = i;
        }

    }
    return signaturePoints;
}
export const signaturePoints = generateSigpoints();
export const fitNotesTo = function (notes, notes2) {
    var results = [];
    for (var i = 0; i < notes.length; i++) {
        if (notes[i].indexOf(sharp) != -1 || notes[i].indexOf(flat) != -1) {
            if (notes2.indexOf(notes[i]) == -1) {
                var options = getOptions(notes[i]);
                var added = false;
                for (var j = options.length; j--;) {
                    if (notes2.indexOf(options[j]) != -1) {
                        results.push(options[j]);
                        added = true;
                        break;
                    }
                }
                if (!added) {
                    results.push(notes[i]);
                }
            }
            else {

                results.push(notes[i]);
            }
        }
        else {
            results.push(notes[i]);
        }
    }
    return results;
}
export const getOptions = function (note) {
    var results = [];
    for (var i in library) {
        if (library[i] == library[note]) {
            results.push(i);
        }
    }
    return results;
}
export const getMetric = function (normal) {
    var values = normal.split("");
    var key = null;
    var addition = 0;
    for (var i = 0; i < values.length; i++) {
        if (values[i] == "-") {
            addition--;
        }
        else if (values[i] == "+") {
            addition++;
        }
        else {
            key = parseInt(metricscale[values[i]]);
        }
    }
    if (key == null) {
        throw 'key not found';
    }
    return key + addition;
}

export const containsType = function (array, info) {
    for (var i = array.length; i--;) {
        if (info.equals(array[i].voice)) {
            return true;
        }
    }
    return false;
}


export function htmlDecode(input) {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
}
export const findLetter = function (num, bias, base7) {
    var result = null;
    for (var i in library) {
        if (base7 != undefined) {
            var flatness = isAccidentaled(base7, "-");
            if (flatness) {
                bias = flat;
            }
            var sharped = isAccidentaled(base7, "+");
            if (sharped) {
                bias = sharp;
            }
        }
        if (library[i] == num) {

            if (i.indexOf(bias) != -1) {
                result = i;
            }
            else if (bias == undefined) {
                result = i;
            }
            if (i.indexOf(sharp) == -1 && i.indexOf(flat) == -1) {
                return i;
            }
        }
    }
    return result;
}

export const isAccidentaled = function (val, acid) {
    var v = val.split("");
    var count = 0;
    for (var i = 0; i < v.length; i++) {
        if (v[i] == acid) {
            count++;
        }
    }
    return count;
}
export const convertTo = function (base, offset) {
    var temp = null;
    if (offset < base) {
        temp = (offset + 12) - base;
    }
    else {
        temp = (offset) - base;
    }
    return temp;
}
export const convertToMelody = function (base, offset) {
    var temp = null;
    if (offset < base) {
        temp = (offset) + base;
    }
    else {
        temp = (offset) + base;
    }
    return temp % 12;
}
export const converttoBase12 = function (v) {
    return v.toString(12);
}
export const getKeyValue = function (melodyobject) {
    return library[melodyobject.key + melodyobject.accidental] + melodyobject.octave * 12;
}
Array.prototype.appendOctave = function (start, copy, octave_offset) {
    var result = [];
    var scalelib = createScaleLib();
    var lastfound = 1;
    for (var i = 0; i < this.length; i++) {

        var purenote = copy[i].replace(flat, "").replace(sharp, "").replace("#", "").toUpperCase();

        var scaleoffset = Math.floor(scalelib.indexOf(purenote, lastfound) / 7);
        if (scalelib.indexOf(purenote, lastfound) % 7 == 0 && scaleoffset != 0) {
            scaleoffset--;
        }
        lastfound = scalelib.indexOf(purenote, lastfound)
        if (octave_offset) {

            result[i] = (this[i] + "/" + (start + octave_offset[i])).toLowerCase();
        } else
            result[i] = (this[i] + "/" + (start + scaleoffset)).toLowerCase();
    }
    return result;
}
Array.prototype.makeDrawable = function (root, bias, start) {
    var result = [];
    for (var i = 0; i < this.length; i++) {
        result.push(findLetter(convertToMelody(library[root], parseInt(this[i])), bias));
    }
    return result.appendOctave(start, result);
}
Array.prototype.toLetterNotes = function (root, bias) {
    var result = [];
    for (var i = 0; i < this.length; i++) {
        result.push(findLetter(convertToMelody(library[root], parseInt(this[i])), bias));
    }
    return result;
}
Array.prototype.removeEmpties = function () {
    var result = [];
    for (var i = 0; i < this.length; i++) {
        if (this[i] != null && this[i] != "") {
            result.push(this[i]);
        }
    }
    return result;
}


if (!Array.prototype.unique) {
    Object.defineProperty(Array.prototype, 'unique', {
        enumerable: false,
        writable: true,
        configurable: true,
        value: function (func) {
            var result = [];
            var finalresult = [];
            func = func || function (x) { return x; };
            var collection = this;
            for (var i = 0; i < collection.length; i++) {
                //if (func(collection[i])) {
                if (result.indexOf(func(collection[i])) === -1) {
                    result.push(func(collection[i]));
                    finalresult.push(collection[i]);
                }
                //}
            }
            return finalresult;
            //return result;
        }
    });
}
export function parseMidiMessage(message, time) {
    return {
        command: message.data[0] >> 4,
        channel: message.data[0] & 0xf,
        note: message.data[1],
        pitch: message.data[1],
        velocity: message.data[2] / 127,
        time: time || message.data.time
    }
}
export function createMidiMessage(pitch, velocity, time, channel, command) {
    return {
        command: command,
        channel: channel,
        note: pitch,
        pitch: pitch,
        velocity: velocity
    }
}
export const getLibray = function () {
    return library;
}
export const convertToMelodyNote = function (x, bias) {
    var octave = Math.floor(x / 12) - 1;
    var note = x % 12;
    let letter = findLetter(note, bias)
    if (letter) {
        note = letter;
    }
    else {
        for (var i in library) {
            if (library[i] == note) {
                note = i;
                break
            }
        }
    }
    return {
        accidental: note[1] || "",
        octave: octave,
        key: note[0]
    }
}
export const getScaleName = function (id) {
    for (var i in bosslist) {
        for (var j = bosslist[i].length; j--;) {
            if (bosslist[i][j].id == id) {
                return bosslist[i][j].official;
            }
        }
    }
    return "[Unknown]";
}
export const getScale = function (id) {
    for (var i in bosslist) {
        for (var j = bosslist[i].length; j--;) {
            if (bosslist[i][j].id == id) {
                return bosslist[i][j];
            }
        }
    }
}
export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
(function (array) {

    if (!array.maximum) {
        Object.defineProperty(array, 'maximum', {
            enumerable: false,
            writable: true,
            configurable: true,
            value: function (func) {
                var result = null;
                var _result = null;
                var collection = this;
                func = func || function (x) { return x; };
                for (var i = 0; i < collection.length; i++) {
                    if (result == null || func(collection[i]) > result) {
                        result = func(collection[i]);
                        _result = collection[i];
                    }
                }
                return result;
            }
        });
    }

    if (!array.minimum) {
        Object.defineProperty(array, 'minimum', {
            enumerable: false,
            writable: true,
            configurable: true,
            value: function (func) {
                var result = null;
                var _result = null;
                var collection = this;
                var val;
                func = func || function (x) { return x; };
                for (var i = 0; i < collection.length; i++) {
                    val = func(collection[i], i);
                    if (result == null || val < result) {
                        result = val;
                        _result = collection[i];
                    }
                }
                return result;
            }
        });
    }
    if (!array.minSelection) {
        Object.defineProperty(array, 'minSelection', {
            enumerable: false,
            writable: true,
            configurable: true,
            value: function (func) {
                var result = null;
                var _result = null;
                var collection = this;
                var val;
                func = func || function (x) { return x; };
                for (var i = 0; i < collection.length; i++) {
                    val = func(collection[i], i);
                    if (result == null || val < result) {
                        result = val;
                        _result = collection[i];
                    }
                }
                return _result;
            }
        });
    }

    if (!array.removeWhere) {
        Object.defineProperty(array, 'removeWhere', {
            enumerable: false,
            writable: true,
            configurable: true,
            value: function (func) {
                var collection = this;
                func = func || function () { return true; }
                var result = collection.filter(func);
                for (var i = 0; i < result.length; i++) {
                    collection.splice(collection.indexOf(result[i]), 1);
                }
                return result;
            }
        });
    }

    if (!array.summation) {
        Object.defineProperty(array, 'summation', {
            enumerable: false,
            writable: true,
            configurable: true,
            value: function (func) {
                var result = 0;
                var collection = this;
                for (var i = 0; i < collection.length; i++) {
                    result = func(collection[i], result, i, collection.length);
                }
                return result;
            }
        });
    }
    if (!array.maxSelection) {
        Object.defineProperty(array, 'maxSelection', {
            enumerable: false,
            writable: true,
            configurable: true,
            value: function (func) {
                var result = null;
                var _result = null;
                var collection = this;
                for (var i = 0; i < collection.length; i++) {
                    if (result == null || func(collection[i]) > result) {
                        result = func(collection[i]);
                        _result = collection[i];
                    }
                }
                return _result;
            }
        });
    }
})(Array.prototype)
export const getBaseRoot = function (root, single, melodylist) {
    var value = parseInt(root, 12);
    var base_int = library[getrootbase(library, melodylist) + getaccidentalsvalue(library, melodylist)];
    var resut = (base_int - value) % 12;
    if (resut < 0) {
        resut += 12;
    }
    var results = "";
    for (var i in library) {
        if (library[i] == resut) {
            if (results == "")
                results += i;
            else {
                results += "/" + i;
            }
            if (single) {
                break;
            }
        }
    }
    return results;
}

export function BuildChordScale(voice, selectedScale, melodylist, baseNote, bias) {
    var baseroot = (library[getrootbase(library, melodylist) + getaccidentalsvalue(library, melodylist)]);
    var _d = getrootbase(library, melodylist) + getaccidentalsvalue(library, melodylist);
    var voicearray = voice;
    var notes = [_d];
    var notes_copy = [_d];
    var octave_offset = [getrootoctave(melodylist) - 4];
    for (var i = 1; i < voicearray.length; i++) {
        var note = findLetter(convertToMelody(baseroot, parseInt(voicearray[i])));
        notes.push(note);
        notes_copy.push(note);
        octave_offset.push(octave_offset[0] + Math.floor((baseroot + parseInt(voicearray[i])) / 12));
    }
    var currentscale = getScaleByNameAndKey(selectedScale, baseNote);
    var scale_ = currentscale.base12;
    var base7 = currentscale.base7.split(" ").removeEmpties();
    var scale_start = getBaseRoot(currentscale.root.toString(12).toUpperCase(), true, melodylist);
    var scale_notes = [];
    var scale_notes_copy = [];
    var scale_octave_offset = null;
    var scaleprintnotes = [];
    var scalemidinotes = [];
    for (var i = 0; i < scale_.length; i++) {
        scale_notes.push(findLetter(convertToMelody(library[scale_start], parseInt(scale_[i])), bias, base7[i]));
        scale_notes_copy.push(findLetter(convertToMelody(library[scale_start], parseInt(scale_[i])), bias, base7[i]));
        if (scale_octave_offset == null) {
            scale_octave_offset = [0];
        }
        else {
            scale_octave_offset.push(Math.floor((library[scale_start] + parseInt(scale_[i])) / 12));
        }
        var selectedcleffchoice2 = "treble";
        var base_offset = 4;
        switch (selectedcleffchoice2) {
            case "alto":
                base_offset = 4;
                break;
            case "bass":
                base_offset = 2;
                break;
            case "tenor":
                base_offset = 4;
        }
        scaleprintnotes.push(scale_notes[i] + '/' + ((scale_octave_offset[i] + base_offset)));
        scalemidinotes.push(library[scale_notes[i]] + ((parseInt(((scale_octave_offset[i] + base_offset))) + 1)) * 12);
    }
    let midiNotes = {};
    voice.map(v => { midiNotes[v + baseNote] = true; })
    let midi = {};
    scalemidinotes.map(v => { midi[v] = true; });
    return {
        scale: {
            midi,
            notes: scaleprintnotes,
            info: currentscale
        },
        voice,
        notes,
        midiNotes,
        baseNote,
        melodylist
    }
}
export default function () { }