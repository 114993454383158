import React from 'react';
import InfoBox from '../../components/InfoBox';

export default class MidiFile extends React.Component {
    render() {
        return (
            <InfoBox selected={this.props.selected} text={`${this.props.name}`} onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick();
                }
            }} subtext={`midi file`} />
        );
    }
}