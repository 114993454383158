import React from 'react';
import { flat, sharp } from '../../base/base-lib';
import StaffNotes from './StaffNotes';

export default class NoteInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { bias: false };
    }
    componentDidUpdate() {

    }
    render() {
        let { props } = this;
        return (
            <div className="form-group" tabIndex={0} onFocus={() => {
                this.setState({ focused: true })
                if (this.props.onNoteInputFocus) {
                    this.props.onNoteInputFocus(true);
                }
            }} onBlur={() => {
                this.setState({ focused: false })
                if (this.props.onNoteInputFocus) {
                    this.props.onNoteInputFocus(false);
                }
            }}>
                <StaffNotes value={this.props.value} skipbank={this.props.skipbank}
                    onPlay={() => {
                        if (props.onPlay) {
                            props.onPlay({ value: this.props.value, play: true });
                        }
                    }}
                    onStop={() => {
                        if (props.onStop) {
                            props.onStop({ value: this.props.value, play: false });
                        }
                    }}
                    onMoveTones={(dir) => {
                        if (props.onMoveTones) {
                            props.onMoveTones(dir)
                        }
                    }}
                    onAddToBank={() => {
                        if (props.onAddToBank) {
                            props.onAddToBank(this.props.value);
                        }
                    }}
                    bias={this.props.bias ? sharp : flat} />
            </div>
        )
    }
}