import { parseMidiMessage, uuidv4 } from "../../base/base-lib";
import { getDrumOutput } from "../../components/music/DrumMachine";
import * as timerService from '../../timer/time-service';
import { getMagentaService } from "./magenta-thunks";
import { isOn, isPercussionOn } from "./MidiNotes";

export const SeratoEventTypes = {
    MidiEvents: 'MIDI-EVENTS',
    MidiInputChange: 'MIDI-INPUT-CHANGE',
    MidiCommandEvents: 'MIDI-COMMAND-EVENTS'
}
let magentaService;
let deviceOptions = {};
export function updateDeviceOptions(options) {
    deviceOptions = options;
}

export default class SeratoComposer {
    static inputDevices = {};
    static async loadMidi(dispatch) {
        return await new Promise((resolve, fail) => {

            (navigator).requestMIDIAccess()
                .then(function (access) {

                    // Get lists of available MIDI controllers
                    // const inputs = access.inputs.values();
                    // const outputs = access.outputs.values();
                    access.inputs.forEach((input) => {
                        MidInputDevice.createInputDevice(input, SeratoComposer.inputDevices, (updateMessage, id, parsed, name) => {
                            let temp = [];
                            let { time } = timerService.getAccurateNow()

                            updateMessage.data.map(v => {
                                temp.push(v)
                            });
                            temp.time = time;
                            magentaService = magentaService || getMagentaService();
                            if (magentaService) {
                                console.log(parsed);
                                if (deviceOptions && deviceOptions[name]) {
                                    RecordingService.addEvent({ time, data: parsed, instrument: deviceOptions[name].instrument });
                                    if (isOn(parsed)) {
                                        SeratoComposer.playNoteDown(parsed, name);
                                    }
                                    else if (isPercussionOn(parsed)) {
                                        let drumPlayer = getDrumOutput();
                                        if (drumPlayer) {
                                            if (parsed.velocity)
                                                drumPlayer.play(parsed.pitch - 1, parsed.velocity > .5 ? 'high' : 'low', 0)
                                        }

                                    }
                                    else {
                                        SeratoComposer.playNoteUp(parsed, name);
                                    }
                                }
                            }
                            // dispatch(SeratoEventTypes.MidiEvents, temp);
                            // dispatch(SeratoEventTypes.MidiCommandEvents, { device: id, command: parsed, time });
                        });
                    })
                    access.onstatechange = function (e) {

                        // Print information about the (dis)connected MIDI controller
                        // console.log(e.port.name, e.port.manufacturer, e.port.state);
                        // console.log(e);
                        dispatch(SeratoEventTypes.MidiInputChange, {
                            id: e.port.id,
                            name: e.port.name,
                            manufacturer: e.port.manufacturer,
                            state: e.port.state,
                            type: e.port.type,
                            version: e.port.version
                        });
                    };

                    resolve();
                }).catch((e) => {
                    fail(e);
                });

        })
    }
    static playNote(parsed, name, time, duration, instrument, kit) {
        magentaService = magentaService || getMagentaService();

        magentaService.playNote({ ...parsed, endTime: time + duration, startTime: time }, deviceOptions && deviceOptions[name] ? deviceOptions[name].kit : kit,
            deviceOptions && deviceOptions[name] ? parseInt(deviceOptions[name].instrument) : instrument);
        // return new Promise(() => {

        //     setTimeout(() => {
        //         this.playNoteDown(parsed, name, instrument, kit);
        //         setTimeout(() => {
        //             this.playNoteUp(parsed, name, instrument, kit);
        //         }, duration);
        //     }, time)
        // })
    }
    static playNoteUp(parsed, name, instrument, kit) {
        magentaService.playNoteUp(parsed, deviceOptions && deviceOptions[name] ? deviceOptions[name].kit : kit,
            deviceOptions && deviceOptions[name] ? parseInt(deviceOptions[name].instrument) : instrument);
    }

    static playNoteDown(parsed, name, instrument, kit) {
        magentaService.playNoteDown(parsed, deviceOptions && deviceOptions[name] ? deviceOptions[name].kit : kit,
            deviceOptions && deviceOptions[name] ? parseInt(deviceOptions[name].instrument) : instrument);
    }
}
export class RecordingService {
    static records = {};
    static currentRecording = null;

    static startRecording() {
        RecordingService.currentRecording = uuidv4();
        RecordingService.records[RecordingService.currentRecording] = [];
        return RecordingService.currentRecording;
    }
    static stopRecording() {
        let res = null;
        if (RecordingService.records[RecordingService.currentRecording]) {
            res = RecordingService.records[RecordingService.currentRecording];
            delete RecordingService.records[RecordingService.currentRecording];
        }
        RecordingService.currentRecording = null;
        return res;
    }
    static addEvent(evt) {
        if (RecordingService.currentRecording &&
            RecordingService.records[RecordingService.currentRecording]) {
            RecordingService.records[RecordingService.currentRecording].push(evt);
        }
    }
}
class MidInputDevice {
    static createInputDevice(input, inputDevices, onmidimessage) {
        if (input) {
            inputDevices[input.id] = input;
            input.onmidimessage = function (evt) {
                let { time } = timerService.getAccurateNow();
                if (!deviceOptions[input.name] || !deviceOptions[input.name].instrument) {
                    deviceOptions[input.name] = {
                        ...(deviceOptions[input.name] | {}), instrument: '0x00'
                    };
                }
                onmidimessage(evt, input.id, parseMidiMessage(evt, time), input.name);
            };
        }
    }
}