
export const play = function (note, status, velocity = 127) {
    let MIDI = window.MIDI;
    if (!MIDI.programChange) {
        return;
    }
    var channel = 0,
        note = parseInt(note),
        delay = 0;

    MIDI.programChange(0, 0);

    if (status === 'on') {
        MIDI.noteOn(channel, note, velocity, delay);
    }
    else {
        MIDI.noteOff(channel, note, delay + .5);
    }
};

let inited = false;
export function init() {
    if (inited) {
        return;
    }
    let MIDI = window.MIDI;


    // Preload all sounds and initialize the instruments.
    MIDI.loadPlugin({
        api: 'webaudio',
        soundfontUrl: './soundfont/',
        instruments: ['acoustic_grand_piano', 'acoustic_guitar_nylon', 'tubular_bells', 'kalimba', 'timpani', 'orchestral_harp', 'xylophone', 'alto_sax', 'tuba', 'pan_flute', 'violin'],
        onsuccess: function () {
        }
    });
    inited = true;
}
