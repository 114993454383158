import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormGroup } from '../../components/FormGroup';
import { SplitModifier } from '../../components/music/modifier/SplitModifier';
import {
    updateModifierItemPosition,
    updateModifierItem,
    removeModifierClick,
    selectedCurrentModifier,
    onModifierItemClicked,
    addModifierType,
    updateModifier,
    getSelectedModifier,
    getSelectedModifierItem,
    getCanSplitModifier,
    getAddTonalModifier,
    getDeepestModifers,
    getAddAModifier,
    getMidiLicks,
    getMidiLickDict
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import ModifierEditor from '../../components/music/ModifierEditor';
import { ModifierTypes } from '../../base/modifier-service';
import { TitlesService } from '../../title-service';
import { ModifierItemParameters } from '../../components/music/modifier/ModifierItemParameters';
import { ModifierMusicStaff } from '../../components/music/modifier/ModifierMusicStaff';
export default function ModifierView() {
    const dispatch = useDispatch();
    const modifier = useSelector(selectedCurrentModifier);
    const selectedItem = useSelector(getSelectedModifier);
    const selectedModifierItem = useSelector(getSelectedModifierItem);
    const canSplit = useSelector(getCanSplitModifier);
    const canDoAnything = useSelector(getAddAModifier);
    const canDoTonalThing = useSelector(getAddTonalModifier);
    const midiLicks = useSelector(getMidiLicks);
    const midiLickDict = useSelector(getMidiLickDict);
    const deepestModifierSpaces = useSelector(getDeepestModifers);

    return (
        <Panel stretch title={'Modifier Editor'}>
            <div className="row">
                <div className="col-md-10">
                    {selectedItem ? <div className="row" style={{ alignContent: 'center' }}>
                        <div className="col-md-1">
                            <FormGroup>
                                <label>Name</label>
                                <input onChange={(e) => {
                                    dispatch(updateModifier({
                                        key: 'name',
                                        value: (e.currentTarget.value)
                                    }));
                                }} type="text" value={modifier.name} className="form-control" placeholder="Enter ..." />
                            </FormGroup>
                        </div>
                        <SplitModifier enabled={canSplit} onSplitAdd={(args) => {
                            dispatch(addModifierType({
                                type: ModifierTypes.Split,
                                ...args
                            }))
                        }} />
                        <div className="col-md-5">
                            <FormGroup>
                                <div className="btn btn-group">
                                    <button {...(canDoAnything ? {} : { disabled: true })} onClick={() => {
                                        dispatch(addModifierType({
                                            type: ModifierTypes.Midi
                                        }))
                                    }} className="btn btn-success">{TitlesService('Midi')}</button>
                                    <button {...(canDoAnything ? {} : { disabled: true })} onClick={() => {
                                        dispatch(addModifierType({
                                            type: ModifierTypes.Arpeggio
                                        }))
                                    }} className="btn btn-success">Arpeggio</button>
                                    <button {...(canDoAnything ? {} : { disabled: true })} onClick={() => {
                                        dispatch(addModifierType({
                                            type: ModifierTypes.Rest
                                        }))
                                    }} className="btn btn-success">{TitlesService('Rest')}</button>
                                    <button {...(canDoTonalThing ? {} : { disabled: true })} onClick={() => {
                                        dispatch(addModifierType({
                                            type: ModifierTypes.StepTone
                                        }))
                                    }} className="btn btn-success">{TitlesService('StepTone')}</button>
                                </div>
                            </FormGroup>
                        </div>
                    </div> : null
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <ModifierMusicStaff
                                modifier={modifier}
                                licks={midiLickDict}
                                item={selectedModifierItem} />
                        </div>
                    </div>
                    <ModifierEditor
                        onDelete={(d) => {
                            dispatch(removeModifierClick(d))
                        }}
                        onItemClick={(d) => {
                            dispatch(onModifierItemClicked(d))
                        }}
                        updateModifierLayout={(args) => {
                            dispatch(updateModifierItemPosition(args))
                        }}
                        selectedItem={selectedModifierItem}
                        deepest={deepestModifierSpaces}
                        modifier={modifier} />
                </div>
                <div className="col-md-2">
                    <ModifierItemParameters
                        modifier={modifier}
                        midiLicks={midiLicks}
                        item={selectedModifierItem}
                        onUpdate={(args) => {
                            dispatch(updateModifierItem(args))
                        }} />
                </div>
            </div>
        </Panel >
    );
}