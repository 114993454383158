import { convertToMelody, convertToMelodyNote, flat, getLibray, notesOverlap, sharp } from "./base-lib";
import ChordMaster from "./chord-master";
import { InvertVoice } from "./datasourcegenerator";

export function BuildFoundMatches(e, bias) {
    let foundMatches = [];
    let currentChords = ProcessChordString(e);
    currentChords.forEach((chordInfo) => {
        let v = [0];
        switch (chordInfo.type) {
            case 'minor':
                v.push(3, 7);
                break;
            case 'major':
                v.push(4, 7);
                break;
            case 'sus4':
            case 'suspended':
                v.push(5, 7);
                break;
            case 'sus2':
                v.push(2, 7);
                break;
            case 'diminished':
                v.push(3, 6);
                break;
        }
        if (chordInfo.no && Object.keys(chordInfo.no).length) {
            let addAt;
            if (chordInfo.no['5']) {
                v.splice(2, 1);
                addAt = 2;
            }
            if (chordInfo.no['3']) {
                v.splice(1, 1);
                addAt = 1;
            }
            if (addAt !== undefined) {
                v.push(12);
            }
        }
        let toremove = [];
        if (chordInfo.added && chordInfo.added.length) {
            chordInfo.added.map((added) => {
                let pushitem = undefined;
                switch (added.semitone) {
                    case '5':
                        pushitem = 7;
                        break;
                    case '7':
                        pushitem = 11;
                        break;
                    case '9':
                        pushitem = 14;
                        break;
                    case '11':
                        pushitem = 17;
                        break;
                    case '13':
                        pushitem = 21;
                        break;
                }
                if (pushitem != undefined) {
                    if (added.accidental) {
                        switch (added.accidental) {
                            case 'b':
                                toremove.push(pushitem);
                                pushitem--;

                                break;
                            case '#':
                                toremove.push(pushitem);
                                pushitem++;
                                break;
                        }
                    }
                }
                if (pushitem != undefined) {
                    v.push(pushitem);
                }
            });
        }

        switch (chordInfo.chordDescription) {
            case '7':
                v.push('A');
                break;
            case '6':
                v.push(9);
                break;
            case '9':
                v.push('A', 14);
                break;
            case '11':
                v.push('A', 14, 17);
                break;
            case '13':
                v.push('A', 14, 17, 21);
                break;
        }
        if (toremove && toremove.length) {
            toremove.map((torem) => {
                let index = v.indexOf(torem);
                if (index === -1 && torem === 11) {
                    index = v.indexOf('A');
                }
                if (index !== -1) {
                    v.splice(index, 1);
                }
            });
        }
        if (chordInfo.hasModifier) {
            if (chordInfo.modifyChordNote) {
                let index = v.indexOf(chordInfo.modifyChordNote);
                if (index !== -1) {
                    if (isNaN(v[index])) {
                        v[index] = (parseInt(v[index], 12) + chordInfo.modifyChord).toString(12);
                    }
                    else {
                        v[index] += chordInfo.modifyChord;
                    }
                }
                else {
                    if (isNaN(chordInfo.modifyChordNote)) {
                        v.push((parseInt(chordInfo.modifyChordNote, 12) + chordInfo.modifyChord).toString(12));
                    }
                    else {
                        v.push(chordInfo.modifyChordNote);
                    }
                }
            }
        }
        let v_temp = v.map(v_t => typeof (v_t) === 'string' ? parseInt(v_t, 12).toString(12) : `${v_t % 12}`.toString(12));
        let temp = FindExactMatch(v_temp.sort((a, b) => a - b).map(v => v.toString()), []).filter(x => !x.smartinfo.isInversion);
        if (temp.length > 1) {
            let ttemp = temp.filter(x => x.smartinfo.name.indexOf('+') === -1);
            if (ttemp.length > 0) {
                temp = ttemp;
            }
        }

        let offset = getLibray()[chordInfo.key.toString() + chordInfo.accidental];
        let values = {};
        let value_voicing = {};
        let value_letter;
        if (temp[0]) {
            temp[0].smartinfo.voice.map((te, index) => {

                let num = (12 * (chordInfo.octave || 3)) + offset + te;
                values[num] = true;
                if (index === 0) {
                    value_letter = convertToMelodyNote(num, bias);
                }
            });
        }

        let invertedVoice = [];
        if (chordInfo.isInversion) {
            let v_nums = v.map(v => typeof (v) === 'string' ? parseInt(`${v}`, 36) : v);
            invertedVoice = InvertVoice(v_nums, chordInfo.inversion);
            console.log('inverted voice');
            console.log(invertedVoice);
            offset += v_nums[chordInfo.inversion];
            v = invertedVoice;
        }
        let voicing_letter;
        let dropped_numbers = [];
        if (chordInfo.overKey) {
            let over_offset = getLibray()[chordInfo.overKey.toString() + (chordInfo.overAccidental || '')];
            if (over_offset < offset) {
                over_offset = over_offset - offset + 12;
            }
            else {
                over_offset = over_offset - offset;
            }
            let over_num = (12 * (chordInfo.octave || 3)) + over_offset + offset;
            dropped_numbers.push(over_num);
        }
        v.map(v => typeof (v) === 'string' ? parseInt(`${v}`, 36) : v).map((te, index) => {

            let num = (12 * (chordInfo.octave || 3)) + offset + te;

            let droppedIndex = dropped_numbers.indexOf(num);
            if (droppedIndex !== -1) {
                dropped_numbers.splice(droppedIndex, 1);
                num -= 12;
            }
            value_voicing[num] = true;
            if (index === 0) {
                voicing_letter = convertToMelodyNote(num, bias);
            }
        });
        if (dropped_numbers.length) {
            dropped_numbers.map(dn => {
                value_voicing[dn - 12] = true;
            });
        }
        foundMatches.push({
            chordInfo,
            voicing_letter,
            value_letter,
            options: temp.map(t => {
                return {
                    smartinfo: t.smartinfo,
                    _info: {
                        id: t._info.id
                    }
                };
            }),
            offset,
            value_voicing,
            values,
            droppedNotes: dropped_numbers
        });
    });
    return foundMatches;
}
// Giant Steps 
// BM7	D7 → GM7	Bb7 → EbM7	Am7 → D7 → GM7  Bb7 → EbM7  F#7 → BM7  Fm7 → Bb7 → EbM7	 Am7 → D7 → GM7	 C#m7 → F#7 → BM7	 Fm7 → Bb7 → E♭M7	 C#m7 → F#7 
export function ProcessChordString(str) {

    //const regex = /(?<key>[A-G])(?<accidental>[b|#]?)(?<suspended_early>sus(?<susamount_early>[4|2]))?(?<suspended2_early>sus(?<susamount2_early>[4|2]))?(?<type>Maj|maj|min|Min|sus|Sus|Dim|dim|o|m|M|d|D|s|S|)?(?<rootchord>10|11|12|13|14|15|16|17|19|18|1|2|3|4|5|6|7|8|9)*(?<suspended_middle>sus(?<susamount_middle>[4|2]))*(?<suspended2_middle>sus(?<susamount2_middle>[4|2]))*(?<added>(\((add)?(?<addeditem1>(([b|#]*(10|11|12|13|14|15|16|17|18|1|2|3|4|5|6|7|8|9))))?(?<addeditem2>(([b|#](10|11|12|13|14|15|16|17|18|1|2|3|4|5|6|7|8|9))))?(?<addeditem3>(([b|#](10|11|12|13|14|15|16|17|18|1|2|3|4|5|6|7|8|9))))?(?<addeditem4>(([b|#](10|11|12|13|14|15|16|17|18|1|2|3|4|5|6|7|8|9))))?(?<addeditem5>(([b|#](10|11|12|13|14|15|16|17|18|1|2|3|4|5|6|7|8|9))))?\))*)(?<suspended>sus(?<susamount>[4|2]))*(?<suspended2>sus(?<susamount2>[4|2]))*(?<modifier>[\+|\-](?<notemodified>5|6|7|8|9)*)*(?<over>\/(?<overkey>[A-G])(?<overaccidental>[b|#]?))*(?<chordaddition>([b|#](10|11|12|13|14|15|16|17|19|18|1|2|3|4|5|6|7|8|9)))?(?<chordaddition2>([b|#](10|11|12|13|14|15|16|17|19|18|1|2|3|4|5|6|7|8|9)))?(?<chordaddition3>([b|#](10|11|12|13|14|15|16|17|19|18|1|2|3|4|5|6|7|8|9)))?(?<inversion> (inv)* (1|2|3|4|5|6|7|8|9))*(?<octave>( \/(1|2|3|4|5|6|7|8|9)))*/gm;
    const regex = /(?<key>[A-G])(?<accidental>[b|#]?)(?<suspended_early>sus(?<susamount_early>[4|2]))?(?<suspended2_early>sus(?<susamount2_early>[4|2]))?(?<type>Maj|maj|min|Min|sus|Sus|Dim|dim|o|m|M|d|D|s|S|)?(?<rootchord>10|11|12|13|14|15|16|17|19|18|1|2|3|4|5|6|7|8|9)*(?<suspended_middle>sus(?<susamount_middle>[4|2]))*(?<suspended2_middle>sus(?<susamount2_middle>[4|2]))*(?<added>(\((add)?(?<addeditem1>(([b|#]*(10|11|12|13|14|15|16|17|18|1|2|3|4|5|6|7|8|9))))?(?<addeditem2>(([b|#](10|11|12|13|14|15|16|17|18|1|2|3|4|5|6|7|8|9))))?(?<addeditem3>(([b|#](10|11|12|13|14|15|16|17|18|1|2|3|4|5|6|7|8|9))))?(?<addeditem4>(([b|#](10|11|12|13|14|15|16|17|18|1|2|3|4|5|6|7|8|9))))?(?<addeditem5>(([b|#](10|11|12|13|14|15|16|17|18|1|2|3|4|5|6|7|8|9))))?\))*)(?<suspended>sus(?<susamount>[4|2]))*(?<suspended2>sus(?<susamount2>[4|2]))*(?<mod>\([-|+]*[1|2|3|4|6|7|8|9|5|10|11|12|13|14|15|16|17]*\))*(?<modifier>[\+|\-](?<notemodified>5|6|7|8|9)*)*(?<removenotes>\(((no)[3|5])*\))*(?<added_all>((?<added_after>(\((([b|#])([5|9]))*)\))*))(?<over>\/(?<overkey>[A-G])(?<overaccidental>[b|#]?))*(?<chordaddition>([b|#](10|11|12|13|14|15|16|17|19|18|1|2|3|4|5|6|7|8|9)))?(?<chordaddition2>([b|#](10|11|12|13|14|15|16|17|19|18|1|2|3|4|5|6|7|8|9)))?(?<chordaddition3>([b|#](10|11|12|13|14|15|16|17|19|18|1|2|3|4|5|6|7|8|9)))?(?<inversion> (inv)* (1|2|3|4|5|6|7|8|9))*(?<octave>( \/(1|2|3|4|5|6|7|8|9)))*/gm;
    const regex_added_tones = /(add)?(?<accidental>[b|#|\-|+]?)(?<semitone>([10|11|12|13|14|15|16|17|18|19|20|1|2|3|4|5|6|7|8|9]*))/gm
    //Bb13(no3)(b9)
    /// (?<added_all>((?<added_after>(\((([b|#])([5|9]))*)\))*))
    const regex_remove_tones = /\((?<no3>no3)*(?<no5>no5)*\)/gm
    //     const str = `BM7
    // D7
    // Bb7`;
    let m;
    let res = [];
    while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        let result = {
            key: '',
            accidental: '',
            type: 'major',
            changes: [],
            octave: 4,
            inversion: 0,
            isInversion: false,
            chordDescription: '',
            userMade: true
        }
        if (m.groups) {
            result.key = m.groups.key;
            result.name = m.groups.key

            switch (m.groups.accidental) {
                case 'b':
                    result.accidental = flat;
                    break;
                case '#':
                    result.accidental = sharp;
                    break;
            }
            result.name = `${result.name}${result.accidental}`;
            let name_before_type = result.name;
            switch (m.groups.type) {
                case 'Maj':
                case 'M':
                    result.type = 'major';
                    result.name = `${result.name} Maj`;
                    break;
                case 'Min':
                case 'm':
                    result.type = 'minor'
                    result.name = `${result.name} min`;
                    break;
                case 'sus':
                case 'Sus':
                case 'Sus':
                case 's':
                case 'S':
                    result.type = 'suspended';
                    result.name = `${result.name} sus`;
                case 'Dim':
                case 'dim':
                case 'd':
                case 'o':
                    result.type = 'diminished';
                    result.name = `${result.name} dim`;
                    break;

            }
            result.no = {}
            if (m.groups.removenotes) {
                let mm;

                while ((mm = regex_remove_tones.exec(m.groups.removenotes)) !== null) {
                    if (mm.index === regex_remove_tones.lastIndex) {
                        regex_remove_tones.lastIndex++;
                    }

                    if (mm.groups.no3) {
                        result.no[3] = true;
                    }
                    if (mm.groups.no5) {
                        result.no[5] = true;
                    }
                }
                result.name = `${result.name}${result.no[3] ? '(no3)' : ''}${result.no[5] ? '(no5)' : ''}`;
            }
            result.added = []
            let added_name = '';
            if (m.groups.added || m.groups.added_after || m.groups.added_all) {
                let all = `${m.groups.added || ''} ${m.groups.added_after || ''} ${m.groups.added_all || ''}}`;
                let mm;
                while ((mm = regex_added_tones.exec(all)) !== null) {
                    if (mm.index === regex_added_tones.lastIndex) {
                        regex_added_tones.lastIndex++;
                    }
                    if (mm.groups.semitone) {
                        if (!result.added.some(v => v.semitone === mm.groups.semitone && v.accidental === mm.groups.accidental)) {
                            result.added.push({
                                semitone: mm.groups.semitone,
                                accidental: mm.groups.accidental || ''
                            });
                            added_name += `(${(mm.groups.accidental === 'b' || mm.groups.accidental === '-') ? flat : ((mm.groups.accidental === '#' || mm.groups.accidental === '+') ? sharp : '')}${mm.groups.semitone})`
                        }
                    }
                }
            }
            if (m.groups.suspended_early) {
                result.type = m.groups.suspended_early;
                result.name = `${name_before_type} sus${m.groups.susamount_early || ''}`;
            }
            if (m.groups.chordaddition) {
                result.changes.push(m.groups.chordaddition)
            }
            if (m.groups.chordaddition2) {
                result.changes.push(m.groups.chordaddition2)
            }
            if (m.groups.chordaddition3) {
                result.changes.push(m.groups.chordaddition3)
            }

            if (m.groups.rootchord) {
                result.chordDescription = m.groups.rootchord;
                result.name = `${result.name} ${result.chordDescription}`;
            }
            if (m.groups.overkey) {
                result.overKey = m.groups.overkey;
                if (m.groups.overaccidental) {
                    switch (m.groups.overaccidental) {
                        case 'b':
                            result.overAccidental = flat;
                            break;
                        case '#':
                            result.overAccidental = sharp;
                            break;
                    }
                }
                result.name = `${result.name}/${result.overKey}${result.overAccidental ? result.overAccidental : ''}`;
            }
            if (m.groups.octave) {
                result.octave = parseInt(m[19], 10);
                if (isNaN(result.octave)) {
                    result.octave = 4;
                }
                result.octave -= 1;
            }
            if (m.groups.modifier) {
                result.hasModifier = true;
                result.modifyChord = 0;
                result.modifyChordNote = 7;
                result.name = `${result.name}${m.groups.modifier}`;
                if (m.groups.modifier.startsWith('+')) {
                    result.modifyChord = 1;
                }
                else if (m.groups.modifier.startsWith('-')) {
                    result.modifyChord = -1;
                }
                switch (m.groups.notemodified) {
                    case 5:
                    case '5':
                        result.modifyChordNote = 7;
                        break;
                    case 6:
                    case '6':
                        result.modifyChordNote = 9;
                        break;
                    case 7:
                    case '7':
                        result.modifyChordNote = 'A';
                        break;
                }
            }
            result.name = `${result.name}${added_name}`
            if (m.groups.inversion) {
                result.inversion = parseInt(m[16], 10);
                if (!isNaN(result.inversion)) {
                    result.isInversion = true;
                    let postfix = '';
                    switch (result.inversion) {
                        case 1:
                            postfix = 'st';
                            break;
                        case 2:
                            postfix = 'nd';
                            break
                        case 3:
                            postfix = 'rd';
                            break
                        case 4:
                        default:
                            postfix = 'th';
                            break
                    }
                    result.name = `${result.name} inv ${result.inversion}${postfix}`;
                }
            }
        }
        // The result can be accessed through the `m`-variable.
        else {
            m.forEach((match, groupIndex) => {
                console.log(`Found match, group ${groupIndex}: ${match}`);
                switch (groupIndex) {
                    case 1:
                        result.key = match;
                        break;
                    case 2:
                        result.accidental = match || '';
                        if (match === 'b') {
                            result.accidental = flat;
                        }
                        else if (match === '#') {
                            result.accidental = sharp;
                        }

                        break;
                    case 3:
                        switch (match || '') {
                            case 'm':
                                result.type = 'minor';
                                break;
                            case 'M':
                                result.type = 'major';
                                break;
                            case 's':
                            case 'S':
                                result.type = 'suspended';
                                break;
                            case 'd':
                            case 'D':
                                result.type = 'diminished';
                                break;
                        }
                        break;
                    case 4:
                        result.chordDescription = match;
                        break;
                }
            });
        }
        if (result.key) {
            res.push(result);
        }
    }

    return res;
}


const minimum_notes_for_chord = 3;
export function CaptureChords(notes) {
    let result = [];

    let currentTick;
    let currentChord = [];
    notes = notes.sort((a, b) => a.ticks - b.ticks);
    for (let i = 0; i < notes.length; i++) {
        let note = notes[i];
        currentTick = note.ticks;
        currentChord = removeAllNotesNotSounding(currentChord, note);
        currentChord.push(note);
        currentChord = currentChord.sort((a, b) => (a.midi || a.pitch) - (b.midi || b.pitch))
        if (currentChord.length >= minimum_notes_for_chord) {
            let found_chords = queryChords(currentChord);
            if (found_chords && found_chords.length) {
                result = result.filter(x => x.tick !== note.ticks);
                result.push({
                    note: convertToMelodyNote(currentChord[0].midi || currentChord[0].pitch),
                    notes: currentChord.map(v => v.midi),
                    tick: note.ticks,
                    chords: found_chords
                })
            }
        }
    }

    return result;
}
function queryChords(chord) {
    let first = chord[0].midi;
    let relative_ = chord.map(v => v.midi - first);
    let v_temp = relative_.map(v_t => typeof (v_t) === 'string' ? parseInt(v_t, 12).toString(12) : (v_t % 12).toString(12));
    let temp = FindExactMatch(v_temp.sort((a, b) => a - b).map(v => v.toString()), []);
    return temp;
}
var reader = new ChordMaster.Reader({ skipRenderer: true });
function FindExactMatch(v, scales) {
    return reader.findMatches(v, scales, 0, 1000, {}, true);
}
function removeAllNotesNotSounding(chords, note) {
    let soundingNotes = chords.filter(x => notesOverlap(x, note));
    if (soundingNotes.length === chords.length) {
        return chords;
    }
    return soundingNotes;
}