import React from 'react';
import { convertToMelodyNote, findLetter } from '../../base/base-lib';
import ChordMaster, { calculateV } from '../../base/chord-master';
import ScaleNotes from './ScaleNotes';
import StaffNotes from './StaffNotes';


export default class OnDeckPalletteList extends React.Component {
    reader = null;
    constructor(props) {
        super(props);
    }
    render() {
        const { props } = this;
        const { value } = this.props;
        let sections = [];
        value.map((v, index) => {
            let component = <DeckPalletteListItem value={v} key={`deck-pallette-${index}`}
                onPlay={(payload) => {
                    if (props.onPlay) {
                        props.onPlay(payload);
                    }
                }} onStop={(payload) => {
                    if (props.onStop) {
                        props.onStop(payload);
                    }
                }}
                onAddToBank={(notes) => {
                    if (props.onAddToBank) {
                        props.onAddToBank(notes);
                    }
                }}
                bias={this.props.bias} />;
            sections.push(component);
        });

        return (
            <div className="timeline">
                {sections}
            </div>
        )
    }
}


class DeckPalletteListItem extends React.Component {
    render() {
        const { props } = this;
        const { value, bias } = this.props;
        const { scale, notes, midiNotes, baseNote, chord } = value;
        let noteLetter = convertToMelodyNote(baseNote, bias);
        let FIFTH_GAP = 7;
        let fifths = this.generateChords(midiNotes, FIFTH_GAP, bias, chord, props);
        let one = this.generateChords(midiNotes, 0, bias, chord, props, 2, 0);
        let msecond = this.generateChords(midiNotes, 1, bias, chord, props, 2, 0);
        let second = this.generateChords(midiNotes, 2, bias, chord, props, 2, 0);
        let mthird = this.generateChords(midiNotes, 3, bias, chord, props, 2, 0);
        let third = this.generateChords(midiNotes, 4, bias, chord, props, 2, 0);

        let fourth = this.generateChords(midiNotes, 5, bias, chord, props, 2, 0);
        let mfifth = this.generateChords(midiNotes, 6, bias, chord, props, 2, 0);
        let fifth = this.generateChords(midiNotes, 7, bias, chord, props, 2, 0);
        let msixth = this.generateChords(midiNotes, 8, bias, chord, props, 2, 0);
        let sixth = this.generateChords(midiNotes, 9, bias, chord, props, 2, 0);
        let mseventh = this.generateChords(midiNotes, 10, bias, chord, props, 2, 0);
        let seventh = this.generateChords(midiNotes, 11, bias, chord, props, 2, 0);
        return (
            <div>
                <i className="fas fa-music bg-blue"></i>
                <div className="timeline-item">
                    <span className="time"><i className="fas fa-clock"></i> {noteLetter.key}{noteLetter.accidental} {scale.info.official}</span>
                    <h3 className="timeline-header"><b href="#">{scale.info.official}</b></h3>

                    <div className="timeline-body">
                        <h5>Chord Notes</h5>
                        <div className="btn-group">
                            {(notes || []).map((note) => {
                                return <button type="button" className="btn btn-info" key={`${note}-note`}>{note}</button>
                            })}
                        </div>
                        <h5>Circle of Fifths</h5>
                        <div style={{
                            flex: 1, display: 'flex', flexDirection: 'row',
                            flexWrap: 'wrap'
                        }}>
                            {fifths}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>I</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row'
                                }}>
                                    {one}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>ii</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row'
                                }}>
                                    {msecond}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>II</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row'
                                }}>
                                    {second}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>iii</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}>
                                    {mthird}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>III</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}>
                                    {third}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>IV</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}>
                                    {fourth}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>v</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}>
                                    {mfifth}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>V</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}>
                                    {fifth}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>vi</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}>
                                    {msixth}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>VI</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}>
                                    {sixth}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>vii</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}>
                                    {mseventh}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5>VII</h5>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}>
                                    {seventh}
                                </div>
                            </div>
                        </div>
                        {/* <h5>Scale</h5>
                        <div style={{
                            flex: 1, display: 'flex', flexDirection: 'row',
                            flexWrap: 'wrap'
                        }}>
                            <ScaleNotes
                                onAddToBank={() => {
                                    if (props.onAddToBank) {
                                        props.onAddToBank({ notes: scale.midi, chord, noteLetter });
                                    }
                                }} title={`${noteLetter.key}${noteLetter.accidental} ${scale.info.official}`} bias={this.props.bias} value={scale.midi} />
                        </div> */}
                    </div>
                    <div className="timeline-footer">
                        {/* <a className="btn btn-primary btn-sm">Read more</a>
                        <a className="btn btn-danger btn-sm">Delete</a> */}
                    </div>
                </div>
            </div>
        );
    }

    generateChords(midiNotes, step, bias, chord, props, steps = 12, skip) {
        return new Array(steps).fill(1).map((_, v) => { return v }).map((fifth, index) => {
            if (skip === index) {
                return;
            }
            let fifthMidiNotes = {};
            let base_note = 10000;
            Object.keys(midiNotes).filter(v => midiNotes[v]).map(v => {
                v = parseInt(v);
                let i = v + (fifth * step);
                i -= (12 * Math.floor((fifth * step) / 14));
                fifthMidiNotes[i] = true;
                base_note = Math.min(base_note, i);
            });
            let noteLetter = convertToMelodyNote(base_note, bias);
            return <StaffNotes
                title={`${noteLetter.key}${noteLetter.accidental} ${chord.name}`}
                bias={this.props.bias}
                key={JSON.stringify(fifthMidiNotes)}
                onPlay={() => {
                    if (props.onPlay) {
                        props.onPlay({ value: fifthMidiNotes, play: true });
                    }
                }} onStop={() => {
                    if (props.onStop) {
                        props.onStop({ value: fifthMidiNotes, play: false });
                    }
                }}
                onAddToBank={() => {
                    if (props.onAddToBank) {
                        props.onAddToBank({ notes: fifthMidiNotes, chord, noteLetter });
                    }
                }}
                value={fifthMidiNotes} />;
        }).filter(x => x);
    }
}