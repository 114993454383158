let audioContext = null;
let unlocked = false;
let isPlaying = false;      // Are we currently playing?
let startTime;              // The start time of the entire sequence.
let current16thNote;        // What note is currently last scheduled?
let lookahead = 25.0;       // How frequently to call scheduling function 
//(in milliseconds)
let scheduleAheadTime = 0.1;    // How far ahead to schedule audio (sec)
// This is calculated from lookahead, and overlaps 
// with next interval (in case the timer is late)
let playStartTime = 0.0;
let nextNoteTime = 0.0;     // when the next note is due.
let nextNoteObject = null;
let noteResolution = 0;     // 0 == 16th, 1 == 8th, 2 == quarter note
let noteLength = 0.05;      // length of "beep" (in seconds)
let canvas,                 // the canvas element
    canvasContext;          // canvasContext is the canvas' context 2D
let last16thNoteDrawn = -1; // the last "box" we drew on the screen
let notesInQueue = [];      // the notes that have been put into the web audio,
// and may or may not have played yet. {note, time}
let timerWorker = null;     // The Web Worker used to fire timer messages
let noteQueue = []; // Notes added to the queue need to be added in order.

// First, let's shim the requestAnimationFrame API, with a setTimeout fallback
window.requestAnimFrame = (function () {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();

function nextNote() {
    let nextNote = noteQueue.shift();
    // Advance current note and time by a 16th note...
    if (nextNote) {
        nextNoteTime = nextNote.start;    // Add beat length to last beat time
        nextNoteObject = nextNote;
    }
    else {
        nextNoteTime = null;
        nextNoteObject = null;
    }
}

export function enqueueNote(notes) {
    noteQueue.push(...notes)
}

export function clearQueue() {
    noteQueue.length = 0;
}

function scheduleNote() {
    if (nextNoteObject) {
        let { note, start, duration, velocity } = nextNoteObject;
        let begin = start - audioContext.currentTime;
        let end = begin + duration;
        // push the note on the queue, even if we're not playing.

        playFunc({ note, time: begin, velocity, on: true });
        playFunc({ note, time: end, velocity, on: false });
    }
}

let playFunc = () => { };
export function setPlayFunction(_playFunc) {
    playFunc = _playFunc;
}

let cueNotes = () => { }
export function setCueNotesFunction(_cueNotes) {
    cueNotes = _cueNotes;
}

function scheduler() {
    if (!isPlaying) { return; }
    // while there are notes that will need to play before the next interval, 
    // schedule them and advance the pointer.
    enqueueNote(cueNotes(scheduleAheadTime * 2));
    if (nextNoteTime === null && noteQueue.length) {
        nextNote();
    }
    while (nextNoteTime !== null && nextNoteTime < audioContext.currentTime + scheduleAheadTime) {
        scheduleNote();
        nextNote();
    }
}

export function play(play_) {
    if (!unlocked) {
        init();
        // play silent buffer to unlock the audio
        let buffer = audioContext.createBuffer(1, 1, 22050);
        let node = audioContext.createBufferSource();
        node.buffer = buffer;
        node.start(0);
        unlocked = true;
    }

    isPlaying = play_;

    if (isPlaying) { // start playing
        current16thNote = 0;
        nextNoteTime = audioContext.currentTime;
        playStartTime = nextNoteTime;
        timerWorker.postMessage("start");
        return "stop";
    } else {
        timerWorker.postMessage("stop");
        return "play";
    }
}

export function record() {
    if (!unlocked) {
        // play silent buffer to unlock the audio
        let buffer = audioContext.createBuffer(1, 1, 22050);
        let node = audioContext.createBufferSource();
        node.buffer = buffer;
        node.start(0);
        unlocked = true;
    }
    playStartTime = audioContext.currentTime;
}
export function getAccurateNow() {
    init();
    return {
        time: audioContext.currentTime || 0,
        start: playStartTime || 0
    };
}

export function init() {
    if (audioContext) {
        return;
    }
    // NOTE: THIS RELIES ON THE MONKEYPATCH LIBRARY BEING LOADED FROM
    // Http://cwilso.github.io/AudioContext-MonkeyPatch/AudioContextMonkeyPatch.js
    // TO WORK ON CURRENT CHROME!!  But this means our code can be properly
    // spec-compliant, and work on Chrome, Safari and Firefox.

    audioContext = new AudioContext();



    timerWorker = new Worker("js/metronomeworker.js");

    timerWorker.onmessage = function (e) {
        if (e.data == "tick") {
            // console.log("tick!");
            scheduler();
        }
        else
            console.log("message: " + e.data);
    };
    timerWorker.postMessage({ "interval": lookahead });
}
