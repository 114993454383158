export function RadioButtonList(props) {
    let { options } = props;
    return (
        <div className="btn-group">
            {options.map((arg) => {
                let { name, value } = arg;
                let buttonStyle = props.getButtonStyle ? props.getButtonStyle(arg) : {};
                return (
                    <button
                        style={buttonStyle}
                        key={`rb-${value}`}
                        type="button" onClick={() => {
                            if (props.onSelect) {
                                props.onSelect(value);
                            }

                        }} className={` btn btn-${props.value === value ? 'success' : 'default'}`}>{name}</button>
                );
            })}
        </div>
    )
}