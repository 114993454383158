import {
    sharp,
    getrootoctave,
    createScaleLib,
    library,
    getrootbase,
    getaccidentalsvalue,
    findLetter,
    convertToMelody,
    containsType,
    fitNotesTo,
    converttoBase12,
    getKeyValue
} from './base-lib';
import { bosslist, parseXml } from './datasourcegenerator';
import { mster_coord_data } from './chord_data';

let selectedcleffchoice2 = "treble";

export const getScale = function (id) {
    for (let i in bosslist) {
        for (let j = bosslist[i].length; j--;) {
            if (bosslist[i][j].id == id) {
                return bosslist[i][j];
            }
        }
    }
}
export const getScaleByName = function (official) {
    for (let i in bosslist) {
        for (let j = bosslist[i].length; j--;) {
            if (bosslist[i][j].official == official) {
                return bosslist[i][j];
            }
        }
    }
}
export const calculateMidi = function (a) {
    return library[a.key + a.accidental] + a.octave * 12
}
export const calculateV = function (melodylist) {
    var sorted = melodylist.sort(function (a, b) {
        var val_a = library[a.key + a.accidental] + a.octave * 12;
        var val_b = library[b.key + b.accidental] + b.octave * 12;
        return val_a - val_b;
    });
    var result = ["00"];
    for (var i = 1; i < sorted.length; i++) {
        result.push(converttoBase12(getKeyValue(sorted[i]) - getKeyValue(sorted[0])));
    }

    return (result);
}

export const shouldShow = function (scales, _scales) {
    for (var i = scales.length; i--;) {
        for (var j = _scales.length; j--;) {
            if (scales[i] == getScaleName(_scales[j])) {
                return true;
            }
        }
    }
    return false;
}
export const getScaleName = function (id) {
    for (var i in bosslist) {
        for (var j = bosslist[i].length; j--;) {
            if (bosslist[i][j].id == id) {
                return bosslist[i][j].official;
            }
        }
    }
    return "[Unknown]";
}

export const getBaseRoot = function (root, single) {
    let value = parseInt(root, 12);
    let base_int = library[getrootbase() + getaccidentalsvalue()];
    let resut = (base_int - value) % 12;
    if (resut < 0) {
        resut += 12;
    }
    let results = "";
    for (let i in library) {
        if (library[i] == resut) {
            if (results == "")
                results += i;
            else {
                results += "/" + i;
            }
            if (single) {
                break;
            }
        }
    }
    return results;
}

const Vex = window.Vex;

export default class ChordMaster {
}
window.ChordMaster = ChordMaster;
const READ_ROWS = [];
ChordMaster.Reader = function (options = {}) {
    this._readRows = READ_ROWS;
    this._scales = [];
    this._chordVoiceTypes = [];
    this._oncompletes = [];
    //this._canvas = $("#staffnotes")[0];
    //this._canvas.width = $(window).width();
    if (!options.skipRenderer) {
        this._chordstaffcanvas = new Vex.Drawer("staffnotes");
    }
    //this._renderer = new Vex.Flow.Renderer(this._canvas, Vex.Flow.Renderer.Backends.CANVAS);
    this.bias = sharp;
    this.parseChordXml();
}
ChordMaster.Reader.prototype = {
    raiseComplete: function () {
        for (let i = 0; i < this._oncompletes.length; i++) {
            this._oncompletes[i]();
        }
    },
    get_lastChords: function () {
        return this._lastChoords;
    },
    add_oncomplete: function (func) {
        this._oncompletes.push(func);
    },
    get_scalemidinotes: function () {
        if (this._scalemidinotes == undefined) {
            return [];
        }
        return this._scalemidinotes;
    },
    get_chordmidinotes: function () {
        if (this._chordmidinotes == undefined) {
            return [];
        }
        return this._chordmidinotes;
    },
    renderNotes: function (_chords) {
        let chords = _chords;
        if (chords == undefined) {
            chords = this._lastChoords;
            if (this._lastChoords == undefined) {
                return;
            }
        }
        else {
            this._lastChoords = chords;
        }
        //let renderer = this._renderer;
        //let ctx = renderer.getContext();
        //ctx.clearRect(0, 0, this._canvas.width, this._canvas.height)
        //let stave = new Vex.Flow.Stave(0, 0, 350);
        //stave.addClef(selectedcleffchoice2);
        //stave.setContext(ctx).draw();

        let scalelib = createScaleLib();
        let baseroot = (library[getrootbase() + getaccidentalsvalue()]);
        let _d = getrootbase() + getaccidentalsvalue(); // getBaseRoot(chords._info.col2.data);
        let voicearray = chords._info.col0.voice._parseVoice;
        let chord_scale_info = chords._info.col3.val();
        let notes = [_d];
        let notes_copy = [_d];
        let octave_offset = [getrootoctave() - 4];
        for (let i = 1; i < voicearray.length; i++) {
            let note = findLetter(convertToMelody(baseroot, parseInt(voicearray[i])));
            notes.push(note);
            notes_copy.push(note);
            octave_offset.push(octave_offset[0] + Math.floor((baseroot + parseInt(voicearray[i])) / 12));
        }
        let currentscale = getScale(chords.selectedScale);
        let scale_ = currentscale.base12;//chords._info.col3._val.convertToMetric();
        let base7 = currentscale.base7.split(" ").removeEmpties();
        let scale_start = getBaseRoot(currentscale.root.toString(12).toUpperCase(), true);;
        let scale_notes = [];
        let scale_notes_copy = [];
        let scale_octave_offset = null;
        let scaleprintnotes = [];
        let scalemidinotes = [];
        for (let i = 0; i < scale_.length; i++) {
            scale_notes.push(findLetter(convertToMelody(library[scale_start], parseInt(scale_[i])), this.bias, base7[i]));
            scale_notes_copy.push(findLetter(convertToMelody(library[scale_start], parseInt(scale_[i])), this.bias, base7[i]));
            if (scale_octave_offset == null) {
                scale_octave_offset = [0];
            }
            else {
                scale_octave_offset.push(Math.floor((library[scale_start] + parseInt(scale_[i])) / 12));
            }
            let base_offset = 4;
            switch (selectedcleffchoice2) {
                case "alto":
                    base_offset = 4;
                    break;
                case "bass":
                    base_offset = 2;
                    break;
                case "tenor":
                    base_offset = 4;
            }
            scaleprintnotes.push(scale_notes[i] + '/' + ((scale_octave_offset[i] + base_offset)));
            scalemidinotes.push(library[scale_notes[i]] + ((parseInt(((scale_octave_offset[i] + base_offset))) + 1)) * 12);
        }
        this._scalemidinotes = scalemidinotes;
        notes = fitNotesTo(notes, scale_notes);
        notes_copy = fitNotesTo(notes, scale_notes);

        let chordnotelist = [];
        let chordmidinotes = [];
        for (let i = 0; i < notes.length; i++) {
            chordnotelist.push(notes[i] + "/" + (octave_offset[i] + 4));
            chordmidinotes.push(library[notes[i]] + (octave_offset[i] + 4 + 1) * 12);
        }
        this._chordmidinotes = chordmidinotes;
        let generateCodeInside = function (chords, scale, clef) {
            let vextab_code = "tabstave notation=true  tablature=false clef=" + clef + " \r\n" +
                "notes ;1 :w (";
            for (let i = 0; i < chords.length; i++) {
                vextab_code += chords[i];
                if (i != chords.length - 1) {
                    vextab_code += ".";
                }
            }
            vextab_code += ")";
            vextab_code += " \r\n tabstave notation=true  tablature=false clef=" + clef + " \r\n";
            vextab_code += "notes ;1  :8d [ ";
            for (let i = 0; i < scale.length; i++) {
                vextab_code += scale[i];
                if (i != scale.length - 1) {
                    vextab_code += " ";
                }
            }
            vextab_code += " ]  \r\n";
            return vextab_code;
        }
        let gencode = generateCodeInside(chordnotelist, scaleprintnotes, selectedcleffchoice2);
        this._chordstaffcanvas.draw(gencode);
        //let _notes = notes.toLower().replace(sharp, "#").replace(flat, "b");
        //scale_notes = scale_notes.toLower().replace(sharp, "#").replace(flat, "b");
        //let shownotes = []; 
        //let scale_run = [];
        //let up = false;
        //let count = 0;
        //let in4 = true;
        //let linehit = false;

    },
    getRows: function (chordDict) {
        let items = { ...chordDict };
        return this._readRows.filter(v => items[v._info.id]);
    },
    findMatches: function (v, scales, page, pagesize, current_matches = {}, exact = false) {
        if (pagesize == undefined) {
            pagesize = 10;
        }
        let matches = [];
        for (let i = this._readRows.length; i--;) {
            if (this._readRows[i].get("col0").success && this._readRows[i].get("col0").voice.matches(v, exact)) {
                if (scales.length == 0 || scales.indexOf(this._readRows[i]._info.col3._name) != -1 || shouldShow(scales, this._readRows[i]._info.col3._scales)) {
                    matches.push(this._readRows[i]);
                }
                else if (current_matches[this._readRows[i]._info.id]) {
                    matches.push(this._readRows[i]);
                }
            }
            else if (this._readRows[i] && this._readRows[i]._info && current_matches[this._readRows[i]._info.id]) {
                matches.push(this._readRows[i]);
            }

        }
        let result = [];
        for (let i = page * pagesize; i < (page + 1) * pagesize; i++) {
            if (i >= 0 && matches.length > i) {
                result.push(matches[i]);
            }
        }
        return result;
    },
    convertToJSON: function (xml) {
        // let rows = $(xml.xml).find("Row");
        // let result = { rows: [] }
        // for (let i = 0; i < rows.length; i++) {
        //     let cells = $(rows[i]).find("Cell");
        //     let row = [];
        //     for (let j = 0; j < cells.length; j++) {
        //         row.push(cells[j].innerText);
        //     }
        //     result.rows.push(row);

        // }
        // let _result = JSON.stringify(result);
    },
    parseChordXml: function (xml) {
        //find every Tutorial and print the author
        let rows = mster_coord_data.rows;

        let info = parseXml();
        let chunk = info;
        let chunkalator = (function () {
            for (let i = chunk.length; i--;) {
                this.parseRow(i, rows[0], chunk[i]);
            }
            // if (info.length > 0) {
            //     chunk = info.splice(0, 20);
            //     setTimeout(chunkalator, 50);
            // }
            // else
            this.raiseComplete();
        }).bind(this);

        for (let i in bosslist) {
            this._scales.push(bosslist[i][0].official);
        }
        if (READ_ROWS.length === 0)
            chunkalator();


    },
    getScales: function () {
        return this._scales;
    },
    parseRow: function (index, body, newbody) {
        let rowinfo = new ChordMaster.RowInfo();
        let _scaleinfo = bosslist;
        rowinfo.smartinfo = newbody;
        for (let e = 0; e < body.length; e++) {
            let content = body[e];
            switch (e) {
                case 0:
                    rowinfo.set("col0", ChordMaster.ParseVoice(content, newbody));
                    break;
                case 1:
                    rowinfo.set("col1", ChordMaster.ParseChordNames(content, newbody));
                    break;
                case 2:
                    rowinfo.set("col2", ChordMaster.ParseCoordAt(content, newbody));
                    break;
                case 3:
                    rowinfo.set("col3", ChordMaster.ParseScaleInfo(content, newbody));
                    break;
                case 4:
                    rowinfo.set("col4", ChordMaster.ParseConnectionData(content, newbody));
                    break;
                case 5:
                    rowinfo.set("col5", ChordMaster.ParseConnectionData(content, newbody));
                    break;
                case 6:
                    rowinfo.set("col6", ChordMaster.ParseConnectionData(content, newbody));
                    break;
                case 7:
                    rowinfo.set("col7", ChordMaster.ParseConnectionData(content, newbody));
                    break;
            }
        }
        this._readRows.push(rowinfo);
        //let info = rowinfo.get("col3");
        //if (this._scales.indexOf(info.scaleName()) == -1) {
        //    this._scales.push(info.scaleName());
        //}
        let voiceinfo = rowinfo.get("col0");
        if (!containsType(this._chordVoiceTypes, voiceinfo.voice)) {
            this._chordVoiceTypes.push(voiceinfo);
        }
    }
};
ChordMaster.ParseScaleInfo = function (data, newdata) {
    if (newdata == undefined) {
        let scale = "scale";
        let Scale = "Scale";
        let Tone = "Tone";
        let one = "1";
        let length = 4;
        let index = data.indexOf(scale);
        index = index == -1 ? data.indexOf(Scale) : index;
        index = index == -1 ? data.indexOf(Tone) : index;
        let addone = 1;
        if (index == -1) {
            length = 1;
            addone = -1;
        }
        index = index == -1 ? data.indexOf(one) : index;
        if (index == -1) {
            return data;
        }
        let scalenamem = data.substring(0, index);
        let _scale = data.substring(index + length + addone, data.length);
        let s = new ChordMaster.Scale(scalenamem);
        s.val(_scale);
        return s;
    }
    else {
        let s = new ChordMaster.Scale();
        s.setScales(newdata.scales);
        return s;
    }

};
ChordMaster.ParseVoice = function (data, moredata) {
    let voice = new ChordMaster.Voice(data, moredata);
    return { success: true, voice: voice };
};
ChordMaster.Scale = function (name) {
    if (name) {
        this._val = null;
        this._name = name.trim();
    }

}
ChordMaster.Scale.prototype = {
    setScales: function (value) {
        this._scales = value;
    },
    val: function (value) {
        if (value == undefined) {
            return this._val;
        }
        if (Object.prototype.toString.call(value) === '[object Array]') {
            this._val = value;
        }
        else {
            this._val = value.split(" ").clean("");
        }
    },
    scaleName: function () {
        return this._name;
    }
};
ChordMaster.Voice = function (voice, betterdata) {
    if (betterdata == undefined) {
        this._voice = voice;
        this.parse();
    }
    else {
        this._voice = betterdata.voice;//.convertToVVoice();
        this._parseVoice = betterdata.voice;
    }
}
ChordMaster.Voice.prototype = {
    parse: function () {
        this._parseVoice = this._voice.split("").clean(" ");
    },
    matches: function (array, exact = false) {
        if (exact && array.length !== this._parseVoice.length) {
            return false;
        }
        let lastj = 0;
        let found_i = false;
        let j;
        for (let i = 0; i < array.length; i++) {
            for (j = lastj; j < this._parseVoice.length; j++) {
                if (parseInt(array[i].toLowerCase(), 12) == (this._parseVoice[j])) {
                    found_i = true;
                    break;
                }
            }
            j = lastj
            if (found_i == false) {
                return false;
            }
            found_i = false;
        }
        return true;
    },
    equals: function (that) {
        return this._voice == that._voice;
    }
};

ChordMaster.ParseChordNames = function (data, newdata) {
    let or = "or";
    let names = data.split(or);
    if (newdata != undefined) {
        let names = newdata._familyname.split(or);
        if (newdata.isInversion) {
            for (let i = names.length; i--;) {
                names[i] = names[i] + "".nth(newdata.inversion);
            }
        }
    }
    return {
        success: true,
        data: ChordMaster.ParseChordName(names)
    };
};
ChordMaster.ParseChordName = function (data) {
    return data;
}
ChordMaster.ParseCoordAt = function (data, newdata) {
    let phrase = "Chord Root at ";
    let phrase3 = " Chord Root at ";
    let phrase2 = " of";
    let index = data.indexOf(phrase3);
    index = index == -1 ? data.indexOf(phrase) : index;
    let index2 = data.indexOf(phrase2);
    if (index == -1 || index2 == -1 || index2 < (index + phrase.length)) {
        let _d = data.substring(index + phrase.length, data.length).trim();
        _d = _d == "11" ? "0B" : _d;
        _d = _d == "10" ? "0A" : _d;
        return {
            success: true,
            orgdata: data,
            data: _d
        }
    }
    else {
        let _d = data.substring(index + phrase.length, index2);
        _d = _d == "11" ? "0B" : _d;
        _d = _d == "10" ? "0A" : _d;
        return {
            success: true,
            orgdata: data,
            data: _d
        }
    }
};

ChordMaster.ParseConnectionData = function (data, newdata) {
    let phrase = "Chord Root at ";
    let phrase2 = " of";
    let index = data.indexOf(phrase);
    let result = [];
    let index2 = data.indexOf(phrase2);
    if (index == -1 || index2 == -1 || index2 < (index + phrase.length)) {
        return data;
    }
    else {
        let modified = data.substring(0, index);
        let array = modified.split(",").trim().clean("").clean(" ").clean();
        for (let i = array.length; i--;) {
            let voice_chord = ChordMaster.ParseVoiceOrChord(array[i], newdata);
            result.push(voice_chord);
        }

    }
    return result;
}

ChordMaster.ParseVoiceOrChord = function (data, newdata) {
    if (data.substring(0, 1) == "V") {
        return { type: "voice", data: ChordMaster.ParseVoice(data, newdata) };
    }
    return { type: "chord", data: ChordMaster.ParseChordNames(data, newdata) };
}

ChordMaster.RowInfo = function () {
    ChordMaster.RowInfo.id++;
    this._info = { id: ChordMaster.RowInfo.id };
}
ChordMaster.RowInfo.id = 0;
ChordMaster.RowInfo.prototype = {
    set: function (type, value) {
        if (value != "")
            this._info[type] = value;
    },
    get: function (type) {
        return this._info[type];
    }
}
ChordMaster.CellInfo = function (type) {
    this._type = type;
    this._value = null;
}
ChordMaster.CellInfo.prototype = {
    value: function (val) {
        if (val == undefined) {
            return this._value;
        }
        this._value = val;
    }
}
