import React from 'react';

export default class NavbarButton extends React.Component {
    render() {

        return (
            <li className="nav-item">
                <a onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick();
                    }
                }} href="#" className="nav-link">{this.props.title || 'Unknown'}</a>
            </li>
        );
    }
}