import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uuidv4 } from '../../base/base-lib';

import Panel from '../../components/panel/Panel';
import {
    addInstrument, getCurrentScene, getCurrentSelectedInstrumentIndex,
    updateSceneMeasureLength,
    setQuantizeMode,
    setQuantizeDurationMode,
    getQuantizeMode,
    toggleProjectPlay,
    getIsPlaying,
    updateTempo,
    getInstruments,
    getTempo,
    selectCurrentMidiCommand,
    setCurrentInstrument,
    getIsRecording,
    updateCurrentSceneName,
    getSceneLength,
    toggleRecord,
    updateChordProgressionBreaks,
    addProgressionBreak,
    applyNextBankToChordProgression,
    getPlayPercentage,
    clearRecording,
    QuantiesOptions,
    getQuantizeDurationMode,
    getSelectedChordProgressionBreak,
    selectNextBank,
    getSelectedChordProgressionGap,
    setSelectedChordProgressionGap,
    setSelectedChordProgressionType,
    ChordProgressionMode,
    setGapBank
} from '../../features/composer/composerSlice';
import MidiNotes from '../../features/composer/MidiNotes';
import { isOn } from '../../features/composer/MidiNotes';
import { FormGroup } from '../FormGroup';
import { RadioButtonList } from '../RadioButtonList';
import { Slider } from '../Slider';
import NoteType from './NoteType';
const _padding = 4;
export default function Scene() {
    const notes = useSelector(selectCurrentMidiCommand);
    const scene = useSelector(getCurrentScene);
    const chordProgression = scene ? scene.chordProgression : null;
    const currentSelectedInstrument = useSelector(getCurrentSelectedInstrumentIndex);
    const instruments = useSelector(getInstruments);
    const isPlaying = useSelector(getIsPlaying);
    const dispatch = useDispatch();
    const tempo = useSelector(getTempo);
    const sceneLength = useSelector(getSceneLength);
    const recording = useSelector(getIsRecording);
    const quantizeMode = useSelector(getQuantizeMode);
    const quantizeDurationMode = useSelector(getQuantizeDurationMode);
    const selectedBreak = useSelector(getSelectedChordProgressionBreak);
    const nextBank = useSelector(selectNextBank);
    const selectedChordProgressionGap = useSelector(getSelectedChordProgressionGap);

    return (
        <Panel stretch title={'Scene'}>
            <div style={{ display: 'flex', padding: 10, height: '100%', flex: 1, flexDirection: 'column' }}>
                <div style={{ display: 'flex', flex: 1, flexGrow: 0 }}>
                    {scene ? <div className="row">
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', height: 140, alignItems: 'center', flexWrap: 'wrap' }}>
                            <FormGroup>
                                <label>Instrument</label>
                                <div>

                                    <button type="button" onClick={() => {
                                        dispatch(addInstrument())
                                    }} className="btn btn-default">
                                        <i className="fas fa-plus"></i>  </button>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>Play</label>
                                <div>

                                    <button type="button" onClick={() => {
                                        dispatch(toggleProjectPlay())
                                    }} className="btn btn-default">
                                        <i className={isPlaying ? "fas fa-stop" : "fas fa-play"}></i>  </button>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>Record</label>
                                <div>

                                    <button type="button" onClick={() => {
                                        dispatch(toggleRecord())
                                    }} className="btn btn-default">
                                        <i className="fas fa-circle" style={{ color: recording ? 'red' : '' }}></i>  </button>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>Name</label>
                                <input onChange={(e) => {
                                    dispatch(updateCurrentSceneName(e.currentTarget.value));
                                }} type="text" value={scene ? scene.name || '' : ''} className="form-control" placeholder="Enter ..." />
                            </FormGroup>
                            <FormGroup>
                                <label>Tempo</label>
                                <input onChange={(e) => {
                                    dispatch(updateTempo(parseInt(e.currentTarget.value)));
                                }} type="text" value={tempo} className="form-control" placeholder="Enter ..." />
                            </FormGroup>
                            <FormGroup>
                                <label>Measure Count</label>
                                <div>
                                    <RadioButtonList options={[1, 2, 4, 8].map((v) => ({ name: v, value: v }))} value={sceneLength} onSelect={(e) => {
                                        dispatch(updateSceneMeasureLength(parseInt(e)));
                                    }} />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>Quantize</label>
                                <div>
                                    <RadioButtonList options={QuantiesOptions} value={quantizeMode} onSelect={(e) => {
                                        dispatch(setQuantizeMode((e)));
                                    }} />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>Quantize Duration</label>
                                <div>
                                    <RadioButtonList options={QuantiesOptions} value={quantizeDurationMode} onSelect={(e) => {
                                        dispatch(setQuantizeDurationMode((e)));
                                    }} />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>Clear</label>
                                <div>

                                    <button type="button" onClick={() => {
                                        dispatch(clearRecording())
                                    }} className="btn btn-default">
                                        <i className="fas fa-circle" style={{ color: recording ? 'red' : '' }}></i>  </button>
                                </div>
                            </FormGroup>
                        </div>
                    </div> : null}
                </div>
                {chordProgression ? (
                    <div style={{ display: 'flex', flex: 1, flexGrow: 0 }}>
                        <div style={{ display: 'flex', flex: '1', paddingLeft: 137.0625, paddingRight: 21 }}>
                            <div style={{ display: 'flex', flex: 1, height: 100 }}>
                                <Slider value={chordProgression.breaks} onChange={(breaks) => {
                                    dispatch(updateChordProgressionBreaks(breaks))
                                }} measureCount={sceneLength} title={'Chord Progression'} menu={<ChordProgressionMenu
                                    nextBank={nextBank}
                                    selectedChordProgressionGap={selectedChordProgressionGap}
                                    onApplyNextBankToChordProgression={(arg) => {
                                        dispatch(applyNextBankToChordProgression(arg));
                                    }}
                                    onSelectChordProgression={(arg) => {
                                        dispatch(setSelectedChordProgressionGap(arg))
                                    }}
                                    onSelectChordProgressionType={(arg) => {
                                        dispatch(setSelectedChordProgressionType(arg))
                                    }}
                                    onAddProgressionBreak={() => {
                                        dispatch(addProgressionBreak())
                                    }} chordProgression={chordProgression} selectedBreak={selectedBreak} />} />
                            </div>
                        </div>
                    </div>) : null}
                <div style={{ display: 'flex', flex: 1, flexGrow: 0 }}>
                    <div style={{ display: 'flex', flex: 1, paddingLeft: 137.0625, paddingRight: 21 }}>
                        <MusicTimerBar />
                    </div>
                </div>
                {scene && scene.tracks ? scene.tracks.map((track, index) => {
                    return <MidiInstrument
                        sceneLength={sceneLength}
                        onBodyDrop={(arg) => {
                            let { ev, gap } = arg;
                            const data = ev.dataTransfer.getData("text/plain");
                            dispatch(setGapBank({ segment: gap, bank: JSON.parse(data) }))
                        }}
                        key={`midi-instrument-${index}`}
                        onClick={() => {
                            if (instruments[index]) {
                                dispatch(setCurrentInstrument(instruments[index].id))
                            }
                        }}
                        chordProgression={chordProgression}
                        track={track}
                        notes={notes}
                        selected={index === currentSelectedInstrument} />
                }) : null}
            </div>
        </Panel>
    );
}

class ChordProgressionMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let progressionBreak = this.props.chordProgression && this.props.chordProgression.gaps ? this.props.chordProgression.gaps.find(v => v.name === this.props.selectedChordProgressionGap) : null;
        let chordName = '';
        if (progressionBreak) {
            let { bank } = progressionBreak;
            if (bank) {
                let { chord, noteLetter } = bank;
                if (chord && noteLetter) {
                    chordName = <NoteType title={`${chord.userMade ? '' : noteLetter.key}${chord.userMade ? '' : noteLetter.accidental} ${chord.name}`} />;
                }
            }
        }
        return (
            <span>
                <div className="row">
                    <div style={{
                        position: 'absolute',
                        top: -51,
                        left: 246,
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        height: 140,
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}>
                        <FormGroup>
                            <div>
                                <RadioButtonList

                                    onSelect={(val) => {
                                        if (val === 1) {
                                            if (this.props.onAddProgressionBreak) {
                                                this.props.onAddProgressionBreak();
                                            }
                                        }
                                    }} options={[{
                                        name: <i className="fas fa-plus" style={{
                                            fontFamily: 'Font Awesome 5 Free'
                                        }} />, value: 1
                                    }, {
                                        name: <i className="fas fa-minus" style={{
                                            fontFamily: 'Font Awesome 5 Free'
                                        }} />,
                                        value: 2
                                    }]} value={null} />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div>
                                <RadioButtonList
                                    getButtonStyle={arg => {
                                        if (arg) {
                                            if (this.props.nextBank) {
                                                return {
                                                    backgroundColor: 'gold'
                                                }
                                            }
                                            if (arg.value === this.props.selectedChordProgressionGap) {
                                                return {
                                                    backgroundColor: 'var(--teal)'
                                                }
                                            }
                                        }
                                        return {}
                                    }}
                                    options={this.props.chordProgression ? this.props.chordProgression.gaps : []}
                                    value={this.props.selectedBreak}
                                    onSelect={(e) => {
                                        if (this.props.nextBank) {
                                            if (this.props.onApplyNextBankToChordProgression) {
                                                this.props.onApplyNextBankToChordProgression({ segment: e })
                                            }
                                        }
                                        else if (this.props.onSelectChordProgression) {
                                            this.props.onSelectChordProgression(e);
                                        }
                                    }} />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div>
                                <RadioButtonList

                                    options={ChordProgressionMode}
                                    value={progressionBreak ? progressionBreak.type : ''}
                                    onSelect={(e) => {
                                        if (this.props.onSelectChordProgressionType) {
                                            this.props.onSelectChordProgressionType(e);
                                        }
                                    }} />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <h3>{chordName}</h3>
                        </FormGroup>
                    </div>
                </div>
            </span >
        )
    }
}

class MusicTimerBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { id: `progress-bar-${uuidv4()}`.split('').filter(x => 'progressbar012346578'.indexOf(x) !== -1).join(''), value: 0 };
        this.instanceState = {};
    }
    shouldComponentUpdate(nextProps) {
        this.instanceState.playing = nextProps;
        return false;
    }
    componentDidMount() {
        console.log('music timer bar did mount');
        let updateFrame = () => {
            // this.setState({ value: getPlayPercentage() })
            this.progressBar = this.progressBar || document.querySelector(`#${this.state.id} .progress-bar`);
            if (this.progressBar) {
                this.progressBar.style.width = `${getPlayPercentage()}%`
            }
            requestAnimationFrame(updateFrame)
        };
        requestAnimationFrame(updateFrame)
    }
    render() {
        return (
            <div className="progress" id={this.state.id} style={{ width: '100%' }}>
                <div className="progress-bar bg-primary progress-bar-striped"
                    role="progressbar"
                    aria-valuenow="40"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: this.state.value + '%', transition: 'width 0s linear' }}>
                    <span className="sr-only">{this.state.value}%</span>
                </div>
            </div>
        )
    }
}
class MidiInstrument extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.midiNotesContainerRef = React.createRef();
    }
    componentDidUpdate() {
        let midiNoteContainer = this.midiNotesContainerRef;
        if (midiNoteContainer && midiNoteContainer.current) {
            requestAnimationFrame(() => {
                let trackNotes = 0;
                let count = 0;
                if (this.props.track) {

                    (this.props.track.notes || []).map(t => {
                        trackNotes += 128 - t.note;
                        count++;
                    });
                }
                for (let i = 0; i < 128; i++) {
                    if (this.props.notes && this.props.notes[i] && isOn(this.props.notes[i].command)) {
                        trackNotes += 128 - i;
                        count++;
                    }
                }
                if (count) {
                    let rect = midiNoteContainer.current.getBoundingClientRect();
                    let top = midiNoteContainer.current.scrollTop;
                    let targetTop = ((trackNotes / count) * 26) - (rect.height / 2);
                    if (!(top < targetTop && targetTop > (top + rect.height))) {
                        midiNoteContainer.current.scrollTo({
                            top: targetTop,
                            behavior: 'smooth'
                        });
                    }
                }
            });
        }
    }
    render() {
        let { track, selected, notes, sceneLength } = this.props;
        return (<div style={{ display: 'flex', ...(selected ? { height: '100%', flex: 1 } : {}), flexDirection: 'column', overflow: 'auto' }}>
            <div style={{ display: 'flex', flex: 1, flexGrow: 1, cursor: 'pointer' }} onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick()
                }
            }}>
                Piano
            </div>
            {selected ? (<div style={{ overflowY: 'scroll' }} ref={this.midiNotesContainerRef}>
                <MidiNotes
                    selected={selected}
                    notes={notes}
                    onBodyDrop={(arg) => {
                        if (this.props.onBodyDrop) {
                            this.props.onBodyDrop(arg);
                        }
                    }}
                    chordProgression={this.props.chordProgression}
                    sceneLength={sceneLength} track={track} />
            </div>) : null}
        </div>)
    }
}