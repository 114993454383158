import { Midi } from '@tonejs/midi';
import MidiWriter from 'midi-writer-js';
import { TrackProperties } from '../features/composer/composerSlice';
import { isOn } from '../features/composer/MidiNotes';
import { MidiInstrumentData } from './instrument-data/instruments';
import { getModifierNotation, getOverlapAmount, isOverlapping, MODIFIER_VIEW_DIVISIONS } from './modifier-service';

export function MidiWriterDefault() {
    // Start with a new track
    var track = new MidiWriter.Track();

    // Define an instrument (optional):
    track.addEvent(new MidiWriter.ProgramChangeEvent({ instrument: 1 }));

    // Add some notes:
    var note = new MidiWriter.NoteEvent({ pitch: ['C4', 'D4', 'E4'], duration: '4' });
    track.addEvent(note);

    // Generate a data URI
    var write = new MidiWriter.Writer(track);
    console.log(write.dataUri());
}

export function convertToMilliseconds(BPM, PPQ, ticks) {
    return (60000 / (BPM * PPQ)) * ticks
}
export function getDuration(trackItem, options = { measureDivisions: 64, measures: 8 }) {
    let total_division_length = (options.measureDivisions * options.measures);
    return `T${Math.round((trackItem.position.w / total_division_length) * (128 * options.measures * 4))}`;
}
export function getDurationNum(trackItem, options = { measureDivisions: 64, measures: 8 }) {
    let total_division_length = (options.measureDivisions * options.measures);
    return ((trackItem.position.w / total_division_length) * (128 * options.measures * 4));
}
export function getStartTick(trackItem, options = { measureDivisions: 64, measures: 8 }) {
    let total_division_length = (options.measureDivisions * options.measures);
    return Math.round((trackItem.position.x / total_division_length) * (128 * options.measures * 4));
}
export function BuildSeratoMidiChordProgression(chordProgression, options = { measureDivisions: 64, measures: 8 }) {
    options.measures = chordProgression.measures;
    let midiFileCount = Math.ceil(options.measures / 8);
    let files = [];
    for (let i = 0; i < midiFileCount; i++) {
        let file = BuildMidiChordProgression(chordProgression, {
            ...options,
            start: (options.measureDivisions) * 8 * i,
            stop: (options.measureDivisions) * 8 * (i + 1)
        });
        files.push(file);
    }
    return files;
}
function getNoteLickDuration(trackItem, options = { measureDivisions: 64, measures: 8 }) {
    let total_division_length = (options.measureDivisions * options.measures);
    return `T${Math.round((trackItem.duration / total_division_length) * (128 * options.measures * 4))}`;
}
function getNoteLickStartTick(trackItem, options = { measureDivisions: 64, measures: 8 }) {
    let total_division_length = (options.measureDivisions * options.measures);
    return Math.round((trackItem.time / total_division_length) * (128 * options.measures * 4));
}
export function BuildMidiModifierLick(notes, options = { start: 0, measureDivisions: MODIFIER_VIEW_DIVISIONS, measures: 1 }) {
    var midi_track = new MidiWriter.Track();
    notes.filter(x => !x.rest).map((_note) => {
        let duration = getNoteLickDuration(_note, options);
        let startTick = getNoteLickStartTick(_note, options);
        let v = _note.midi;
        let note = new MidiWriter.NoteEvent({
            duration,
            pitch: [v],
            startTick: startTick - (options.start * ((8)))
        })
        midi_track.addEvent(note);
    })
    var write = new MidiWriter.Writer([midi_track]);
    return write.buildFile();
}
export function BuildMidiChordProgression(chordProgression, options = {
    lickDic: null,
    measureDivisions: 64,
    measures: 8,
    start: null,
    tempo: 128,
    modifiers: null,
    stop: null
}) {

    let { spaces } = chordProgression;
    options.measures = chordProgression.measures;
    let spaceTracks = spaces.filter(x => !x.id.startsWith('measure')).groupBy((x) => x.position.y);
    let tracks = Object.keys(spaceTracks).map((track_key, index) => {
        if (parseInt(track_key) % 2 === 0) {
            return null;
        }
        let track_key_index = Math.ceil(parseInt(track_key) / 2)
        var midi_track = new MidiWriter.Track();
        if (chordProgression.tracks && chordProgression.tracks[track_key_index]) {
            let instrument = chordProgression.tracks[track_key_index][TrackProperties.INSTRUMENT];
            if (instrument) {
                let temp = MidiInstrumentData.find(x => x.hexcode === instrument)
                if (temp) {
                    midi_track.addInstrumentName(temp.instrument);
                    midi_track.addEvent(new MidiWriter.ProgramChangeEvent({ instrument: parseInt(temp.hexcode) }));
                }
            }
        }
        let track = spaceTracks[track_key].sort((a, b) => {
            return a.position.x - b.position.x;
        });
        let modifierTrack = spaceTracks[parseInt(track_key) + 1];
        track.map((trackItem, index) => {
            let overlappingModifiers = modifierTrack ? modifierTrack.filter(x => isOverlapping(x, trackItem)) : [];
            let sortedOverlappingModifiers = overlappingModifiers.sort((b, a) => {
                return getOverlapAmount(a, trackItem) - getOverlapAmount(b, trackItem);
            });
            let modifier = sortedOverlappingModifiers[0];
            if (modifier) {
                let default_notes = Object.keys(trackItem.chordData.notes || { ...trackItem.chordData.values, ...trackItem.chordData.value_voicing }).map(t => {
                    return {
                        midi: parseInt(t),
                        time: 0,
                        duration: null
                    }
                })
                let startTick = getStartTick(trackItem, options);
                let duration = getDurationNum(trackItem, options);
                let modifierNotes = getModifierNotation(options.modifiers[modifier.modifierId], null, default_notes, options.lickDic);
                modifierNotes.map((modifiedNote) => {
                    let {
                        time,
                        midi,
                        velocity = 75
                    } = modifiedNote;
                    if (chordProgression.tracks && chordProgression.tracks[track_key_index]) {
                        let volume = chordProgression.tracks[track_key_index][TrackProperties.VOLUME];
                        if (!isNaN(volume)) {
                            velocity = parseInt(volume);
                        }
                    }
                    let startPercentage = time / MODIFIER_VIEW_DIVISIONS;
                    let durationPercentage = modifiedNote.duration / MODIFIER_VIEW_DIVISIONS;
                    let note = new MidiWriter.NoteEvent({
                        duration: `T${Math.round(durationPercentage * duration)}`,
                        startTick: startTick + (startPercentage * duration),
                        pitch: [midi],
                        velocity
                    });
                    midi_track.addEvent(note);
                })
            }
            else {
                let velocity = 75;
                if (chordProgression.tracks && chordProgression.tracks[track_key_index]) {
                    let volume = chordProgression.tracks[track_key_index][TrackProperties.VOLUME];
                    if (!isNaN(volume)) {
                        velocity = parseInt(volume);
                    }
                }
                Object.keys(trackItem.chordData.notes || { ...trackItem.chordData.values, ...trackItem.chordData.value_voicing }).map(v => {
                    if (options.start !== null && options.stop !== null) {
                        let startTick = getStartTick(trackItem, options);
                        let duration = getDuration(trackItem, options);
                        if (options.start <= trackItem.position.x && trackItem.position.x <= options.stop) {


                            let note = new MidiWriter.NoteEvent({
                                duration,
                                pitch: [v],
                                startTick: startTick - (options.start * ((8))),
                                velocity
                            })
                            midi_track.addEvent(note);
                        }
                    }
                    else {
                        let note = new MidiWriter.NoteEvent({
                            duration: getDuration(trackItem, options),
                            pitch: [v],
                            startTick: getStartTick(trackItem, options),
                            velocity
                        })
                        midi_track.addEvent(note);
                    }
                });
            }
        });
        midi_track.setTempo(options.tempo)
        return midi_track;
    }).filter(x => x)

    // // Generate a data URI
    var write = new MidiWriter.Writer(tracks);
    return write.buildFile();
}
export function BuildMidiFromRecord(record, tempo = 120) {
    let track = new MidiWriter.Track();
    let events = [];
    let eventDic = {};
    let factor = ((60000 / (tempo * 128))) * 50;
    let startTime = record[0].time
    record.map(item => {
        if (!eventDic[item.data.pitch]) {
            if (isOn(item.data)) {
                eventDic[item.data.pitch] = item;
            }
        }
        else {
            if (!isOn(item.data)) {
                events.push(new MidiWriter.NoteEvent({
                    duration: `T${Math.round((item.data.time - eventDic[item.data.pitch].time) * factor)}`,
                    startTick: (eventDic[item.data.pitch].time - startTime) * factor,
                    pitch: [item.data.pitch],
                    velocity: eventDic[item.data.pitch].data.velocity * 100
                }))
                delete eventDic[item.data.pitch]
            }
        }
    });
    events.map(evt => {
        track.addEvent(evt);
    })
    let tracks = [track]
    track.setTempo(tempo)
    var write = new MidiWriter.Writer(tracks);
    return write.buildFile();
}
export function BuildMidiFromPattern(pattern, tempo) {
    var track = new MidiWriter.Track();
    pattern.map(patt => {
        return patt.map(pat => {
            let factor = ((128 * (1 / 16) * 4));
            let note = new MidiWriter.NoteEvent({
                duration: `T${Math.round((pat.endTime - pat.startTime) * factor)}`,
                startTick: pat.startTime * factor,
                pitch: [pat.pitch],
                velocity: pat.velocity || 100
            });
            track.addEvent(note);
        })
    })
    track.setTempo(tempo)
    let tracks = [track]
    var write = new MidiWriter.Writer(tracks);
    return write.buildFile();
}
export function BuildMidiFromEditor(notes, tempo, ppq, instruments) {
    let trackIds = [];
    notes.map(v => {
        if (trackIds.indexOf(v.track) === -1) {
            trackIds.push(v.track);
        }
    });
    let tracks = []
    let factor = 1;
    if (ppq !== undefined) {
        factor = 128 / ppq
    }

    for (let i = 0; i < trackIds.length; i++) {
        let currentTrack = trackIds[i];
        var track = new MidiWriter.Track();
        if (instruments[currentTrack] !== undefined) {
            track.addEvent(new MidiWriter.ProgramChangeEvent({
                instrument: parseInt(instruments[currentTrack])
            }));
        }
        notes.filter(x => x.track === currentTrack).map(pat => {
            let note = new MidiWriter.NoteEvent({
                duration: `T${pat.durationTicks * factor}`,
                startTick: pat.ticks * factor,
                pitch: [pat.midi || pat.pitch],
                velocity: pat.velocity || 100
            });
            track.addEvent(note);
        })

        track.setTempo(tempo);
        tracks.push(track);
    }
    var write = new MidiWriter.Writer(tracks);
    return write.buildFile();
}
(
    function (array) {
        if (!array.groupBy) {
            Object.defineProperty(array, 'groupBy', {
                enumerable: false,
                writable: true,
                configurable: true,
                value: function (func) {
                    var collection = this;
                    var result = {};
                    for (var i = 0; i < collection.length; i++) {
                        var t = func(collection[i]);
                        result[t] = result[t] || [];
                        result[t].push(collection[i]);
                    }
                    return result;
                }
            });
        }
    }
)(Array.prototype)