import React from 'react';
import ChordMaster from '../../base/chord-master';
import Vex from 'vexflow';
import { convertToMelodyNote, flat, sharp, uuidv4 } from '../../base/base-lib';
import { useDispatch } from 'react-redux';
import { play } from '../../features/composer/composerSlice';
import NoteType, { WriteNoteType } from './NoteType';
import { TitlesService } from '../../title-service';
import { onDragState } from '../../base/dragstateservice';
const VF = Vex.Flow;
const RENDER_HEIGHT = 200;
const RENDER_WIDTH = 200;
export default class StaffNotes extends React.Component {
    notes = null;
    constructor(props) {
        super(props);
        let _state = { id: `c_${uuidv4()}`, note: null, selected: {} };
        this.state = _state;
        this.titleRef = React.createRef();
    }
    getRenderWidth() {
        if (this.props.supersize) {
            return RENDER_WIDTH * 4;
        }
        return RENDER_WIDTH;
    }
    getRenderHeight() {
        if (this.props.supersize) {
            return RENDER_HEIGHT * 2;
        }
        return RENDER_HEIGHT
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps && nextProps.value) {

            throttle(() => {
                try {
                    this.currentProps = nextProps;
                    if (this.titleRef.current) {
                        this.titleRef.current.innerHTML = WriteNoteType(this.props.title || (this.props.arg ? this.props.arg.name : ''));
                    }
                    this.updateRenderer(nextProps);
                } catch (e) {
                    console.error(e)
                }

            }, 250)
        }
        return true;
    }
    updateRenderer(nextProps) {
        if (nextProps && nextProps.value) {
            let notes = [];
            let keys = [];
            let accidentals = [];
            let lowestOctave = 100;
            Object.keys(nextProps.value).map((v, index) => {
                if (nextProps.value[v]) {
                    let res = convertToMelodyNote(v, this.props.bias);
                    let key = res.key.toLowerCase();
                    switch (res.accidental) {
                        case sharp:
                            key += '#';
                            accidentals.push({ index, accidental: new VF.Accidental('#') })
                            break;
                        case flat:
                            key += 'b';
                            accidentals.push({ index, accidental: new VF.Accidental('b') })
                            break;
                    }
                    key += "/" + res.octave;
                    lowestOctave = Math.min(res.octave, lowestOctave);
                    keys.push(key);
                }
            })

            if (this.notes !== keys.join('-')) {

                // Create a stave at position 10, 40 of width 400 on the canvas.

                let { context } = this.state;
                if (context) {
                    context.clear();
                    if (this.props.supersize) {
                        context.scale(2, 2);
                    }
                    var stave = new VF.Stave(10, 40, this.getRenderWidth());
                    let clef = lowestOctave >= 4 ? 'treble' : 'bass';
                    // Add a clef and time signature.
                    stave.addClef(clef);

                    // Connect it to the rendering context and draw!
                    stave.setContext(context).draw();

                    if (keys.length) {
                        let staveNote = new VF.StaveNote({ clef, keys: keys, duration: 'w' });
                        accidentals.forEach((accidental) => {
                            staveNote = staveNote.addAccidental(accidental.index, accidental.accidental);
                        })
                        notes.push(staveNote)
                        // Create a voice in 4/4 and add the notes from above
                        var voices = [new VF.Voice({ num_beats: 4, beat_value: 4 }).addTickables(notes)];
                        // Format and justify the notes to 400 pixels.
                        var formatter = new VF.Formatter().joinVoices(voices).format(voices, this.getRenderWidth());

                        // Render voices
                        voices.forEach(function (v) { v.draw(context, stave); })
                    }
                    this.notes = keys.join('-');
                }
            }
        }
    }
    componentDidMount() {
        setTimeout(() => {

            this.createRenderer();
        }, 100)
    }

    createRenderer() {
        let renderer = new VF.Renderer(document.getElementById(`${this.state.id}`), VF.Renderer.Backends.CANVAS);
        renderer.resize(this.getRenderWidth(), this.getRenderHeight());
        let context = renderer.getContext();

        this.setState({
            context,
            renderer
        });
    }

    render() {
        let { props } = this;
        let extra = {};
        if (props.onRemove) {
            extra.onRemove = () => {
                if (props.onRemove) {
                    props.onRemove();
                }
            }
        }
        if (props.onMoveTones) {
            extra.onMoveTones = (args) => {
                if (props.onMoveTones) {
                    props.onMoveTones({ direction: args, selected: this.state.selected })
                }
            }
        }
        return (
            <div style={{
                overflow: 'hidden',
                backgroundColor: 'white',
                cursor: 'pointer'
            }} tabIndex={0} onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick();
                }
            }}>
                <StaffNotePlayerBoard is_music_playing={this.props.is_music_playing} skipbank={this.props.skipbank} onPlay={() => {
                    if (props.onPlay) {
                        props.onPlay(this.props.value);
                    }
                }} onStop={() => {
                    if (props.onStop) {
                        props.onStop(this.props.value);
                    }
                }} onAddToBank={() => {
                    if (props.onAddToBank) {
                        props.onAddToBank(this.props.value, this.props.arg);
                    }
                }} {...extra} value={this.currentProps ? this.currentProps.value : null} />
                <h3 ref={this.titleRef} style={{ color: 'black' }}></h3>
                {this.props.hide_voicing ? null : <div style={{ position: 'absolute', right: 10, top: 40 }}>
                    <StaffNotesSelect value={this.props.value} selected={this.state.selected} onToggle={(key) => {
                        this.setState({
                            selected: {
                                ...this.state.selected,
                                [key]: !this.state.selected[key]
                            }
                        })
                    }} />
                </div>}
                <canvas draggable="true" onDragStart={(ev) => {
                    console.log(ev);
                    ev.dataTransfer.dropEffect = "copy";
                    let noteLetter = convertToMelodyNote(parseInt(Object.keys(this.props.value)[0]), this.props.bias)
                    ev.dataTransfer.setData("text/plain", JSON.stringify({
                        value: this.props.value,
                        notes: this.props.value,
                        noteLetter,
                        chordInfo: this.props.arg,
                        chord: this.props.arg,
                        title: this.props.title || (this.props.arg ? this.props.arg.name : '')
                    }))
                    onDragState({ dragging: true })

                }} width={this.getRenderWidth()} height={this.getRenderHeight()} id={this.state.id}></canvas>
            </div>
        )
    }
}

export function StaffNotesSelect(props) {
    let keys = Object.keys(props.value || {});
    return (
        <div className="btn-group-vertical">
            {keys.map((key, _index) => {
                return (
                    <button key={`btn-${_index}`} type="button" className={props.selected[keys.length - _index - 1] ? "btn btn-success" : "btn btn-info"} onClick={() => {
                        if (props.onToggle) {
                            props.onToggle(keys.length - _index - 1);
                        }
                    }}>
                        {1 + _index}
                    </button>
                )
            })}
        </div>
    )
}

export function StaffNotePlayerBoard(props) {
    return (
        <div className="btn-group">
            <button type="button" className={props.is_music_playing ? "btn btn-disabled" : "btn btn-success"} onMouseDown={() => {
                if (!props.is_music_playing)
                    if (props.onPlay) {
                        props.onPlay();
                    }
            }} onMouseUp={() => {
                if (!props.is_music_playing)
                    if (props.onStop) {
                        props.onStop();
                    }
            }}>
                <i className="fas fa-play"></i>
            </button>
            {props.skipbank ? null : <button type="button" onClick={() => {
                if (props.onAddToBank) {
                    props.onAddToBank();
                }
            }} className="btn btn-default">
                <i className="fas fa-plus"></i>
            </button>}
            {!props.onRemove ? null : <button type="button" className="btn btn-default" onClick={() => {
                if (props.onRemove) {
                    props.onRemove();
                }
            }}><i className="fas fa-times" ></i></button>}
            {!props.onMoveTones ? null : <button type="button" title={TitlesService('octave-down')} className="btn btn-default" onClick={() => {
                if (props.onMoveTones) {
                    props.onMoveTones(12);
                }
            }}><i className="fas fa-arrow-up" ></i></button>}
            {!props.onMoveTones ? null : <button type="button" title={TitlesService('octave-up')} className="btn btn-default" onClick={() => {
                if (props.onMoveTones) {
                    props.onMoveTones(-12);
                }
            }}><i className="fas fa-arrow-down" ></i></button>}
            {!props.onMoveTones ? null : <button title={TitlesService('semitone-down')} type="button" className="btn btn-default" onClick={() => {
                if (props.onMoveTones) {
                    props.onMoveTones(1);
                }
            }}><i className="fas fa-chevron-up" ></i></button>}
            {!props.onMoveTones ? null : <button title={TitlesService('semitone-up')} type="button" className="btn btn-default" onClick={() => {
                if (props.onMoveTones) {
                    props.onMoveTones(-1);
                }
            }}><i className="fas fa-chevron-down" ></i></button>}
        </div>)
}
function throttle(fn, ms) {
    let timeout;
    function exec() {
        fn.apply()
    }
    function clear() {
        if (timeout == undefined) { }
        else { clearTimeout(timeout); }
    }
    if (fn !== undefined && ms !== undefined) {
        timeout = setTimeout(exec, ms)
    } else {
        console.error('callback function and the timeout must be supplied')
    }
    // API to clear the timeout
    throttle.clearTimeout = function () {
        clear();
    }
}