import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BuildFoundMatches } from '../../base/chord-progression';

import Panel from '../../components/panel/Panel';
import {
    getCurrentInstrument,
    getSelectedScene,
    generateMusic,
    playGeneratedMusic,
    selectCurrentMidiCommand,
    selectScenes,
    setOutOfBandScene,
    selectBias,
    isMagentaLoaded,
    isMagentaGenerating,
    isMagentaLoading,
    isMusicPlaying,
    getCurrentChordPlaying,
    anErrorOccuredDuringGeneration
} from '../../features/composer/composerSlice';
import { GenerateMusicAsync, GetSequenceAsMidi, PlayMagentaMusicAsync } from '../../features/composer/magenta-thunks';
import { TitlesService } from '../../title-service';
import { FormGroup } from '../FormGroup';

export default function MusicGenerator() {
    const scenes = Object.values(useSelector(selectScenes));
    const dispatch = useDispatch();
    const bias = useSelector(selectBias)
    const [chordText, setChordText] = useState("");
    const [foundMatches, setFoundMatches] = useState([])
    const playing = useSelector(isMusicPlaying);
    const isloading = useSelector(isMagentaLoading);
    const isgenerating = useSelector(isMagentaGenerating);
    const currentIndexPlaying = useSelector(getCurrentChordPlaying)
    const errorHappened = useSelector(anErrorOccuredDuringGeneration);
    const isloaded = useSelector(isMagentaLoaded)
    setOutOfBandScene(scenes);
    let extras = {
        disabled: isloading || isgenerating || !isloaded
    }
    return (


        <Panel stretch title={TitlesService('Music Generator')}>
            <div style={{ display: 'flex', padding: 10, flex: 1, flexDirection: 'column' }}>
                <span>{isloading ? TitlesService("Loading") : ""}</span>
                <div style={{ display: 'flex', flex: 1, maxHeight: 40, flexGrow: 1 }}>
                    <div className="btn-group">
                        <button {...extras} type="button" onClick={() => {
                            dispatch(GenerateMusicAsync({ progression: foundMatches, chords: chordText.split(' ').filter(x => x) }))
                        }} className="btn btn-default">
                            {isgenerating ? TitlesService('Generating') : 'Generate Music'}
                        </button>
                        <button  {...extras} type="button" onClick={() => {
                            dispatch(PlayMagentaMusicAsync())
                        }} className="btn btn-default">
                            {playing ? TitlesService('Stop') : TitlesService('Play')}
                        </button>
                        <button  {...extras} type="button" onClick={() => {
                            dispatch(GetSequenceAsMidi())
                        }} className="btn btn-default">
                            { playing ? TitlesService('Wait') : TitlesService('Store Midi') }
                        </button>

                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                            <FormGroup>
                                <label>Chords</label>
                                <input
                                    value={chordText}
                                    onChange={(e) => {
                                        let _foundMatches = BuildFoundMatches(e.currentTarget.value, bias);
                                        setChordText(e.currentTarget.value);
                                        setFoundMatches(_foundMatches);

                                    }} type="text" className="form-control" placeholder={"Enter Progression ..."} />
                            </FormGroup>
                            <p className={'text-danger'}>{errorHappened ? TitlesService('MusicGenError') : ''}</p>
                            <FormGroup>
                                <span>Current Chord count : {foundMatches.length}</span>
                            </FormGroup>
                            <FormGroup>
                                <CurrentChordGroup
                                    chords={foundMatches.map(x => x.chordInfo && x.chordInfo.name)}
                                    activeIndex={currentIndexPlaying} />
                            </FormGroup>

                        </div>
                    </div>
                </div>
            </div>
        </Panel >
    );
}

function CurrentChordGroup(props) {
    return (
        <div className="btn-group">
            {props.chords.map((chord, chordIndex) => {
                return (
                    <button key={`chord-${chordIndex}-${chord}`} type="button" className={
                        props.activeIndex === chordIndex ? "btn btn-success" : "btn btn-info"}>{
                            chord}</button>
                )
            })}
        </div>
    )
}