const _padding = 4;

export function FormGroup(props) {
    return (
        <div onClick={() => {
            if (props.onClick) {
                props.onClick();
            }
        }} className="form-group" style={{ padding: props.padding || _padding }}>
            {props.children}
        </div>
    );
}