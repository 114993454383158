import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChordMaster from '../../base/chord-master';
import { getScaleByName } from '../../base/chord-master';
import {
    getTempo,
    selectCurrentMidiFile,
    updateTempo
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import { MidiTrackEditor } from '../../components/music/MidiTrack';
import { TitlesService } from '../../title-service';
import { notesOverlap, uuidv4 } from '../../base/base-lib';
import { FormGroup } from '../../components/FormGroup';
import { getActiveTrack, getActiveTracks, getIsPlaying, getIsQuantized, getRepeat, getTrackEditorFileName, getTrackEditorInstrument, getTrackEditorSelection, getTrackEditorValue, getUserId, setIsQuantized, setRepeating, toggleTrack, trackEditorUpdate } from '../user/userSlice';
import { CHANGE_SELECTED_VELOCITY, CHORDIFY, CREATE_LICC, LOAD_CURRENT_LICC, SendCommandToMidiTrackerEditor, SET_INSTRUMENT, SET_REPEAT, SPLIT, STORE_MIDI, STORE_MIDI_CLIPBOARD, TOGGLE_PLAY } from './MidiTrackerEditor';
import { MidiInstrumentData } from '../../base/instrument-data/instruments';
import { COMMON_BASE_CHORDS } from '../../base/chord-constants';
import { storeUserLicc } from '../../base/firebase-app';
import { BuildMidiFromEditor } from '../../base/midi-writer-service';
import { createLick } from '../../base/midi-service';
import { createModifierName } from '../../base/nameService';
import { createLiccModifier } from '../../base/modifier-service';
import { LoadCloudModifiersAsync, StoreCloudModifiersAsync } from './magenta-thunks';
let reader;
export function MidiTrackerEditorControls() {
    let selectedDic = useSelector(getTrackEditorSelection);
    let dispatch = useDispatch();
    let name = useSelector(getTrackEditorFileName);
    let tempo = useSelector(getTempo);
    let uid = useSelector(getUserId);
    let [local_velocity, setLocalVelocity] = useState(0);
    let [local_tempo, setLocalTempo] = useState(tempo);
    let isQuantized = useSelector(getIsQuantized);
    let ppq = 960;
    let is_playing = useSelector(getIsPlaying);
    let repeat = useSelector(getRepeat)
    let instrument = useSelector(getTrackEditorInstrument);
    let selectedItem = selectedDic ? Object.keys(selectedDic)[0] || null : null;
    let selectedNotes = selectedDic ? Object.values(selectedDic) : null;
    let midiNotes = selectedNotes ? selectedNotes.map(v => v.midi) : null;
    let midiScaleNotes = midiNotes ? midiNotes.map(v => v - 60).map(v => v % 12) : null;
    let instruments = useSelector(getTrackEditorValue('instruments')) || {};
    let modifierScale = useSelector(getTrackEditorValue('modifier-scale')) || null;
    let tracks = useSelector(getActiveTracks);
    let currentTrack = useSelector(getActiveTrack);
    let selectedItems = selectedDic ? Object.keys(selectedDic) : null;
    reader = reader || (new ChordMaster.Reader({ skipRenderer: true }));
    let scales = reader.getScales();

    let compatiblescales = scales.map(sc => {
        return getScaleByName(sc);
    }).filter(x => {
        return midiScaleNotes && !midiScaleNotes.some(v => x.base12.indexOf(v) === -1)
    });

    let container_style = { padding: 4 }
    let control_style = { padding: 10, paddingTop: 0 };
    return (
        <Panel stretch title={TitlesService('Midi Track Editor Controls')}>
            <div style={container_style}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <div>
                        <div style={{ width: 250 }}>
                            <FormGroup>
                                <label>{TitlesService('Name')}</label>
                                <input onChange={(e) => {
                                    dispatch(trackEditorUpdate({ type: 'name', value: e.currentTarget.value }));
                                }} type="text" value={name} className="form-control" placeholder="Enter ..." />
                            </FormGroup>
                        </div>
                    </div>
                    <div>
                        <label>{TitlesService('Controls')}</label>
                        <FormGroup>
                            <div className="btn-group">
                                <button type="button"
                                    className={"btn btn-success"}
                                    onClick={() => {
                                        if (is_playing) {
                                            dispatch(setRepeating(false))
                                        }
                                        SendCommandToMidiTrackerEditor(TOGGLE_PLAY, {});
                                    }}><i className={is_playing ? 'fa fa-stop' : "fa fa-play"} />
                                </button>
                                <button type="button"
                                    className={repeat ? "btn btn-success" : "btn btn-default"}
                                    onClick={() => {
                                        dispatch(setRepeating(!repeat))
                                    }}><i className={'fas fa-redo'} />
                                </button>
                            </div>
                        </FormGroup>
                    </div>
                    <div>
                        <label>{TitlesService('Tracks')}</label>
                        <FormGroup>
                            <div className="btn-group">
                                {[0, 1, 2, 3, 4, 5, 6, 7].map((track) => {
                                    return (
                                        <button type="button" key={`asd-split-size-${track}`}
                                            className={tracks.indexOf(track) === -1 ? "btn btn-disabled" : (currentTrack === track ? "btn btn-danger" : "btn btn-success")}
                                            onClick={() => {
                                                dispatch(toggleTrack(track));
                                            }}>
                                            {track + 1}
                                        </button>
                                    )
                                })}
                            </div>
                        </FormGroup>
                    </div>
                    <div>
                        <label>{TitlesService('Split')}</label>
                        <FormGroup>
                            <div className="btn-group">
                                {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((size, size_index) => {
                                    return (
                                        <button type="button" key={`button-split-size-${size_index}`}
                                            className={!selectedItem ? "btn btn-disabled" : "btn btn-success"}
                                            onClick={() => {
                                                SendCommandToMidiTrackerEditor(SPLIT, { notes: selectedItems, split: size.value || size })
                                            }}>
                                            {size.name || size}
                                        </button>
                                    )
                                })}
                            </div>
                        </FormGroup>
                    </div>
                    <div>
                        <label>{TitlesService('Chords')}</label>
                        <FormGroup>
                            <div className="btn-group">
                                {COMMON_BASE_CHORDS.map((cbc) => {
                                    return (
                                        <button type="button" key={`cbc-${cbc.value}`}
                                            className={!selectedItem ? "btn btn-disabled" : "btn btn-success"}
                                            onClick={() => {
                                                SendCommandToMidiTrackerEditor(CHORDIFY, { notes: selectedItems, chord: cbc.value })
                                            }}>
                                            {cbc.name}
                                        </button>

                                    )
                                })}
                            </div>
                        </FormGroup>
                    </div>
                    <div className="control" style={control_style}>
                        <label>{TitlesService('Tempo')} {local_tempo}</label>
                        <p className="range-field grey-text">
                            <input type="range" min="20" max="240" onBlur={() => {
                                if (tempo !== local_tempo) {
                                    dispatch(updateTempo(local_tempo));
                                }
                            }} onChange={(evt) => {
                                setLocalTempo(+evt.currentTarget.value);
                            }} value={local_tempo} step="1" />
                        </p>
                    </div>
                    <div className="control" style={control_style}>
                        <label>{TitlesService('Volume/Velocity')} {local_velocity}</label>
                        <p className="range-field grey-text">
                            <input type="range" min="0" max="127" onBlur={() => {
                                if (tempo !== local_velocity) {
                                }
                            }} onChange={(e) => {
                                setLocalVelocity(parseInt(e.currentTarget.value));
                                SendCommandToMidiTrackerEditor(CHANGE_SELECTED_VELOCITY, { velocity: local_velocity })
                            }} value={local_velocity} step="1" />
                        </p>
                    </div>
                    <div>
                        <label>{TitlesService('Midi Commands')}</label>
                        <FormGroup>
                            <div className="btn-group">
                                <button type="button" title={TitlesService('Save')}
                                    className={"btn btn-success"}
                                    onClick={() => {
                                        SendCommandToMidiTrackerEditor(STORE_MIDI, {});
                                    }}><i className={'fas fa-cloud-download-alt'} />
                                </button>
                                <button type="button" title={TitlesService('Load Current Licc')}
                                    className={"btn btn-success"}
                                    onClick={() => {
                                        SendCommandToMidiTrackerEditor(LOAD_CURRENT_LICC, {});
                                    }}><i className={"fas fa-truck-loading"} />
                                </button>
                                <button type="button" title={TitlesService('Quantize')}
                                    className={isQuantized ? "btn btn-info" : "btn btn-default"}
                                    onClick={() => {
                                        dispatch(setIsQuantized(!isQuantized))
                                    }}><i className={'fas fa-clock'} />
                                </button>
                                <button type="button" title={TitlesService('Add Current Licc')}
                                    className={"btn btn-success fa"}
                                    onClick={() => {
                                        SendCommandToMidiTrackerEditor(LOAD_CURRENT_LICC, {
                                            track: currentTrack
                                        });
                                    }}>
                                    <i className="fas fas fa-truck-loading"></i>
                                    <i className="fas fa-plus fa-stack-1x fa-inverse" style={{
                                        color: '#08765a',
                                        position: 'absolute',
                                        top: 0,
                                        left: 15
                                    }}></i>
                                </button>
                                <button type="button" title={TitlesService('Copy Selected Notes')}
                                    className={"btn btn-success"}
                                    onClick={() => {
                                        dispatch(trackEditorUpdate({ type: 'mode', value: 'copy' }));
                                    }}><i className={"fas fa-copy"} />
                                </button>
                                <button type="button" title={TitlesService('Save As Licc')}
                                    className={"btn btn-success"}
                                    onClick={() => {
                                        SendCommandToMidiTrackerEditor(CREATE_LICC, {});
                                    }}><i className={"fas fa-save"} />
                                </button>
                            </div>
                        </FormGroup>
                    </div>
                    <div >
                        <label>{TitlesService('Instrument')}</label>
                        <FormGroup>
                            <div className="btn-group">
                                <select className="form-control" value={
                                    instruments && instruments[currentTrack] ? instruments[currentTrack] : instrument || '0x00'
                                }
                                    onChange={(val) => {
                                        if (val && val.currentTarget) {
                                            SendCommandToMidiTrackerEditor(SET_INSTRUMENT, {
                                                value: parseInt(val.currentTarget.value)
                                            });
                                            dispatch(trackEditorUpdate({ type: 'instrument', value: (val.currentTarget.value) }));
                                            dispatch(trackEditorUpdate({
                                                type: 'instruments',
                                                currentTrack: currentTrack,
                                                value: (val.currentTarget.value)
                                            }));
                                        }
                                    }}>
                                    {MidiInstrumentData.map(v => {
                                        return <option key={`option-${v.hexcode}-${v.instrument}`} value={v.hexcode}>{v.instrument}</option>
                                    })}
                                </select>
                            </div>
                        </FormGroup>
                    </div>
                </div>
                <div>
                    <label>{TitlesService('Licc Modifier Builder')}</label>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <div>
                            <label>{TitlesService('Licc Modifier Controls')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    <button type="button" className={uid ? "btn btn-success" : "btn btn-disabled"}
                                        title={TitlesService('')}
                                        onClick={() => {
                                            let licc = createLick({ ppq: 128, name: createModifierName() }, JSON.parse(JSON.stringify(selectedNotes)));
                                            let liccmodifier = createLiccModifier(licc);
                                            dispatch(StoreCloudModifiersAsync({ ...liccmodifier, owner: uid })).then(() => {
                                                return dispatch(LoadCloudModifiersAsync({ uid }))
                                            })
                                        }} >
                                        <i className={'fas fa-save'} />
                                    </button>
                                </div>
                            </FormGroup>
                        </div>
                        <div >
                            <label>{TitlesService('Scales')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    <select className="form-control" value={modifierScale}
                                        onChange={(val) => {
                                            if (val && val.currentTarget) {
                                                dispatch(trackEditorUpdate({ type: 'modifier-scale', value: (val.currentTarget.value) }));
                                            }
                                        }}>
                                        {compatiblescales.map(v => {
                                            return <option key={`option-${v.id}-${v.official}`} value={v.id}>{v.official}</option>
                                        })}
                                    </select>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </div>
            </div>
        </Panel >
    );
}
