import { SSL_OP_NO_TLSv1_1 } from 'constants';
import React from 'react';
import { ArpeggioDivisions, ArpeggioOptions, MidiLinkMapping, ModifierTypes, StepTonalOptions_Step, UseOptions } from '../../../base/modifier-service';
import { TitlesService } from '../../../title-service';
import { FormGroup } from '../../FormGroup';

export class ModifierItemParameters extends React.Component {
    reader = null;
    constructor(props) {
        super(props);
        this.state = {
            ratios: [1, 1],
            split: 2
        };
    }
    render() {
        const { modifier, item } = this.props;
        let extra = {}
        if (modifier) {
            let modifierItem = modifier.spaces.find(x => x.id === item);
            if (modifierItem) {
                switch (modifierItem.type) {
                    case ModifierTypes.StepTone:
                        return <StepToneMenu
                            modifier={modifier}
                            item={modifierItem}
                            onUpdate={(args) => {
                                if (this.props.onUpdate) {
                                    this.props.onUpdate(args)
                                }
                            }} />
                    case ModifierTypes.Midi:
                        return <MidiMenu midiLicks={this.props.midiLicks || []}
                            modifier={modifier}
                            item={modifierItem}
                            onUpdate={(args) => {
                                if (this.props.onUpdate) {
                                    this.props.onUpdate(args)
                                }
                            }} />
                    case ModifierTypes.Arpeggio:
                        return <ArpeggioMenu
                            modifier={modifier}
                            item={modifierItem}
                            onUpdate={(args) => {
                                if (this.props.onUpdate) {
                                    this.props.onUpdate(args)
                                }
                            }} />
                }
            }
        }
        return (
            <div className="col-md-12" >
            </div>
        )
    }
}
class ArpeggioMenu extends React.Component {
    render() {
        let { item } = this.props;
        return (
            <div className="col-md-12" >
                <FormGroup>
                    <label>Arpeggio</label>
                    <select className="form-control" value={item.args.type || ''} onChange={(val) => {
                        this.props.onUpdate({ value: val.currentTarget.value, key: 'type' })
                    }}>
                        <option value={''}>Select</option>
                        {(ArpeggioOptions).map(v => {
                            return <option
                                key={`licks-${v.value}`}
                                value={v.value}>{v.title()}</option>
                        })}
                    </select>
                </FormGroup>
                <FormGroup>
                    <label>Use Custom Divisions</label>
                    <select className="form-control" value={item.args.useDivisions || ''} onChange={(val) => {
                        this.props.onUpdate({ value: val.currentTarget.value, key: 'useDivisions' })
                    }}>
                        {(UseOptions).map(v => {
                            return <option
                                key={`use-divisions-${v.value}`}
                                value={v.value}>{v.title()}</option>
                        })}
                    </select>
                </FormGroup>
                {!item.args.useDivisions ? null : <FormGroup>
                    <label title={TitlesService('DivisionsExplanation')}>Divisions</label>
                    <select className="form-control" value={item.args.divisions || ''} onChange={(val) => {
                        this.props.onUpdate({ value: val.currentTarget.value, key: 'divisions' })
                    }}>
                        <option value={''}>Select</option>
                        {(ArpeggioDivisions).map(v => {
                            return <option
                                key={`divisions-${v.value}`}
                                value={v.value}>{v.title()}</option>
                        })}
                    </select>
                </FormGroup>}
            </div>
        )
    }
}
class StepToneMenu extends React.Component {
    render() {
        let { item } = this.props;
        return (
            <div className="col-md-12" >
                <FormGroup>
                    <label>Step Tones</label>
                    <select className="form-control" value={item.args.type || ''} onChange={(val) => {
                        this.props.onUpdate({ value: val.currentTarget.value, key: 'type' })
                    }}>
                        <option value={''}>Select</option>
                        {(StepTonalOptions_Step).map(v => {
                            return <option
                                key={`StepTonalOptions_Step-${v.value}`}
                                value={v.value}>{v.title()}</option>
                        })}
                    </select>
                </FormGroup>
            </div>
        )
    }
}
class MidiMenu extends React.Component {
    render() {
        let { item, midiLicks } = this.props;
        if (item.args.midiMap === 'licc-mapping') {
            return <div className="col-md-12"></div>
        }
        return (
            <div className="col-md-12" >
                <FormGroup>
                    <label>Midi Lick</label>
                    <select className="form-control" value={item.args.lick || ''} onChange={(val) => {
                        this.props.onUpdate({ value: val.currentTarget.value, key: 'lick' })
                    }}>
                        <option value={''}>Select</option>
                        {(midiLicks || []).map(v => {
                            return <option key={`licks-${v.id}`} value={v.id}>{v.name}</option>
                        })}
                    </select>
                </FormGroup>
                <FormGroup>
                    <label>Midi Mapping</label>
                    <select className="form-control" value={item.args.midiMap || ''} onChange={(val) => {
                        this.props.onUpdate({ value: val.currentTarget.value, key: 'midiMap' })
                    }}>
                        {(MidiLinkMapping).map(v => {
                            return <option key={`midi-map-${v.value}`} value={v.value}>{v.title()}</option>
                        })}
                    </select>
                </FormGroup>
            </div>
        )
    }
}