let default_lang = 'en';
export function TitlesService(key, lang = null) {
    lang = lang || default_lang;


    if (lib[key] && lib[key][lang]) {
        return lib[key][lang];
    }
    return `[${key}]`;
}

const lib = {
    'MusicGenError': {
        en: `Sorry, couldn't generate music with that progression.`
    },
    'Quantize': {
        en: 'Quantize'
    },
    'Midi Commands': {
        en: 'Midi Commands'
    },
    'Save': {
        en: 'Save'
    },
    'Music Recorder': {
        en: 'Music Recorder'
    },
    'Midi Track Editor': {
        en: 'Midi Track Editor'
    },
    'Panels': {
        en: 'Panels'
    },
    'Lock': {
        en: 'Lock'
    },
    'Chords': {
        en: 'Chords'
    },
    'Instrument': {
        en: 'Instrument'
    },
    'Copy Selected Notes': {
        en: 'Copy Selected Notes'
    },
    'Load Current Licc': {
        en: 'Load Current Licc'
    },
    'Add Current Licc': {
        en: 'Add Current Licc'
    },
    'Major': {
        en: 'Major'
    },
    'Minor': {
        en: 'Minor'
    },
    'Sus': {
        en: 'Sus'
    },
    'Dim': {
        en: 'Dim'
    },
    'Volume/Velocity': {
        en: 'Volume/Velocity'
    },
    'Midi Instruments': {
        en: 'Midi Instruments'
    },
    'Delete Configuration': {
        en: 'Delete Configuration'
    },
    'Current Panel Configuration': {
        en: 'Current Panel Configuration'
    },
    'Panel Configurations': {
        en: 'Panel Configurations'
    },
    'Midi Track Editor Controls': {
        en: 'Midi Track Editor Controls'
    },
    'Name': {
        en: 'Name'
    },
    'Measures': {
        en: 'Measures'
    },
    'Settings': {
        en: 'Settings'
    },
    'Tempo': {
        en: 'Tempo'
    },
    'Search by instruments': {
        en: 'Search by instruments'
    },
    'Space Configurations': {
        en: 'Panel Configurations'
    },
    'Search by text': {
        en: 'Search by text'
    },
    'Zoom': {
        en: 'Zoom'
    },
    'SmallKey': {
        en: 'S'
    },
    'MediumKey': {
        en: 'M'
    },
    'LargeKey': {
        en: 'L'
    },
    'Chord Operations': {
        en: 'Chord Operations'
    },
    'Resize': {
        en: 'Resize'
    },
    'Split': {
        en: 'Split'
    },
    'Copy': {
        en: 'Copy'
    },
    'Controls': {
        en: 'Controls'
    },
    'YouwillLoseChangesToChordProgression': {
        en: `You will lose any changes not saved, are you sure you want to proceed?`
    },
    'YouwillLoseChangesToModifier': {
        en: `You will lose any changes not saved, are you sure you want to proceed?`
    },
    'YouwillLoseChangesToLicc': {
        en: `You will lose any changes not saved, are you sure you want to proceed?`
    },
    'Local Progressions': {
        en: 'Local Progressions'
    },
    'Local Modifiers': {
        en: 'Local Modifiers'
    },
    'NotSavedToCloud': {
        en: 'Not saved to the cloud yet'
    },
    'NotSaved': {
        en: 'Not saved yet'
    },
    'Saved Modifiers': {
        en: 'Saved Modifiers'
    },
    'SignIn': {
        en: 'Sign In'
    },
    'Community Cloud Progressions': {
        en: 'Community Cloud Progressions'
    },
    'DeletePattern': {
        en: 'Delete pattern'
    },
    'StoreInMyCloud': {
        en: 'Store in my cloud.'
    },
    'My Cloud Progressions': {
        en: 'My Cloud Progressions'
    },
    'Liccs': {
        en: 'Liccs'
    },
    'Log in to see your progressions': {
        en: 'Log in to see your progressions'
    },
    'Log in to see community progressions': {
        en: 'Log in to see community progressions'
    },
    'StorePattern': {
        en: 'Stores a pattern to your account.'
    },
    'Modifier': {
        en: 'Modifier'
    },
    'Music Generator': {
        en: 'Music Generator'
    },
    'Drum Generator': {
        en: 'Drum Generator'
    },
    'Swap': {
        en: 'Swap'
    },
    'Reset': {
        en: 'Reset'
    },
    'Random': {
        en: 'Random'
    },
    'Tracks': {
        en: 'Tracks'
    },
    'Select': {
        en: 'Select'
    },
    'Generating': {
        en: 'Generating'
    },
    'Play': {
        en: 'Play'
    },
    'Stop': {
        en: 'Stop'
    },
    'Track': {
        en: 'Track'
    },
    'unknown': {
        en: 'Unknown'
    },
    'Add': {
        en: 'Add'
    },
    'Chop': {
        en: 'Chop'
    },
    'Chord Progression': {
        en: 'Chord Progression'
    },

    'Modifiers': {
        en: 'Modifiers'
    },
    'What is this?': {
        en: 'What is this?'
    },
    'Start the builder': {
        en: 'Start the builder'
    },
    'ExportExplaination': {
        en: 'This will export the chord progression to a midi file.'
    },
    'Midi Files': {
        en: 'Midi Files'
    },
    'SeratoExportExplaination': {
        en: 'This will export the chord progression in 8 measure chunks to a serieas of midi files.'
    },
    'How do I add my chord progression to the Non-Linear Editor': {
        en: 'How do I add my chord progression to the Non-Linear Editor?'
    },
    'DivisionsExplanation': {
        en: 'Arpeggios will be evenly divided of this number of units.'
    },
    'VoiceMapping': {
        en: 'Voice Mapping'
    },
    'DefaultMapping': {
        en: 'Default Mapping'
    },
    'ModuloVoiceMapping': {
        en: 'Modulo Voice Mapping'
    },
    'Midi': {
        en: 'Midi'
    },
    'Rest': {
        en: 'Rest'
    },
    'octave-up': {
        en: 'Octave up'
    },
    'octave-down': {
        en: 'Octave down'
    },
    'My Chord Progressions': {
        en: 'My Chord Progressions'
    },
    'Shrink Wrap': {
        en: 'Shrink Wrap'
    },
    'ShrinkWrapDescription': {
        en: 'This will cause the chopped lick to trim the rests from the beginning and end of the clip.'
    },
    'Split-Add': {
        en: 'Add split'
    },
    'Split-Add-Description': {
        en: `Split the incoming notes into separate sections. 
        If the modifier is operating on a 1 measure chord, and 
        the ration is set to (1,1). It will duplicate the 
        chord into two chords of half the length. Effectively 
        turning a whole note chord into two half-note chords.`
    }
};