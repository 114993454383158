import React from 'react';
export class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thumbs: [
                { val: 10, lbl: 'Value A' },
                { val: 15, lbl: 'Value B' },
                { val: 25, lbl: 'Value C' },
                { val: 30, lbl: 'Value D' }
            ]
        }
        this.myRef = React.createRef();
    }
    render() {
        let widthProcessor = this.props.widthProcessor || ((w) => w);
        return (
            <div className="slider-container" ref={this.myRef} style={{ position: this.props.relative ? 'relative' : 'absolute' }}>
                <div className="slider-body">
                    <SliderInternal
                        thumbs={this.props.value || this.state.thumbs}
                        min={this.props.min !== undefined ? this.props.min : 1}
                        step={this.props.step !== undefined ? this.props.step : 1}
                        title={this.props.title}
                        menu={this.props.menu || null}

                        max={this.props.max ? this.props.max : (this.props.measureCount || 1) * 32}
                        parentWidth={this.myRef && this.myRef.current && this.myRef.current.parentElement ? widthProcessor(this.myRef.current.parentElement.getBoundingClientRect().width) : null}
                        onThumbChanged={(res) => {
                            let step = this.props.step !== undefined ? this.props.step : 1

                            if (this.props.value) {
                                let values = this.props.value.map(v => ({ ...v }));
                                values[res.index].val = step < 1 ? parseFloat(res.value) : parseInt(res.value);
                                if (this.props.onChange) {
                                    this.props.onChange([...values])
                                }
                            }
                            else {
                                this.state.thumbs[res.index].val = step < 1 ? parseFloat(res.value) : parseInt(res.value);
                                this.setState({
                                    thumbs: [...this.state.thumbs]
                                })
                            }
                        }} />
                </div>
            </div>
        )
    }
}


function SliderInternal(props) {

    let { min = 0, max = 50, thumbs, step } = props;
    let nv = thumbs.length;
    let tpos = thumbs.map((c, i) => `calc(var(--r) + (var(--v${i}) - var(--min))/var(--dif)*var(--uw))`);
    let fill = tpos.map(c => `linear-gradient(90deg, red ${c}, transparent 0)`);
    let hole = tpos.map(c => `radial-gradient(circle at ${c}, red var(--r), transparent 0)`)
    let groupStyle = {
        [`--color1`]: '#333',
        [`--color2`]: '#333',
        [`--color3`]: '#555',
        [`--color4`]: '#ffffff',
        [`--min`]: min,
        [`--max`]: max,
        [`--step`]: step,
        ['--w']: (props.parentWidth ? `${props.parentWidth}px` : '') || '20em',
        ['--fill']: fill.join(', '),
        ['--hole']: hole.join(', ')
    };
    thumbs.map((c, i) => {
        groupStyle = {
            ...groupStyle,
            [`--v${i}`]: c.val,
            [`--vt${i}`]: `'${c.val}'`
        }
    });
    let lio = thumbs.map((c, i) => {
        return [
            <label key={'label-' + i} className="sr-only" htmlFor={`v${i}`}>{c.lbl}</label>,
            <input key={'range-' + i} type="range" id={`v${i}`} onChange={(e) => {
                console.log(e);
                props.onThumbChanged({ index: i, value: e.target.value })
            }} min={min} value={c.val} step={step} max={max} />,
            <output className="out" key={'output-' + i} htmlFor={`v${i}`} out={`${c.val}`} style={{ ['--c']: `var(--v${i})`, ['--ct']: `var(--vt${i})`, content: 'var(--ct)' }}></output>
        ]
    }).flat();
    return (
        <div role='group'
            className="wrap"
            aria-labelledby='multi-lbl'
            style={groupStyle} >
            <div id="multi-lbl">{props.title}: {props.menu}</div>
            {lio}
        </div>
    )

}