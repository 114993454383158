import { useState } from "react";
import * as helpservice from '../base/help-service';

export function Help(props) {
    const [currentTopic, setCurrentTopic] = useState(null);
    let topics = helpservice.getTopics(props.type);
    let topicVideos = helpservice.getTopic(currentTopic);
    let video = null;
    if (topicVideos) {
        video = topicVideos.video
    }
    return (
        <div className="col-md-12" >
            <div onClick={() => {
                if (props.onClose) {
                    props.onClose();
                }
            }} style={{ cursor: 'pointer', display: 'flex', flex: '1 0 auto', flexDirection: 'row', alignItems: 'center' }}> <h1>Help</h1>&nbsp;&nbsp;<i className="fa fa-times" /></div>
            <div style={{
                display: 'flex',
                flex: '1 0 auto',
                flexDirection: 'row'
            }}>
                <div style={{ width: 350 }}>
                    <h2></h2>Help Topics
                    <ul className="list-unstyled" style={{}}>
                        {topics.map((topic) => {
                            let { title, id } = topic;
                            return (
                                <li style={{ cursor: 'pointer' }} onClick={() => {
                                    setCurrentTopic(id);
                                }}><h4>{title}</h4></li>
                            )
                        })}
                    </ul>
                </div>
                <div className="col-md-4">
                    {video ? <iframe
                        width="560"
                        height="315"
                        src={video}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe> : null}
                </div>
            </div>
        </div>
    );
}