import { truncatedNormalVariable } from '@magenta/music/node_modules/@tensorflow/tfjs-layers/dist/variables';
import { createAsyncThunk, createNextState, createSlice } from '@reduxjs/toolkit';
import { createDispatchHook } from 'react-redux';
import { uuidv4 } from '../../base/base-lib';
import { doActiveSelect, doSetActiveSelect } from '../../base/dragstateservice';
import * as fireApp from '../../base/firebase-app';
import { TitlesService } from '../../title-service';
import { AddPatternAsync, GetMidiFolderStructure, LoadUserPatternsAsync, PlayMagentaNotesAsnyc, StopMagentaPlayerAsync } from '../composer/magenta-thunks';
import { updateDeviceOptions } from '../composer/seratoComposer';
import { LoadSpaceConfigurations } from '../composer/user-thunks';

const initialState = {
  signedIn: false,
  notes: [],
  theoryProgressions: {},
  theoryProgressionList: [],
  midiFolderStructure: {},
  currentMidiFolder: 'midis',
  deviceOptions: {},
  midiFolderNavStack: [],
  kitloading: {},
  repeat: false,
  is_playing: false,
  isQuantized: true,
  toasts: {},
  current_selected_space_config: null,
  space_configurations: [],
  trackEditor: {},
  tracks: [0],
  current_space_configuration: null
};
function getProfileImage(data) {
  return data?.user?.photoURL || '';
}
function getUid(data) {
  return data?.user?.uid;
}
function getDisplayName(data) {
  return data?.user?.displayName || '';
}
export const searchForTheoryProgressions = createAsyncThunk(
  'user/search-for-theory-progressions',
  async (query) => {
    const response = await fireApp.searchTheoryProgressions(query);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuthenticationInfo: (state, action) => {
      state.signedIn = action.payload.signedIn;
      state.userProfileImage = getProfileImage(action.payload)
      state.uid = getUid(action.payload);
      state.displayName = getDisplayName(action.payload);
    },
    setCurrentMidiFolderRoot: (state, action) => {
      state.midiFolderNavStack.push(state.currentMidiFolder);
      state.currentMidiFolder = action.payload;
    },
    setRepeating: (state, action) => {
      state.repeat = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.is_playing = action.payload;
    },
    setCurrentSpaceConfiguration: (state, action) => {
      state.current_space_configuration = action.payload;
    },
    removeToast: (state, action) => {
      delete state.toasts[action.payload.id]
    },
    setMidiNotes: (state, action) => {
      state.notes = action.payload;
    },
    setIsQuantized: (state, action) => {
      state.isQuantized = action.payload;
    },
    trackEditorUpdate: (state, action) => {
      switch (action.payload.type) {
        case 'mode':
          switch (action.payload.value) {
            case 'complete-copy':
              state.trackEditor[action.payload.type] = null;
              state.trackEditor.selection = null;
              state.trackEditor.copyItems = null;
              break;
            case 'copy':
              state.trackEditor[action.payload.type] = action.payload.value;
              if (state.trackEditor.selection) {
                let temp = JSON.parse(JSON.stringify(state.trackEditor.selection));
                let new_value = {}
                Object.values(temp).map(v => {
                  v.id = uuidv4();
                  new_value[v.id] = v;
                })
                state.trackEditor.copyItems = new_value;

                doSetActiveSelect(Object.values(JSON.parse(JSON.stringify(new_value))))
              }
              break;
          }
          break;
        case 'instruments':
          state.trackEditor[action.payload.type] = state.trackEditor[action.payload.type] || {};
          state.trackEditor[action.payload.type][action.payload.currentTrack] = action.payload.value;
          break;
        default:
          state.trackEditor[action.payload.type] = action.payload.value;
          break;
      }
    },
    toggleTrack: (state, action) => {
      if (state.tracks.indexOf(action.payload) === -1) {
        state.tracks = [action.payload, ...state.tracks]
      }
      else {
        if (state.tracks[0] !== action.payload) {
          state.tracks = [action.payload, ...state.tracks.filter(x => x !== action.payload)];
        } else {
          state.tracks = state.tracks.filter(x => x !== action.payload);
        }
      }
      if (!state.tracks.length) {
        state.tracks = [0]
      }
    },
    loadingKitSamples: (state, action) => {
      state.kitloading[action.payload.kit] = true;
      let id = uuidv4();
      state.toasts[id] = {
        id,
        text: TitlesService('Loading kit')
      }
    },
    setDeviceOptions: (state, action) => {
      let { name, key, value } = action.payload;
      state.deviceOptions[name] = state.deviceOptions[name] || {};
      state.deviceOptions[name][key] = value;
      updateDeviceOptions(JSON.parse(JSON.stringify(state.deviceOptions)));
    },
    loadedKitSamples: (state, action) => {
      state.kitloading[action.payload.kit] = false;
      let id = uuidv4();
      state.toasts[id] = {
        id,
        text: TitlesService('Loaded kit')
      }
    },
    setCurrentSelectedSpaceConfig: (state, action) => {
      state.current_selected_space_config = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchForTheoryProgressions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchForTheoryProgressions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.theoryProgressionList = action.payload;
      });
    builder.addCase(AddPatternAsync.fulfilled, (state, action) => {
      if (action.payload) {
      }
    });
    builder.addCase(GetMidiFolderStructure.fulfilled, (state, action) => {
      if (action.payload) {
        state.midiFolderStructure[action.payload.id] = action.payload;
      }
    });
    builder.addCase(LoadSpaceConfigurations.fulfilled, (state, action) => {
      if (action.payload) {
        state.space_configurations = action.payload;
      }
    })
    builder.addCase(PlayMagentaNotesAsnyc.pending, (state) => {
      state.is_playing = true;
    })
    builder.addCase(StopMagentaPlayerAsync.fulfilled, (state) => {
      state.is_playing = false;
    })
  }
});

export const {
  setCurrentSpaceConfiguration,
  setDeviceOptions,
  setAuthenticationInfo,
  setCurrentMidiFolderRoot,
  setMidiNotes,
  setRepeating,
  setIsQuantized,
  toggleTrack,
  setIsPlaying,
  loadedKitSamples,
  loadingKitSamples,
  trackEditorUpdate,
  removeToast,
  setCurrentSelectedSpaceConfig
} = userSlice.actions;
export const getActiveTracks = (state) => {
  return state.user.tracks;
}
export const getActiveTrack = (state) => {
  return state.user.tracks[0];
}
export const getUserSignedIn = (state) => {
  return state.user.signedIn;
}
export const getRepeat = (state) => {
  return state.user.repeat;
}
export const getIsPlaying = (state) => {
  return state.user.is_playing;
}
export const getToasts = (state) => {
  return Object.values(state.user.toasts);
}

export const getNotes = (state) => {
  return state.user.notes
}

export const getDeviceOptions = (state) => {
  return (state.user.deviceOptions)
}
export const getUserProfileImage = (state) => {
  return state.user.userProfileImage;
}
export const getUserId = (state) => {
  return state.user.uid;
}
export const getUserDisplayName = (state) => {
  return state.user.displayName;
}
export const getProgressionList = (state) => {
  return state.user.signedIn ? state.user.theoryProgressionList : [];
}
export const hasParentFolder = (state) => {
  return state.user.currentMidiFolder !== 'midis';
}
export const getCurrentMidiRoot = (state) => {
  if (state.user.midiFolderStructure[state.user.currentMidiFolder]) {
    return state.user.midiFolderStructure[state.user.currentMidiFolder]
  }
  return null;
}
export const getTrackEditorSelection = (state) => {
  return state.user.trackEditor.selection;
}
export const getIsQuantized = (state) => {
  return state.user.isQuantized;
}
export const getTrackEditorFileName = (state) => {
  return state.user.trackEditor.name;
}
export const getTrackEditorValue = (type) => (state) => {
  return state.user.trackEditor[type];
}
export const getTrackEditorInstrument = (state) => {
  return state.user.trackEditor.instrument;
}
export const getSpaceConfigurations = (state) => {
  return state.user.space_configurations;
}
export const selectCurrentSpaceConfiguration = (state) => {
  return state.user.current_space_configuration;
}
export const getCurrentSelectedSpaceConfiguration = (state) => {
  return state.user.current_selected_space_config;
}
export const getCurrentMidiFiles = (state) => {
  if (state.user.midiFolderStructure[state.user.currentMidiFolder]) {
    return state.user.midiFolderStructure[state.user.currentMidiFolder].files
  }
  return [];
}
export const getCurrentMidiFolders = (state) => {
  if (state.user.midiFolderStructure[state.user.currentMidiFolder]) {
    return state.user.midiFolderStructure[state.user.currentMidiFolder].folders;
  }
  return [];
}

export default userSlice.reducer;
