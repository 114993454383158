import React from 'react';
import { convertToMelodyNote, findLetter } from '../../base/base-lib';
import ChordMaster, { calculateV } from '../../base/chord-master';
import { FormGroup } from '../FormGroup';
import ScaleNotes from './ScaleNotes';
import StaffNotes from './StaffNotes';


export default class Banks extends React.Component {
    reader = null;
    constructor(props) {
        super(props);
    }
    render() {
        const { props } = this;
        const { nextBank, banks } = this.props;

        let sections = [];

        let bankItems = Object.keys(banks).map((bankKey) => {
            let bankInfo = banks[bankKey];
            return <BankItem onPlay={() => {
                if (props.onPlay) {
                    props.onPlay(bankInfo.notes);
                }
            }} onStop={() => {
                if (props.onStop) {
                    props.onStop(bankInfo.notes);
                }
            }} onRemove={() => {
                if (props.onRemove) {
                    props.onRemove(bankKey);
                }
            }} onMoveTones={(dir) => {
                if (props.onMoveTones) {
                    props.onMoveTones({ direction: dir, key: bankKey })
                }
            }} bankKey={bankKey} key={`BANK-key-${bankKey}`} bankInfo={bankInfo} />
        })

        return (
            <div style={{ padding: 10 }}>
                <div style={{
                    flex: 1,
                    flexDirection: 'row'
                }}>
                    <div>
                        {this.props.nameInput}
                    </div>
                    <div>
                        {nextBank ? <StaffNotes onPlay={() => {
                            if (props.onPlay) {
                                props.onPlay(nextBank.notes);
                            }
                        }} skipbank onStop={() => {
                            if (props.onStop) {
                                props.onStop(nextBank.notes);
                            }
                        }} value={nextBank.notes} /> : null}
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {bankItems}
                </div>
            </div>
        )
    }
}

function BankItem(props) {
    let { bankKey, bias, bankInfo } = props;
    let { notes, chord, noteLetter } = bankInfo;
    let linkedNote = convertToMelodyNote(bankKey, bias);
    if (!linkedNote) {
        return <div></div>
    } if (!noteLetter) {
        return <div></div>
    }
    return (<div>

        <h4>{linkedNote.key}{linkedNote.accidental}/{linkedNote.octave}</h4>
        <StaffNotes skipbank
            title={`${chord.userMade ? '' : noteLetter.key}${chord.userMade ? '' : noteLetter.accidental} ${chord.name}`}
            bias={bias}
            key={JSON.stringify(notes)}
            onPlay={() => {
                if (props.onPlay) {
                    props.onPlay({ value: notes, play: true });
                }
            }} onStop={() => {
                if (props.onStop) {
                    props.onStop({ value: notes, play: false });
                }
            }}
            onRemove={() => {
                if (props.onRemove) {
                    props.onRemove();
                }
            }}
            onMoveTones={(dir) => {
                if (props.onMoveTones) {
                    props.onMoveTones(dir)
                }
            }}
            onAddToBank={() => {
                if (props.onAddToBank) {
                    props.onAddToBank(bankInfo);
                }
            }}
            value={notes} />
    </div>)
}
