import React from 'react';
import PanelButton from './PanelButton';


export default class Panel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }
    buttons() {
        return this.props.buttons ? this.props.buttons() : [];
    }
    footer() {
        return this.props.footer ? this.props.footer() : [];
    }
    open() {
        return this.state.open
    }
    stretch() {
        return this.props.stretch ? { height: '100%' } : {}
    }
    bodyBackgroundColor() {
        return this.props.bodyBackgroundColor ? { backgroundColor: this.props.bodyBackgroundColor } : {};
    }
    bodyStyle() {
        return {
            ...this.bodyBackgroundColor()
        }
    }
    sidePanel() {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row'
            }}>
                <div style={{
                    minWidth: 250
                }}>{this.props.sidepanel}</div>
                <div style={{
                    flex: 1
                }}>{this.props.children}</div>
            </div>
        )
    }
    render() {
        return (
            <div className={`card direct-chat direct-chat-warning box-header ${this.props.cls || ''}`} style={{
                display: 'flex',
                paddingTop: 1,
                paddingBottom: 1,
                ...this.stretch()
            }}>
                <div className="card-header drag-me-header">
                    <h3 className="card-title">{this.props.title}</h3>
                    <div className="card-tools">
                        {this.buttons()}
                    </div>
                </div>
                <div className="card-body" onDragOver={(ev) => {
                    ev.preventDefault();
                    ev.dataTransfer.dropEffect = "copy";
                    if (this.props.onBodyDragOver) {
                        this.props.onBodyDragOver(ev);
                    }
                }}
                    onDrop={(ev) => {
                        if (this.props.onBodyDrop) {
                            this.props.onBodyDrop(ev);
                        }
                    }} style={{ ...this.bodyStyle() }}>
                    {!!this.props.sidepanel ? this.sidePanel() : this.props.children}
                </div>
                <div className="card-footer">
                    {this.footer()}
                </div>
            </div >
        )
    }
}