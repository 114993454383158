import { uuidv4 } from "./base-lib";
import { CaptureChords } from "./chord-progression";

const state = {
    notes: {},
    chords: {}
}

export function getNotes(id) {
    return state.notes[id];
}
export function getChords(id) {
    return state.chords[id];
}
export function setNotes(id, notes) {
    state.notes[id] = notes.map(v => {
        v.id = v.id || uuidv4();
        return v;
    });
    state.chords[id] = CaptureChords(notes);
}