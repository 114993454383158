export default [{
    name: `I can't believe that you're in love with me`,
    composer: '',
    key: '',
    progression: 'C7 F Fm C D7 G7 C Dm7 G7 C E7 Bm7 G E7 A7 D7 Am7 F D7 G7 C7/G Fm C D7 G7 C'.split(' ').filter(x => x)
}, {
    name: `I can't Get Started`,
    composer: '',
    key: '',
    progression: 'C Dm7 G7 E7 Cm C Cm7 G7 C A7 D7 G7 C A7 Em7 A7 D Em7 Dm7 Em7 Dm7 G7 C7 Cm Bm C A7 Dm7 G7 C'.split(' ').filter(x => x)
}, {
    name: 'Giant Steps',
    composer: 'John Coltrane',
    progression: ['BM7', 'D7', 'GM7', 'Bb7', 'EbM7', 'Am7', 'D7', 'GM7', 'Bb7', 'EbM7', 'F#7', 'BM7', 'Fm7', 'Bb7', 'EbM7', 'Am7', 'D7', 'GM7', 'C#m7', 'F#7', 'BM7', 'Fm7', 'Bb7', 'EbM7', 'C#m7', 'F#7']
},
{
    name: 'Countdown',
    composer: 'John Coltrane',
    progression: ['Em7', 'F7', 'BbM7', 'Db7', 'GbM7', 'A7', 'DM7', 'Dm7', 'Eb7', 'AbM7', 'B7', 'EM7', 'G7', 'CM7']
},
{
    name: 'Tune Up',
    composer: 'John Coltrane',
    progression: ['Em7', 'A7', 'DM7', 'DM7', 'Dm7', 'G7', 'CM7', 'CM7']
},
{
    name: 'A-Hunting We Will Go',
    composer: 'Traditional',
    progression: 'G D G C G D7 G'.split(' ').filter(x => x)
},
{
    name: 'A Tisket, A Tasket',
    composer: 'Traditional',
    progression: 'C G C G C'.split(' ').filter(x => x)
},
{
    name: 'Abide With Me',
    composer: 'Traditional',
    progression: 'C G7 C F G C F C Dm C D7 G C G7 C F A7 Dm G7 C F C G7 C'.split(' ').filter(x => x)
},
{
    name: 'All Glory, Laud and Honor',
    composer: 'Mechior Teschner',
    progression: 'C F G7 C G Am F G C F G7 C G Am F G C D G Am D G C F C G C F G C'.split(' ').filter(x => x)
},
{
    name: 'All The Pretty Little Horses',
    composer: 'Traditional',
    progression: 'Em D G Em D G Em G D C G Em D G Em D G Em'.split(' ').filter(x => x)
},
{
    name: 'All Through the Night',
    composer: 'Welsh Hymn',
    progression: 'G C A D C D7 G C A D C D7 G Am D7 G C A D C D7 G'.split(' ').filter(x => x)
},
{
    name: 'Angels We Have Heard on High',
    composer: 'Traditional French Melody',
    key: 'FM',
    progression: 'F C F C F C C7 F F C F C F F C7 F F D Gm C F Bb C C7 F C F Bb F C F D Gm C F Bb C C7 F C F Bb C7 F'.split(' ').filter(x => x)
},
{
    name: 'Amazing Grace',
    composer: 'Traditional',
    key: 'GM',
    progression: 'G D7 Em C G D7 G C G Em D7 G'.split(' ').filter(x => x)
},
{
    name: 'America',
    composer: 'Traditional',
    key: 'FM',
    progression: 'F Gm C Dm C F Dm Gm F C Dm Gm F C F C7 F C F Bb F Bb F C7 F'.split(' ').filter(x => x)
},
{
    name: 'America, the Beautiful',
    composer: 'Katherine Bates & Samuel A. Ward',
    key: 'GM',
    progression: 'G D D7 G D7 G D A7 D D7 G D7 D D7 G Am G C G C D7 G'.split(' ').filter(x => x)
},
{
    name: 'Anchors Aweigh',
    composer: 'Miles, Zimmerman, Lottman',
    key: 'CM',
    progression: 'C G Am C G7 C F C Cd G7 C D7 G7 F G7 C G Am C G7 C F C Am G#d Am C G7 C'.split(' ').filter(x => x)
},
{
    name: 'Are You Sleeping? Brother John',
    composer: 'Traditional',
    key: 'GM',
    progression: 'G D7 G D7 G D7 G D7 G D7 G D7 G D7 G D7 G'.split(' ').filter(x => x)
},
{
    name: 'Arkansas Traveler',
    composer: 'American Folksong',
    key: 'GM',
    progression: 'G C G D7 A7 D7 G C G C D7 G D7 G D7 G D7 G D7 G D7 G D7 G C D7 G'.split(' ').filter(x => x)
},
{
    name: 'Auld Lang Syne',
    composer: 'Traditional',
    key: 'FM',
    progression: 'F C7/E F Bb F/A F Ed A7/C#'.split(' ').filter(x => x)
},
{
    name: 'Honey',
    composer: '',
    key: '',
    progression: 'F Bb f G7 C7 F Dd Gm7 C7 F Bb F A7 Gm Dm F7 Bb Dd F Cm D7 G7 C7 F'.split(' ').filter(x => x)
},
{
    name: 'Adeste Fideles',
    composer: '',
    key: '',
    progression: 'G D G D G C G/D D Em D A D A7 D Em7 D/A A7 D G D7 G Ds7 D7 G D G Em Am D G D G D7 G G D G D7 G D G D7 G D A7 D D7 G C G/D Am/D D7 G'.split(' ').filter(x => x)
}, {
    name: `In My Merry Oldsmobile`,
    composer: 'G Edwards',
    key: '',
    progression: 'D G E7 A7 D7 D7 G G E7 Em A7 D7 G'.split(' ').filter(x => x)
}, {
    name: `Smiles`,
    composer: '',
    key: '',
    progression: 'D7 Am7 D7 G Gm B7 Em A7 Em7 A7 D7 Cm6 D7 G7 C Cd G D7 Am7 D7 G'.split(' ').filter(x => x)
}, {
    name: `Sweet Adeline`,
    composer: '',
    key: '',
    progression: 'Bb Eb Eb Bb G7 C7 F7 Bb Bb Bb7 Eb Bb C7 F7 Bb Eb Eb Bb G7 C7 F7 Bb Eb Ebm Eb F7 Bb D7 Eb G7 C7 F7 Bb F7 Bb D7 Cb G7 C7 Ed Bb G7 C7 F7 Bb'.split(' ').filter(x => x)
}, {
    name: `Take Me Back To New York Town`,
    composer: 'H Von Tilzer',
    key: '',
    progression: 'C Bd G7 Cd C Cd G7 C Ed G7 C E7 F D7 C G7 C'.split(' ').filter(x => x)
}, {
    name: `Wedding Bells`,
    composer: '',
    key: '',
    progression: 'Bb Eb Cdim Bb Bb Cd Bb Dd F7 Bb Ed F7 Bb D7 Gm C7 F7 Bb Eb Cd Bb F7 Bb Eb Cd Bb Dd F7 Bb'.split(' ').filter(x => x)
}, {
    name: `L'Amour Toujours L'Amour`,
    composer: 'R Frimi',
    key: '',
    progression: 'D A7 A7 D Dd Em7 A7 D C#7 F#m E7 Em7 A7 D C#7 F#m E7 Em7 A7 D C#7 F#m E7 Em7 A7 D A7 A Bm6 C7 F#7 Bm G6 C#7 D Em7 A9 D'.split(' ').filter(x => x)
}, {
    name: `Madelon`,
    composer: 'C. Robert',
    key: '',
    progression: 'C G C G7 C Dm Dm7 G7 C Ed G D7 G7 C F Dm7 G7 C'.split(' ').filter(x => x)
}, {
    name: `Play To Me, Gypsy`,
    composer: 'K. Vacek',
    key: '',
    progression: 'Ab F7 Bbm Eb7 Ab Ebd Eb7 Ab Db Ab '.split(' ').filter(x => x)
}, {
    name: `Song of the Islands`,
    composer: 'C. King',
    key: '',
    progression: 'C Cd C7 G7 C7 F D7 G7 C7 F'.split(' ').filter(x => x)
}, {
    name: `Sweet Leilani`,
    composer: 'Owens',
    key: '',
    progression: 'C7 F F7 Bb Bbm F Fm C9 F Dm Cd C7 F Cd C7 Dd C9 F C7 F F7 Bb Bbm F Fm C9 F Dm Cd C7 F Cd C7 Dd C9 F'.split(' ').filter(x => x)
}, {
    name: `That Tumble Down Shack in Athlone`,
    composer: '',
    key: '',
    progression: 'C Fm C G C E7 F C A7 Dm D7 G7 C Fm C G C E7 F C D7 G7 C'.split(' ').filter(x => x)
}, {
    name: `Italian Street Song`,
    composer: '',
    key: '',
    progression: 'D D D A7 E7 D Em7 A7 D Em B Em B Em A7 Bm E7 A7 D'.split(' ').filter(x => x)
}, {
    name: `Merry Go Round Waltz`,
    composer: '',
    key: '',
    progression: 'Bb F7 Bb Eb G7 Cm Bb Bb'.split(' ').filter(x => x)
}, {
    name: `You're Breaking My Heart`,
    composer: 'Genaro-Skylar',
    key: '',
    progression: 'C Dm7 G7 C Gm Dm E7 EM B G7 E7 Am Dm7 Fm C G7 C'.split(' ').filter(x => x)
}, {
    name: `Semper Paratus (Always Ready)`,
    composer: 'F.S. Van Boskerck',
    key: '',
    progression: 'F Bb F C7 F A7 Dm Cd C G7 C7 F Bb F C7 F A7 Dm Ebm F G7 C7 F'.split(' ').filter(x => x)
}, {
    name: `Stout Hearted Men`,
    composer: 'S Romberg',
    key: '',
    progression: 'F C9 C7 F C F D7 Gm Gm F C7 F Gm Bbm F D7 Gm Am C7 F'.split(' ').filter(x => x)
}, {
    name: `The Shores of Tripoli`,
    composer: 'L.Z. Phillips',
    key: '',
    progression: 'Eb Bb7 Eb Eb7 Eb Eb Bb7 Eb Bb7 Eb G7 Ab Eb G7 Ab Eb Ed Bb7 Eb Bb7 Eb Fm Bb7 Eb'.split(' ').filter(x => x)
}, {
    name: `Shoeless Joe From Hannibal MO`,
    composer: '',
    key: '',
    progression: 'Eb6 Bb7 Eb6 Bb7 Eb6 Ab6 Eb9 Ab6 Eb7 Ab6 Eb7 Eb7 C9 Bb9 Eb Bb9 Db9 Ab Db Eb Fm7 Eb'.split(' ').filter(x => x)
}, {
    name: `Whatever Lola wantsl Lola Gets`,
    composer: '',
    key: '',
    progression: 'Dm9 Dm A Dm7 E7 Em7/B Bb A7 Dm10 Dm A Dm7 E7 Gm Ab Gm A7 Dm Eb D Eb D A7 Dm Dm A Dm7 E7 Em7/B Bb A7 Dm Bb Dm Ab A7 Dm Db Dm Dm6'.split(' ').filter(x => x)
}, {
    name: `Next Time It Happens`,
    composer: 'Rodgers & Hammerstein',
    key: '',
    progression: 'C C6 C Dm7 G7 Dm7 Em Dm7 Em Dm7 G7 Dm7 G7 Dm7 G7 Dm7 G7 C F B7 Cd Em7 C Dm7 G7 C C6 C G Bm G G6 G Am7 D7 G Dm7 G7 C C6 C Dm7 G7 Dm7 Em Dm7 Em Dm7 G7 Dm7 G7 G9 Dm7 G7 C9 C7 F B7 Cd Em7 C Dm7 G7 Dm7 G7 G9 Dm7 G7 C9 C7 F B7 Cd Em7 C Dm7 G7 C7 F6 C6 Dm7 C D9 C G7/C G7 C'.split(' ').filter(x => x)
}, {
    name: `I've Never Been In Love Before`,
    composer: 'Loesser',
    key: '',
    progression: 'F7 Bb Gm Cm7 F7 F7+6 Bbm7 Dm7 G7+5 Cm F7 Bb Bb7 Bb Bb7-5 Bb7 Bb7+5 Eb7 Cm7 F7 Bb7 D7+5 Gm C7 A7 D A7 F7 F7-5 Bb Gm Cm7 F7 F7+5 Bb7 Dm7 G7+5 Cm Cm7 F7 Bb Eb Gbd Bb'.split(' ').filter(x => x)
}, {
    name: `If I Were A Bell`,
    composer: 'Loesser',
    key: '',
    progression: 'A7 Ebd G9 Gm7 Bbm F C Fm F#d Eb7 D7 G9 G7 Bbm C7 C7b9 F Bb9 A Bb7 A A7 Ebd G9 Gm7 Bbm F C Fm F#d Eb7 D7 G9 G7 Bbm C7 C7b9 F Bb Abd F E7+5 Eb7 D9 D7b9 Dm7 G9-5 F7 Dm7 Bb7 C9 F Eb F'.split(' ').filter(x => x)
}, {
    name: `I Love Paris`,
    composer: 'Cole Porter',
    key: '',
    progression: 'Cm G7 Fm G7 Cm C Dm7 G7 C F C G7 C Cd G7'.split(' ').filter(x => x)
}, {
    name: `Whistle While You Work`,
    composer: '',
    key: '',
    progression: 'G+ C C6 C7 C6 Dm7 G7 Dm7 G7 Dm7 G7 C F F6 F F6 Fm Fm6 C Cd Dm7 G7 Dm7 G7 Dm7 G7 C'.split(' ').filter(x => x)
}, {
    name: `Now Is The Hour`,
    composer: '',
    key: '',
    progression: 'F Bb Bb F C7 Gm7 C7 F Bb Fd F Dm G7 C7 Gm7 C7 F Bb Bbm F C7 Gm7 C7 F Bb Fd F D+ D7 G7 C7 F'.split(' ').filter(x => x)
}, {
    name: `Anne, Get Your Gun`,
    composer: 'Irving Berlin',
    key: '',
    progression: 'C G7 C G7 C Cd Dm7 G7 C G7 C G7 C Dm7 C Dm7 G7 C Dm7 G7 Dm7 C Dm7 G7 Dd Am Am7 D7 G7 C G7 C G7 C Cd Dm7 G7 C C7 F Fm C Cd Dm7 C F C Gm A7 D7 G7 C C7 F Fm7 C Cd D7 C'.split(' ').filter(x => x)
}, {
    name: ``,
    composer: '',
    key: '',
    progression: ''.split(' ').filter(x => x)
}, {
    name: ``,
    composer: '',
    key: '',
    progression: ''.split(' ').filter(x => x)
}, {
    name: ``,
    composer: '',
    key: '',
    progression: ''.split(' ').filter(x => x)
}, {
    name: ``,
    composer: '',
    key: '',
    progression: ''.split(' ').filter(x => x)
}, {
    name: ``,
    composer: '',
    key: '',
    progression: ''.split(' ').filter(x => x)
}, {
    name: ``,
    composer: '',
    key: '',
    progression: ''.split(' ').filter(x => x)
}, ...([{
    name: `April Showers`,
    composer: 'L. Silvers',
    key: '',
    progression: 'Am7 D7 C#7/D D7 G7 G6 C#7/G# Am F7/A D7 C#7/D D7 G7 G6 F7 E7 Bm7 E E7 Am Am Em7 A9 D7 C#/D Am7/D Dd D7 Am7 D7 C#7/D D7 G7 G6 G G6 G G6 Bm7/F E7 F7 E7 Am Am+ G6 G+ Bm7(b5)/F E7 F7 E7 Am Am+ Am6 D#7/A# E7/B C7 Am7(b5) G/D Em7 A9 D7/A Bbd D7/A Am7/D D13(b9) G6'.split(' ').filter(x => x)
}, {
    name: `Afternoon In Paris`,
    composer: 'J. Lewis',
    key: '',
    progression: 'C7 Cm7 F7 Bb7 Bbm7 Eb7 Ab7 Dm7(b5) G7b9 C7 Am7 Dm7 G7 C7 Am7 Dm7 G7 Dbm7 Gb7 Dm7 G7 C7 Cm7 F7 Bb7 Bbm7 Eb7 Ab7 Dm7(b5) G7(b5) C7 Am7 Dm7 G7'.split(' ').filter(x => x)
}, {
    name: `After You've Gone`,
    composer: 'J.T. Layton',
    key: '',
    progression: 'EbM7 Ab9(#11) Bb7 G9 C9 F9 Bb6 Bb7 Eb7 Ab9(#11) Bb7 G7 Cm7 G7/D Eb6 Ab9(#11) Bb7 D7 Gm7 C13 Bb7 D7 Gm7 13 Bb7/F G7(b9) G7(b9) Cm7 F13(b9) Bb6 Fm7 Bb7'.split(' ').filter(x => x)
}, {
    name: `Ain't Misbehavin'`,
    composer: 'F Waller',
    key: '',
    progression: 'Eb7 Cm7 Fm7 Bb9 Eb G+7 Ab6 Abm6 Eb Gb7 Fm7 Bb9 Eb Ed F7 Bb7 Eb6 Ab Eb6 G7 Cm Ab7/C F7/C C7 Bb Bd Cm7 F9 Bb7 C7 F7 Bb7 Eb7 Cm7 Fm7 Bb9 Eb G7+ Ab6 Abm7 Eb Gb7 Fm7 Bb7 Eb6 C7 Fm7 Bb7'.split(' ').filter(x => x)
}, {
    name: `Ain't She Sweet`,
    composer: 'M Ager',
    key: '',
    progression: 'Bb6 E9/B Cm7 F7 Bb6 E9/B Cm7 F7 Bb6 D7 G7 G+7 C7 F7 Bb6 Bb7 Eb9 Eb7 Bb7 Eb9 Eb9 Eb6 Bd Cm7 F7 Bb6 E9/B Cm7 F7 Bb6 E9/B Cm7 F7 Bb6 D7 G7 G+7 C7 F7 Bb6'.split(' ').filter(x => x)
}, {
    name: `Alfie's Theme`,
    composer: 'S Rollins',
    key: '',
    progression: 'Bbm7 Bbm7/Ab Gm7(b5) Gb7 Bb7/F Gm7(b5) Cm7(b5) F7(#9) Bbm7 Bbm7/Ab Gm7(b5) Eb7 Bbm7/F Gm7(b5) Cm7(b5) F7 Bbm7 Bbm7 Db7/Ab Gb7 Bbm7 Db7/Ab Gb7 Bbm7 Db7/Ab Gb7 Bbm7 Db7/Ab Gb7 Bbm7'.split(' ').filter(x => x)
}, {
    name: `Airegin`,
    composer: 'S Rollins',
    key: '',
    progression: 'Fm7 C7(#9b5) Fm7 F7 Bbm7 F+7(#9) Bbm7 Dm7 G7 C7 C#m7 F#7 B7 Cm7 F7 Bb7 Bbb7 Eb7 Ab7 Gm7(b5) C7 Bbm7 Eb7 Cm7(b5) F7 Bbm7 Eb7 Ab6 Gm7(b5) C7'.split(' ').filter(x => x)
}, {
    name: `Alice In Wonderland`,
    composer: 'S Fain',
    key: '',
    progression: 'Dm7 G7 C7 F7 Bm7(b5) E7 Am7 Eb7 Dm7 G7 Em7 Am7 Dm7 G7 Em7 A7 C^ Am7 Dm7 G7 Em7 Am7 Dm7 G7 C7 F#m7(b5) B7(#9) Em7 A7 Dm7 A7/E Dm7/F A7 D7 G7 Bm7 G7 C7 F7 Bm7(b5) E7 Am7 Eb7 Dm7 G7 Em7 Am7 Eb7 Dm7 G7 Em7 Am7 Dm7 G7 C6'.split(' ').filter(x => x)
}, {
    name: `All Blues`,
    composer: 'M Davis',
    key: '',
    progression: 'G7 C7 G7 D7(#9) Eb7(#9) D7(#9) G7'.split(' ').filter(x => x)
}, {
    name: `A Child is Born`,
    composer: 'Thad Jones',
    key: '',
    progression: 'Bb7 Eb(-5)/Bb Bb7 Eb(-6)/Bb Bb9 Eb(-)/Bb A(-7)'.split(' ').filter(x => x)
}, {
    name: `As Time Goes By`,
    composer: 'Herman ???',
    key: '',
    progression: 'Fm7 Bbm7 Bbm6 Bb7 Eb6 Fm7 F# Gm7 F7 Fm7 Bb7 Eb9 Fm7 Bb7 Eb6 Ebm7 Abm7 C7 Fm Ad Cm Ab7 F7 Bb7 Bbd Bb7 Fm7 Bb7 Bbm6 Bb7 Eb6 Fm7 F#7 Gm7 F7 Gm7 C7 Fm7 Bb7 Eb6 Db7 Eb6 Eb9'.split(' ').filter(x => x)
}, {
    name: `Autumn In New York`,
    composer: 'Vernon Duke',
    key: '',
    progression: 'Gm7 Am7 Bb6 C7 F7 Gm7 Am7 D7(b9) Gm7 Am7 Bb6 C7 Am7(b5) D7 Gm7 Dm7 G7(b9) C7 C7 Ca7 Gm7 Am7 Bb6 C7 F7 Gm7 Am7 D7 Db7 Cm7 Dm7 Ebm6 F7 Bbm6 Abm7 Gb7 Fm7 C7 Fm Ab7 Db7 Ab7 Db7 Ab7 Gm7 Am7 Bbm6 C7(b9) Fm'.split(' ').filter(x => x)
}, {
    name: `Autumn Leaves`,
    composer: 'Johnny Mercer',
    key: '',
    progression: 'Am7 D7 G7 C7 F#m7(b5) B7 Em B7 Em F#m7(b5) B7(b9) Em Am7 D7 G7 F#m7(b5) B7(b9) Em7 Eb7 Dm7 Db7 C7 B7(b9) Em'.split(' ').filter(x => x)
}, {
    name: `Bessie's Blues`,
    composer: 'John Coltrane',
    key: '',
    progression: 'Eb7 Ab7 Eb7 Ab7 Eb7 Bb7 Ab7 Eb7 Bb7'.split(' ').filter(x => x)
}, {
    name: `Big Nick`,
    composer: 'John Coltrane',
    key: '',
    progression: 'G7 B9 Am7 D7 G7 E7 Am7 D7 G Bd C7 C#d G7/D E7 Am7 D7 Am7 D7 G7 E7 Am7 D7'.split(' ').filter(x => x)
}, {
    name: `Blue Bossa`,
    composer: '',
    key: '',
    progression: 'Cm7 Fm7 Dm7(b5) G7 Cm7 Ebm7 Ab7 Db7 Dm7(b5) G7 Cm7 G7'.split(' ').filter(x => x)
}, {
    name: `Blue Comedy`,
    composer: 'Michael Gibbs',
    key: '',
    progression: 'Eb7 Ab7 Eb7 Db7 D7 G7 F#7 B7 E7 F7 A7(#11) Bb7(b9)'.split(' ').filter(x => x)
}, {
    name: `Blue in Green`,
    composer: 'Miles Davis',
    key: '',
    progression: 'Bb7(#11) A7 Dm7(9) Db7 Cm7 F7(b9) Bb7 A7(b13) Dm6(9) E7(#9) Am7(9) Dm7(9) Bb7(#11) A7(#9) Dm6(9)'.split(' ').filter(x => x)
}, {
    name: `Blue Monk`,
    composer: 'Thelonius Monk',
    key: '',
    progression: 'Bb Eb7 Bb F7 Bb Bb7 Eb Ed7 Bb F7 Bb F7 Bb F7 F7 Bb F7'.split(' ').filter(x => x)
}, {
    name: `Blue Trane`,
    composer: 'John Coltrane',
    key: '',
    progression: 'Fm7 Bb7 Cm Fm7 Bb7 Cm Bbm7 Eb7 Fm Fm7 Bb7 Cm Am7 Gm Fm7 Bb7 Cm Fm7 Bb7 Cm7(b5) Cm'.split(' ').filter(x => x)
}, {
    name: `Chicken Feathers`,
    composer: 'steve Kuhn',
    key: '',
    progression: 'Gm Bb7 Eb7 Am7(b5) D7 Gm Em7(b5) Am7(b5) D7 Am7(b5) D7 Dm7(b5) Em7(b5) Fm7 Am7(b5) D7(b9) Gm Bb7 Eb7 Am7(b5) D7 Gm Em7(b5) Am7(b5) D7 Gm'.split(' ').filter(x => x)
}, {
    name: `Come Sun Day`,
    composer: 'Duke Ellington',
    key: '',
    progression: 'F7 Eb7 F7 Da7 G7 Cm7 F7 Bb Eb Bb Eb Eb D9 Eb7 D7(9) Gm7 C7 F7 Cm7 F7 Ab7 Ga7 C7 Fa7 F7 Eb7 F7 Da7 G7 Cm7 F7 Ab7 A7(b9) Bb'.split(' ').filter(x => x)
}, {
    name: `Con Alma`,
    composer: 'Dizzie Gillespie',
    key: '',
    progression: 'E7 G#7/D# C#m7 B7 Bb7 Eb7 Eb7 Ab9 Db7 F7/C Bbm7 Ab7 G7 C7 Cm7(b5) F7(b9) F#m7 B7 E7 Fm7 Bb7'.split(' ').filter(x => x)
}, {
    name: `Confirmation`,
    composer: 'Charlie Parker',
    key: '',
    progression: 'F Em7(b5) A7 Dm Cm7 Fa7 Fm7 Bb7 Am7(b5) D7 G7 C7(b9) F Em7(b5) A7 Dm Cm7 F7 Fm7 Bb7 Am7(b5) D7 Gm7 C7 F Cm Cm(9) Cm7 F7 Bb7 Ebm7 Ab7 Db7 Gm7 C7 F Em7(b5) A7 Dm Cm7 F7 Fm7 Bb7 Am7(b5) D7 Gm7 C7 F'.split(' ').filter(x => x)
}, {
    name: `Coral`,
    composer: 'Keith Jarret',
    key: '',
    progression: 'Cm7 F7 D/Bb Bb7 Am7(b5) D7(b9) Gm7 C7 B7 F#/A# G#m7 B7/F# Gb Gb7 Fm7(9 11) Dm7(b5) G7(b9)'.split(' ').filter(x => x)
}, {
    name: `Moonchild`,
    composer: 'Keith Jarret',
    key: '',
    progression: 'C#m7 F#7 G#m7 Em7 C#m7 G# Dm7 E7 Am Fm7 Bb7 Am7 Em7'.split(' ').filter(x => x)
}, {
    name: `Could It Be You`,
    composer: 'Cole Porter',
    key: '',
    progression: 'Bb7 Cm7 F7 Bb7 Gm7 Gbm7 Fm7 Bb9 Eb7 Fm7 Bb7 Eb7 Gm7 C7 F7 A7(b9) Dm7 G7 Am7(b5) D7(b9) Gm Gm7 C7 F7 C9 Cm7 F7 Bb7 D7 Eb7 C7 G7 Ebm7 Ab7 Bb7 C9 Cm7 F7 Bb6 Cm7 F9'.split(' ').filter(x => x)
}, {
    name: `Countdown`,
    composer: 'John Coltrane',
    key: '',
    progression: 'Em7 F7 Bb7 Db7 Gb7 A7 D7 Dm7 Eb7 Ab7 B7 E7 G7 C7 Cm7 Db7 Gb7 A7 D7 F7 Bb7 Em7 F7 Bb7 Eb7'.split(' ').filter(x => x)
}, {
    name: `Days and Nights Waiting`,
    composer: 'K. Jarrett',
    key: '',
    progression: 'Cm7 F7(b9) Bb7 A7 F#m7 Bm7 Em7 A7 D Cm7 F7 Ebm7 Ab7 Cm'.split(' ').filter(x => x)
}, {
    name: `Dearly Beloved`,
    composer: 'Kern/Mercer',
    key: '',
    progression: 'Dm7/G G7 Dm7/G G7 Dm7/G G7 Dm7/G G7 C7 Am7 Dm7 G7 Dm7 G7 C7 Am7 Abm7 Db7 C7 Am7 D7 Dm7 G7 Dm7 G7 C6'.split(' ').filter(x => x)
}, {
    name: `Dexterity`,
    composer: 'Charlie Parker',
    key: '',
    progression: 'Bb Cm7 F7(b9) Bb G7 Cm7 F7 Bb Bb7 Eb Ab7 Dm7 Db7 Cm7 F7 Bb Cm7 F7(b9) Bb G7 Cm7 F7 Bb Bb7 Eb Ab7 Cm7 F7 Bb Am7 D7 Am7 D7 Dm7 G7 Gm7 C7 Cm7 F Bb Cm7 F7(b9) Bb G7 Cm7 F7 Bb Bb7 Eb Ab7 Cm7 F7 Bb'.split(' ').filter(x => x)
}, {
    name: `Django`,
    composer: 'John Lewis',
    key: '',
    progression: 'F Bb6 C7 F F7 Bbm7 Eb7 Ab7 Db7 C7 G7 C7 F Bbm6 C7 Fm Fm Bbm C7 F7a Fm'.split(' ').filter(x => x)
}, {
    name: `Donna Lee`,
    composer: 'Charlie Parker',
    key: '',
    progression: 'Ab F7 Bb7 Bbm7 Eb7 Ab Ebm7 D7 Db Dbm7 Ab F7 Bb7 Bbm7 Eb7 Ab F7 Bb7 C7 Fm C7(#9) Fm C7 Fm Abd Ab F7 Bbm7 Eb7 Ab Bbm7 Eb9'.split(' ').filter(x => x)
}, {
    name: `Don't Get Around Much Anymore`,
    composer: 'Duke Ellington',
    key: '',
    progression: 'C7 Dm7 D#7 Em7 A7 Am7 D7 G7 C G7 C C9 F Fm Em7 C C7 Ca7 D7 F#m7(b5) B7(b9) Em7 D7(b9) G7 C7 Dm7 D#7 Em7 A7 Am7 D7 G7 C'.split(' ').filter(x => x)
}, {
    name: `The Duke`,
    composer: 'Dave Brybeck',
    key: '',
    progression: 'C7 F7 F#m7(b5) B7 Em7 Am9 Dm7 Fm7 Bb7 Eb7 Db7 Cm7 Bm7 Bbm7 Ab7 Dm7 Db7 C7 F Em7 D7 C7 Bbm7 Ab G Gb F Eb D Db7 C7  F7 F#m7(b5) B7 Em7 Am9 Dm7 Fm7 Bb7 Eb7 Db7 Cm7 Bm7 Bbm7 Ab7 Dm7 Db7 C7'.split(' ').filter(x => x)
}, {
    name: `Easy to Love`,
    composer: 'Cole Porter',
    key: '',
    progression: 'Dm7 Gm7 Dm7 G7 C7 F7 Em7 F7 Dm7 G7 C7 Am7 Dm7 G7 C7 Am7 Dm7 G7 Em7 A7 Dm7 G7 Em7 A7 A7 Dm7 Fm6 C7 E7 Ebd Dm7 G7 C6'.split(' ').filter(x => x)
}, {
    name: `Ecclusiastics`,
    composer: 'Charles Mingus',
    key: '',
    progression: 'F7 Gm7 Am7 Bb7 B7 E7 Am7 Da7 Bb7 Db7 E7 G7 C7 F7 Bb7 Eb7 Ab7 Db7 Gm7(b5) C7 F7'.split(' ').filter(x => x)
}, {
    name: `El Gaucho`,
    composer: 'Wayne Smarte',
    key: '',
    progression: 'F7 Eb7 Dm Bb7 B7(b9) C7 Fm11 Gb7 Fm11 Gb7 Eb7 Eb7 Cm9 Dm13 Cm9 Dm11 Bm11 Em11'.split(' ').filter(x => x)
}, {
    name: `Footprints`,
    composer: 'Wayne Shorter',
    key: '',
    progression: 'Cm7 Cm7 Fm7 Cm7 D7 Db7 Cm7'.split(' ').filter(x => x)
}, {
    name: `Gemini`,
    composer: 'Jimmy Heath',
    key: '',
    progression: 'Eb Db Eb Db Eb Db Eb Db Ebm9 Ab9 Eb Db Eb Ga7 C7(#9) F7(b9) Bba7 C7(#9) Bb7(#9) Eb Db Eb Db'.split(' ').filter(x => x)
}, {
    name: `The Girl From Ipanema`,
    composer: 'Jobim',
    key: '',
    progression: 'F7 G7 Gm7 Gb7 F7 Gb7 F7 Gb7  B7 F#m7 D7 Gm7 Eb7 Am7 D7(b9) Gm7 C7(b9) F7 Gm7 C7(b9) F7 G7 Gm7 Gb7 F7 Gb7'.split(' ').filter(x => x)
}, {
    name: `Grand Central`,
    composer: 'John Coltrane',
    key: '',
    progression: 'Fm Bbm7 Eb7 Abm7 Db7 Gbm7 Cb7 Fm Gb7 Fm Gb7 Fm Bbm7 Eb7 Abm7 Db7 Gbm7 Cb7 Fm F#9 B7 Bbm7 Eb7 Abm7 Gm7 C7 Fm Bbm7 Eb7 Abm7 Db7 Gbm7 Cb7 Fm Bbm7 Eb7 Abm7 Db7 Gbm7 Cb7 Fm Gb7 Fm Gb7 F Gb7 Fm'.split(' ').filter(x => x)
}, {
    name: `Groovin' High`,
    composer: 'Dizzie Gillespie',
    key: '',
    progression: 'Eb Am7 D7 Am7 D7 Eb Gm7 C7 Gm7 C7 F7 F7 Fm7 Bb7 Fm7 Bb7 Eb7 Gm7 F#m7 Fm7 E7 Eb Am7 D7 Am7 D7 Eb Gm7 C7 Gm7 C7 F7 F7 Fm7 Bb7 Fm7 Bb7 Fm7 Abm7 Db7 Eb6 Gb7 Fm7 E9'.split(' ').filter(x => x)
}, {
    name: `Half Nelson`,
    composer: 'Miles Davis',
    key: '',
    progression: 'C7 Fm7 Bb7 Bb7 C7 Dd7 G7 C7 Bm7 E7 Bbm7 Eb7 Ab7 Am7 D7 Ab7 D7 Dm7 G7 C Bb7 Ab7 Db7'.split(' ').filter(x => x)
}, {
    name: `Hassan's Dream`,
    composer: 'Benny Golson',
    key: '',
    progression: 'Bb Cd Bb Db Ebm7 C7(b9) F7 Bb Bbm7 Gb7 F7(b13) F7(b9) Bb Db7 C7 F7 Bb Cd Bb Db Ebm7 C7(b9) F7 Bb Bbm7 Gb7 F7(b13) F7(b9) Bb Db7 C7 Bb'.split(' ').filter(x => x)
}, {
    name: `Heaven`,
    composer: 'Duke Ellington',
    key: '',
    progression: 'Am7 Bb9 G7 C7 Fa7 A7 Bb9 G7 C7 Fa7 Fm9 Bb9 Eb7 Em7 A7(b9) Dm7 Cm7(b5) F7 A7 Bb7 G7 C7 Cm7 B7 Bb7'.split(' ').filter(x => x)
}, {
    name: `I Got It Bad`,
    composer: 'Duke',
    key: '',
    progression: 'G7 Em7 A7 Am7 B7 E7 A7 D7 G6 Em7 Am7 D7 G7 Em7 A7 Am7 B7 E7 A7 D7 G6 Db7(#11) C7 Cm6 F7 G7 F7 Bm7 E7 Am7 Dm G7 Em7 A7 Am7 B7 E7 A7 D7 G6 Am7 D7'.split(' ').filter(x => x)
}, , {
    name: `Impressions`,
    composer: 'John Coltrane',
    key: '',
    progression: 'Dm7 Dm7 Ebm7 Ebm7 Dm7 Dm7'.split(' ').filter(x => x)
}, {
    name: `In A Mellow Tone`,
    composer: 'Duke Ellington',
    key: '',
    progression: 'Bb7 Eb7 Ab7 Ebm7 Ab7 Db7 Db Dd7 Ab7/Eb F7 Bb7 Eb7 F7 Bb7 Eb7 Ab7 Ebm7 Ab7 Db7 Db7 Db7 Dd7 Ab7/Eb F7 Bb7 Eb7 Ab G7 Gb7 F7'.split(' ').filter(x => x)
}, {
    name: `Inchworm`,
    composer: 'Frank Loesser',
    key: '',
    progression: 'F Eb F Eb F F7 Bb6 G7 C7 F Eb F Eb F F7 Bb7 Bb6 F6 C7(b9) F F Eb13 F Eb13 F Eb13 F Eb13'.split(' ').filter(x => x)
}, {
    name: `Israel`,
    composer: 'John Carisi',
    key: '',
    progression: 'Dm Dm(b6) Dm6 D7(b9) Gm Gm(b6) Gm6 A7(b13) D7 F7 Bb7 A7 Dm F7 Bb7 A7'.split(' ').filter(x => x)
}, {
    name: `It Don't Mean A Thing`,
    composer: 'Duke',
    key: '',
    progression: 'Gm Gm/F# Gm/F C7/E Eb7 D7 Gm C7 C#d7 Bb/D Da7 Gm Gm/F# Gm/F C7/E Eb7 D7 Gm C7 C#d7 Bb/D Bb Fm7 Bb7 Eb7 Gm7 C7 F7 D7 Gm Gm/F# Gm/F C7/E Eb7 D7 Gm Gm C7 C#d7 Da7'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: C`,
    composer: '',
    key: '',
    progression: 'C F G'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: Db`,
    composer: '',
    key: '',
    progression: 'Db Gb Ab'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: D`,
    composer: '',
    key: '',
    progression: 'D G A'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: Eb`,
    composer: '',
    key: '',
    progression: 'Eb Ab Bb'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: E`,
    composer: '',
    key: '',
    progression: 'E A B'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: F`,
    composer: '',
    key: '',
    progression: 'F Bb C'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: F#`,
    composer: '',
    key: '',
    progression: 'F# A# C'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: G`,
    composer: '',
    key: '',
    progression: 'G C D'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: Ab`,
    composer: '',
    key: '',
    progression: 'Ab Db Eb'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: A`,
    composer: '',
    key: '',
    progression: 'A D E'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: Bb`,
    composer: '',
    key: '',
    progression: 'Bb Eb F'.split(' ').filter(x => x)
}, {
    name: `Popular I–IV–V: B`,
    composer: '',
    key: '',
    progression: 'B E F#'.split(' ').filter(x => x)
},
{
    name: `Popular I–V–vi–IV: C`,
    composer: '',
    key: '',
    progression: 'C G Am F'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–IV: Db`,
    composer: '',
    key: '',
    progression: 'Db Ab Bb Gb'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–IV: D`,
    composer: '',
    key: '',
    progression: 'D A Bm G'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–IV: Eb`,
    composer: '',
    key: '',
    progression: 'Eb Bb Cm Ab'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–IV: E`,
    composer: '',
    key: '',
    progression: 'E B C#m A'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–IV: F`,
    composer: '',
    key: '',
    progression: 'F C Dm Bb'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–IV: F#`,
    composer: '',
    key: '',
    progression: 'F# C# D#m B'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–IV: G`,
    composer: '',
    key: '',
    progression: 'G D Em C'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–IV: Ab`,
    composer: '',
    key: '',
    progression: 'Ab Eb Fm Db'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–IV: A`,
    composer: '',
    key: '',
    progression: 'A E F#m D'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–IV: Bb`,
    composer: '',
    key: '',
    progression: 'Bb F Gm Eb'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–IV: B`,
    composer: '',
    key: '',
    progression: 'B F# G#M E'.split(' ').filter(x => x)
},



{
    name: `Popular ii–V–I: C`,
    composer: '',
    key: '',
    progression: 'Dm G C'.split(' ').filter(x => x)
}, {
    name: `Popular ii–V–I: Db`,
    composer: '',
    key: '',
    progression: 'Ebm Ab Db'.split(' ').filter(x => x)
}, {
    name: `Popular ii–V–I: D`,
    composer: '',
    key: '',
    progression: 'Em A D'.split(' ').filter(x => x)
}, {
    name: `Popular ii–V–I: Eb`,
    composer: '',
    key: '',
    progression: 'Fm Bb Eb'.split(' ').filter(x => x)
}, {
    name: `Popular ii–V–I: E`,
    composer: '',
    key: '',
    progression: 'F#b B E'.split(' ').filter(x => x)
}, {
    name: `Popular ii–V–I: F`,
    composer: '',
    key: '',
    progression: 'Gm C F'.split(' ').filter(x => x)
}, {
    name: `Popular ii–V–I: F#`,
    composer: '',
    key: '',
    progression: 'G#m C# F#'.split(' ').filter(x => x)
}, {
    name: `Popular ii–V–I: G`,
    composer: '',
    key: '',
    progression: 'Am D G'.split(' ').filter(x => x)
}, {
    name: `Popular ii–V–I: Ab`,
    composer: '',
    key: '',
    progression: 'Bbm Eb Ab'.split(' ').filter(x => x)
}, {
    name: `Popular ii–V–I: A`,
    composer: '',
    key: '',
    progression: 'Bm E A'.split(' ').filter(x => x)
}, {
    name: `Popular ii–V–I: Bb`,
    composer: '',
    key: '',
    progression: 'Cm F Bb'.split(' ').filter(x => x)
}, {
    name: `Popular ii–V–I: B`,
    composer: '',
    key: '',
    progression: 'C#m F# B'.split(' ').filter(x => x)
},







{
    name: `Popular I–V–vi–iii–IV–I–IV–V: C`,
    composer: '',
    key: '',
    progression: 'C G Am Em F C F G'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–iii–IV–I–IV–V: Db`,
    composer: '',
    key: '',
    progression: 'Db Ab Bbm Fm Gb Db Gb Ab'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–iii–IV–I–IV–V: D`,
    composer: '',
    key: '',
    progression: 'D A Bm F#m G D G A'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–iii–IV–I–IV–V: Eb`,
    composer: '',
    key: '',
    progression: 'Eb Bb Cm Gm Ab Eb Ab Bb'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–iii–IV–I–IV–V: E`,
    composer: '',
    key: '',
    progression: 'E B C#m G#m A E A B'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–iii–IV–I–IV–V: F`,
    composer: '',
    key: '',
    progression: 'F C Dm Am Bb F Bb C'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–iii–IV–I–IV–V: F#`,
    composer: '',
    key: '',
    progression: 'F# C# D#m A#m B F# B C#'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–iii–IV–I–IV–V: G`,
    composer: '',
    key: '',
    progression: 'G D Em Bm C G C D'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–iii–IV–I–IV–V: Ab`,
    composer: '',
    key: '',
    progression: 'Ab Eb Fm Cm Db Ab Db Eb'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–iii–IV–I–IV–V: A`,
    composer: '',
    key: '',
    progression: 'A E F#m C#m D A D E'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–iii–IV–I–IV–V: Bb`,
    composer: '',
    key: '',
    progression: 'Bb F Gm Dm Eb Bb Eb F'.split(' ').filter(x => x)
}, {
    name: `Popular I–V–vi–iii–IV–I–IV–V: B`,
    composer: '',
    key: '',
    progression: 'B F# G#m D#m E B E F#'.split(' ').filter(x => x)
},
{
    name: `Lie Awake`,
    composer: 'Mitch Coodley',
    key: '',
    progression: 'D7sus4 B7sus4 A7sus4 G7(#11) F#7sus4 Em9 Fm7 Bb7 Db7 Db7 Cm7 Bbm7 Db7 Db7 Cm7 Bbm7 E7 D7sus4 B7sus4 A7sus4 G7(#11) F#7sus4 Em9'.split(' ').filter(x => x)
}, {
    name: `Little B's Poem`,
    composer: 'Bobby Hutcherson',
    key: '',
    progression: 'Am7 Gm7 Am7 Dm7 Gm7 Fm7 Gm7 Em7(b5) A7 Dm7 Ebm7 Ab7 Db7 Dm7 G7 C7 C#m7 A7 Bm7 C#m7 Dm7 Em7 Gm7'.split(' ').filter(x => x)
}, {
    name: `Littles Niles`,
    composer: 'Randy Weston',
    key: '',
    progression: 'Bbm Bbm6 Gb7 F7 D7(b5) G7 C7(b5) F7 Fd F#d Fd Fd F#d Fd F#d C7(b5) F7 Gb7 Cb7 Bbm6'.split(' ').filter(x => x)
}, {
    name: `Liturgy`,
    composer: 'Michael Gibbs',
    key: '',
    progression: 'F#d F#7 Gd E7 Bb7 A Ebm7 Ebm7(b5) D(#11) Dbm7 Dbm7(b5) C7(#11) Bm7(b5) Bb7(#11) Asus4 A'.split(' ').filter(x => x)
}, {
    name: `Little Waltz`,
    composer: 'Ron Carter',
    key: '',
    progression: 'Fm Em7(b5) Ebm6 Db7 G7 C7 Fm C7 Fm Dm7(b5) G7 C7 F7 Bbm7 Eb7 Ab G C7 F Em7(b5) Ebm6 Db G7 C7 F'.split(' ').filter(x => x)
}, {
    name: `Long Ago and Far Away`,
    composer: 'Kern/ Gerschiwn',
    key: '',
    progression: 'F6 Dm7 Gm7 C7 F7 Gm7 C7 F6 Gm7 C7 Am7 D7 Gm7 C7 Ab6 Bbm7 Eb7 Ab7 G7 C7 Am7 D7 Gm7 C7 F7 Bb7 Eb7 F6 Dm7 Gm7 C7 F6 Gm7 C7'.split(' ').filter(x => x)
}, {
    name: `Lonnie's Lament`,
    composer: 'John Coltrane',
    key: '',
    progression: 'Cm7 Dm7 Eb7 Dm7 Cm7 Dm7 Eb7 Dm9 Cm7 Dm7 Eb7 Dm7 Cm7 Dm7 Eb7 Dm7 Cm7 Bb7 Eb7 Ab7 Ab7 A7 Ab7 G7 Cm7 Dm7 Eb7 Gm7 G7 Cm7 Dm7 Eb7 Dm7'.split(' ').filter(x => x)
}, {
    name: `Looking Back`,
    composer: 'Richard Niles',
    key: '',
    progression: 'Am Em F7 Bb7 Db/Eb Eb/F Gb/c F/Db Gb F B/C# D A/C# C G/B Bm Bb/D F/C Ab/C Eb/Bb E7sus4 Bb'.split(' ').filter(x => x)
}, {
    name: `Vibe - Happy`,
    composer: '',
    key: '',
    progression: 'C F G C'.split(' ').filter(x => x)
}, {
    name: `Vibe - Joyful`,
    composer: '',
    key: '',
    progression: 'C G Am F'.split(' ').filter(x => x)
}, {
    name: `Vibe - Dark`,
    composer: '',
    key: '',
    progression: 'F C Am Em'.split(' ').filter(x => x)
}, {
    name: `Vibe - Pop`,
    composer: '',
    key: '',
    progression: 'F Am F G'.split(' ').filter(x => x)
}, {
    name: `Vibe - Emotional`,
    composer: '',
    key: '',
    progression: 'F C Am G'.split(' ').filter(x => x)
}, {
    name: `Vibe - Sad`,
    composer: '',
    key: '',
    progression: 'Dm Am F G'.split(' ').filter(x => x)
}, {
    name: `Vibe - Powerful`,
    composer: '',
    key: '',
    progression: 'F G Am G'.split(' ').filter(x => x)
}, {
    name: ``,
    composer: '',
    key: '',
    progression: ''.split(' ').filter(x => x)
}, {
    name: ``,
    composer: '',
    key: '',
    progression: ''.split(' ').filter(x => x)
}, {
    name: ``,
    composer: '',
    key: '',
    progression: ''.split(' ').filter(x => x)
}, {
    name: ``,
    composer: '',
    key: '',
    progression: ''.split(' ').filter(x => x)
}].filter(x => x.name))].filter(x => x.name)
