import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Panel from '../../components/panel/Panel';
import {
    getCurrentInstrument,
    addNewScene,
    addInstrument,
    getSelectedScene,
    removeCurrentScene,
    setCurrentScene,
    selectCurrentMidiCommand,
    selectScenes,
    setOutOfBandScene,
    initialize
} from '../../features/composer/composerSlice';
import MidiNotes from '../../features/composer/MidiNotes';

export default function Scenes() {
    const notes = useSelector(selectCurrentMidiCommand);
    const scenes = Object.values(useSelector(selectScenes));
    const dispatch = useDispatch();
    const selectedScene = useSelector(getSelectedScene);
    const currentInstrument = useSelector(getCurrentInstrument);
    setOutOfBandScene(scenes);
    return (
        <Panel stretch title={'Scenes'}>
            <div style={{ display: 'flex', height: '100%', flex: 1, flexDirection: 'column' }}>
                <div style={{ display: 'flex', flex: 1, maxHeight: 40, flexGrow: 1 }}>
                    <div className="btn-group">
                        <button type="button" onClick={() => {
                            dispatch(initialize());
                            dispatch(addNewScene())
                        }} className="btn btn-default">
                            Add      Scene &nbsp;      <i className="fas fa-plus"></i>
                        </button>
                        {selectedScene ? <button type="button" onClick={() => {
                            dispatch(removeCurrentScene())
                        }} className="btn btn-default">
                            Delete Scene &nbsp;      <i className="fas fa-minus"></i>
                        </button> : null}
                    </div>
                </div>
                <div style={{ display: 'flex', flex: 1, overflowY: 'scroll', width: 'calc(100% - 40px)' }}>
                    <SceneList scenes={scenes} selectedId={selectedScene} onClick={(id) => {
                        dispatch(setCurrentScene(id));
                    }} />
                </div>
            </div>
        </Panel>
    );
}

function SceneList(props) {
    let { scenes } = props;

    return (
        <div style={{ width: '100%' }}>
            {scenes.map(scene => {
                return <SceneItem key={`scene-${scene.id}`} name={scene.name} selected={scene.id === props.selectedId} onClick={() => {
                    if (props.onClick) {
                        props.onClick(scene.id);
                    }
                }} />;
            })}
        </div>
    )
}
function SceneItem(props) {
    return (
        <blockquote className={props.selected ? '' : "quote-secondary"} onClick={() => {
            if (props.onClick) {
                props.onClick();
            }
        }}>
            <p>{props.name || 'unnamed'}</p>
            <small><cite title="Source Title"></cite></small>
        </blockquote>
    )
}