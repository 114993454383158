import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup } from '../../components/FormGroup';
import MidiTrack from '../../components/music/MidiTrack';
import Panel from '../../components/panel/Panel';
import { TitlesService } from '../../title-service';
import {
    updateMidiFileBreaks,
    updateLick,
    getCurrentLickObj
} from './composerSlice';

export default function MidiLickDetails() {
    const dispatch = useDispatch();
    let currentLick = useSelector(getCurrentLickObj);
    if (!currentLick) return (
        <Panel stretch title={TitlesService('Current Lick')}>
        </Panel>);
    let { startTicks, endTicks, ppq } = currentLick;
    return (
        <Panel stretch title={TitlesService('Current Lick')}>
            <div className="">
                <div className="row">
                    <div className="col-md-12" >
                        <FormGroup onClick={() => { }}>
                            <label >Name</label>&nbsp;
                            <input value={currentLick && currentLick.name} onChange={(e) => {
                                dispatch(updateLick({
                                    key: 'name',
                                    value: e.currentTarget.value
                                }))
                            }} type="text" className="form-control" placeholder="Enter ..." />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12" style={{ height: 400 }}>
                        <MidiTrack
                            parentElementCls={'card-body'}
                            start={startTicks}
                            end={endTicks}
                            height={400}
                            ppq={ppq}
                            track={currentLick}
                        />
                    </div>
                </div>
            </div>
        </Panel>
    );
}