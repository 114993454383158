const state = {
    shiftKeyPressed: false,
    controlKeyPressed: false,
    altKeyPressed: false,
    down: false,
    panels: null
}
export const mouseDown = (down) => {
    state.down = down;
}
export const isDown = () => {
    return state.down;
}
export const onShiftKey = (up) => {
    state.shiftKeyPressed = up;
}
export const isShiftOn = () => {
    return state.shiftKeyPressed;
}
export const events_handlers = {
    click: {}
};
export function isParentOf(parent, item) {
    if (item === parent) {
        return true;
    }
    else if (item === null || item === document.body) {
        return false;
    }
    return isParentOf(parent, item.parentElement);
}
export const addKeyServiceEventHandler = (type, name, handler) => {
    events_handlers[type][name] = handler;
}

export const onclick = (event) => {
    Object.values(events_handlers.click).forEach(x => x(event))
}
export const onCtrlKey = (up) => {
    state.controlKeyPressed = up;
}

export const onAltKey = (up) => {
    state.altKeyPressed = up;
}
export const isAltOn = () => {
    return state.altKeyPressed;
}
export const setPanels = (space) => {
    state.panels = space;
}
export const isPanelOpen = (name) => {
    if (state.panels) {
        return state.panels.indexOf(name) !== -1;
    }
    return false;
}