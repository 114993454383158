import React from 'react';
import ChordMaster from '../../base/chord-master';
import SidebarMenu from '../main/SideBarMenu';
import SidebarMenuItem from '../main/SideBarMenuItem';


export default class ScaleList extends React.Component {
    reader = null;
    constructor(props) {
        super(props);
        this.reader = new ChordMaster.Reader({ skipRenderer: true });
        this.state = { currentpage: 0, pagingsize: 10000 };
    }
    render() {
        const { selected } = this.props;
        var matches = this.reader.getScales();
        return (
            <SidebarMenu>
                {
                    matches.map((match, index) => {
                        return <SidebarMenuItem key={`list-item-${index}`} icon={(selected || {})[match] ? 'fas fa-check-circle' : 'fas far fa-circle'}
                            title={match} onClick={() => {
                                if (this.props.onSelectToggle) {
                                    this.props.onSelectToggle(match);
                                }
                            }} ></SidebarMenuItem>
                    })
                }
            </SidebarMenu>
        )
        // return (
        //     <table className="table table-hover text-nowrap">
        //         <thead>
        //             <tr>
        //                 <th>Name</th>
        //                 <th>Family Name</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {matches.map((match, index) => {
        //                 return <OptionListItem selected={(selected || {})[match]} onClick={() => {
        //                     if (this.props.onSelectToggle) {
        //                         this.props.onSelectToggle(match);
        //                     }
        //                 }} match={match} key={`list-item-${index}`} />;
        //             })}
        //         </tbody>
        //     </table>
        // )
    }
}
class OptionListItem extends React.Component {
    render() {
        let { selected } = this.props;
        let name = this.props.match;
        return (
            <tr onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick();
                }
            }}>
                <td>{name}</td>
                <td><input type="checkbox" disabled {...(selected ? { checked: true } : { checked: false })} /></td>
            </tr>
        )
    }
}