let state = null;
export function onDragState(val) {
    state = val;
}

export function getDragState() {
    return state && state.dragging;
}

let listeners = {};
export function setDragStateListener(name, type, handler) {
    listeners[name] = {
        name,
        type,
        handler
    };
}
export function doDeletePressed() {
    Object.values(listeners).map(x => {
        switch (x.type) {
            case 'delete':
                x.handler();
                break;
        }
    })
}
export function doActiveSelect(args) {
    Object.values(listeners).map(x => {
        switch (x.type) {
            case 'select':
                x.handler(args);
                break;
        }
    })
}
export function doSetActiveSelect(args) {
    Object.values(listeners).map(x => {
        switch (x.type) {
            case 'set-selected':
                x.handler(JSON.parse(JSON.stringify(args)))
                break;
        }
    })
}