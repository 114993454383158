import React from 'react';
import { convertToMelodyNote, findLetter, key, notesOverlap, sharp, uuidv4 } from '../../base/base-lib';
import ChordMaster, { calculateV } from '../../base/chord-master';
import { FormGroup } from '../FormGroup';
import ScaleNotes from './ScaleNotes';
import StaffNotes from './StaffNotes';
import FontService from '../../base/font-service';
import * as keyservice from '../../base/key-service';
import { setDragStateListener } from '../../base/dragstateservice';

const MIDI_TRACK_FONT = 'Paytone One';
const MAXIMUM_HORIZONTAL_ZOOM_FACTOR = 16;
export default class MidiTrack extends React.Component {
    reader = null;
    height = undefined;
    top = 127 / 2;
    left = 0;
    vZoom = .15;
    horizontalZoom = 1;
    constructor(props) {
        super(props);
        this.state = {
            canvasId: `c_${uuidv4()}`,
            leftPosition: 0,
            topPosition: 0,
            vZoom: .15,
            horizontalZoom: 1
        }
        this.titleRef = React.createRef();
        this.internalDiv = React.createRef();
        this.canvasRef = React.createRef();
    }
    shouldComponentUpdate(nextProps, props) {
        if (this.props.songData) {
            if (this.props.trackIndex !== this.props.songData.trackIndex) {
                if (this.props.songData.top !== undefined) {
                    this.top = this.props.songData.top;
                }
                if (this.props.songData.left !== undefined) {
                    this.left = this.props.songData.left;
                }
                if (this.props.songData.vZoom !== undefined) {
                    this.vZoom = this.props.songData.vZoom;
                }
                if (this.props.songData.horizontalZoom !== undefined) {
                    this.horizontalZoom = this.props.songData.horizontalZoom;
                }
            }
        }
        this.draw();
        return false;
    }
    componentDidMount() {
        this.draw();

    }
    getParentElementCls(rootEl) {
        rootEl = rootEl || this.internalDiv.current;
        if (this.props.parentElementCls) {
            if (rootEl.parentElement.classList.contains(this.props.parentElementCls)) {
                return rootEl.parentElement;
            }
            else {
                return this.getParentElementCls(rootEl.parentElement);
            }
        }
        return null;
    }
    draw() {
        this.drawAgain = true;
        let height = this.getHeight();
        let padding = 10;
        let updateDraw = () => {
            if (this.drawAgain) {
                this.drawAgain = false;
                var canvas = document.getElementById(this.state.canvasId);
                if (canvas && canvas.getContext) {
                    canvas.height = height;
                    var ctx = canvas.getContext('2d');
                    this.height = canvas.parentElement.parentElement.getBoundingClientRect().height - 10;
                    canvas.height = this.height;
                    canvas.parentElement.style = `${this.height}px`;
                    let canvasTargetWidth = canvas.parentElement.getBoundingClientRect().width;
                    let parentElement = this.getParentElementCls();
                    if (parentElement) {
                        canvasTargetWidth = parentElement.getBoundingClientRect().width;
                    }
                    canvas.width = canvasTargetWidth - 2 * padding;

                    this.drawContainer(canvas, ctx)
                }
            }
            if (this.titleRef && this.titleRef.current) {
                this.titleRef.current.innerHTML = this.props.track.name
            }
            if (this.drawAgain) {
                updateDraw();
            }
        };
        requestAnimationFrame(updateDraw)
    }
    drawContainer(canvas, ctx) {
        let height = this.getHeight();
        let { verticalStart = 0, verticalEnd = 127 } = this.props;
        let outerPadding = 1;
        let trackVerticalOffset = 30;
        let containerMenuWidth = 130;
        let trackContainerLeftPadding = outerPadding + containerMenuWidth;
        ctx.fillStyle = 'rgb(225, 225, 225)';
        let trackContainerWidth = canvas.width - (outerPadding * 2);
        let trackContainerHeight = height - (outerPadding * 2) - trackVerticalOffset;
        ctx.strokeRect(outerPadding, outerPadding, trackContainerWidth, height - (outerPadding * 2));
        let rowHeight = MIDI_TRACK_POSITION_DATA.keyHeight;
        this.vZoom = (1 / (rowHeight / trackContainerHeight)) * (1 / (verticalEnd - verticalStart))

        if (this.props.ppq) {
            this.drawTempoRelated(canvas, ctx, {
                x: trackContainerLeftPadding,
                y: trackVerticalOffset + outerPadding,
                trackVerticalOffset,
                width: trackContainerWidth,
                height: trackContainerHeight
            });
            this.drawOutline(ctx, outerPadding, trackVerticalOffset + outerPadding,)
        }

        this.drawMidiNoteRows(canvas, ctx, {
            x: trackContainerLeftPadding,
            y: trackVerticalOffset + outerPadding,
            trackVerticalOffset,
            width: trackContainerWidth,
            height: trackContainerHeight
        });

        if (this.props.track) {
            this.drawTrack(canvas, ctx, {
                x: trackContainerLeftPadding,
                y: trackVerticalOffset + outerPadding,
                width: trackContainerWidth,
                height: trackContainerHeight
            });
            this.drawSelection(ctx, {
                x: trackContainerLeftPadding,
                y: trackVerticalOffset + outerPadding,
                width: trackContainerWidth,
                height: trackContainerHeight
            })
        }
        this.drawLeftContainerMenu(ctx, {
            x: outerPadding,
            y: trackVerticalOffset + outerPadding,
            width: containerMenuWidth,
            height: trackContainerHeight
        })
        if (this.props.ppq) {
            ctx.globalAlpha = 1;
            this.drawRect(ctx, '#F8F9FA', outerPadding, 0, trackContainerWidth, trackVerticalOffset)
            this.drawTopContainerBox(canvas, ctx, {
                x: trackContainerLeftPadding,
                y: trackVerticalOffset + outerPadding,
                trackVerticalOffset,
                width: trackContainerWidth,
                height: trackContainerHeight
            });
        }

    }
    drawLeftContainerMenu(ctx, args) {
        let { x, y, width, height } = args;
        ctx.globalAlpha = 1;

        this.drawRect(ctx, MENU_CONTAINER_BACKGROUND, x, y, width, height);
        this.drawMidiKeyboard(ctx, {
            ...args,
            width: 30,
            outerWallLeft: x,
            x: width - 30
        })
    }
    drawSelection(ctx, args) {

        let { x, y, width, height } = args;
        let { selection_start, selection_end } = this.props;
        if (selection_end !== undefined && selection_start !== undefined) {

            // Turn transparency on
            ctx.globalAlpha = 0.62;
            let widthRelative = (selection_end - selection_start) / 100;
            let relativeHeight = 1;
            let actual_y = y;
            let relativeX = selection_start / 100;
            let actual_x = relativeX * width + x;
            ctx.fillStyle = 'rgb(52, 152, 219)';
            ctx.fillRect(actual_x, actual_y, width * widthRelative, height * relativeHeight);
        }
    }
    drawBorder(ctx, xPos, yPos, width, height, thickness = 1, border = null) {
        ctx.fillStyle = border || '#000';
        ctx.fillRect(xPos - (thickness), yPos - (thickness), width + (thickness * 2), height + (thickness * 2));
    }
    drawOutline(ctx, xPos, yPos, width, height, thickness = 1, marginX = 0, marginY = 0) {
        ctx.fillRect(
            xPos + (marginX),
            yPos + (marginY),
            width - (marginX * 2),
            height - (marginY * 2)
        );
    }
    drawInner(ctx, xPos, yPos, width, height, thickness = 1, style = null, marginX = 0, marginY = 0) {
        if (style)
            ctx.fillStyle = style;
        if (width < ((thickness * 2) + (marginX * 2))) {
            marginX = 0;
        }
        if (width < ((thickness * 2) + (marginX * 2))) {
            thickness = 0;
        }
        if (height < ((thickness * 2) + (marginY * 2))) {
            marginY = 0;
        }
        if (height < ((thickness * 2) + (marginY * 2))) {
            thickness = 0;
        }
        ctx.fillRect(
            xPos + (thickness) + (marginX),
            yPos + (thickness) + (marginY),
            width - (thickness * 2) - (marginX * 2),
            height - (thickness * 2) - (marginY * 2)
        );
    }
    drawTempoRelated(canvas, ctx, args) {
        let { x, y, trackVerticalOffset, width, height } = args;
        let measureNumberOffsetX = 10;
        let { start, ppq, end } = this.props;
        let new_start = this.left * (end - start);
        let new_end = new_start + (end - start) * this.horizontalZoom

        end = new_end;
        start = new_start;
        let total_duration = end - start;

        if (ppq) {
            let temp = Math.floor(start / ppq);
            let next_beat = (temp) * ppq;
            let numberOfBeats = (end - start) / ppq;
            ctx.lineWidth = 1;

            for (let i = -1; i < numberOfBeats; i = i + 1) {
                ctx.strokeStyle = '#212529';
                let rel_x = ((((next_beat + (i * ppq)) - start) / total_duration));
                let actual_x = rel_x * width + x;
                let factor = 4;
                if ((i + temp) % factor === 0) {
                    let rel_x_next = ((((next_beat + ((i + (factor)) * ppq)) - start) / total_duration));
                    let actual_x_next = rel_x_next * width + x;
                    this.drawRect(ctx,
                        (i + temp) / 2 % 2 === 0 ? '#212529' : '#495057',
                        actual_x,
                        y,
                        (actual_x_next - actual_x) / 2,
                        height);
                }
                this.drawLine(ctx, { x: actual_x, y }, { x: actual_x, y: y + height })
            }
        }
    }
    drawTopContainerBox(canvas, ctx, args) {
        let { x, y, trackVerticalOffset, width, height } = args;
        let measureNumberOffsetX = 2;
        let { start, ppq, end } = this.props;
        let new_start = this.left * (end - start);
        let new_end = new_start + (end - start) * this.horizontalZoom

        end = new_end;
        start = new_start;
        let total_duration = end - start;

        if (ppq) {
            let temp = Math.floor(start / ppq);
            let next_beat = (temp) * ppq;
            let numberOfBeats = (end - start) / ppq;
            ctx.lineWidth = 1;

            ctx.textAlign = "start"

            for (let i = -1; i < numberOfBeats; i = i + 1) {
                ctx.strokeStyle = '#212529';
                let rel_x = ((((next_beat + (i * ppq)) - start) / total_duration));
                let rel_x_next = ((((next_beat + ((i + 1) * ppq)) - start) / total_duration));
                let actual_width = (rel_x_next * width) - (rel_x * width);
                let actual_x = rel_x * width + x;
                let factor = 4;
                let x_factor = factor;

                if (numberOfBeats > 30) {
                    x_factor *= 2;
                }
                if ((i + temp) % factor === 0) {
                    this.drawLine(ctx, { x: actual_x, y: y }, { x: actual_x, y: y / 2 })
                }
                if (actual_width > 5)
                    if ((i + temp) % (x_factor) === 0) {
                        ctx.fillStyle = '#212529';
                        ctx.font = `18px ${MIDI_TRACK_FONT}`;
                        ctx.fillText(`${((temp) + i)}`, actual_x + measureNumberOffsetX, y + 25 - trackVerticalOffset);
                    }
            }
        }
    }
    drawLine(ctx, pt1, pt2) {

        ctx.beginPath();       // Start a new path
        ctx.moveTo(pt1.x, pt1.y);    // Move the pen to (30, 50)
        ctx.lineTo(pt2.x, pt2.y);  // Draw a line to (150, 100)
        ctx.stroke();          // Render the path
    }
    drawRect(ctx, style, x, y, width, height) {
        ctx.fillStyle = style;
        ctx.fillRect(x, y, width, height);
    }
    drawTrack(canvas, ctx, args) {
        let { x, y, width, height } = args;
        let { start, end, verticalStart = 0, verticalEnd = 127 } = this.props;
        let new_start = this.left * (end - start);
        let new_end = new_start + (end - start) * this.horizontalZoom
        end = new_end;
        start = new_start;
        let total_duration = end - start;
        let verticalRange = verticalEnd - verticalStart;
        verticalRange = verticalRange * this.vZoom;
        clearTrackSelection(this.props.trackIndex);
        if (this.props.track && this.props.track.notes) {
            this.props.track.notes.map((v, noteIndex) => {
                if ((v.ticks + v.durationTicks) >= start && (v.ticks) < end) {
                    let widthRelative = v.durationTicks / total_duration;
                    let relativeHeight = 1 / verticalRange;
                    let relativeY = 1 - (v.midi - this.top) / verticalRange;
                    // let relativeY = (v.midi - this.top) / verticalRange;
                    let actual_y = relativeY * height + y;
                    let relativeX = (v.ticks - start) / (end - start);
                    let actual_x = relativeX * width + x;
                    ctx.fillStyle = NOTE_OUTLINE_COLOR;
                    this.drawOutline(ctx, actual_x, actual_y, width * widthRelative, height * relativeHeight, 1, 1, 1)

                    let { selection_start, selection_end } = this.props;
                    if (selection_end !== undefined && selection_start !== undefined) {
                        if (this.isInsideSelection(actual_x, width * widthRelative, args)) {
                            if (hasTrackStart(this.props.trackIndex)) {
                                setTrackSelectionEnd(this.props.trackIndex, noteIndex);
                            }
                            else {
                                setTrackSelectionStart(this.props.trackIndex, noteIndex)
                            }
                            ctx.fillStyle = NOTE_SELECTION;
                        }
                        else {
                            ctx.fillStyle = NOTE_COLORS[v.midi % NOTE_COLORS.length] || NOTE_COLOR;
                        }
                    }
                    else {
                        ctx.fillStyle = NOTE_COLORS[v.midi % NOTE_COLORS.length] || NOTE_COLOR;
                    }
                    // ctx.fillRect(actual_x, actual_y, width * widthRelative, height * relativeHeight);
                    this.drawInner(ctx, actual_x, actual_y, width * widthRelative, height * relativeHeight, 1, null, 3, 3)
                }
            })
        }
        for (let i = 0; i < 127; i = i + 12) {
            ctx.fillStyle = 'rgb(0, 2, 2)';
            let relativeY = 1 - (i - this.top) / verticalRange;
            // let relativeY = (i - this.top) / verticalRange;
            let actual_y = relativeY * height + y;
            ctx.fillRect(0, y + (actual_y), width, 1);
        }
    }
    isInsideSelection(_x, _width, args) {
        let { selection_start, selection_end } = this.props;
        let { x, y, width, height } = args;
        let widthRelative = (selection_end - selection_start) / 100;
        let relativeX = selection_start / 100;
        let actual_x = relativeX * width + x;
        return doOverlap(
            { x: actual_x, y: 1 },
            { x: actual_x + width * widthRelative, y: -1 },
            { x: _x, y: 1 },
            { x: _x + _width, y: -1 })
    }
    drawMidiNoteRows(canvas, ctx, args) {
        let { x, y, width, height } = args;
        let { start, end, verticalStart = 0, verticalEnd = 127 } = this.props;
        let new_start = this.left * (end - start);
        let new_end = new_start + (end - start) * this.horizontalZoom
        end = new_end;
        start = new_start;
        let verticalRange = verticalEnd - verticalStart;
        verticalRange = verticalRange * this.vZoom;
        let prevAlpha = ctx.globalAlpha;
        ctx.globalAlpha = .1;
        Array(127).fill(0).map((_, v) => {
            // let relativeY = (v - this.top) / verticalRange;

            let relativeY = 1 - (v - this.top) / verticalRange;
            if (relativeY > -.1 && relativeY < 1) {
                let widthRelative = 1;
                let relativeHeight = 1 / verticalRange;
                let actual_y = relativeY * height + y;
                let actual_x = x;
                ctx.fillStyle = "#ff0000";// "#DEE2E6";
                if (v % 2 === 0)
                    this.drawRect(ctx, "#6C757D", actual_x, actual_y, width * widthRelative, height * relativeHeight)
            }
        });
        ctx.globalAlpha = prevAlpha;
    }
    drawMidiKeyboard(ctx, args) {
        let { x, y, outerWallLeft, width, height } = args;
        let { start, end, verticalStart = 0, verticalEnd = 127 } = this.props;
        let new_start = this.left * (end - start);
        let new_end = new_start + (end - start) * this.horizontalZoom
        end = new_end;
        start = new_start;
        let total_duration = end - start;
        let verticalRange = verticalEnd - verticalStart;
        verticalRange = verticalRange * this.vZoom;
        let prevAlpha = ctx.globalAlpha;
        ctx.globalAlpha = 1;
        let fontHeight = 18;
        ctx.font = `${fontHeight}px ${MIDI_TRACK_FONT}`;
        Array(127).fill(0).map((_, v) => {
            let relativeY = 1 - (v - this.top) / verticalRange;
            if (relativeY > -1 && relativeY < 1) {
                let widthRelative = 1;
                let relativeHeight = 1 / verticalRange;
                let actual_y = relativeY * height + y;
                let actual_x = x;
                let temp = convertToMelodyNote(v, sharp);
                let note_height = height * relativeHeight;
                if (temp.accidental) {
                    this.drawRect(ctx, DARK_KEYS, actual_x, actual_y, width * widthRelative, note_height)
                }
                else {
                    this.drawBorder(ctx, actual_x, actual_y, width * widthRelative, note_height, 1, DARK_KEYS)
                    this.drawInner(ctx, actual_x, actual_y, width * widthRelative, note_height, 1, LIGHT_KEYS)
                }

                if (note_height > 20) {
                    if (temp.accidental) {
                        ctx.fillStyle = '#ffffff';
                    }
                    else {
                        ctx.fillStyle = '#ffffff';
                    }
                    if (temp.key === 'C' && !temp.accidental) {
                        ctx.fillText(`${temp.key}${temp.accidental}${temp.key === 'C' && !temp.accidental ? (temp.octave + 1) : ''}`, outerWallLeft + 5, actual_y + note_height / 2 + fontHeight / 2);
                    }
                    else {
                        ctx.fillText(`${temp.key}${temp.accidental}`, actual_x - width, actual_y + note_height / 2 + fontHeight / 2);
                    }
                }
            }
        })
        ctx.globalAlpha = prevAlpha;
    }
    getHeight() {
        return this.height || this.props.height || 100;
    }
    render() {
        const { props } = this;
        const { nextBank, banks, trackSelected } = this.props;

        if (props.track && props.track.notes && props.track.notes.length) {
            const doScreenScroll = (e) => {
                if (!this.lastTime || (e.timeStamp - this.lastTime) > 25) {
                    console.log(e);
                    let element = e.target;
                    let maxScrollLeft = element.scrollWidth - element.clientWidth;
                    let maxScrollTop = element.scrollHeight - element.clientHeight;
                    let skipFactor = .1;
                    this.lastTime = e.timeStamp;
                    if (true) {
                        if (keyservice.isShiftOn()) {
                            if (this.scrollLeft !== undefined) {
                                let direction = this.scrollLeft - element.scrollLeft;
                                if (direction) {
                                    this.horizontalZoom = Math.min(MAXIMUM_HORIZONTAL_ZOOM_FACTOR, Math.max(.0001, direction > 0 ? (this.horizontalZoom || 0) * (1 + skipFactor) : (this.horizontalZoom || 0) * (1 - skipFactor)))
                                    if (this.props.onViewChange) {
                                        requestAnimationFrame(() => {
                                            this.props.onViewChange({
                                                top: this.top,
                                                left: this.left,
                                                vZoom: this.vZoom,
                                                horizontalZoom: this.horizontalZoom
                                            });
                                        });
                                    }
                                }
                            }
                        }
                        else {
                            if (this.scrollTop !== undefined) {
                                let direction = this.scrollTop - element.scrollTop;
                                if (direction) {
                                    if (false) {
                                        this.vZoom = Math.min(1, Math.max(0.01, direction > 0 ? (this.vZoom || 0) * (1 + skipFactor) : (this.vZoom || 0) * (1 - skipFactor)))
                                    }
                                    else {
                                        this.top = Math.max(0, Math.min(127, this.top + (direction) * this.vZoom));
                                    }
                                    if (this.props.onViewChange) {
                                        requestAnimationFrame(() => {

                                            this.props.onViewChange({
                                                top: this.top,
                                                left: this.left,
                                                vZoom: this.vZoom,
                                                horizontalZoom: this.horizontalZoom
                                            });
                                        });
                                    }
                                }
                            }
                        }
                        this.scrollLeft = maxScrollLeft / 2;
                        this.scrollTop = maxScrollTop / 2;
                        element.scrollTo(maxScrollLeft / 2, maxScrollTop / 2);
                        console.log('alt on')
                        console.log(`horizontal zoom : ${this.horizontalZoom}`)
                        console.log(`vertical zoom : ${this.vZoom}`)
                        this.draw();
                    }
                }
            }

            return (
                <div ref={this.internalDiv} style={{ padding: 0, position: 'relative', height: this.getHeight() }}>
                    <canvas ref={this.canvasRef} id={this.state.canvasId} height={this.getHeight()}></canvas>
                    <div style={{
                        position: 'absolute',
                        zIndex: 0,
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: `100%`,
                        overflow: 'auto',
                        opacity: .001
                    }} onScroll={(e) => {
                        if (!this.running) {
                            this.running = true;
                            doScreenScroll(e)
                            this.running = false;
                        }
                    }}>
                        <div style={{

                            height: '200%',
                            width: '200%'
                        }} onMouseDown={(e) => {
                            console.log(e);
                            this.startPosition = {
                                top: this.top,
                                left: this.left,
                                x: e.clientX || e.pageX || e.screenX,
                                y: e.clientY || e.pageY || e.screenY
                            }
                            this.dragging = true;
                        }}
                            onMouseMove={(e) => {
                                if (this.dragging) {
                                    let newX = e.clientX || e.pageX || e.screenX;
                                    let newY = e.clientY || e.pageY || e.screenY;
                                    this.left = Math.max(0, Math.min(1, this.startPosition.left - (this.startPosition.x - newX) * this.horizontalZoom / this.canvasRef.current.getBoundingClientRect().width))
                                    this.top = Math.max(0, Math.min(127, this.startPosition.top + (this.startPosition.y - newY) * this.vZoom));
                                    console.log(`left: ${this.left} / ${this.startPosition.x - newX}`)
                                    console.log(`top: ${this.top} / ${this.startPosition.y - newY}`)
                                    this.draw();
                                    if (this.props.onViewChange) {
                                        this.props.onViewChange({
                                            top: this.top,
                                            left: this.left,
                                            vZoom: this.vZoom,
                                            horizontalZoom: this.horizontalZoom
                                        })
                                    }
                                }
                            }} onMouseUp={() => {
                                this.dragging = false;
                            }} onMouseOut={() => {
                                // this.dragging = false;
                            }} ></div>
                    </div>
                </div >
            )
        }
        return <div></div>;
    }
}

let trackSelections = {};
function clearTrackSelection(trackIndex) {
    trackSelections[trackIndex] = {
        start: undefined,
        end: undefined
    };
}
function setTrackSelectionStart(trackIndex, start) {
    trackSelections[trackIndex] = {
        start,
    };
}
function setTrackSelectionEnd(trackIndex, end) {
    trackSelections[trackIndex].end = end;
}
function hasTrackStart(trackIndex) {
    return trackSelections[trackIndex].start !== undefined;
}

export function GetTrackSelections(trackIndex) {
    return trackSelections[trackIndex] || null;
}

function doOverlap(l1, r1, l2, r2) {

    // To check if either rectangle is actually a line
    // For example :  l1 ={-1,0}  r1={1,1}  l2={0,-1}
    // r2={0,1}

    if (l1.x == r1.x || l1.y == r1.y || l2.x == r2.x
        || l2.y == r2.y) {
        // the line cannot have positive overlap
        return false;
    }

    // If one rectangle is on left side of other
    if (l1.x >= r2.x || l2.x >= r1.x)
        return false;

    // If one rectangle is above other
    if (r1.y >= l2.y || r2.y >= l1.y)
        return false;

    return true;
}
let NOTE_OUTLINE_COLOR = '#ADB5BD';
let NOTE_COLOR = '#CED4DA';
let NOTE_PLAYING_COLOR = '';
let MENU_CONTAINER_BACKGROUND = '#212529';
let NOTE_SELECTION = '#FF0000';
let DARK_KEYS = "#000000";
let LIGHT_KEYS = "#ffffff";
let COPIED_KEYS = '#A93F55';
let CHORD_TITLE_BACKGROUND = '#000000';
let NOTE_COLORS = `c33c54-254e70-37718e-8ee3ef-aef3e7-b4d4ee-d6c3c9-8ed081-bcaf9c-f0e100-ee8434-aeb4a9`.split('-').map(v => `#${v}`)
'adb5bd-6c757d-495057-343a40-212529-111111-eeeeee'.split('-').map((v, index) => {
    switch (index) {
        case 0:
            NOTE_OUTLINE_COLOR = `#${v}`;
            break;
        case 1:
            NOTE_COLOR = `#${v}`;
            break;
        case 2:
            NOTE_PLAYING_COLOR = `#${v}`;
            break;
        case 3:
            NOTE_SELECTION = `#${v}`;
            break;
        case 4:
            MENU_CONTAINER_BACKGROUND = `#${v}`;
            break;
        case 5:
            DARK_KEYS = `#${v}`;
            break;
        case 6:
            LIGHT_KEYS = `#${v}`;
            break;
    }
})
export class MidiTrackEditor extends React.Component {
    reader = null;
    height = undefined;
    top = 127 / 2;
    left = 0;
    vZoom = .15;
    horizontalZoom = 1;
    constructor(props) {
        super(props);
        this.state = {
            canvasId: `c_${uuidv4()}`,
            noteCanvasId: `n_${uuidv4()}`,
            leftPosition: 0,
            topPosition: 0,
            vZoom: .15,
            horizontalZoom: 1
        }
        this.titleRef = React.createRef();
        this.internalDiv = React.createRef();
        this.canvasRef = React.createRef();
        this.noteCanvasRef = React.createRef();
    }
    shouldComponentUpdate(nextProps, props) {
        this.draw();
        return false;
    }
    componentDidMount() {
        this.unmounted = false;
        this.draw();
    }
    componentWillUnmount() {
        this.unmounted = true;
    }
    getParentElementCls(rootEl) {
        rootEl = rootEl || this.internalDiv.current;
        if (this.props.parentElementCls) {
            if (rootEl.parentElement.classList.contains(this.props.parentElementCls)) {
                return rootEl.parentElement;
            }
            else {
                return this.getParentElementCls(rootEl.parentElement);
            }
        }
        return null;
    }
    draw() {
        this.drawAgain = true;
        let height = this.getHeight();
        let padding = 10;
        let updateDraw = () => {
            if (this.drawAgain) {
                this.drawAgain = false;
                var canvas = document.getElementById(this.state.canvasId);
                if (canvas && canvas.getContext) {
                    canvas.height = height;
                    var ctx = canvas.getContext('2d');
                    this.height = canvas.parentElement.parentElement.getBoundingClientRect().height - 10;
                    canvas.height = this.height;
                    canvas.parentElement.style = `${this.height}px`;
                    let canvasTargetWidth = canvas.parentElement.getBoundingClientRect().width;
                    let parentElement = this.getParentElementCls();
                    if (parentElement) {
                        canvasTargetWidth = parentElement.getBoundingClientRect().width;
                    }
                    canvas.width = canvasTargetWidth - 2 * padding;
                    this.drawContainer(canvas, ctx, ctx)
                }
            }
            if (this.titleRef && this.titleRef.current) {
                this.titleRef.current.innerHTML = this.props.track.name
            }
            if (this.drawAgain) {
                updateDraw();
            }
        };
        requestAnimationFrame(updateDraw)
    }
    drawContainer(canvas, ctx, noteCtx) {
        let height = this.getHeight();
        let { containerMenuWidth, trackVerticalOffset, outerPadding } = MIDI_TRACK_POSITION_DATA;
        let { verticalStart = 0, verticalEnd = 127 } = this.props;
        let trackContainerLeftPadding = outerPadding + containerMenuWidth;
        ctx.fillStyle = 'rgb(225, 225, 225)';
        let trackContainerWidth = canvas.width - (outerPadding * 2);
        let trackContainerHeight = height - (outerPadding * 2) - trackVerticalOffset;
        ctx.strokeRect(outerPadding, outerPadding, trackContainerWidth, height - (outerPadding * 2));
        let rowHeight = MIDI_TRACK_POSITION_DATA.keyHeight;
        this.vZoom = (1 / (rowHeight / trackContainerHeight)) * (1 / (verticalEnd - verticalStart))
        if (this.props.ppq) {
            this.drawTempoRelated(canvas, ctx, {
                x: trackContainerLeftPadding,
                y: trackVerticalOffset + outerPadding,
                trackVerticalOffset,
                width: trackContainerWidth,
                height: trackContainerHeight
            });
            this.drawOutline(ctx, outerPadding, trackVerticalOffset + outerPadding,)
        }

        this.drawMidiNoteRows(canvas, ctx, {
            x: trackContainerLeftPadding,
            y: trackVerticalOffset + outerPadding,
            trackVerticalOffset,
            width: trackContainerWidth,
            height: trackContainerHeight
        });

        if (this.props.notes) {
            this.drawTrack(noteCtx, {
                x: trackContainerLeftPadding,
                y: trackVerticalOffset + outerPadding,
                width: trackContainerWidth,
                height: trackContainerHeight
            });
            this.drawChords(noteCtx, {
                x: trackContainerLeftPadding,
                y: trackVerticalOffset + outerPadding,
                width: trackContainerWidth,
                height: trackContainerHeight
            })
            if (this.props.draw_selection) {
                this.drawSelection(ctx, {
                    x: trackContainerLeftPadding,
                    y: trackVerticalOffset + outerPadding,
                    width: trackContainerWidth,
                    height: trackContainerHeight
                })
            }
        }
        this.drawLeftContainerMenu(ctx, {
            x: outerPadding,
            y: trackVerticalOffset + outerPadding,
            width: containerMenuWidth,
            height: trackContainerHeight
        })
        if (this.props.ppq) {
            ctx.globalAlpha = 1;
            this.drawRect(ctx, '#F8F9FA', outerPadding, 0, trackContainerWidth, trackVerticalOffset)
            this.drawTopContainerBox(canvas, ctx, {
                x: trackContainerLeftPadding,
                y: trackVerticalOffset + outerPadding,
                trackVerticalOffset,
                width: trackContainerWidth,
                height: trackContainerHeight
            });
        }

    }
    drawLeftContainerMenu(ctx, args) {
        let { x, y, width, height } = args;
        ctx.globalAlpha = 1;

        this.drawRect(ctx, MENU_CONTAINER_BACKGROUND, x, y, width, height);
        this.drawMidiKeyboard(ctx, {
            ...args,
            width: 30,
            outerWallLeft: x,
            x: width - 30
        })
    }
    drawSelection(ctx, args) {

        let { x, y, width, height } = args;
        let { selection_start, selection_end } = this.props;
        if (selection_end !== undefined && selection_start !== undefined) {

            // Turn transparency on
            ctx.globalAlpha = 0.62;
            let widthRelative = (selection_end - selection_start) / 100;
            let relativeHeight = 1;
            let actual_y = y;
            let relativeX = selection_start / 100;
            let actual_x = relativeX * width + x;
            ctx.fillStyle = 'rgb(52, 152, 219)';
            ctx.fillRect(actual_x, actual_y, width * widthRelative, height * relativeHeight);
        }
    }
    drawBorder(ctx, xPos, yPos, width, height, thickness = 1, border = null) {
        ctx.fillStyle = border || '#000';
        ctx.fillRect(xPos - (thickness), yPos - (thickness), width + (thickness * 2), height + (thickness * 2));
    }
    drawOutline(ctx, xPos, yPos, width, height, thickness = 1, marginX = 0, marginY = 0) {
        ctx.fillRect(
            xPos + (marginX),
            yPos + (marginY),
            width - (marginX * 2),
            height - (marginY * 2)
        );
    }
    drawInner(ctx, xPos, yPos, width, height, thickness = 1, style = null, marginX = 0, marginY = 0) {
        if (style)
            ctx.fillStyle = style;
        if (width < ((thickness * 2) + (marginX * 2))) {
            marginX = 0;
        }
        if (width < ((thickness * 2) + (marginX * 2))) {
            thickness = 0;
        }
        if (height < ((thickness * 2) + (marginY * 2))) {
            marginY = 0;
        }
        if (height < ((thickness * 2) + (marginY * 2))) {
            thickness = 0;
        }
        ctx.fillRect(
            xPos + (thickness) + (marginX),
            yPos + (thickness) + (marginY),
            width - (thickness * 2) - (marginX * 2),
            height - (thickness * 2) - (marginY * 2)
        );
    }
    drawTempoRelated(canvas, ctx, args) {
        let { x, y, width, height } = args;
        let { start, ppq, end } = this.props;
        let new_start = this.left * (end - start);
        let new_end = new_start + (end - start) * this.horizontalZoom
        let minimum_tempo_marker_width = 120;
        end = new_end;
        start = new_start;
        let total_duration = end - start;
        if (ppq) {
            let temp = Math.floor(start / ppq);
            let last_color = temp % 2 === 0 ? '#212529' : '#495057';
            let next_beat = (temp) * ppq;
            let numberOfBeats = (end - start) / ppq;
            ctx.lineWidth = 1;

            for (let i = -1; i < numberOfBeats; i = i + 1) {
                ctx.strokeStyle = '#212529';
                let rel_x = ((((next_beat + (i * ppq)) - start) / total_duration));
                let actual_x = rel_x * width + x;
                let factor = this.getFactor(width / numberOfBeats);
                let rel_x_next = ((((next_beat + ((i + (factor)) * ppq)) - start) / total_duration));
                let actual_x_next = rel_x_next * width + x;
                let actual_width = (actual_x_next - actual_x);

                if ((i + temp) % factor === 0 || actual_width > minimum_tempo_marker_width) {
                    this.drawRect(ctx,
                        last_color === '#212529' ? '#495057' : '#212529',
                        actual_x,
                        y,
                        actual_width,
                        height);
                    last_color = last_color === '#212529' ? '#495057' : '#212529'
                }
                this.drawLine(ctx, { x: actual_x, y }, { x: actual_x, y: y + height });
                ctx.strokeStyle = '#ffffff';

                let cuts = getTrackCuts(actual_width);
                let minimum_sub_tempo_marker_width = 20;
                new Array(cuts).fill(0).map((_, i) => {
                    let offset_x = ((actual_width / cuts) * i);
                    if (actual_width > minimum_sub_tempo_marker_width)
                        this.drawLine(ctx, {
                            x: actual_x + offset_x, y
                        }, {
                            x: actual_x + offset_x, y: y + height
                        });
                })
            }
        }
    }
    getFactor(sectionWidth) {
        if (sectionWidth) {
            if (sectionWidth > 100) {
                return 1;
            }
        }
        return 2;
    }
    drawTopContainerBox(canvas, ctx, args) {
        let { x, y, trackVerticalOffset, width, height } = args;
        let measureNumberOffsetX = 2;
        let { start, ppq, end } = this.props;
        let new_start = this.left * (end - start);
        let new_end = new_start + (end - start) * this.horizontalZoom

        end = new_end;
        start = new_start;
        let total_duration = end - start;

        if (ppq) {
            let temp = Math.floor(start / ppq);
            let next_beat = (temp) * ppq;
            let numberOfBeats = (end - start) / ppq;
            ctx.lineWidth = 1;

            ctx.textAlign = "start"
            for (let i = -1; i < numberOfBeats; i = i + 1) {
                ctx.strokeStyle = '#212529';
                let rel_x = ((((next_beat + (i * ppq)) - start) / total_duration));
                let rel_x_next = ((((next_beat + ((i + 1) * ppq)) - start) / total_duration));
                let actual_width = (rel_x_next * width) - (rel_x * width);
                let actual_x = rel_x * width + x;
                let factor = this.getFactor(width / numberOfBeats);
                let x_factor = factor;
                // if (numberOfBeats > 30) {
                //     x_factor *= 2;
                // }
                if ((i + temp) % factor === 0) {
                    this.drawLine(ctx, { x: actual_x, y: y }, { x: actual_x, y: y / 2 })
                }

                if (actual_width > 25)
                    if ((i + temp) % (x_factor) === 0) {
                        ctx.fillStyle = '#212529';
                        ctx.font = `18px ${MIDI_TRACK_FONT}`;
                        ctx.fillText(`${Math.floor(((temp) + i) / 4)}:${((temp) + i) % 4}`, actual_x + measureNumberOffsetX, y + 25 - trackVerticalOffset);
                    }
            }
        }
    }
    drawLine(ctx, pt1, pt2) {

        ctx.beginPath();       // Start a new path
        ctx.moveTo(pt1.x, pt1.y);    // Move the pen to (30, 50)
        ctx.lineTo(pt2.x, pt2.y);  // Draw a line to (150, 100)
        ctx.stroke();          // Render the path
    }
    drawRect(ctx, style, x, y, width, height) {
        ctx.fillStyle = style;
        ctx.fillRect(x, y, width, height);
    }
    drawChords(ctx, args) {
        if (this.props.chords && this.props.chords.length) {
            let { x, y, width, height } = args;
            let { start, end, verticalStart = 0, verticalEnd = 127 } = this.props;
            let new_start = this.left * (end - start);
            let new_end = new_start + (end - start) * this.horizontalZoom
            end = new_end;
            start = new_start;
            let textBaseline = ctx.textBaseline;
            let textAlign = ctx.textAlign;
            let fontHeight = 32;
            let left_padding = 5;
            let last_chord_name = null;
            this.props.chords.map((chord, index) => {
                let ticks = chord.tick;
                let relativeX = (ticks - start) / (end - start);
                let actual_x = relativeX * width + x;
                let actual_y = y + 20;
                let info = chord.chords[0];
                if (info && info.smartinfo) {
                    let note_name = `${chord.note.key}${chord.note.accidental || ''} ${info.smartinfo.name}`;
                    if (info.smartinfo.isInversion) {
                        let base_note = chord.notes[chord.notes.length - info.smartinfo.inversion];
                        let melody_note = convertToMelodyNote(base_note, sharp);
                        note_name = `${melody_note.key}${melody_note.accidental || ''} ${info.smartinfo.name} inv ${info.smartinfo.inversion}`;
                    }
                    if (note_name === last_chord_name) {
                        return;
                    }
                    last_chord_name = note_name;
                    ctx.textBaseline = 'hanging';
                    ctx.fillStyle = '#ffffff';
                    ctx.textAlign = 'left';
                    ctx.font = `${fontHeight}px ${MIDI_TRACK_FONT}`;
                    let innerDrawWidth = ctx.measureText(note_name);

                    ctx.fillStyle = CHORD_TITLE_BACKGROUND;
                    this.drawInner(ctx, actual_x, actual_y, innerDrawWidth.width + left_padding + left_padding, fontHeight + 6, 1, null, 3, 3);
                    ctx.fillStyle = '#ffffff';
                    ctx.fillText(`${note_name}`, actual_x + left_padding, actual_y);
                    last_chord_name = note_name
                }
            });
            ctx.textBaseline = textBaseline;
            ctx.textAlign = textAlign;
        }

    }
    drawTrack(ctx, args) {
        let { x, y, width, height } = args;
        let { start, end, verticalStart = 0, verticalEnd = 127, tracks, currentTrack } = this.props;
        let new_start = this.left * (end - start);
        let new_end = new_start + (end - start) * this.horizontalZoom
        end = new_end;
        start = new_start;
        let total_duration = end - start;
        let verticalRange = verticalEnd - verticalStart;
        verticalRange = verticalRange * this.vZoom;
        let activeTracks = {};
        (tracks || []).map(v => {
            activeTracks[v] = true;
        })
        clearTrackSelection(this.props.trackIndex);
        if (this.props.notes) {
            let paintNotes = (v, noteIndex, all) => {
                if (activeTracks && !activeTracks[v.track]) {
                    return;
                }
                let forceDraw = false;
                if (this.dragItem && this.dragItem.id === v.id) {
                    forceDraw = true;
                }
                if (((v.ticks + v.durationTicks) >= start && (v.ticks) < end) || forceDraw) {
                    let { midi, ticks, durationTicks } = v;
                    if (this.dragItem && this.dragItem.id === v.id && this.dragItem.change) {
                        switch (this.dragItem.part) {
                            case 'left':
                                let endTick = v.durationTicks + v.ticks;
                                durationTicks = Math.max(0, endTick - this.dragItem.change.ticks);
                                ticks = endTick - durationTicks;
                                break;
                            case 'right':
                                durationTicks = Math.max(0, this.dragItem.change.ticks - v.ticks);
                                break;
                            case 'middle':
                                midi = this.dragItem.change.midi;
                                ticks = this.dragItem.change.ticks;
                                break;
                        }
                        this.dragItem.result = {
                            midi,
                            ticks,
                            durationTicks
                        }
                    }
                    else if (this.dragItem && this.dragItem.relativeChange && this.selectedNotesDic && this.selectedNotesDic[v.id]) {
                        midi += this.dragItem.relativeChange.midi;
                        ticks += this.dragItem.relativeChange.tick;
                        durationTicks += this.dragItem.relativeChange.duration;
                    }
                    let widthRelative = durationTicks / total_duration;
                    let relativeHeight = 1 / verticalRange;
                    let relativeY = 1 - (midi - this.top) / verticalRange;
                    let actual_y = relativeY * height + y;
                    let relativeX = (ticks - start) / (end - start);
                    let actual_x = relativeX * width + x;
                    let actual_width = width * widthRelative;
                    ctx.fillStyle = NOTE_OUTLINE_COLOR;
                    v.position = {
                        x: actual_x,
                        y: actual_y
                    }
                    v.dimensions = {
                        width: actual_width,
                        height: height * relativeHeight
                    }
                    if (all === true) {
                        ctx.fillStyle = `#D1D646`;
                    }
                    let { selection_start, selection_end, currentTrack } = this.props;
                    let alpha = ctx.globalAlpha;
                    if (v.track !== currentTrack) {
                        ctx.globalAlpha = 0.2;
                    }
                    this.drawOutline(ctx, actual_x, actual_y, v.dimensions.width, v.dimensions.height, 1, 1, 1)


                    if (selection_end !== undefined && selection_start !== undefined) {
                        if (forceDraw || this.isInsideSelection(actual_x, actual_width, args) || this.isSelectedNote(v)) {
                            if (hasTrackStart(this.props.trackIndex)) {
                                setTrackSelectionEnd(this.props.trackIndex, noteIndex);
                            }
                            else {
                                setTrackSelectionStart(this.props.trackIndex, noteIndex)
                            }
                            ctx.fillStyle = NOTE_SELECTION;
                        }
                        else {
                            ctx.fillStyle = (NOTE_COLORS[v.midi % NOTE_COLORS.length] || NOTE_COLOR);
                        }
                        if (this.playing_notes && this.playing_notes.indexOf(v.id) !== -1) {
                            ctx.fillStyle = NOTE_PLAYING_COLOR;
                        }
                    }
                    else {
                        ctx.fillStyle = (NOTE_COLORS[v.midi % NOTE_COLORS.length] || NOTE_COLOR);
                    }
                    if (all === true) {
                        ctx.fillStyle = COPIED_KEYS;
                    }
                    let note_height = height * relativeHeight;
                    let note_width = width * widthRelative;
                    // ctx.fillRect(actual_x, actual_y, width * widthRelative, height * relativeHeight);
                    this.drawInner(ctx, actual_x, actual_y, note_width, note_height, 1, null, 3, 3)

                    if (note_height > 20 && actual_width > 40) {
                        if (v.velocity) {
                            ctx.fillStyle = '#fffff0';
                            this.drawInner(ctx, actual_x + 10, actual_y + note_height - Math.min((v.velocity / 128) * note_height, note_height), 20, Math.min((v.velocity / 128) * note_height, note_height), 1, null, 3, 3)
                        }
                        let temp = convertToMelodyNote(midi, sharp);
                        if (temp.accidental) {
                            ctx.fillStyle = '#222222';
                        }
                        else {
                            ctx.fillStyle = '#222222';
                        }
                        let fontHeight = 14;
                        ctx.font = `${fontHeight}px ${MIDI_TRACK_FONT}`;
                        ctx.textAlign = "end";
                        let note_letter_offset = 8;
                        if (temp.key === 'C' && !temp.accidental) {
                            ctx.fillText(`${temp.key}${temp.accidental}${temp.key === 'C' && !temp.accidental ? (temp.octave + 1) : ''}`, actual_x + note_width - note_letter_offset, actual_y + note_height - fontHeight / 2);
                        }
                        else {
                            ctx.fillText(`${temp.key}${temp.accidental}`, actual_x + note_width - note_letter_offset, actual_y + note_height - fontHeight / 2);
                        }

                    }
                    ctx.globalAlpha = alpha;
                }
            }
            let props_notes = (this.props.notes || []).filter(x => x);
            let sorted = props_notes.sort((a, b) => {
                if (a.track === currentTrack) {
                    return 1;
                }
                else if (b.track === currentTrack) {
                    return 1;
                }
                else if (activeTracks && activeTracks[a.track]) {
                    return 1;
                }
                else if (activeTracks && activeTracks[b.track]) {
                    return 1;
                }
                else {
                    return a.track - b.track;
                }
            });
            sorted.map(paintNotes);
            if (this.selectedNotesDic && this.props.mode === 'copy') {
                Object.values(this.selectedNotesDic || {}).map((v, index) => {
                    if (this.selectedNotesDic[v.id]) {
                        paintNotes(this.selectedNotesDic[v.id], index, true)
                    }
                })
            }

        }
        for (let i = 0; i < 127; i = i + 12) {
            ctx.fillStyle = 'rgb(0, 2, 2)';
            let relativeY = 1 - (i - this.top) / verticalRange;
            // let relativeY = (i - this.top) / verticalRange;
            let actual_y = relativeY * height + y;
            ctx.fillRect(0, y + (actual_y), width, 1);
        }
    }
    isSelectedNote(v) {
        return !!(this.selectedNotesDic && this.selectedNotesDic[v.id])
    }
    isInsideSelection(_x, _width, args) {
        let { selection_start, selection_end } = this.props;
        let { x, y, width, height } = args;
        let widthRelative = (selection_end - selection_start) / 100;
        let relativeX = selection_start / 100;
        let actual_x = relativeX * width + x;
        return doOverlap(
            { x: actual_x, y: 1 },
            { x: actual_x + width * widthRelative, y: -1 },
            { x: _x, y: 1 },
            { x: _x + _width, y: -1 })
    }
    drawMidiNoteRows(canvas, ctx, args) {
        let { x, y, width, height } = args;
        let { start, end, verticalStart = 0, verticalEnd = 127 } = this.props;
        let new_start = this.left * (end - start);
        let new_end = new_start + (end - start) * this.horizontalZoom
        end = new_end;
        start = new_start;
        let verticalRange = verticalEnd - verticalStart;
        verticalRange = verticalRange * this.vZoom;
        let prevAlpha = ctx.globalAlpha;
        ctx.globalAlpha = .1;
        // let temp = vZoom = 20/height*(1/127);
        Array(127).fill(0).map((_, v) => {
            let relativeY = 1 - (v - this.top) / verticalRange;
            if (relativeY > -.1 && relativeY < 1) {
                let widthRelative = 1;
                let relativeHeight = 1 / verticalRange;
                let actual_y = relativeY * height + y;
                let actual_x = x;
                ctx.fillStyle = "#ff0000";// "#DEE2E6";
                if (v % 2 === 0)
                    this.drawRect(ctx, "#6C757D", actual_x, actual_y, width * widthRelative, height * relativeHeight)
            }
        });
        ctx.globalAlpha = prevAlpha;
    }
    drawMidiKeyboard(ctx, args) {
        let { x, y, outerWallLeft, width, height } = args;
        let { start, end, verticalStart = 0, verticalEnd = 127 } = this.props;
        let new_start = this.left * (end - start);
        let new_end = new_start + (end - start) * this.horizontalZoom
        end = new_end;
        start = new_start;
        let total_duration = end - start;
        let verticalRange = verticalEnd - verticalStart;
        verticalRange = verticalRange * this.vZoom;
        let prevAlpha = ctx.globalAlpha;
        ctx.globalAlpha = 1;
        let fontHeight = 18;
        ctx.font = `${fontHeight}px ${MIDI_TRACK_FONT}`;
        Array(127).fill(0).map((_, v) => {
            let relativeY = 1 - (v - this.top) / verticalRange;
            if (relativeY > -1 && relativeY < 1) {
                let widthRelative = 1;
                let relativeHeight = 1 / verticalRange;
                let actual_y = relativeY * height + y;
                let actual_x = x;
                let temp = convertToMelodyNote(v, sharp);
                let note_height = height * relativeHeight;
                if (temp.accidental) {
                    this.drawRect(ctx, DARK_KEYS, actual_x, actual_y, width * widthRelative, note_height)
                }
                else {
                    this.drawBorder(ctx, actual_x, actual_y, width * widthRelative, note_height, 1, DARK_KEYS)
                    this.drawInner(ctx, actual_x, actual_y, width * widthRelative, note_height, 1, LIGHT_KEYS)
                }

                if (note_height > 20) {
                    if (temp.accidental) {
                        ctx.fillStyle = '#ffffff';
                    }
                    else {
                        ctx.fillStyle = '#ffffff';
                    }
                    ctx.textAlign = 'right';
                    if (temp.key === 'C' && !temp.accidental) {
                        ctx.fillText(`${temp.key}${temp.accidental}${temp.key === 'C' && !temp.accidental ? (temp.octave + 1) : ''}`, actual_x - width, actual_y + note_height / 2 + fontHeight / 2);
                    }
                    else {
                        ctx.fillText(`${temp.key}${temp.accidental}`, actual_x - width / 2, actual_y + note_height / 2 + fontHeight / 2);
                    }
                }
            }
        })
        ctx.globalAlpha = prevAlpha;
    }
    getHeight() {
        return this.height || this.props.height || 100;
    }
    static handlers = {}
    static forceDraw(id, handler) {
        this.handlers[id] = (note_ids) => {
            handler(note_ids);
        }
    }
    static executeForceDraw(id, notes) {
        if (this.handlers && this.handlers[id]) {
            this.handlers[id](notes);
        }
    }
    render() {
        const { props } = this;
        const { nextBank, banks, trackSelected } = this.props;
        MidiTrackEditor.forceDraw(this.props.id || 'default', (selected_notes) => {
            this.playing_notes = selected_notes;
            this.draw();
        })
        if (true || (props.track && props.track.notes && props.track.notes.length)) {
            const doScreenScroll = (e) => {
                if (!this.lastTime || (e.timeStamp - this.lastTime) > 25) {
                    console.log(e);
                    let element = e.target;
                    let maxScrollLeft = element.scrollWidth - element.clientWidth;
                    let maxScrollTop = element.scrollHeight - element.clientHeight;
                    let skipFactor = .1;
                    this.lastTime = e.timeStamp;
                    if (true) {
                        if (keyservice.isShiftOn()) {
                            console.log('shift on')
                            if (this.scrollLeft !== undefined) {
                                let direction = this.scrollLeft - element.scrollLeft;
                                if (direction) {
                                    this.horizontalZoom = Math.min(MAXIMUM_HORIZONTAL_ZOOM_FACTOR, Math.max(.0001, direction > 0 ? (this.horizontalZoom || 0) * (1 + skipFactor) : (this.horizontalZoom || 0) * (1 - skipFactor)))
                                    if (this.props.onViewChange) {
                                        requestAnimationFrame(() => {
                                            this.props.onViewChange({
                                                top: this.top,
                                                left: this.left,
                                                vZoom: this.vZoom,
                                                horizontalZoom: this.horizontalZoom
                                            });
                                        });
                                    }
                                }
                            }
                        }
                        else {
                            if (this.scrollTop !== undefined) {
                                let direction = this.scrollTop - element.scrollTop;
                                if (direction) {
                                    if (false) {
                                        this.vZoom = Math.min(1, Math.max(0.01, direction > 0 ? (this.vZoom || 0) * (1 + skipFactor) : (this.vZoom || 0) * (1 - skipFactor)))
                                    }
                                    else {
                                        this.top = Math.max(0, Math.min(127, this.top + (direction) * this.vZoom));
                                    }
                                    if (this.props.onViewChange) {
                                        requestAnimationFrame(() => {

                                            this.props.onViewChange({
                                                top: this.top,
                                                left: this.left,
                                                vZoom: this.vZoom,
                                                horizontalZoom: this.horizontalZoom
                                            });
                                        });
                                    }
                                }
                            }
                        }
                        this.scrollLeft = maxScrollLeft / 2;
                        this.scrollTop = maxScrollTop / 2;
                        element.scrollTo(maxScrollLeft / 2, maxScrollTop / 2);
                        console.log('alt on')
                        console.log(`horizontal zoom : ${this.horizontalZoom}`)
                        console.log(`vertical zoom : ${this.vZoom}`)
                        this.draw();
                    }
                }
            }
            setDragStateListener('midi-track', 'select', (boundingClientRect) => {
                let { notes, copy_notes } = this.props;
                if (notes && notes.length) {
                    let range = this.getSelectTickMidiRange(boundingClientRect);
                    console.log(range);
                    if (range) {
                        let selectedNotes = [...notes, ...(copy_notes || [])].filter(x =>
                            x.midi >= range.start.midi &&
                            x.midi <= range.end.midi &&
                            x.track === this.props.currentTrack &&
                            notesOverlap(x, {
                                durationTicks: range.end.tick - range.start.tick,
                                ticks: range.start.tick
                            }));
                        let keepSelected = false;
                        if (this.hovered_items && this.hovered_items.length) {
                            keepSelected = true;
                        }
                        if (!keepSelected) {
                            this.selectedNotesDic = {};
                        }
                        selectedNotes.map(v => {
                            this.selectedNotesDic[v.id] = v;
                        })
                        if (this.props.onSelection) {
                            this.props.onSelection(JSON.parse(JSON.stringify(this.selectedNotesDic)));
                        }
                        this.draw();
                    }
                }

            });
            setDragStateListener('midi-track-copy', 'set-selected', (selectedNotes) => {
                this.selectedNotesDic = {}
                selectedNotes.map(v => {
                    this.selectedNotesDic[v.id] = v;
                })
                this.draw();
            })
            setDragStateListener('midi-track-delete', 'delete', () => {
                if (!this.unmounted) {
                    if (this.props.deleteSelectedItems) {
                        this.props.deleteSelectedItems(Object.keys(this.selectedNotesDic));
                        this.selectedNotesDic = {};
                    }
                }
            });
            return (
                <div className="multi-selectable-area app-display-select-cc invalid-on-shift-down" ref={this.internalDiv} style={{
                    userSelect: 'none',
                    padding: 0,
                    position: 'relative',
                    height: this.getHeight()
                }} >
                    {/* <canvas ref={this.noteCanvasRef} id={this.state.noteCanvasId} style={{
                        position: 'absolute',
                        zIndex: 1,
                        pointerEvents: 'none'

                    }}></canvas> */}
                    <canvas style={{
                        pointerEvents: 'none'
                    }} ref={this.canvasRef} id={this.state.canvasId} height={this.getHeight()}></canvas>
                    <div style={{
                        position: 'absolute',
                        zIndex: 2,
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: `100%`,
                        overflow: 'auto',
                        opacity: .001
                    }} onKeyPress={() => {

                    }} onScroll={(e) => {
                        if (!this.running) {
                            this.running = true;
                            doScreenScroll(e)
                            this.running = false;
                        }
                    }}>
                        <div style={{

                            height: '200%',
                            width: '200%',
                            userSelect: 'none'
                        }} onKeyDown={() => {

                        }} onMouseDown={(e) => {
                            console.log(e);
                            this.startPosition = {
                                top: this.top,
                                left: this.left,
                                x: e.clientX || e.pageX || e.screenX,
                                y: e.clientY || e.pageY || e.screenY
                            }
                            let temp = this.getHoverItems(e);
                            let hovered_items = temp.notes;
                            this.hovered_items = hovered_items;
                            if (hovered_items.length) {
                                let rec = this.canvasRef.current.getBoundingClientRect();
                                let relpos = getRelativePositionOfEvent(rec, e)
                                let hovered_item = hovered_items[0];

                                let x_ = relpos.x - hovered_item.position.x;
                                if (hovered_item.dimensions.width < 30) {
                                    // use percentage to decide, left, right or middle grab
                                    if (x_ / hovered_item.dimensions.width < .33) {
                                        this.dragItem = { tick: 0, id: hovered_item.id, part: 'left', hovered_items: hovered_items.map(v => v.id) };
                                    }
                                    else if (x_ / hovered_item.dimensions.width > .66) {
                                        this.dragItem = { tick: 0, id: hovered_item.id, part: 'right', hovered_items: hovered_items.map(v => v.id) };
                                    }
                                    else {
                                        this.dragItem = { tick: temp.tick, id: hovered_item.id, part: 'middle', hovered_items: hovered_items.map(v => v.id) };
                                    }
                                }

                                else {
                                    const grab_width = 10;
                                    if (x_ < grab_width) {
                                        this.dragItem = { tick: 0, id: hovered_item.id, part: 'left', hovered_items: hovered_items.map(v => v.id) };
                                    }
                                    else if (x_ > (hovered_item.dimensions.width - grab_width)) {
                                        this.dragItem = { tick: 0, id: hovered_item.id, part: 'right', hovered_items: hovered_items.map(v => v.id) };
                                    }
                                    else {
                                        this.dragItem = { tick: temp.tick, id: hovered_item.id, part: 'middle', hovered_items: hovered_items.map(v => v.id) };
                                    }
                                }
                            }
                            else {
                                if (keyservice.isShiftOn()) {
                                    this.dragging = true;
                                } else {
                                }
                            }

                        }}
                            onDoubleClick={(e) => {
                                let rec = this.canvasRef.current.getBoundingClientRect();
                                let relpos = getRelativePositionToContainer(rec, e)
                                console.log(relpos);
                                let {
                                    start,
                                    end
                                } = getStartAndEnd({ ...this.props, left: this.left, horizontalZoom: this.horizontalZoom });

                                var canvas = this.canvasRef.current;
                                let trackContainerWidth = canvas.width - (MIDI_TRACK_POSITION_DATA.outerPadding * 2);
                                let offset_x_in_ticks = (end - start) * (relpos.x / trackContainerWidth);
                                let startingTick = start + offset_x_in_ticks;
                                let {
                                    midi,
                                    melodyNote
                                } = getMidiRow({
                                    verticalEnd: this.props.verticalEnd,
                                    verticalStart: this.props.verticalStart,
                                    height: this.height,
                                    vZoom: this.vZoom,
                                    actual_y: relpos.y - MIDI_TRACK_POSITION_DATA.containerY,
                                    top: this.top
                                });
                                console.log(`starting tick : ${startingTick}`)
                                console.log(`midi note: ${midi}`);
                                if (this.props.onAddNote) {
                                    let numberOfBeats = (end - start) / this.props.ppq;
                                    let cut = getTrackCuts(trackContainerWidth / numberOfBeats);
                                    this.props.onAddNote({
                                        midi,
                                        tick: startingTick,
                                        cuts: cut
                                    })
                                }
                            }}
                            onMouseMove={(e) => {
                                let add_busy = false;
                                if (this.dragItem) {
                                    this.dragItem.change = this.getHoverMidiTickLocation(e,);
                                    let all_notes = [...this.props.notes, ...(this.props.copy_notes || [])];
                                    if (!this.selectedNotesDic || !this.selectedNotesDic[this.dragItem.id]) {
                                        this.selectedNotesDic = this.selectedNotesDic || {};
                                        this.selectedNotesDic[this.dragItem.id] = all_notes.filter(x => x.id === this.dragItem.id)[0];
                                    }
                                    if (this.selectedNotesDic) {
                                        let v = all_notes.find(x => x.id === this.dragItem.id);
                                        if (v) {
                                            let midi_change = 0;
                                            let tick_change = 0;
                                            let duration_change = 0;
                                            switch (this.dragItem.part) {
                                                case 'left':
                                                    let endTick = v.durationTicks + v.ticks;
                                                    let durationTicks = Math.max(0, endTick - this.dragItem.change.ticks);
                                                    duration_change = (durationTicks) - v.durationTicks;
                                                    tick_change = (endTick - durationTicks) - v.ticks;
                                                    break;
                                                case 'right':
                                                    duration_change = Math.abs((v.ticks - this.dragItem.change.ticks)) - v.durationTicks
                                                    break;
                                                case 'middle':
                                                    midi_change = this.dragItem.change.midi - v.midi;
                                                    tick_change = this.dragItem.change.ticks - v.ticks;
                                                    break;
                                            }
                                            this.dragItem.relativeChange = {
                                                midi: midi_change,
                                                tick: tick_change,
                                                duration: duration_change
                                            }
                                        }
                                    }
                                    this.draw();
                                    add_busy = true;
                                }
                                else if (this.dragging) {
                                    let newX = e.clientX || e.pageX || e.screenX;
                                    let newY = e.clientY || e.pageY || e.screenY;
                                    this.left = Math.max(0, Math.min(1, this.startPosition.left - (this.startPosition.x - newX) * this.horizontalZoom / this.canvasRef.current.getBoundingClientRect().width))
                                    this.top = Math.max(0, Math.min(127, this.startPosition.top + (this.startPosition.y - newY) * this.vZoom));
                                    this.draw();
                                    if (this.props.onViewChange) {
                                        this.props.onViewChange({
                                            top: this.top,
                                            left: this.left,
                                            vZoom: this.vZoom,
                                            horizontalZoom: this.horizontalZoom
                                        })
                                    }
                                    add_busy = true;
                                }
                                else {
                                    let temp = this.getHoverItems(e);
                                    let hovered_items = temp.notes;
                                    if (hovered_items && hovered_items.length) {
                                        this.internalDiv.current.style.cursor = 'pointer';
                                    }
                                    else {
                                        this.internalDiv.current.style.cursor = ''
                                    }
                                }
                                if (add_busy) {
                                    if (!this.internalDiv.current.classList.contains('is-busy')) {
                                        this.internalDiv.current.classList.add('is-busy')
                                    }
                                }
                                else {
                                    if (this.internalDiv.current.classList.contains('is-busy')) {
                                        this.internalDiv.current.classList.remove('is-busy')
                                    }
                                }

                            }} onMouseUp={() => {
                                this.dragging = false;
                                let draw = false;
                                if (this.dragItem) {
                                    if (this.props.updateNotes) {
                                        let { start, end } = this.props;
                                        var canvas = this.canvasRef.current;
                                        let new_start = this.left * (end - start);
                                        let new_end = new_start + (end - start) * this.horizontalZoom
                                        end = new_end;
                                        start = new_start;
                                        let numberOfBeats = (end - start) / this.props.ppq;
                                        let trackContainerWidth = canvas.width - (MIDI_TRACK_POSITION_DATA.outerPadding * 2);
                                        let cut = getTrackCuts(trackContainerWidth / numberOfBeats);

                                        this.props.updateNotes({
                                            selectedNotes: this.selectedNotesDic,
                                            dragItem: this.dragItem,
                                            cut
                                        });
                                        draw = true;
                                    }
                                    this.dragItem = null;
                                    draw = true;
                                }
                                let keepSelected = false;
                                if (this.hovered_items && this.hovered_items.length) {
                                    keepSelected = true;
                                }
                                if (!keepSelected) {
                                    this.selectedNotesDic = {};
                                    if (this.props.onSelectionComplete) {
                                        this.props.onSelectionComplete();
                                    }
                                    draw = true;
                                }
                                if (draw) {
                                    this.draw();
                                }
                            }} onMouseOut={() => {
                                // this.dragging = false;
                            }} ></div>
                    </div>
                </div >
            )
        }
        return <div></div>;
    }
    deselect() {
        this.dragItem = null;
        this.selectedNotesDic = null;
        this.draw();
    }

    getHoverMidiTickLocation(e) {
        let rec = this.canvasRef.current.getBoundingClientRect();
        let relpos = getRelativePositionToContainer(rec, e); let {
            start, end
        } = getStartAndEnd({ ...this.props, left: this.left, horizontalZoom: this.horizontalZoom });

        let trackContainerWidth = this.canvasRef.current.width - (MIDI_TRACK_POSITION_DATA.outerPadding * 2);
        let offset_x_in_ticks = (end - start) * (relpos.x / trackContainerWidth);
        let startingTick = start + offset_x_in_ticks;
        if (this.dragItem) {
            startingTick -= this.dragItem.tick
        }
        let {
            midi
        } = getMidiRow({
            verticalEnd: this.props.verticalEnd,
            verticalStart: this.props.verticalStart,
            height: this.height,
            vZoom: this.vZoom,
            actual_y: relpos.y - MIDI_TRACK_POSITION_DATA.containerY,
            top: this.top
        });
        return { ticks: startingTick, midi }
    }
    getSelectTickMidiRange(bb) {
        if (!this.canvasRef.current) {
            return;
        }
        let rec = this.canvasRef.current.getBoundingClientRect();
        let relpos = getRelativeBoundingBoxToContainer(rec, bb)
        let {
            start, end
        } = getStartAndEnd({ ...this.props, left: this.left, horizontalZoom: this.horizontalZoom });
        let trackContainerWidth = this.canvasRef.current.width - (MIDI_TRACK_POSITION_DATA.outerPadding * 2);
        let offset_x_in_ticks = (end - start) * (relpos.x / trackContainerWidth);
        let offset_x_end_in_ticks = (end - start) * ((relpos.x + bb.width) / trackContainerWidth);
        let startingTick = start + offset_x_in_ticks;
        let endingTick = start + offset_x_end_in_ticks;
        let {
            midi
        } = getMidiRow({
            verticalEnd: this.props.verticalEnd, verticalStart: this.props.verticalStart, height: this.height,
            vZoom: this.vZoom, actual_y: relpos.y - MIDI_TRACK_POSITION_DATA.containerY, top: this.top
        });
        let end_midi_data = getMidiRow({
            verticalEnd: this.props.verticalEnd, verticalStart: this.props.verticalStart, height: this.height,
            vZoom: this.vZoom, actual_y: relpos.y + bb.height - MIDI_TRACK_POSITION_DATA.containerY, top: this.top
        });

        return {
            start: {
                tick: startingTick,
                midi: end_midi_data.midi
            },
            end: {
                tick: endingTick,
                midi
            }
        }
    }
    getHoverItems(e) {
        let rec = this.canvasRef.current.getBoundingClientRect();
        let relpos = getRelativePositionToContainer(rec, e);
        let {
            start, end
        } = getStartAndEnd({ ...this.props, left: this.left, horizontalZoom: this.horizontalZoom });

        let trackContainerWidth = this.canvasRef.current.width - (MIDI_TRACK_POSITION_DATA.outerPadding * 2);
        let offset_x_in_ticks = (end - start) * (relpos.x / trackContainerWidth);
        let startingTick = start + offset_x_in_ticks;
        let {
            midi
        } = getMidiRow({
            verticalEnd: this.props.verticalEnd,
            verticalStart: this.props.verticalStart,
            height: this.height,
            vZoom: this.vZoom,
            actual_y: relpos.y - MIDI_TRACK_POSITION_DATA.containerY,
            top: this.top
        });
        let { notes, copy_notes } = this.props;
        let same_row = [];
        if (copy_notes && copy_notes.length) {
            same_row = copy_notes.filter(x => x.track === this.props.currentTrack && x.midi === midi && notesOverlap(x, {
                durationTicks: 2, ticks: startingTick
            }));
        }
        else {
            same_row = notes.filter(x => x.track === this.props.currentTrack && x.midi === midi && notesOverlap(x, {
                durationTicks: 2, ticks: startingTick
            }));
        }
        return { notes: same_row, tick: same_row[0] ? -(same_row[0].ticks - startingTick) : 0 };
    }
}
const MIDI_TRACK_POSITION_DATA = {
    outerPadding: 1,
    trackVerticalOffset: 30,
    containerMenuWidth: 130,
    containerY: 0,
    containerX: 0,
    keyHeight: 30
};
(() => {
    let trackContainerLeftPadding = MIDI_TRACK_POSITION_DATA.outerPadding + MIDI_TRACK_POSITION_DATA.containerMenuWidth;
    MIDI_TRACK_POSITION_DATA.containerX = trackContainerLeftPadding;
    MIDI_TRACK_POSITION_DATA.containerY = MIDI_TRACK_POSITION_DATA.trackVerticalOffset + MIDI_TRACK_POSITION_DATA.outerPadding;
})();
function getTrackCuts(actual_width) {
    let cuts = 1;
    let minimum_sub_tempo_marker_width = 20;
    if (actual_width / 32 > minimum_sub_tempo_marker_width) {
        cuts = 32;
    } else if (actual_width / 16 > minimum_sub_tempo_marker_width) {
        cuts = 16;
    }
    else if (actual_width / 8 > minimum_sub_tempo_marker_width) {
        cuts = 8;
    } else if (actual_width / 4 > minimum_sub_tempo_marker_width) {
        cuts = 4;
    }
    return cuts;
}

function getRelativePositionOfEvent(rect, evt) {
    return {
        x: evt.clientX - rect.left,
        y: evt.clientY - rect.top
    }
}
function getRelativeBoundingBoxPosition(rect, boundingBox) {
    return {
        x: boundingBox.left - rect.left,
        y: boundingBox.top - rect.top
    }
}
function getStartAndEnd({ start, end, left, horizontalZoom }) {
    let new_start = left * (end - start);
    let new_end = new_start + (end - start) * horizontalZoom
    end = new_end;
    start = new_start;
    return {
        end,
        start
    }
}
function getRelativeBoundingBoxToContainer(rect, boundingBox) {
    let relPos = getRelativeBoundingBoxPosition(rect, boundingBox);
    return {
        x: relPos.x - MIDI_TRACK_POSITION_DATA.containerX,
        y: relPos.y - MIDI_TRACK_POSITION_DATA.containerY
    }
}
function getRelativePositionToContainer(rect, evt) {
    let relPos = getRelativePositionOfEvent(rect, evt);

    return {
        x: relPos.x - MIDI_TRACK_POSITION_DATA.containerX,
        y: relPos.y - MIDI_TRACK_POSITION_DATA.containerY
    }
}

function getMidiRow({ verticalEnd, verticalStart, vZoom, height, actual_y, top }) {

    let { containerMenuWidth, trackVerticalOffset, outerPadding } = MIDI_TRACK_POSITION_DATA;
    let verticalRange = verticalEnd - verticalStart;

    verticalRange = verticalRange * vZoom;
    let relativeY = (actual_y + outerPadding + 14) / height;
    let midi = (1 - relativeY) * verticalRange;
    let melodyNote = convertToMelodyNote(Math.floor(midi + top), sharp)
    return {
        midi: Math.floor(midi + top),
        melodyNote
    }
}