import { Midi } from '@tonejs/midi';
import { uuidv4 } from './base-lib';
const midiLibrary = {};
const midiFileData = {};
const songInformation = {};
export const getSongLength = (fileName) => {

    if (midiLibrary && midiLibrary[fileName] && midiLibrary[fileName].duration) {
        return midiLibrary[fileName].durationTicks
    }
    return null;
}
export const convertMidiToPattern = (midi) => {
    let pattern = generatePattern();
    let ppq = midi.header.ppq;
    midi.tracks[0].notes.map(note => {
        let index = Math.round(4 * note.ticks / ppq);
        if (pattern.length > index) {
            if (pattern[index].indexOf(note.midi) === -1) {
                pattern[index].push(note.midi)
            }
        }
    })
    return pattern;
}
export const generatePattern = () => {
    return [].concat([].interpolate(0, 32, i => []))
}
export const getMidi = (fileName) => {

    if (midiLibrary && midiLibrary[fileName]) {
        return midiLibrary[fileName]
    }
    return null;
}
export const getMidiAsData = (fileName) => {

    if (midiFileData && midiFileData[fileName]) {
        return midiFileData[fileName]
    }
    return null;
}
export const getSongName = (fileName) => {

    if (songInformation && songInformation[fileName] && songInformation[fileName].name) {
        return songInformation[fileName].name;
    }
    return null;
}

export const createMidiLick = (notesForChop, midi, trackIndex, state, args) => {
    let notes = notesForChop.map(x => JSON.parse(JSON.stringify(x)));
    args = args || {};
    let lick = createLick(args, notes, midi, trackIndex);
    state.midiLicks[lick.id] = lick;
}

export const getTracks = (fileName) => {
    if (midiLibrary && midiLibrary[fileName] && midiLibrary[fileName].tracks) {
        return midiLibrary[fileName].tracks;
    }
    return null;
}

export const getMidiTempos = (fileName) => {
    if (midiLibrary && midiLibrary[fileName] && midiLibrary[fileName].header && midiLibrary[fileName].header.tempos) {
        return [...midiLibrary[fileName].header.tempos];
    }
    return null;
}

export const getMidiPPQ = (fileName) => {
    if (midiLibrary[fileName]) {
        let ppq = getPPQ(midiLibrary[fileName]);

        return ppq;
    }

    return null;
}
export function createLick(args, notes, midi, trackIndex) {
    let { shrinkWrap } = args;
    let minimum = notes.minimum(x => x.time);
    let minimumTicks = notes.minimum(x => x.ticks);
    let end = notes.maximum(x => x.time + x.duration);
    let endTicks = notes.maximum(x => x.ticks + x.durationTicks);
    notes.map(v => {
        v.time = v.time - minimum;
        v.ticks = v.ticks - minimumTicks;
    });

    let lick = {
        id: uuidv4(),
        notes,
        midi,
        shrinkWrap,
        ppq: args.ppq || getMidiPPQ(midi),
        minimumTicks,
        minimum,
        start: 0,
        startTicks: 0,
        end: end - minimum,
        endTicks: endTicks - minimumTicks,
        trackIndex,
        version: 1,
        name: `${args.name || midi} ${isNaN(trackIndex) ? '' : trackIndex + 1}`
    };
    return lick;
}

function _calculateMidiBeats(ppq, tempos) {
    let res = [];
    let totalTicks = 0;
    tempos.map((tempo, index) => {
        let { bpm, ticks, time } = tempo;
        let tick = getTick(bpm, ppq)
        if (index - 1 === tempos.length) {
            // last tempo
        }
        else {
            let nextTempo = tempos[index + 1];
            let totalTicks = nextTempo.ticks - ticks;
            let durationBetweenTempoChanges = convertToMilliseconds(bpm, ppq, nextTempo.ticks - ticks);
            let durationInMinutes = ((durationBetweenTempoChanges / 1000) / 60);
            let numberOfBeats = durationInMinutes * bpm;
            console.log(numberOfBeats);
            let extraTime = numberOfBeats - Math.floor(numberOfBeats);
            let floorNumberOfBeats = Math.floor(numberOfBeats);
            let floorTotalTicks = (totalTicks / numberOfBeats) * (floorNumberOfBeats + extraTime)
            for (let i = 0; i < floorNumberOfBeats; i++) {
                res.push({
                    tick: totalTicks + (floorTotalTicks / floorNumberOfBeats) * i
                });
            }
        }
    })
}


function getTempos(midi) {
    if (midi && midi.header && midi.header.tempos) {
        return [...midi.header.tempos]
    }
    return null;
}
function convertToMilliseconds(BPM, PPQ, ticks) {
    return (60000 / (BPM * PPQ)) * ticks
}
function getTick(bpm, ppq) {
    return (60000 / (bpm * ppq))
}
function getNoteTime(timeLeft, tempoArray, ppq) {
    if (tempoArray.length > 1) {
        let itemA = tempoArray[0];
        let itemB = tempoArray[1];
        let totalTicks = itemB.ticks - itemA.ticks;

        if (timeLeft > totalTicks) {
            let remainder = timeLeft - totalTicks;
            let remainingTempos = tempoArray.slice(1);
            let convertedMilliseconds = convertToMilliseconds(itemA.bpm, ppq, totalTicks)
            return convertedMilliseconds + getNoteTime(remainder, remainingTempos, ppq);
        }
        else {
            let convertedMilliseconds = convertToMilliseconds(itemA.bpm, ppq, timeLeft);
            return convertedMilliseconds;
        }
    }
    else if (tempoArray.length === 1) {
        let itemA = tempoArray[0];
        let convertedMilliseconds = convertToMilliseconds(itemA.bpm, ppq, timeLeft);
        return convertedMilliseconds;
    }
    else {
        throw 'There needs to be tempo information to calculate time.';
    }
}
export function getPPQ(midi) {
    if (midi && midi.header.ppq) {
        return midi.header.ppq;
    }
}
function getTracksInformation(midi) {

    return midi.tracks.map((track) => {
        return getTrackInformation(track, midi)
    });
}
function getTrackInformation(track, midi) {
    let tempos = getTempos(midi);
    let ppq = getPPQ(midi);
    return {
        notes: track.notes.map((note) => {
            return {
                time: getNoteTime(note.ticks, tempos, ppq)
            }
        })
    }
}
export const readMidiFile = (fileData) => {
    const midi = new Midi(fileData);
    return midi;
}

export const getMidiFilePPQ = (data) => {

    const midi = new Midi(data);
    let ppq = getPPQ(midi);
    return ppq;
}

export const loadMidi = (fileName, fileData) => {
    if (fileData) {
        const midi = new Midi(fileData);
        let tempos = getTempos(midi);
        let ppq = getPPQ(midi)
        if (tempos && ppq) {
            midiLibrary[fileName] = midi;
            midiFileData[fileName] = fileData;
            songInformation[fileName] = {
                beats: getMidiPPQ(fileName)
            };
        }
        console.log(midi);
    }
}