import { getReqs, setupCreateUpdateClocks } from "../firebase-app";
// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app';



export async function load_space_configurations(args) {
    let { db } = getReqs()
    let { uid } = args;
    let collection = db.collection(`space_configurations`).doc(`${uid}`).collection('space_configuration');
    let result = [];
    await collection.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            result.push(convertTospace_configuration(doc));
        });
    }).catch((error) => {
        console.log("Error getting documents: ", error);
    });
    return result.filter(x => x);
}

function convertTospace_configuration(doc) {
    let temp = {
        id: doc.id,
        ...doc.data()
    }
    let prog = JSON.parse(temp.space_configuration);
    temp = {
        ...prog,
        ...temp,
        fire_id: doc.id,
        id: doc.id,
        originalId: prog.originalId || prog.id
    }
    setupCreateUpdateClocks(temp);


    return temp;
}

export async function delete_space_configuration(args) {
    let { db } = getReqs()
    if (args.uid) {
        let obj = db.collection(`space_configurations`).doc(`${args.uid}`).collection('space_configuration').doc(args.id);
        await obj.delete();
        return true;
    }
    return false;
}

export async function store_space_configuration(args) {
    let { db } = getReqs()
    let { owner, fire_id } = args;
    if (fire_id) {
        // update
        let space_configuration_collection = db.collection(`space_configurations`).doc(`${owner}`).collection('space_configuration').doc(fire_id);
        let temp = {
            version: (args.version || 0) + 1,
            owner,
            space_configuration: JSON.stringify(args)
        };
        await space_configuration_collection.update({
            ...temp,
            updated: firebase.firestore.FieldValue.serverTimestamp()
        });

        return true;
    }
    else {
        // add
        let collection = db.collection(`space_configurations`).doc(`${owner}`).collection('space_configuration');
        let temp = {
            version: (args.version || 0) + 1,
            owner,
            space_configuration: JSON.stringify(args)
        }
        const docRef = await collection.add({
            ...temp,
            created: firebase.firestore.FieldValue.serverTimestamp()
        });

        console.log("Document written with ID: ", docRef.id);
        return true;
    }
}
