import React from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';

import {
    toggleSelectedChordProgression,
    addAndSelectChordProgression,
    deleteChordProgression,
    selectChordProgressions,
    getCurrentChordProgression
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import ChordProgressionItems from '../../components/music/ChordProgressionItems';
import { TitlesService } from '../../title-service';
import { getCloudChordProgressons } from '../server/serviceSlice';
import { getUserSignedIn } from '../user/userSlice';

export function ChordProgressionsView() {
    const dispatch = useDispatch();
    let progressions = useSelector(selectChordProgressions);
    let selected = useSelector(getCurrentChordProgression);
    let cloud_progressions = useSelector(getCloudChordProgressons);
    const logged_in = useSelector(getUserSignedIn);
    return (
        <Panel stretch title={TitlesService('My Chord Progressions')}>
            <ChordProgressionItems
                logged_in={logged_in}
                selected={selected}
                progressions={progressions}
                cloud_progressions={cloud_progressions}
                onAddAndSelect={(progression) => {
                    dispatch(addAndSelectChordProgression(progression))
                }}
                onDeleteItem={(id) => {
                    dispatch(deleteChordProgression({ id }))
                }}
                onSelectToggle={(chordMatch) => {
                    dispatch(toggleSelectedChordProgression({ id: chordMatch }))
                }} />
        </Panel>
    );
}