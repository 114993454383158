import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SeratoComposer from './seratoComposer';
import MidiInputDevice from './MidiInputDevice';
import { SeratoEventTypes } from './seratoComposer';

import {
    chordInputClear,
    chordInputSelect,
    chordInputSet,
    play,
    moveChordInput,
    selectBias,
    selectChordInputValue,
    toggleBias
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import NoteInput from '../../components/music/NoteInput';

export function ChordInput() {
    //   const count = useSelector(selectCount);
    const chordInputValue = useSelector((state) => selectChordInputValue(state, 'chord-input'));
    const dispatch = useDispatch();
    const bias = useSelector(selectBias);
    return (
        <Panel stretch title={'Chord Input'} onBodyDrop={(ev) => {
            const data = ev.dataTransfer.getData("text/plain");
            let chordData = JSON.parse(data);
            let keyId = Object.keys(chordData.value)[0];
            dispatch(chordInputSet({
                id: 'chord-input',
                focused: true,
                data: chordData
            }))

            dispatch(chordInputSet({
                id: 'key-input',
                focused: true,
                data: {
                    value: { [keyId]: chordData.value[keyId] }
                }
            }))


        }} onBodyDragOver={(ev) => {
            ev.preventDefault();
            ev.dataTransfer.dropEffect = "copy";

        }} bodyBackgroundColor={'white'} footer={() => {
            return (
                <div style={{ display: 'flex', flex: '1 0 auto' }}>
                    <button type="button" className="btn btn-tool" onClick={() => {
                        dispatch(chordInputClear({ id: 'chord-input' }))
                    }} data-card-widget="clear">
                        <i className="fas fa-times"></i>
                    </button>
                    <div key="biascheck">
                        <label>Bias
                            <input type="checkbox" {...{ checked: (bias || false) }} onChange={() => { }} onClick={() => {
                                // this.setState({ bias: !this.state.bias })
                                dispatch(toggleBias())
                            }} /></label>
                    </div>
                </div>)
        }}>
            <NoteInput
                onPlay={(payload) => {
                    dispatch(play(payload))
                }}
                onStop={(payload) => {
                    dispatch(play(payload))
                }}
                onMoveTones={(dir) => {
                    dispatch(moveChordInput(dir))
                }}
                skipbank
                value={chordInputValue} bias={bias} onNoteInputFocus={(focused) => {
                    dispatch(chordInputSelect({ id: 'chord-input', focused }))
                }} />
        </Panel>
    );
}