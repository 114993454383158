import React from 'react';
import { version } from './version';
export default class ContentFooter extends React.Component {

    render() {
        return (
            <footer className="main-footer">
                <strong>Copyright &copy; 2014-2021 <a href="">mephistowa</a>.</strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> {version.major}.{version.minor}.{version.build}
                </div>
            </footer>
        )
    }
}