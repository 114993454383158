import React from 'react';

export default class SidebarContainer extends React.Component {

    render() {
        return (
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <a href="#" className="brand-link">
                    <img src="dist/img/AdminLTELogo.png" alt="Composer Companion Logo" className="brand-image img-circle elevation-3"
                        style={{ opacity: .8 }} />
                    <span className="brand-text font-weight-light" title="Composer Companion Studio Style">CC SS</span>
                </a>
                {this.props.children}
            </aside>
        )
    }
}