import React from 'react';
import ChordMaster, { calculateV } from '../../base/chord-master';
import Infinite from 'mephistowa-react-infinite';



const FilterKeys = {
    Diminished: '°',
    Major: 'Ma',
    Minor: 'Mi',
    Compacted: 'c',
    Suspended: 'Sus'

}

export default class OptionsList extends React.Component {
    reader = null;
    constructor(props) {
        super(props);
        this.reader = new ChordMaster.Reader({ skipRenderer: true });
        this.state = { currentpage: 0, pagingsize: 10000 };
    }
    render() {
        const { selected, filter } = this.props;
        let { currentpage, pagingsize } = this.state;
        var v = calculateV(this.props.melodylist || []);
        var scales = this.props.scales || [];// getSelectedScales();
        var matches = this.reader.findMatches(v, scales, currentpage, pagingsize, (selected || {}));
        if (filter && filter.length) {
            matches = matches.filter((item) => {
                let { inversion, name, _familyName } = item.smartinfo;
                name = `${name}` || '';
                Object.keys(FilterKeys).map(v => {
                    if (name.indexOf(FilterKeys[v]) !== -1) {
                        name += v;
                    }
                });

                return !filter.some(v => {
                    v = v.toLowerCase();
                    return !((`${(inversion + 1)}` || '').toLowerCase().indexOf(v) !== -1 ||
                        (`${name}` || '').toLowerCase().indexOf(v) !== -1 ||
                        (`${_familyName}` || '').toLowerCase().indexOf(v) !== -1);
                });
            });
        }
        return (
            <Infinite useParentContainer elementHeight={49}>
                {matches.map((match, index) => {
                    return <OptionListItem mode={this.props.mode} selected={(selected || {})[match._info.id] || false} onClick={() => {
                        if (this.props.onSelectToggle) {
                            this.props.onSelectToggle(match);
                        }
                    }} match={match} key={`list-item-${match._info.id}`} />;
                })}
            </Infinite>
        )
    }
}
class OptionListItem extends React.Component {
    render() {
        let { selected, mode } = this.props;
        let { inversion, isInversion, name, _familyName } = this.props.match.smartinfo;
        return (
            <div style={{ flex: 1, flexDirection: 'row', height: 49, display: 'flex', cursor: 'pointer' }} onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick();
                }
            }}>
                <div style={{ paddingLeft: 4 }}>{mode === 'select' ? <i className="fas fa-music" ></i> : <i className={!selected ? "fas fa-circle" : "fas fa-check-circle"}></i>}</div>
                <div style={{ paddingLeft: 4 }}>{name}</div>
                <div style={{ paddingLeft: 4 }}>{_familyName}</div>
                <div style={{ paddingLeft: 4 }}>{(inversion + 1)}{["st", "nd", "rd", "th"][inversion] || 'th'}</div>
            </div>
        )
    }
}