function code() {
    let music_vae = window.music_vae;
    let core = window.core;

    return {
        tf: window.tf,
        mm: core,
        ...music_vae
    }
}
let { tf, mm, MusicVAE, Decoder, Encoder } = code();
export { tf, mm, MusicVAE, Decoder, Encoder };
const QPM = 120;
const STEPS_PER_QUARTER = 24;
const Z_DIM = 256;
const HUMANIZE_SECONDS = 0.01;

export const START_PLAYING = 'START_PLAYING';
export const LOADED_SAMPLES = 'LOADED_SAMPLES';
export const LOADING_SAMPLES = 'LOADING_SAMPLES';
export const PLAYING_CHORD_PROGRESSION = 'PLAYING_CHORD_PROGRESSION';
export const GENERATE_MUSIC = 'GENERATE_MUSIC';
export const KITS = ['jazz_kit', 'sgm_plus', 'salamander'];
let tick_listeners = {};
let tick_start_listeners = {};
let tick_end_listeners = {};

export function addTickStartListener(name, callback) {
    tick_start_listeners[name] = { name, callback };
}
export function addTickEndListener(name, callback) {
    tick_end_listeners[name] = { name, callback };
}
export function addTickListener(name, callback) {
    tick_listeners[name] = { name, callback };
}

export default class MagentaService {

    player = null;
    z1 = null;
    z2 = null;
    chordSeqs = null;
    progSeqs = null;
    alphaSliderValue = 0;
    changingChords = false;
    kitLoading = {};
    kitLoaded = {};
    playing = false;
    numSteps = 3;
    chordInputs = []
    chords = []
    kits = [];
    mm = null;
    model = null;
    init() { // Set up Multitrack MusicVAE.
        const model = new MusicVAE('https://storage.googleapis.com/magentadata/js/checkpoints/music_vae/multitrack_chords');
        this.model = model;

        // Set up an audio player.
        this.player = this.initPlayerAndEffects();
        this.setStoppedState = this.setStoppedState.bind(this);
    }
    setupProgression(chords, progression) {
        // let chords = progression.map(v => v.options[0].smartinfo.voice.map(b => (b + v.offset) % 12).sort((a, b) => a - b))
        this.chords = chords;
    }
    midiToSequenceProto(midi) {
        return mm.midiToSequenceProto(midi)
    }
    playComposerProgression(magenta_notes, selected_tracks = [], tracks = null, track_selection = undefined) {
        if (this.playing) {
            this.playing = false;
            this.setStoppedState();
            this.player.stop();
        }

        let track_seq = mm.midiToSequenceProto(magenta_notes);
        if (selected_tracks && selected_tracks.length) {
            track_seq.notes = track_seq.notes.filter(x => {
                return selected_tracks.indexOf(x.instrument) !== -1;
            });
        }
        if (track_selection && !isNaN(track_selection.end) && !isNaN(track_selection.start)) {
            track_seq.notes = track_seq.notes.slice(track_selection.start, track_selection.end + 1);
            let _start = track_seq.notes[0].startTime;
            let _end = track_seq.notes[track_seq.notes.length - 1].endTime;
            track_seq.notes.forEach(v => {
                v.startTime = v.startTime - _start;
                v.endTime = v.endTime - _start;
            });
            track_seq.totalTime = _end - _start;
        }
        this.playing = true;
        this.raise(START_PLAYING, {});
        Object.values(tick_start_listeners).map(v => {
            v.callback();
        });

        return this.player.start(this.humanize(track_seq)).then(() => {
            this.playing = false;
            this.setStoppedState();
            this.player.stop();

            Object.values(tick_end_listeners).forEach((listener) => {
                listener.callback();
            });
        }).catch(e => {
            console.log(e)
        });
    }
    switchKit(kit) {
        if (this.playing) {
            this.playing = false;
            this.setStoppedState();
            this.player.stop();
        }
        if (this.kits[kit]) {
            this.player = this.kits[kit]
        }
    }
    getKit(i) {
        return this.kits[i]
    }
    stopPlaying() {
        if (this.playing) {
            this.playing = false;
            this.setStoppedState();
            this.player.stop();
            Object.values(tick_end_listeners).forEach((listener) => {
                listener.callback();
            });
        }
    }
    interpolate(melody1, melody2, numInterpolations) {

        function interpolateMelodies() {
            let interpolatedNoteSequences = this.model.interpolate([melody1, melody2], numInterpolations);
            //it is possible that the original melodies will shift slightly depending on the model
            //lets preserve the original melodies
            interpolatedNoteSequences[0] = melody1;
            interpolatedNoteSequences[interpolatedNoteSequences.length - 1] = melody2;
            return interpolatedNoteSequences;
        }
        return interpolateMelodies();
    }
    generate() {
        this.raise(GENERATE_MUSIC, { complete: false });
        (this.modelInitialized ? Promise.resolve() : this.model.initialize())
            .then(() => {
                this.modelInitialized = true;
                this.setUpdatingState();
                return new Promise((resolve, err) => {
                    setTimeout(() => {
                        try {
                            this.generateSample(z => {
                                this.z1 = z;
                                this.generateSample(z => {
                                    this.z2 = z;
                                    this.generateProgressions(() => {
                                        this.setStoppedState();
                                        this.raise(GENERATE_MUSIC, { complete: true });
                                        resolve();
                                    }, () => {
                                        err();
                                    });

                                }, () => {
                                    err();
                                });
                            }, () => {
                                err();
                            });
                        } catch (e) {
                            err();
                        }
                    }, 0);
                })
            }).catch(() => {
                this.setStoppedState();
                this.raise(GENERATE_MUSIC, { error: true, complete: true });
            });
    }

    // Sample a latent vector.
    generateSample(doneCallback, err) {
        const z = tf.randomNormal([1, Z_DIM]);
        z.data().then(zArray => {
            z.dispose();
            doneCallback(zArray);
        }).catch(e => {
            err();
        });
    }

    // Randomly adjust note times.
    humanize(s) {
        const seq = mm.sequences.clone(s);
        seq.notes.forEach((note) => {
            let offset = HUMANIZE_SECONDS * (Math.random() - 0.5);
            if (seq.notes.startTime + offset < 0) {
                offset = -seq.notes.startTime;
            }
            if (seq.notes.endTime > seq.totalTime) {
                offset = seq.totalTime - seq.notes.endTime;
            }
            seq.notes.startTime += offset;
            seq.notes.endTime += offset;
        });
        return seq;
    }


    // Concatenate multiple NoteSequence objects.
    concatenateSequences(seqs) {
        const seq = mm.sequences.clone(seqs[0]);
        let numSteps = seqs[0].totalQuantizedSteps;
        for (let i = 1; i < seqs.length; i++) {
            const s = mm.sequences.clone(seqs[i]);
            s.notes.forEach(note => {
                note.quantizedStartStep += numSteps;
                note.quantizedEndStep += numSteps;
                seq.notes.push(note);
            });
            numSteps += s.totalQuantizedSteps;
        }
        seq.totalQuantizedSteps = numSteps;
        return seq;
    }

    // Interpolate the two styles for a single chord.
    interpolateSamples(chord, doneCallback, errorback) {
        const z1Tensor = tf.tensor2d(this.z1, [1, Z_DIM]);
        const z2Tensor = tf.tensor2d(this.z2, [1, Z_DIM]);
        const zInterp = slerp(z1Tensor, z2Tensor, this.chords.length);

        return this.model.decode(zInterp, undefined, { chordProgression: [chord] }, STEPS_PER_QUARTER)
            .then(sequences => doneCallback(sequences)).catch(errorback);
    }

    // Generate interpolations for all chords.
    generateInterpolations(chordIndex, result, doneCallback, errorback) {
        try {
            if (chordIndex === this.chords.length) {
                doneCallback(result);
            } else {
                return this.interpolateSamples(this.chords[chordIndex], seqs => {
                    for (let i = 0; i < this.chords.length; i++) {
                        result[i].push(seqs[i]);
                    }
                    return this.generateInterpolations(chordIndex + 1, result, doneCallback, errorback);
                }, errorback)
            }
        } catch (e) {
            if (errorback)
                errorback(e)
            else
                throw e
        }
    }

    // Generate chord progression for each alpha.
    generateProgressions(doneCallback, errorCallback) {
        try {
            let temp = [];
            for (let i = 0; i < this.chords.length; i++) {
                temp.push([]);
            }
            return this.generateInterpolations(0, temp, seqs => {
                this.chordSeqs = seqs;
                let concatSeqs = this.chordSeqs.map(s => this.concatenateSequences(s));
                this.progSeqs = concatSeqs.map(seq => {
                    const mergedSeq = mm.sequences.mergeInstruments(seq);
                    const progSeq = mm.sequences.unquantizeSequence(mergedSeq);
                    progSeq.ticksPerQuarter = STEPS_PER_QUARTER;
                    return progSeq;
                });

                const fullSeq = this.concatenateSequences(concatSeqs);
                const mergedFullSeq = mm.sequences.mergeInstruments(fullSeq);

                this.setLoadingState();
                return this.player.loadSamples(mergedFullSeq)
                    .then(doneCallback);
            }, errorCallback);
        } catch (e) {
            errorCallback();
        }
    }
    statusDiv() { }
    // Set UI state to updating styles.
    setUpdatingState() {
        this.statusDiv('Updating arrangements...');
        // controls.setAttribute('disabled', true);
    }

    // Set UI state to updating instruments.
    setLoadingState() {
        this.statusDiv('Loading samples...');
        // controls.setAttribute('disabled', true);
        // chordsContainer.setAttribute('disabled', true);
        // changeChordsButton.innerText = 'Change chords';
    }

    // Set UI state to playing.
    setStoppedState() {
        this.statusDiv('Ready to play!');
        // statusDiv.classList.remove('loading');
        // controls.removeAttribute('disabled');
        // chordsContainer.setAttribute('disabled', true);
        // changeChordsButton.innerText = 'Change chords';
        // playButton.innerText = 'Play';
        // chordInputs.forEach(c => c.classList.remove('playing'));
    }

    // Set UI state to playing.
    setPlayingState() {
        this.statusDiv('Move the slider to interpolate between styles.');
        // playButton.innerText = 'Stop';
        // controls.removeAttribute('disabled');
        // chordsContainer.setAttribute('disabled', true);
        // changeChordsButton.innerText = 'Change chords';
    }

    // Set UI state to changing chords.
    setChordChangeState() {
        this.statusDiv('Change chords (triads only) then press Done.');
        // changeChordsButton.innerText = 'Done';
        // chordsContainer.removeAttribute('disabled');
        // chordInputs.forEach(c => c.classList.remove('playing'));
    }

    // Play the interpolated sequence for the current slider position.
    playProgression(chordIdx) {
        const idx = this.alphaSliderValue;

        // chordInputs.forEach(c => c.classList.remove('playing'));
        // chordInputs[chordIdx].classList.add('playing');
        this.raise(PLAYING_CHORD_PROGRESSION, { chordIdx })
        const unquantizedSeq = mm.sequences.unquantizeSequence(this.chordSeqs[idx][chordIdx]);
        this.raise(START_PLAYING, {})
        this.player.start(this.humanize(unquantizedSeq))
            .then(() => {
                const nextChordIdx = (chordIdx + 1) % this.chords.length;

                this.playProgression(nextChordIdx);
            });
    }
    raise(evt, args) {
        if (this.listeners) {
            this.listeners.filter(x => x.evt === evt).map(x => x.callback(args));
        }
    }
    listen(evt, callback) {
        this.listeners = this.listeners || [];
        this.listeners.push({ evt, callback });
    }
    // Update the start style.
    updateSample1() {
        this.playing = false;
        this.setUpdatingState();
        this.player.stop();
        setTimeout(() => {
            this.generateSample(z => {
                this.z1 = z;
                this.generateProgressions(this.setStoppedState);
            });
        }, 0);
    }

    // Update the end style.
    updateSample2() {
        this.playing = false;
        this.setUpdatingState();
        this.player.stop();
        setTimeout(() => {
            this.generateSample(z => {
                this.z2 = z;
                this.generateProgressions(this.setStoppedState);
            });
        }, 0);
    }
    getSequenceAsMidi() {
        const idx = this.alphaSliderValue;
        const midi = mm.sequenceProtoToMidi(this.progSeqs[idx]);
        return midi;
    }
    // Save sequence as MIDI.
    saveSequence() {
        const idx = this.alphaSliderValue;
        const midi = mm.sequenceProtoToMidi(this.progSeqs[idx]);
        const file = new Blob([midi], { type: 'audio/midi' });

        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, 'prog.mid');
        } else { // Others
            const a = document.createElement('a');
            const url = URL.createObjectURL(file);
            a.href = url;
            a.download = 'prog.mid';
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }
    // Save sequence as MIDI.
    saveMidi(midi, name) {
        const file = new Blob([midi], { type: 'audio/midi' });

        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, name || 'prog.mid');
        } else { // Others
            const a = document.createElement('a');
            const url = URL.createObjectURL(file);
            a.href = url;
            a.download = name || 'prog.mid';
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

    // Start or stop playing the sequence at the current slider position.
    togglePlaying() {
        mm.Player.tone.context.resume();

        if (this.playing) {
            this.playing = false;
            this.setStoppedState();
            this.player.stop();
        } else {
            this.playing = true;
            this.setPlayingState();
            this.playProgression(0);
        }
    }

    // Start or finish changing chords.
    toggleChangeChords() {
        if (this.changingChords) {
            this.changingChords = false;
            this.chords = this.chordInputs.map(c => c.value);
            this.setUpdatingState();
            setTimeout(() => this.generateProgressions(this.setStoppedState), 0);
        } else {
            this.playing = false;
            this.changingChords = true;
            this.setChordChangeState();
            this.player.stop();
        }
    }

    // One of the chords has been edited.
    chordChanged() {
        const isGood = (chord) => {
            if (!chord) {
                return false;
            }
            try {
                mm.chords.ChordSymbols.pitches(chord);
                return true;
            } catch (e) {
                return false;
            }
        }

        var allGood = true;
        this.chordInputs.forEach(c => {
            if (isGood(c.value)) {
                // c.classList.remove('invalid');
            } else {
                // c.classList.add('invalid');
                allGood = false;
            }
        });

        // changeChordsButton.disabled = !allGood;
    }
    playNote(parsed, kit, program = 0) {
        let currentKit = kit === undefined ? 1 : kit;
        if (this.kitLoaded[currentKit] && this.kitLoaded[currentKit][program]) {
            this.kits[currentKit].playNote(parsed.startTime, { ...parsed, program });
        }
        else if (!(this.kitLoading[currentKit] && this.kitLoading[currentKit][program])) {
            this.kitLoading[currentKit] = this.kitLoading[currentKit] || {};
            this.kitLoading[currentKit][program] = true;
            this.raise(LOADING_SAMPLES, { kit: currentKit });
            // let samples = [].interpolate(0, 100, velocity => [].interpolate(0, 127, (v) => {
            //     return ({
            //         pitch: v,
            //         velocity: velocity,
            //         program: program || 0, // note.program ||
            //         isDrum: false, // note.isDrum ||
            //     });
            // })).flat();
            this.kits[currentKit].loadAllSamples(program).then(() => {
                this.kitLoaded[currentKit] = this.kitLoaded[currentKit] || {};
                this.kitLoaded[currentKit][program] = true;
                this.kitLoading[currentKit][program] = false;
                this.raise(LOADED_SAMPLES, { kit: currentKit });
            });
        }
    }
    playNoteDown(parsed, kit, program = 0) {
        let currentKit = kit === undefined ? 1 : kit;
        if (this.kitLoaded[currentKit] && this.kitLoaded[currentKit][program]) {
            this.kits[currentKit].playNoteDown({ ...parsed, velocity: parsed.velocity < 1 ? parsed.velocity * 128 : parsed.velocity, program });
        }
        else if (!(this.kitLoading[currentKit] && this.kitLoading[currentKit][program])) {
            this.kitLoading[currentKit] = this.kitLoading[currentKit] || {};
            this.kitLoading[currentKit][program] = true;
            this.raise(LOADING_SAMPLES, { kit: currentKit });
            this.kits[currentKit].loadAllSamples(program).then(() => {
                this.kitLoaded[currentKit] = this.kitLoaded[currentKit] || {};
                this.kitLoaded[currentKit][program] = true;
                this.kitLoading[currentKit][program] = false;
                this.raise(LOADED_SAMPLES, { kit: currentKit });
            });
        }
    }
    playNoteUp(parsed, kit, program = 0) {
        let currentKit = kit === undefined ? 1 : kit;
        if (this.kitLoaded[currentKit] && this.kitLoaded[currentKit][program]) {
            this.kits[currentKit].playNoteUp({ ...parsed, program });
        }
    }
    initPlayerAndEffects() {
        const MAX_PAN = 0.2;
        const MIN_DRUM = 35;
        const MAX_DRUM = 81;

        // Set up effects chain.
        const globalCompressor = new mm.Player.tone.MultibandCompressor();
        const globalReverb = new mm.Player.tone.Freeverb(0.25);
        const globalLimiter = new mm.Player.tone.Limiter();
        globalCompressor.connect(globalReverb);
        globalReverb.connect(globalLimiter);
        globalLimiter.connect(mm.Player.tone.Master);

        let Tone = mm.Player.tone;
        if (!currentSchedulerId) {
            currentSchedulerId = Tone.Transport.scheduleRepeat(tick, '16n');
        }
        // Set up per-program effects.
        const programMap = new Map();
        for (let i = 0; i < 128; i++) {
            const programCompressor = new mm.Player.tone.Compressor();
            const pan = 2 * MAX_PAN * Math.random() - MAX_PAN;
            const programPanner = new mm.Player.tone.Panner(pan);
            programMap.set(i, programCompressor);
            programCompressor.connect(programPanner);
            programPanner.connect(globalCompressor);
        }

        // Set up per-drum effects.
        const drumMap = new Map();
        for (let i = MIN_DRUM; i <= MAX_DRUM; i++) {
            const drumCompressor = new mm.Player.tone.Compressor();
            const pan = 2 * MAX_PAN * Math.random() - MAX_PAN;
            const drumPanner = new mm.Player.tone.Panner(pan);
            drumMap.set(i, drumCompressor);
            drumCompressor.connect(drumPanner);
            drumPanner.connect(globalCompressor);
        }
        //         
        //   soundFontPlayers.push(new mm.SoundFontPlayer(baseUrl + 'salamander'));
        //   soundFontPlayers.push(new mm.SoundFontPlayer(baseUrl + 'sgm_plus'));
        //   soundFontPlayers.push(new mm.SoundFontPlayer(baseUrl + 'jazz_kit'));

        // Set up SoundFont player.
        const baseUrl = 'https://storage.googleapis.com/magentadata/js/soundfonts/';
        const kits = ['jazz_kit', 'sgm_plus', 'salamander'].map(kit => {
            let player = new mm.SoundFontPlayer(baseUrl + kit, globalCompressor, programMap, drumMap);
            return player
        });
        this.kits = kits;
        //'https://storage.googleapis.com/download.magenta.tensorflow.org/soundfonts_js/sgm_plus'
        return kits[1];
    }
}

function tick(time = null) {
    if (time === null) {
        let Tone = mm.Player.tone;
        time = Tone.now() - Tone.context.lookAhead;
    }
    raiseTick(time);
}

let currentSchedulerId;
function raiseTick(time) {
    Object.values(tick_listeners).forEach((listener) => {
        listener.callback(time);
    });
}

// Construct spherical linear interpolation tensor.
function slerp(z1, z2, n) {
    const norm1 = tf.norm(z1);
    const norm2 = tf.norm(z2);
    const omega = tf.acos(tf.matMul(tf.div(z1, norm1),
        tf.div(z2, norm2),
        false, true));
    const sinOmega = tf.sin(omega);
    const t1 = tf.linspace(1, 0, n);
    const t2 = tf.linspace(0, 1, n);
    const alpha1 = tf.div(tf.sin(tf.mul(t1, omega)), sinOmega).as2D(n, 1);
    const alpha2 = tf.div(tf.sin(tf.mul(t2, omega)), sinOmega).as2D(n, 1);
    const z = tf.add(tf.mul(alpha1, z1), tf.mul(alpha2, z2));
    return z;
}
