import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadMidi } from "../../base/midi-service";
import { BuildMidiFromRecord } from "../../base/midi-writer-service";
import { getSongFileName } from "../../base/nameService";
import { getTempo, loadMidiFile, updateTempo } from "../../features/composer/composerSlice";
import { RecordingService } from "../../features/composer/seratoComposer";
import { TitlesService } from "../../title-service";
import { FormGroup } from "../FormGroup";
import Panel from "../panel/Panel";

export function MusicRecorder(props) {
    const dispatch = useDispatch();

    let [recording, setRecording] = useState(false);
    let [currentRecording, setCurrentRecording] = useState(false);
    const tempo = useSelector(getTempo);
    return (
        <Panel stretch title={TitlesService('Music Recorder')}>
            <div>
                <label>{TitlesService('Controls')}</label>
                <FormGroup>
                    <div className="btn-group">
                        <button type="button"
                            className={!recording ? "btn btn-success" : "btn btn-danger "}
                            onClick={() => {
                                if (recording) {
                                    let record = RecordingService.stopRecording();
                                    if (record && record.length) {
                                        let midi = BuildMidiFromRecord(record, tempo);
                                        let name = getSongFileName()
                                        loadMidi(name, midi);
                                        dispatch(loadMidiFile({ name: name }))
                                    }
                                }
                                else {
                                    let currentRec = RecordingService.startRecording();
                                    setCurrentRecording(currentRec);
                                }
                                setRecording(!recording);
                            }}>
                            <i className={!recording ? "fas fa-microphone" : "fas fa-microphone"} />
                        </button>
                    </div>
                </FormGroup>
            </div>
            <div>
                <FormGroup>
                    <label>{TitlesService('Tempo')}</label>
                    <input onChange={(e) => {
                        dispatch(updateTempo(parseInt(e.currentTarget.value)));
                    }} type="text" value={tempo} className="form-control" placeholder="Enter ..." />
                </FormGroup>
            </div>
        </Panel >
    );
}
