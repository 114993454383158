import React from 'react';

export default class NavbarHolder extends React.Component {
    render() {
        return (
            <ul className={`navbar-nav ${this.props.right ? 'ml-auto' : ''}`}>
                {this.props.children}
            </ul>
        );
    }
}