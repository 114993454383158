import React from 'react';
import { ComposerHeader } from './features/composer/ComposerHeader';
import './App.css';
import Preloader from './components/Preloader';
import Navbar from './components/nav/Navbar';
import NavbarHolder from './components/nav/NavbarHolder';
import NavbarButton from './components/nav/NavbarButton';
import SidebarContainer from './components/main/SideBarContainer';
import Sidebar from './components/main/SideBar';
import SidebarMenu from './components/main/SideBarMenu';
import SidebarMenuItem from './components/main/SideBarMenuItem';
import ContentWrapper from './components/content/ContentWrapper';
import ChordProgressionNonLinearEditorView from './features/composer/ChordProgressionNonLinearEditorView';
import ContentHeader from './components/content/ContentHeader';
import ContentMain from './components/content/ContentMain';
import ContentFooter from './components/content/ContentFooter';
import ControlSideBar from './components/ControlSideBar';
import SpacePane, { GetSpacePane } from './components/content/SpacePane';
import Scene from './components/music/Scene';
import { ChordInput } from './features/composer/ChordInput';
import { KeyInput } from './features/composer/KeyInput';
import { ChordOptionLists } from './features/composer/ChordOptionLists';
import { ScaleOptionList } from './features/composer/ScaleOptionList';
import { ChordProgressionsView } from './features/composer/ChordProgressionsView';
import ModifierView from './features/composer/ModifierView';
import { ModifierOptionsList } from './features/composer/ModifierOptionsList';
import { OnDeckPallettes } from './features/composer/OnDeckPallettes';
import { ChordPallette } from './features/composer/ChordPallette';
import Scenes from './components/music/Scenes';
import MusicGenerator from './components/music/MusicGenerator';
import * as midiService from './base/midi-service';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportToSerato,
  loadComposerCompanionStorage,
  newComposerCompanionStorage,
  saveComposerCompanionStorage,
  loadMidiFile
} from './features/composer/composerSlice';
import {
  setAuthenticationInfo,
  getUserProfileImage,
  getUserSignedIn,
  getUserDisplayName,
  getUserId,
  getSpaceConfigurations,
  getToasts
} from './features/user/userSlice';
import { QuickInputController } from './features/composer/QuickInputComponent';
import { MidiFiles, MidiFolderSystem, SearchMidiFiles } from './features/composer/MidiFiles';
import { MidiViewer } from './features/composer/MidiViewer';
import { readFile } from './base/util';
import { MidiLicks } from './features/composer/MidiLicks';
import MidiLickDetails from './features/composer/MidiLickDetails';
import * as keyservice from './base/key-service';
import { TitlesService } from './title-service';
import * as spacePanelConfigService from './base/space-panel-config-service';
import { PROVIDERS, setAuthStateChangedHandler, signinWith, signOut } from './base/firebase-app';
import Welcome from './components/music/Welcome';
import DrumGenerator from './features/composer/DrumGenerator';
import { GetMidiFolderStructure, LoadCloudLiccsAsync, LoadCloudModifiersAsync, LoadUserChordProgressionsAsync, LoadUserPatternsAsync } from './features/composer/magenta-thunks';
import { useState } from 'react';
import { doActiveSelect, doDeletePressed, getDragState, onDragState } from './base/dragstateservice';
import { SpaceConfigurations, SpacePanelConfiguration } from './features/composer/SpaceConfigurations';
import { CreateSpaceConfigurationAsync, LoadSpaceConfigurations } from './features/composer/user-thunks';
import { FormGroup } from './components/FormGroup';
import { ToastContainer } from './components/main/ToastContainer';
import { MusicRecorder } from './components/music/MusicRecorder';
import { MidiTrackerEditor } from './features/composer/MidiTrackerEditor';
import { MidiTrackerEditorControls } from './features/composer/MidiTrackEditorControls';
import { ChordSelectList } from './features/composer/ChordSelectList';
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panels: {
        NLE: true,
        'Chord Input': true,
        ScaleOptionList: true,
        ChordOptionLists: true,
        OnDeckPallettes: true,
        MidiFiles: true,
        MidiViewer: true,
        QuickInputController: true
      }
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true })
      document.body.addEventListener('mousedown', event => {
        keyservice.mouseDown(true)
      })
      document.body.addEventListener('mouseup', event => {
        keyservice.mouseDown(false)
      });
      document.body.addEventListener('click', (event) => {
        keyservice.onclick(event);
      })
      document.body.addEventListener("keydown", event => {
        if (event.shiftKey) {
          keyservice.onShiftKey(true)
          console.log('shift  pressed')
        }
        if (event.ctrlKey) {
          keyservice.onCtrlKey(true)
        }
        if (event.altKey) {
          keyservice.onAltKey(true)
          console.log('alt  pressed')
        }
      });
      document.body.addEventListener('keyup', event => {
        if (event.shiftKey || event.code === 'ShiftLeft' || event.code === 'ShiftRight') {
          keyservice.onShiftKey(false)
          console.log('shift  released')
        }
        if (event.ctrlKey) {
          keyservice.onCtrlKey(false)
        }
        if (event.altKey || event.code === 'AltLeft' || event.code === 'AltRight') {
          keyservice.onAltKey(false)
          console.log('alt  released')
        }
        if (event.key == 'Delete') {
          doDeletePressed();
        }
      })

      let { space, panels } = spacePanelConfigService.GetPanelConfiguration(spacePanelConfigService.AppSpaceConfigurations.WELCOME);
      this.updatePanels(panels);
      this.updateSpace(space);
    }, 4000)
  }
  enabled(key) {
    return this.state.panels[key] ? 'text-success' : 'text-danger';
  }
  isEnabled(key) {
    return this.state.panels[key] ? true : false;
  }
  toggle(key) {
    this.setState({
      panels: {
        ...this.state.panels,
        [key]: !this.state.panels[key]
      }
    }, () => {
      keyservice.setPanels(Object.keys(this.state.panels).filter(x => this.state.panels[x]));
    })
  }
  start(config) {
    let { space, panels } = spacePanelConfigService.GetPanelConfiguration(config);
    this.startSpace(panels, space);
  }
  startSpace(panels, space) {
    this.updatePanels(panels);

    this.updateSpace(space);
  }
  render() {


    return (
      <Wrapper drag={this.state.drag}>
        <Preloader hide={this.state.loaded} />
        <Navbar >
          <NavbarHolder>
            <NavbarButton
              title={TitlesService('Chop')}
              onClick={() => {
                this.start(spacePanelConfigService.AppSpaceConfigurations.MIDI_CHOP);
              }} />
            <NavbarButton
              title={TitlesService('Chord Progression')}
              onClick={() => {
                this.start(spacePanelConfigService.AppSpaceConfigurations.PROGRESSION);
              }} />
            <NavbarButton
              title={TitlesService('Modifiers')}
              onClick={() => {
                this.start(spacePanelConfigService.AppSpaceConfigurations.MODIFIER_CONFIG);
              }} />
          </NavbarHolder>
          <NavbarHolder right />
        </Navbar>
        <SidebarContainer >
          <Sidebar >
            <UserInfoMenu />
            <SignInMenu />
            {this.isEnabled('Welcome') ? null : <SidebarMenu>
              <NewButton />
              <SaveButton />
              <FileLoader />
            </SidebarMenu>}
            <SidebarMenu>
              <Settings panels={this.state.panels} />
              <SpaceConfigurationsMenu onSetCurrentSpaceConfiguration={(config) => {
                try {
                  let { panels, space } = config;
                  this.startSpace(panels, JSON.parse(space))
                } catch (e) {
                  console.error(e);
                }
              }} />
              <SidebarMenuItem icon={`fas fa-cube`} title="Modules" >
                <SidebarMenuItem title={TitlesService('Midi Instruments')}
                  icon={`nav-icon fas fa-cube ${this.enabled('ComposerHeader')}`} onClick={() => {
                    this.toggle('ComposerHeader');
                  }} />
                {/* <SidebarMenuItem title="Scenes" icon={`nav-icon fas fa-cube ${this.enabled('Scenes')}`} onClick={() => {
                  this.toggle('Scenes');
                }} />
                <SidebarMenuItem title="Scene" icon={`nav-icon fas fa-cube ${this.enabled('Scene')}`} onClick={() => {
                  this.toggle('Scene');
                }} /> */}
                <SidebarMenuItem title={TitlesService('Music Recorder')} icon={`nav-icon fas fa-cube ${this.enabled('MUSIC_RECORDER')}`}
                  onClick={() => {
                    this.toggle('MUSIC_RECORDER');
                  }} />
                <SidebarMenuItem title={TitlesService('Midi Track Editor')} icon={`nav-icon fas fa-cube ${this.enabled('MidiTrackerEditor')}`}
                  onClick={() => {
                    this.toggle('MidiTrackerEditor');
                  }} />
                <SidebarMenuItem title="NLE" icon={`nav-icon fas fa-cube ${this.enabled('NLE')}`} onClick={() => {
                  this.toggle('NLE');
                }} />
                <SidebarMenuItem title="Welcome" icon={`nav-icon fas fa-cube ${this.enabled('Welcome')}`} onClick={() => {
                  this.toggle('Welcome');
                }} />
                <SidebarMenuItem title="Chord Input" icon={`nav-icon fas fa-cube ${this.enabled('Chord Input')}`} onClick={() => {
                  this.toggle('Chord Input');
                }} />
                <SidebarMenuItem title="Key Input" icon={`nav-icon fas fa-cube ${this.enabled('Key Input')}`} onClick={() => {
                  this.toggle('Key Input');
                }} />
                <SidebarMenuItem title="Scales" icon={`nav-icon fas fa-cube ${this.enabled('ScaleOptionList')}`} onClick={() => {
                  this.toggle('ScaleOptionList');
                }} />
                <SidebarMenuItem title="Chords" icon={`nav-icon fas fa-cube ${this.enabled('ChordOptionLists')}`} onClick={() => {
                  this.toggle('ChordOptionLists');
                }} />
                <SidebarMenuItem title="ChordSelectList" icon={`nav-icon fas fa-cube ${this.enabled('ChordSelectList')}`} onClick={() => {
                  this.toggle('ChordSelectList');
                }} />
                <SidebarMenuItem title="On Deck Pallette" icon={`nav-icon fas fa-cube ${this.enabled('OnDeckPallettes')}`} onClick={() => {
                  this.toggle('OnDeckPallettes');
                }} />
                <SidebarMenuItem title="Chord Pallette" icon={`nav-icon fas fa-cube ${this.enabled('ChordPallette')}`} onClick={() => {
                  this.toggle('ChordPallette');
                }} />
                <SidebarMenuItem title="Quick Input" icon={`nav-icon fas fa-cube ${this.enabled('QuickInputController')}`} onClick={() => {
                  this.toggle('QuickInputController');
                }} />
                <SidebarMenuItem title={TitlesService('Midi')} icon={`nav-icon fas fa-cube ${this.enabled('MIDI')}`} onClick={() => {
                  this.toggle('MIDI');
                }} />
                <SidebarMenuItem title={TitlesService('MidiFolderSystem')} icon={`nav-icon fas fa-cube ${this.enabled('MidiFolderSystem')}`} onClick={() => {
                  this.toggle('MidiFolderSystem');
                }} />
                <SidebarMenuItem title={TitlesService('Modifier')} icon={`nav-icon fas fa-cube ${this.enabled('MODIFIER')}`} onClick={() => {
                  this.toggle('MODIFIER');
                }} />
                <SidebarMenuItem title={TitlesService('Liccs')} icon={`nav-icon fas fa-cube ${this.enabled('LICKS')}`} onClick={() => {
                  this.toggle('LICKS');
                }} />
                <SidebarMenuItem title={TitlesService('Music Generator')} icon={`nav-icon fas fa-cube ${this.enabled('MUSIC_GENERATOR')}`} onClick={() => {
                  this.toggle('MUSIC_GENERATOR');
                }} />
                <SidebarMenuItem title={TitlesService('Drum Generator')} icon={`nav-icon fas fa-cube ${this.enabled('DRUM_MACHINE')}`} onClick={() => {
                  this.toggle('DRUM_MACHINE');
                }} />
                <SidebarMenuItem title={TitlesService('Space Configurations')} icon={`nav-icon fas fa-cube ${this.enabled('SPACE_CONFIGURATIONS')}`} onClick={() => {
                  this.toggle('SPACE_CONFIGURATIONS');
                }} />
              </SidebarMenuItem>
            </SidebarMenu>
          </Sidebar>
        </SidebarContainer>
        <ContentWrapper>
          <ContentHeader></ContentHeader>
          <ContentMain>

            {this.state.spacePanelHidden ? null : <SpacePane spaces={[{
              name: 'ComposerHeader',
              element: () => {
                if (this.isEnabled('ComposerHeader'))
                  return <ComposerHeader />
              }
            }, {
              name: 'SpaceConfigurations',
              element: () => {
                if (this.isEnabled('SPACE_CONFIGURATIONS')) {
                  return <SpaceConfigurations onSetCurrentSpaceConfiguration={(config) => {
                    try {
                      let { panels, space } = config;
                      this.startSpace(panels, JSON.parse(space))
                    } catch (e) {
                      console.error(e);
                    }
                  }} />
                }
              }
            }, {
              name: 'MidiTrackerEditor',
              element: () => {
                if (this.isEnabled('MidiTrackerEditor')) {
                  return <MidiTrackerEditor />
                }
              }
            }, {
              name: 'MidiTrackerEditorControls',
              element: () => {
                if (this.isEnabled('MidiTrackerEditor')) {
                  return <MidiTrackerEditorControls />
                }
              }
            }, {
              name: 'MusicRecorder',
              element: () => {
                if (this.isEnabled('MUSIC_RECORDER')) {
                  return <MusicRecorder />
                }
              }
            }, {
              name: 'SpacePanelConfiguration',
              element: () => {
                if (this.isEnabled('SPACE_CONFIGURATIONS')) {
                  return <SpacePanelConfiguration />
                }
              }
            }, {
              name: 'Welcome',
              element: () => {
                if (this.isEnabled('Welcome')) {
                  return <Welcome onStartTheBuilder={() => {
                    this.start(spacePanelConfigService.AppSpaceConfigurations.PROGRESSION)
                  }} />
                }
              }
            }, {
              name: 'Scenes',
              element: () => {
                if (this.isEnabled('Scenes'))
                  return <Scenes />
              }
            }, {
              name: 'Drum Generator',
              element: () => {
                if (this.isEnabled(spacePanelConfigService.APP_PANELS.DRUM_MACHINE)) {
                  return <DrumGenerator />
                }
              }
            }, {
              name: 'MidiFolderSystem',
              element: () => {
                if (this.isEnabled('MidiFolderSystem')) {
                  return <MidiFolderSystem />
                }
              }
            }, {
              name: 'Music Generator',
              element: () => {
                if (this.isEnabled('MUSIC_GENERATOR')) {
                  return <MusicGenerator />
                }
              }
            }, {
              name: 'Scene',
              element: () => {
                if (this.isEnabled('Scene'))
                  return <Scene />
              }
            }, {
              name: 'ChordProgressionNonLinearEditorView',
              element: () => {
                if (this.isEnabled('NLE'))
                  return <ChordProgressionNonLinearEditorView />
              },
            }, {
              name: 'ChordProgressionsView',
              element: () => {
                if (this.isEnabled('NLE'))
                  return <ChordProgressionsView />
              }
            }, {
              name: 'ModifierView',
              element: () => {
                if (this.isEnabled('MODIFIER')) {
                  return <ModifierView />
                }
              }
            }, {
              name: 'ModifierOptionsList',
              element: () => {
                if (this.isEnabled('MODIFIER') || this.isEnabled('NLE')) {
                  return <ModifierOptionsList />
                }
              }
            }, {
              name: 'ChordInput',
              element: () => {
                if (this.isEnabled('Chord Input'))
                  return <ChordInput />
              }
            }, {
              name: 'KeyInput',
              element: () => {
                if (this.isEnabled('Key Input'))
                  return <KeyInput />
              }
            }, {
              name: 'ScaleOptionList',
              element: () => {
                if (this.isEnabled('ScaleOptionList'))
                  return <ScaleOptionList />
              }
            }, {
              name: 'ChordOptionLists',
              element: () => {
                if (this.isEnabled('ChordOptionLists')) return <ChordOptionLists />
              }
            }, {
              name: 'OnDeckPallettes',
              element: () => {
                if (this.isEnabled('OnDeckPallettes')) return <OnDeckPallettes />
              }
            }, {
              name: 'ChordSelectList',
              element: () => {
                if (this.isEnabled('ChordSelectList')) return <ChordSelectList />
              }
            }, {
              name: 'ChordPallette',
              element: () => {
                if (this.isEnabled('ChordPallette')) return <ChordPallette />
              }
            }, {
              name: 'QuickInputController',
              element: () => {
                if (this.isEnabled('QuickInputController'))
                  return <QuickInputController />
              }
            }, {
              name: 'MidiFiles',
              element: () => {
                if (this.isEnabled('MIDI'))
                  return <MidiFiles />
              }
            }, {
              name: 'SearchMidiFiles',
              element: () => {
                if (this.isEnabled('MIDI')) {
                  return <SearchMidiFiles />
                }
              }
            }, {
              name: 'MidiViewer',
              element: () => {
                if (this.isEnabled('MIDI'))
                  return <MidiViewer />
              }
            }, {
              name: 'MidiLicks',
              element: () => {
                if (this.isEnabled('LICKS'))
                  return <MidiLicks />
              }
            }, {
              name: 'MidiLickDetails',
              element: () => {
                if (this.isEnabled('LICKS'))
                  return <MidiLickDetails />
              }
            }

            ]} />}
          </ContentMain>
          <ContentFooter />
          <ControlSideBar></ControlSideBar>
        </ContentWrapper>
      </Wrapper>
    );
  }

  updateSpace(space) {
    localStorage.setItem('space-pane', JSON.stringify(space));
    this.setState({
      spacePanelHidden: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          spacePanelHidden: false
        });
      }, 100);
    });
  }

  updatePanels(panels) {
    let update_panels = {};

    keyservice.setPanels(panels)
    panels.forEach(p => {
      update_panels[p] = true;
    });

    this.setState({
      panels: update_panels
    });
  }
}
function overLap(rect1, rect2) {
  var overlap = !(rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom);
  return overlap;
}
function Wrapper(props) {
  const dispatch = useDispatch();
  const [x1, setX1] = useState(null);
  const [y1, setY1] = useState(null);
  const [x2, setX2] = useState(null);
  const [y2, setY2] = useState(null);
  const [mouseDown, setMouseDown] = useState(null);
  const toasts = useSelector(getToasts);
  const [active, setActive] = useState(false);
  const [invalidSpace, setInvalidSpace] = useState(false);
  const [bb, setBB] = useState(null)
  return (
    <div className={`wrapper ${active ? 'wrapper-drag-on' : ''}`}
      onMouseDown={(evt) => {
        let rect = evt.currentTarget.getBoundingClientRect()
        setX1(evt.clientX - rect.x);
        setY1(evt.clientY - rect.y);
        setMouseDown(true)
      }}
      onMouseUp={(evt) => {
        let selectDom = document.body.querySelector('.app-display-select-cc');
        if (active) {
          if (selectDom) {
            doActiveSelect(selectDom.getBoundingClientRect());
          }
          (setActive(false));
        }
        setMouseDown(false)
      }}
      onMouseMove={(evt) => {
        let rect = evt.currentTarget.getBoundingClientRect()
        setBB(rect);
        setX2(evt.clientX - rect.x);
        setY2(evt.clientY - rect.y);
        if ((Math.abs(x1 - x2) > 5 || Math.abs(y1 - y2) > 5) && keyservice.isDown()) {
          let area = document.body.querySelector('.multi-selectable-area');
          if (area && area.classList.contains('invalid-on-shift-down') && keyservice.isShiftOn()) {
            area = null;
          }
          else if (area && area.classList.contains('is-busy')) {
            area = null;
          }
          let selectDom = document.body.querySelector('.app-display-select-cc');
          if ((area && selectDom)) {
            if (!overLap(area.getBoundingClientRect(), selectDom.getBoundingClientRect())) {
              setInvalidSpace(true)
            }
            else {
              setInvalidSpace(false);
            }
          }
          else {
            setInvalidSpace(true)
          }
          setActive(true);
        }
      }}
      onDragEnd={() => {
        onDragState({ dragging: false })
      }}
      onMouseOut={(evt) => {
        if (evt.target === evt.currentTarget) {
          (setActive(false));
        }
      }}>
      {active && !(getDragState()) && keyservice.isDown() ? <DisplaySelect invalidSpace={invalidSpace} style={{ visibility: invalidSpace ? 'hidden' : '' }} box={{
        left: invalidSpace ? 0 : Math.min(x1, x2),
        top: invalidSpace ? 0 : Math.min(y1, y2),
        right: invalidSpace ? 0 : Math.max(x1, x2),
        bottom: invalidSpace ? 0 : Math.max(y1, y2)
      }} /> : null}
      <ToastContainer toasts={toasts} />
      {props.children}
    </div>
  )
}
function DisplaySelect(props) {
  return (
    <div className="app-display-select-cc" style={{
      ...(props.style || {}),
      position: 'absolute',
      left: props.box.left,
      top: props.box.top,
      width: props.box.right - props.box.left,
      height: props.box.bottom - props.box.top,
      backgroundColor: '#ff000021',
      border: 'solid #ff0000aa 1px',
      zIndex: props.invalidSpace ? -1 : 10000000,
      pointerEvents: 'none'
    }}>
    </div>
  )
}
function NewButton() {
  let dispatch = useDispatch();
  return (
    <SidebarMenuItem icon={`fas fa-plus`} onClick={() => {
      dispatch(newComposerCompanionStorage())
    }} title="New" />
  )
}
function SaveButton() {
  let dispatch = useDispatch();
  return (

    <SidebarMenuItem icon={`fas fa-save`} onClick={() => {
      dispatch(saveComposerCompanionStorage())
    }} title="Save" />
  )
}

function ExportSerato() {
  let dispatch = useDispatch();
  return (
    <SidebarMenuItem onClick={() => {
      dispatch(exportToSerato());
    }} title={"Export"} />
  )
}

class FileLoader extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <SidebarMenuItem icon={`fas fa-folder-open`} title="Open"  >
        <LoadComposerCompanionFiles />
      </SidebarMenuItem>
    )
  }
}
function SignInMenu() {
  const isSignedIn = useSelector(getUserSignedIn);

  if (isSignedIn) {
    return <span />
  }
  return (
    <SidebarMenu>
      <SidebarMenuItem title="Sign In" >
        <SidebarMenuItem title="Google" icon={`nav-icon fab fa-google`} onClick={() => {
          signinWith(PROVIDERS.GOOGLE);
        }} />
        <SidebarMenuItem title="Facebook" icon={`nav-icon fab fa-facebook`} onClick={() => {
          signinWith(PROVIDERS.FACEBOOK);
        }} />
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
function SpaceConfigurationsMenu(props) {
  const space_configurations = useSelector(getSpaceConfigurations);
  const isSignedIn = useSelector(getUserSignedIn);

  if (!isSignedIn) {
    return <span></span>
  }

  return (
    <SidebarMenuItem childrenStyle={{ paddingLeft: 10 }} icon="fas fa-columns" title={TitlesService('Panels')}>
      {(space_configurations || []).map(v => {
        return (
          <SidebarMenuItem icon="fas fa-columns" key={`space-config-menu-${v.id}`} onClick={() => {
            if (props.onSetCurrentSpaceConfiguration) {
              let { panels, space } = v;
              props.onSetCurrentSpaceConfiguration({ panels, space })
            }
          }} title={v.name}>
          </SidebarMenuItem>
        )
      })}
    </SidebarMenuItem>
  )
}
function Settings(props) {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(getUserSignedIn);
  const uid = useSelector(getUserId);

  if (!isSignedIn) {
    return <span></span>
  }

  return (
    <SidebarMenuItem icon={`fas fa-cogs`} title={TitlesService('Settings')}>
      <SidebarMenuItem icon="fas fa-columns" title={TitlesService('Panel Configurations')}>
        <SaveNewSpaceConfig onCreate={(args) => {
          args = { owner: uid, uid, ...args };
          let spaces = Object.keys(props.panels).filter(x => props.panels[x]);
          let config = GetSpacePane();
          dispatch(CreateSpaceConfigurationAsync({
            ...args,
            panels: spaces,
            space: config
          })).then(() => {
            return dispatch(LoadSpaceConfigurations({
              ...args
            }))
          }).catch(() => { })
        }} />
      </SidebarMenuItem>
    </SidebarMenuItem>
  )
}
function SaveNewSpaceConfig(props) {
  const [mode, setMode] = useState(null);
  const [new_name, setNewName] = useState('');
  const SET_NAME = 'SET_NAME';
  if (mode == SET_NAME) {
    return (
      <FormGroup>
        <label>{TitlesService('Name')}</label>
        <input type="text" value={new_name}
          onChange={(e) => {
            setNewName(e.currentTarget.value);
          }} className="form-control" placeholder={TitlesService('name...')} />
        <FormGroup>
          <div className="btn-group">
            <button className="btn btn-success" onClick={() => {
              if (new_name.trim()) {
                if (props.onCreate) {
                  props.onCreate({
                    name: new_name.trim()
                  })
                }
                setMode(null);
              }
            }}><i className="fa fa-plus" /></button>
            <button className="btn btn-default" onClick={() => {
              setMode(null);
            }}><i className="fa fa-times" /></button>
          </div>
        </FormGroup>
      </FormGroup>
    );
  }
  return (
    <SidebarMenuItem title={TitlesService('Save new config')} onClick={() => {
      setMode(SET_NAME)
    }} />
  )
}
function UserInfoMenu() {
  let dispatch = useDispatch();
  const isSignedIn = useSelector(getUserSignedIn);
  const imageUrl = useSelector(getUserProfileImage);
  const displayName = useSelector(getUserDisplayName);
  setAuthStateChangedHandler((data) => {
    dispatch(setAuthenticationInfo(data))
    if (data && data.signedIn) {
      dispatch(LoadUserPatternsAsync(data.user.uid)).then(() => {
        return dispatch(LoadUserChordProgressionsAsync({ uid: data.user.uid }));
      }).catch((e) => console.error(e)).then(() => {
        return dispatch(LoadCloudModifiersAsync({ uid: data.user.uid }));
      }).catch((e) => console.error(e)).then(() => {
        return dispatch(LoadCloudLiccsAsync({ uid: data.user.uid }));
      }).catch((e) => console.error(e)).then(() => {
        return dispatch(GetMidiFolderStructure({}));
      }).catch((e) => console.error(e)).then(() => {
        return dispatch(LoadSpaceConfigurations({ uid: data.user.uid }));
      }).catch((e) => console.error(e));
    }
  })

  if (!isSignedIn) {
    return <span />
  }
  return (

    <SidebarMenu>
      <div className="user-panel mt-3 pb-3 mb-3 d-flex" style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <div className="image">
          <img src={imageUrl} className="img-circle elevation-2" alt={displayName} />
        </div>
        <div className="info">
          <a href="#" className="d-block">{displayName}</a>
        </div>
        <a href="#" style={{
          width: 25
        }} onClick={() => {
          signOut()
        }}>
          <i className={` fas fa-sign-out-alt`}></i>
        </a>
      </div>
    </SidebarMenu>
  )
}
function LoadComposerCompanionFiles() {
  let dispatch = useDispatch();


  return (

    <div id="drop_zone" style={{
      border: '5px solid blue',
      width: 200,
      whiteSpace: 'break-spaces',
      padding: 10,
      height: 100
    }}
      onDrop={(ev) => {
        console.log('File(s) dropped');

        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();

        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          let lastFile = null;
          let droppedFiles = [];
          for (var i = 0; i < ev.dataTransfer.items.length; i++) {
            // If dropped items aren't files, reject them
            if (ev.dataTransfer.items[i].kind === 'file') {
              var file = ev.dataTransfer.items[i].getAsFile();
              lastFile = file;
              droppedFiles.push(file);
            }
          }
          if (lastFile) {
            if (lastFile.name.endsWith('.cc')) {
              lastFile.text().then((txt) => {
                dispatch(loadComposerCompanionStorage({ data: txt }))
              }).catch(e => console.error(e))
            }
          }
          if (droppedFiles) {
            for (let i = 0; i < droppedFiles.length; i++) {
              readFile(droppedFiles[i]).then((data) => {
                midiService.loadMidi(droppedFiles[i].name, data);
                dispatch(loadMidiFile({ name: droppedFiles[i].name }))
              })
            }
          }
        } else {
          // Use DataTransfer interface to access the file(s)
          // for (var i = 0; i < 
        }

      }} onDragOver={(ev) => {

        console.log('File(s) in drop zone');

        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
      }}>
      <p>Drag one or more files to this Drop Zone ...</p>
    </div>
  )
}