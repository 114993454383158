import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SeratoComposer from './seratoComposer';
import MidiInputDevice from './MidiInputDevice';
import { SeratoEventTypes } from './seratoComposer';

import {
    chordInputClear,
    chordInputSelect,
    chordInputSet,
    play,
    selectBias,
    selectChordInputValue,
    toggleBias
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import NoteInput from '../../components/music/NoteInput';

export function KeyInput() {
    //   const count = useSelector(selectCount);
    const chordInputValue = useSelector((state) => selectChordInputValue(state, 'key-input'));
    const dispatch = useDispatch();
    const bias = useSelector(selectBias);
    return (
        <Panel stretch title={'Key Input'} onBodyDrop={(ev) => {
            const data = ev.dataTransfer.getData("text/plain");
            console.log(data);
            dispatch(chordInputSet({
                id: 'key-input',
                focused: true,
                data: JSON.parse(data)
            }))

        }} onBodyDragOver={(ev) => {
            ev.preventDefault();
            ev.dataTransfer.dropEffect = "move";

        }} bodyBackgroundColor={'white'} footer={() => {
            return (
                <div style={{ display: 'flex', flex: '1 0 auto' }}>
                    <button type="button" key="clear-button" class="btn btn-tool" onClick={() => {
                        dispatch(chordInputClear({ id: 'key-input' }))
                    }} data-card-widget="clear">
                        <i class="fas fa-times"></i>
                    </button><div key="biascheck">
                        <label>Bias
                <input type="checkbox" {...{ checked: bias }} onClick={() => {
                                // this.setState({ bias: !this.state.bias })
                                dispatch(toggleBias())
                            }} /></label>
                    </div>
                </div>)
        }}>
            <NoteInput onPlay={(payload) => {
                dispatch(play(payload))
            }} onStop={(payload) => {
                dispatch(play(payload))
            }} value={chordInputValue} bias={bias} onNoteInputFocus={(focused) => {
                dispatch(chordInputSelect({ id: 'key-input', clear: true, focused }))
            }} />
        </Panel>
    );
}