import React from 'react';
import { useDispatch } from 'react-redux';

import {
    play
} from '../../features/composer/composerSlice';
import Panel from '../../components/panel/Panel';
import {
} from '../../features/composer/composerSlice';
import { TitlesService } from '../../title-service';
import { FormGroup } from '../FormGroup';
import { RadioButtonList } from '../RadioButtonList';
import { Slider } from '../Slider';
import StaffNotes from './StaffNotes';
const _padding = 4;
export default function Welcome(props) {
    const dispatch = useDispatch();

    return (
        <Panel stretch title={'Welcome'}>
            <div className="container">
                <div className="row">
                    <div className="col-md-4" style={{ textAlign: 'center' }}>
                        <h1 style={{
                            fontFamily: "'Inter', sans-serif"
                        }}>The super fast music idea builder!</h1>
                        <h3>Create chord progressions for your next banger. Dig for licks in the midi fields.</h3>
                        <h2>Get inspired.</h2>
                        <br />
                        <br />
                        <button type="button" onClick={() => {
                            if (props.onStartTheBuilder) {
                                props.onStartTheBuilder();
                            }
                        }}
                            className="btn btn-success"
                            title={TitlesService('Add Progression')}>{TitlesService('Start the builder')} </button>
                    </div>
                    <div className="col-md-8">
                        <StaffNotes skipbank hide_plus hide_voicing supersize value={{ 60: true, 64: true, 67: true }}
                            onPlay={(payload) => {
                                dispatch(play({ value: payload, play: true }))
                            }} />
                    </div>
                </div>
            </div>


        </Panel>
    );
}
