import React from 'react';
import { convertToMelodyNote, flat, getLibray, sharp } from '../base/base-lib';
import { FindExactMatch } from '../features/composer/composerSlice';
import { FormGroup } from './FormGroup';
import StaffNotes from './music/StaffNotes';
import { InvertVoice, invertVoices } from '../base/datasourcegenerator';
import { uniqueArtists } from '../base/unique-artists';
import { TitlesService } from '../title-service';
import { BuildFoundMatches } from '../base/chord-progression';
export class QuickInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { searchedFor: '', searchText: '', txt: '', patterns: [], chords: [], measureLength: '.5' };
    }
    render() {
        let { props } = this;
        return (
            <div style={{
                flex: '1 1 0%',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 10,
                position: 'relative'
            }}>
                <div className="row">
                    <div className="col-md-10">
                        <div style={{
                            flex: '1 1 0%',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            position: 'relative'
                        }}>
                            <div className="row" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative'
                            }}>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <FormGroup>
                                                <label>Chords</label>
                                                <input
                                                    value={this.state.txt}
                                                    onChange={(e) => {
                                                        let foundMatches = this.handleChordTextChange(e);

                                                        this.setState({
                                                            txt: e.currentTarget.value,
                                                            chords: foundMatches
                                                        })
                                                    }} type="text" className="form-control" placeholder={this.state.searchedFor || "Enter ..."} />
                                            </FormGroup>
                                            <FormGroup>
                                                <span>Current Chord count : {this.state.chords.length}</span>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <FormGroup>
                                                <label>Song Progressions</label>
                                                <select className="form-control" value={this.state.txt} onChange={(val) => {
                                                    console.log(val);
                                                    if (val && val.currentTarget) {
                                                        let foundMatches = this.handleChordTextChange(val)
                                                        if (foundMatches) {
                                                            this.setState({
                                                                txt: val.currentTarget.value,
                                                                chords: foundMatches
                                                            })
                                                        }
                                                    }
                                                }}>
                                                    <option>Select</option>
                                                    {(this.props.chordProgressions || []).filter(v => v.progression).map((v, index) => {
                                                        return <option key={'option-' + index} value={v.progression.join(' ')}>{v.name}</option>
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <FormGroup>
                                                <label>Measures per Chord</label>
                                                <select className="form-control" value={this.state.measureLength} onChange={(val) => {
                                                    console.log(val);
                                                    this.setState({ measureLength: val.currentTarget.value })
                                                }}>
                                                    {[.5, 1, 2, 3, 4, 8].map(v => {
                                                        return <option key={v} value={v}>{v}</option>
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </div>

                                    </div>

                                    <FormGroup>
                                        <div className="btn-group">
                                            <button type="button" onClick={() => {
                                                if (this.props.buildSceneProgressions) {
                                                    this.props.buildSceneProgressions({
                                                        measureLength: parseFloat(this.state.measureLength),
                                                        chords: this.state.chords,
                                                        dontPlayChords: true
                                                    });
                                                }
                                            }} className="btn btn-success" title={TitlesService('Add Progression')}>{TitlesService('Add')} <i className="fas fa-plus"></i></button>
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row" style={{
                                overflow: 'auto',
                                margin: 0,
                                flex: '1 1 0%'
                            }}>
                                <div
                                    className={"col-md-12"}
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        flex: '1 0 auto',
                                        flexWrap: 'wrap',
                                        height: 'fit-content'
                                    }}>
                                    {this.state.chords.map((info, index) => {
                                        let { chordInfo, value_voicing, voicing_letter } = info;
                                        if (chordInfo) {
                                            return [
                                                <div key={`qi-contain-${index}`} style={{ flexDirection: 'row', display: 'flex' }}>
                                                    <StaffNotes arg={chordInfo} is_music_playing={this.props.is_music_playing} onPlay={(t) => {

                                                        if (props.onPlay) {
                                                            props.onPlay({ value: t, play: true });
                                                        }
                                                    }} onStop={(t) => {
                                                        if (props.onStop) {
                                                            props.onStop({ value: t, play: false });
                                                        }
                                                    }}
                                                        onAddToBank={(t, arg) => {
                                                            if (props.onAddToBank) {
                                                                props.onAddToBank({ noteLetter: voicing_letter, notes: t, chord: arg });
                                                            }
                                                        }}
                                                        bias={this.props.bias ? sharp : flat}
                                                        key={`qi-voicing-${index}`} value={{ ...value_voicing }} />
                                                </div>]
                                        }
                                    }).filter(x => x)}
                                </div>
                            </div>

                            {this.state.chords.length ? null : (
                                <div className="row">
                                    <div className="col-md-12" style={{ textAlign: 'center' }}>
                                        <h1>Enter chords</h1>
                                        <h2>or</h2>
                                        <h1>Select a Song Progression</h1>
                                        <h2>or</h2>
                                        <h1>Find a song by artist</h1>
                                    </div>
                                </div>
                            )}
                        </div >
                    </div>
                    <div className="col-md-2">
                        <div>

                            <FormGroup>
                                <label>Artists</label>
                                <input type="text" value={this.state.searchText}
                                    onChange={(e) => {
                                        this.setState({
                                            searchText: e.currentTarget.value
                                        })
                                    }} className="form-control" placeholder="Search ..." />
                                <div style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    background: '#343a40',
                                    width: 'calc(100% - 10px)',
                                    maxHeight: 200,
                                    overflowY: 'auto',
                                    overflowX: 'hidden'
                                }}>
                                    <ul className="nav nav-pills nav-sidebar flex-column" style={{
                                    }}>
                                        {this.state.searchText ? uniqueArtists.filter(x =>
                                            x.toLocaleLowerCase().indexOf(`${this.state.searchText}`.toLocaleLowerCase()) !== -1
                                        ).map((v, index) => {
                                            return (
                                                <li key={`li-${index}`} className="nav-item" style={{ width: '100%', cursor: 'pointer' }} onClick={() => {
                                                    if (this.props.onSearchTheoryProgressions) {
                                                        this.props.onSearchTheoryProgressions(v || '');
                                                        this.setState({
                                                            searchText: '',
                                                            searchedFor: v
                                                        })
                                                    }
                                                }}>{v}</li>
                                            );
                                        }) : null}
                                    </ul>
                                </div>
                                <br />
                                <div>
                                    <ul className="nav nav-pills nav-sidebar flex-column">
                                        {(this.props.progressionList || []).map((progList, index) => {
                                            return <ProgressionListItem key={`proglist-${index}`} data={progList} onSectionClicked={(chordProgression) => {
                                                let foundMatches = this.handleChordTextChange({ currentTarget: { value: chordProgression } })
                                                if (foundMatches) {
                                                    this.setState({
                                                        txt: chordProgression,
                                                        chords: foundMatches
                                                    })
                                                }
                                            }} />
                                        })}
                                    </ul>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    handleChordTextChange(e) {
        return this.buildFoundMatches(e.currentTarget.value, this.props.bias);
    }

    buildFoundMatches(e, bias) {
        return BuildFoundMatches(e, bias);
    }
}

function ProgressionListItem(props) {
    let { data } = props;
    let { title, author, sections = [] } = data;
    return (<li className="nav-item" style={{ width: '100%' }}  >
        <div className="callout callout-">
            <h3>
                {title}
            </h3>
            <h5>
                {author}
            </h5>
            <div>
                {sections.map((section, index) => {
                    return <ProgressionListItemSection onSectionClicked={(chordProgression) => {
                        if (props.onSectionClicked) {
                            props.onSectionClicked(chordProgression);
                        }
                    }}
                        data={section} key={`sectuib-item-${index}`} />;
                })}
            </div>
        </div>
    </li>)
}
function ProgressionListItemSection(props) {
    let { data } = props;
    let { name, chordProgression = [] } = data;

    return (
        <div style={{ cursor: 'pointer' }} className="callout callout-info" onClick={() => {
            if (props.onSectionClicked) {
                props.onSectionClicked(chordProgression.join(' '));
            }
        }}>
            <div>{name}</div>
            <div style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }}>{chordProgression.slice(0, 8).join(' ')}{chordProgression.length > 8 ? '...' : ''}</div>
        </div>
    );
}
