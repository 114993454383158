import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MidiFile from './MidiFile';

import {
    selectCurrentMidiFile,
    selectMidiFileName,
    resize,
    updateMidiFileBreaks,
    updateMidiFileSelection,
    chopMidiFile,
    setMidiFileBreaks,
    updateMidiFileVerticalZoom,
    updateMidiFileSelectionLocks,
    getMidiFileData,
    updateMidiFileTrackSelection,
    onMidiTrackChange,
    getSelectedMidiTracks,
    getChordProgressionMidi,
    stopPlay,
    isMusicPlaying,
    getComposerState,
    saveMidiFileToMachine,
    getCurrentLickObj,
    getIsPlaying
} from './composerSlice';
import { Slider } from '../../components/Slider';
import * as midiService from '../../base/midi-service';
import * as midiWriterService from '../../base/midi-writer-service';
import Panel from '../../components/panel/Panel';
import MidiTrack, { GetTrackSelections, MidiTrackEditor } from '../../components/music/MidiTrack';
import { readFile } from '../../base/util';
import { FormGroup } from '../../components/FormGroup';
import { TitlesService } from '../../title-service';
import MidiSpace from '../../components/music/MidiSpace';
import * as NoteService from '../../base/note-service';
import { PlayMagentaNotesAsnyc, StopMagentaPlayerAsync } from './magenta-thunks';
import { getRepeatingSections } from '../../base/comparison';
import { getSongFileName } from '../../base/nameService';
import { getActiveTrack, getActiveTracks, getNotes, getRepeat, getTrackEditorFileName, getTrackEditorInstrument, getTrackEditorValue, toggleTrack } from '../user/userSlice';
const MIDI_VIEWER = 'MIDI_VIEWER';
export function MidiViewer() {
    const dispatch = useDispatch();
    const currentMidiFile = useSelector(selectCurrentMidiFile)
    const songLength = currentMidiFile ? midiService.getSongLength(currentMidiFile) : null;
    const songName = currentMidiFile ? midiService.getSongName(currentMidiFile) : null;
    let songDataSelector = useSelector(getMidiFileData);
    const is_music_playing = useSelector(isMusicPlaying)
    const songData = currentMidiFile ? songDataSelector(currentMidiFile) : null;
    const selectedTracks = useSelector(getSelectedMidiTracks(currentMidiFile)) || {};
    const tracks = currentMidiFile ? midiService.getTracks(currentMidiFile) : null;
    let notes = NoteService.getNotes(MIDI_VIEWER) || [];
    let chords = NoteService.getChords(MIDI_VIEWER) || [];

    let currentLicc = useSelector(getCurrentLickObj);
    let instrument = useSelector(getTrackEditorInstrument);
    let track_name = useSelector(getTrackEditorFileName);
    let track_editor_mode = useSelector(getTrackEditorValue('mode'))
    let track_editor_selected = useSelector(getTrackEditorValue('selection'));
    let copy_items = useSelector(getTrackEditorValue('copyItems'))
    let instruments = useSelector(getTrackEditorValue('instruments')) || {};
    let repeat = useSelector(getRepeat);
    const is_playing = useSelector(getIsPlaying);
    let active_tracks = useSelector(getActiveTracks);
    let currentTrack = useSelector(getActiveTrack);
    let selection_start = 0;
    let selection_end = 0;
    let vertical_start = 0;
    let vertical_end = 127;
    let ppq = 960;
    // const [notes, setNotes] = useState([]);
    // let notes = [];
    // if (tracks) {
    //     tracks.forEach((track, trackIndex) => {
    //         track.notes.map(note => {
    //             notes.push({
    //                 ...note,
    //                 track: trackIndex
    //             });
    //         })
    //     })
    // }
    const [step_counter, setStepCounter] = useState(null);
    var [hasBeenStarted, setHasBeenStarted] = useState(false);
    const [track_cut, setTrackCuts] = useState(8);
    const [selected, setSelected] = useState(null);

    let start = 0;
    let end = songLength;
    if (songData) {
        ppq = midiService.getMidiPPQ(currentMidiFile);
        // start = Math.min(...songData.breaks.map(v => v.val))
        // end = Math.max(...songData.breaks.map(v => v.val));
        selection_start = Math.min(...songData.selections.map(v => v.val));
        selection_end = Math.max(...songData.selections.map(v => v.val));
        // vertical_start = Math.min(...songData.verticalZoom.map(v => v.val));
        // vertical_end = Math.max(...songData.verticalZoom.map(v => v.val));
    }

    return (
        <Panel stretch title={songName || currentMidiFile || 'Midi Viewer'}>
            <div className="" style={{
                position: 'sticky',
                top: 0,
                backgroundColor: `#343a40`,
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                zIndex: 100
            }}>
                <div className="row" style={{ margin: 0, paddingLeft: 5 }}>
                    <div>
                        <label>{TitlesService('Controls')}</label>
                        <FormGroup>
                            <div className="btn-group">
                                {is_music_playing ? null : <button type="button" className={"btn btn-success"} onClick={() => {
                                    let midi_data = midiService.getMidiAsData(currentMidiFile)
                                    let selected_tracks = Object.keys(selectedTracks).filter(x => selectedTracks[x]).map(v => parseInt(v));;
                                    dispatch(PlayMagentaNotesAsnyc({ midi: midi_data, selected_tracks: active_tracks }))
                                }}>
                                    <i className="fa fa-play" />
                                </button>}
                                {is_music_playing ? <button type="button" className={"btn btn-info"} onClick={() => {
                                    dispatch(stopPlay())
                                    dispatch(StopMagentaPlayerAsync());
                                }}>
                                    <i className="fa fa-stop" />
                                </button> : null}
                                <button type="button" className={"btn btn-info"} onClick={() => {
                                    let midi_data = midiService.getMidiAsData(currentMidiFile)

                                    saveMidiFileToMachine(midi_data, songName || currentMidiFile || getSongFileName());

                                }}>
                                    <i className="fa fa-download" />
                                </button>
                            </div>
                        </FormGroup>
                    </div>
                    <div>
                        <div>
                            <label>{TitlesService('Lock')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    <button type="button" className={(songData ? songData.locks.selections || false : false) ? "btn btn-info" : "btn btn-info"} onClick={() => {
                                        dispatch(updateMidiFileSelectionLocks({
                                            midi: currentMidiFile,
                                            key: 'selections',
                                            value: !(songData ? songData.locks.selections : false)
                                        }));
                                    }}>
                                        <i className={(songData ? songData.locks.selections || false : false) ? "far fa-check-square" : "far fa-square"} />
                                    </button>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label title={TitlesService('ShrinkWrapDescription')}>{TitlesService('Shrink Wrap')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    <button type="button" className={(songData ? songData.locks.shrinkWrap || false : false) ? "btn btn-info" : "btn btn-info"} onClick={() => {
                                        dispatch(updateMidiFileSelectionLocks({
                                            midi: currentMidiFile,
                                            key: 'shrinkWrap',
                                            value: !(songData ? songData.locks.shrinkWrap : false)
                                        }));
                                    }}>
                                        <i className={(songData ? songData.locks.shrinkWrap || false : false) ? "far fa-check-square" : "far fa-square"} />
                                    </button>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label title={TitlesService('Chop Description')}>{TitlesService('Chop')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    <button type="button" className={(songData ? songData.locks.shrinkWrap || false : false) ? "btn btn-info" : "btn btn-info"} onClick={() => {
                                        if (selected) {
                                            dispatch(chopMidiFile({
                                                notes: Object.values(selected).map(v => {
                                                    v.time = midiWriterService.convertToMilliseconds(100, ppq, v.ticks);
                                                    v.duration = midiWriterService.convertToMilliseconds(100, ppq, v.durationTicks);
                                                    return v;
                                                }),
                                                midi: currentMidiFile
                                            }));
                                        }
                                    }}><i class="fas fa-crop-alt"></i>
                                    </button>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <label>{TitlesService('Tracks')}</label>
                                <FormGroup>
                                    <div className="btn-group">
                                        {(tracks || []).map((info, track) => {
                                            return (
                                                <button type="button" key={`asd-split-size-${track}`}
                                                    className={active_tracks.indexOf(track) === -1 ? "btn btn-disabled" : (currentTrack === track ? "btn btn-danger" : "btn btn-success")}
                                                    onClick={() => {
                                                        dispatch(toggleTrack(track));
                                                    }}>
                                                    {track + 1}
                                                </button>
                                            )
                                        })}
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: `calc(100% - 100px)` }}>
                <MidiTrackEditor
                    start={start}
                    id={MIDI_VIEWER}
                    end={end}
                    height={400}
                    ppq={ppq}
                    tracks={active_tracks}
                    currentTrack={currentTrack}
                    notes={JSON.parse(JSON.stringify(notes))}
                    chords={chords}
                    mode={track_editor_mode}
                    copy_notes={(track_editor_mode === 'copy' ? Object.values(JSON.parse(JSON.stringify(copy_items))) : [])}
                    deleteSelectedItems={(notes_to_delete) => {
                    }}
                    onAddNote={(args) => {
                    }}
                    updateNotes={(args) => {
                        let { cut, selectedNotes, dragItem } = args;
                    }}
                    onSelection={(selection) => {
                        setSelected(selection)
                    }}
                    verticalStart={vertical_start}
                    verticalEnd={vertical_end}
                    selection_start={0}
                    selection_end={0} />
            </div>
        </Panel >
    );
}


