export function generateSeratoInstrument() {
    return (
        {
            "type": "instrument",
            "name": "Acoustic Piano",
            "effects": getDefaultEffects(),
            "release": 1.0,
            "instrument_file": "content:///Serato%20Packs/Core/Instruments/Acoustic%20Piano/Acoustic%20Piano.serato-instrument"
        }
    )
}
export function generateSceneTrackNote(start, duration, number, velocity) {
    return {
        "start": Math.round(start * 10) / 10,
        "duration": Math.round(duration * 10) / 10,
        "number": parseInt(number, 10),
        "velocity": velocity
    }
}

export function generateSceneTrack(notes) {
    if (!notes || !notes.length) {
        return {};
    }
    return {
        notes
    }
}

export function generateScene(name, length, tracks = []) {
    return (
        {
            "name": name,
            "length": Math.round(length * 10) / 10,
            "deck_sequences": [
                /** for drums */
                generateSceneTrack([]),
                ...tracks
            ]
        }
    )
}

export function getSeratoTemplate(setClips, setScenes, setInstruments, tempo) {
    return (
        {
            "version": 61,
            "bpm": tempo || 100.0,
            "decks":
                [
                    {
                        "type": "drums",
                        "name": "Clean 808 Drum Kit",
                        "content_name": "Clean 808 Drum Kit",
                        "effects": getDrumEffects(),
                        "drums": getDrums(),
                        "make_sequence_genre": "dancehall_or_latin"
                    },
                    ...setInstruments()
                ],
            "scenes": [...setScenes()],
            "arrangement": {
                "tracks":
                    [
                        ...setClips()
                    ]
            },
            "master":
            {
                "effects":
                    [
                        {
                            "effect": ":/effects/Brightener EQ.serato-effect",
                            "value": 0.5,
                            "on": false
                        },
                        {
                            "effect": ":/effects/Master Compressor.serato-effect",
                            "value": 0.0,
                            "on": false
                        },
                        {
                            "effect": ":/effects/Limiter.serato-effect",
                            "value": 0.0,
                            "on": false
                        }
                    ]
            }
        }
    );
}

function getDefaultEffects() {
    return (
        [
            {
                "effect": ":/effects/Studda Fader.serato-effect",
                "value": 0.0018518518518518823,
                "on": false
            },
            {
                "effect": ":/effects/Light Sizzle Crusher.serato-effect",
                "value": 0.5,
                "on": false
            },
            {
                "effect": ":/effects/Dry Reverb.serato-effect",
                "value": 0.0,
                "on": false,
                "mode": 2
            }
        ]
    );
}
function getDrumEffects() {
    return [
        {
            "effect": ":/effects/Beam Me Up Echo.serato-effect",
            "value": 0.0,
            "on": false,
            "mode": 2
        },
        {
            "effect": ":/effects/Light Sizzle Crusher.serato-effect",
            "value": 0.0,
            "on": false
        },
        {
            "effect": ":/effects/Drums Reverb.serato-effect",
            "value": 0.0,
            "on": false,
            "mode": 2
        }
    ]
}
function getDrums() {
    return (
        [
            {
                "sample":
                {
                    "file": "content:///Serato%20Packs/Core/Drums/Clean%20808%20Drum%20Kit/01%20Punch%20Kick%20%28Clean%20808%20Drum%20Kit%29.wav",
                    "end": 0.31333333333333335
                }
            },
            {
                "sample":
                {
                    "file": "content:///Serato%20Packs/Core/Drums/Clean%20808%20Drum%20Kit/02%20Snare%20%28Clean%20808%20Drum%20Kit%29.wav",
                    "end": 0.32034013605442174
                },
                "effects":
                    [
                        null,
                        null,
                        {
                            "effect": ":/effects/Drums Reverb.serato-effect",
                            "value": 0.0,
                            "on": false,
                            "mode": 2
                        }
                    ]
            },
            {
                "sample":
                {
                    "file": "content:///Serato%20Packs/Core/Drums/Clean%20808%20Drum%20Kit/03%20Closed%20Hi-hat%20%28Clean%20808%20Drum%20Kit%29.wav",
                    "end": 0.17560090702947845
                },
                "effects_beats": 4,
                "effects":
                    [
                        null,
                        {
                            "effect": ":/effects/Modulated LPF.serato-effect",
                            "value": 0.25934606481481448,
                            "on": false
                        },
                        {
                            "effect": ":/effects/Delay.serato-effect",
                            "value": 1.0,
                            "on": false
                        }
                    ]
            },
            {
                "sample":
                {
                    "file": "content:///Serato%20Packs/Core/Drums/Clean%20808%20Drum%20Kit/04%20Open%20Hi-hat%20%28Clean%20808%20Drum%20Kit%29.wav",
                    "end": 0.80678004535147396
                }
            },
            {
                "sample":
                {
                    "file": "content:///Serato%20Packs/Core/Drums/Clean%20808%20Drum%20Kit/05%20Dist%20Kick%20%28Clean%20808%20Drum%20Kit%29.wav",
                    "end": 0.86562358276643991
                }
            },
            {
                "sample":
                {
                    "file": "content:///Serato%20Packs/Core/Drums/Clean%20808%20Drum%20Kit/06%20Clap%20%28Clean%20808%20Drum%20Kit%29.wav",
                    "end": 2.2224489795918365
                }
            },
            {
                "sample":
                {
                    "file": "content:///Serato%20Packs/Core/Drums/Clean%20808%20Drum%20Kit/07%20Clave%20%28Clean%20808%20Drum%20Kit%29.wav",
                    "end": 0.037619047619047621
                }
            },
            {
                "sample":
                {
                    "file": "content:///Serato%20Packs/Core/Drums/Clean%20808%20Drum%20Kit/08%20Cowbell%20%28Clean%20808%20Drum%20Kit%29.wav",
                    "end": 1.0016780045351474
                }
            }
        ]
    )
}