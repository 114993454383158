export const AppSpaceConfigurations = {
    MIDI_CHOP: 'MIDI_CHOP',
    PROGRESSION: 'PROGRESSION',
    MODIFIER_CONFIG: 'MODIFIER_CONFIG',
    WELCOME: 'WELCOME',
    WORK_FLOW_CHORD_PROGRESSION: 'WORK_FLOW_CHORD_PROGRESSION'
}
export const APP_PANELS = {
    DRUM_MACHINE: 'DRUM_MACHINE',
    QUICK_INPUT: 'QuickInputController',
    MIDI_TRACK_EDITOR: 'MidiTrackerEditor'
}
export function GetPanelConfiguration(configId) {

    switch (configId) {
        case AppSpaceConfigurations.MIDI_CHOP:
            return MIDI_CHOP_CONFIG;
        case AppSpaceConfigurations.PROGRESSION:
            return PROGRESSION_CONFIG;
        case AppSpaceConfigurations.MODIFIER_CONFIG:
            return MODIFIER_CONFIG;
        case AppSpaceConfigurations.WELCOME:
            return WELCOME;
        case AppSpaceConfigurations.WORK_FLOW_CHORD_PROGRESSION:
            return WORK_FLOW_CHORD_PROGRESSION;
        default:
            break;
    }
}
const WELCOME = {
    panels: ['Welcome'],
    space: {
        "lg": [{
            "w": 6, "h": 4, "x": 1, "y": 0,
            "i": "space-pane-Welcome",
            "moved": false,
            "static": false
        }]
    }
}

const WORK_FLOW_CHORD_PROGRESSION = {
    panels: ['QuickInputController'],
    space: { "lg": [{ "w": 12, "h": 9, "x": 0, "y": 0, "i": "space-pane-QuickInputController", "moved": false, "static": false }] }
}

const MIDI_CHOP_CONFIG = {
    panels: ['LICKS', 'MIDI', 'MidiFolderSystem', 'MUSIC_RECORDER', 'ComposerHeader'],
    space: {
        "lg": [{
            "w": 2, "h": 2, "x": 0, "y": 2, "i": "space-pane-ComposerHeader", "moved": false, "static": false
        }, {
            "w": 2, "h": 2, "x": 0, "y": 4, "i": "space-pane-MusicRecorder", "moved": false, "static": false
        }, {
            "w": 2, "h": 3, "x": 0, "y": 6, "i": "space-pane-MidiFolderSystem", "moved": false, "static": false
        }, {
            "w": 2, "h": 2, "x": 0, "y": 0, "i": "space-pane-MidiFiles", "moved": false, "static": false
        }, {
            "w": 2, "h": 3, "x": 2, "y": 6, "i": "space-pane-SearchMidiFiles", "moved": false, "static": false
        }, {
            "w": 10, "h": 6, "x": 2, "y": 0, "i": "space-pane-MidiViewer", "moved": false, "static": false
        }, {
            "w": 2, "h": 3, "x": 6, "y": 6, "i": "space-pane-MidiLicks", "moved": false, "static": false
        }, {
            "w": 2, "h": 3, "x": 4, "y": 6, "i": "space-pane-MidiLickDetails", "moved": false, "static": false
        }]
    }
};

const PROGRESSION_CONFIG = {
    panels: ['NLE', 'ScaleOptionList', 'OnDeckPallettes', 'ChordOptionLists', 'Chord Input', 'QuickInputController', 'DRUM_MACHINE'],
    space: {
        "lg": [{
            "w": 12, "h": 7, "x": 0, "y": 12, "i": "space-pane-Drum Generator", "moved": false, "static": false
        }, {
            "w": 10, "h": 5, "x": 2, "y": 4, "i": "space-pane-ChordProgressionNonLinearEditorView", "moved": false, "static": false
        }, {
            "w": 2, "h": 3, "x": 0, "y": 4, "i": "space-pane-ChordProgressionsView", "moved": false, "static": false
        }, {
            "w": 2, "h": 2, "x": 0, "y": 7, "i": "space-pane-ModifierOptionsList", "moved": false, "static": false
        }, {
            "w": 1, "h": 3, "x": 1, "y": 9, "i": "space-pane-ChordInput", "moved": false, "static": false
        }, {
            "w": 2, "h": 3, "x": 2, "y": 9, "i": "space-pane-ScaleOptionList", "moved": false, "static": false
        }, {
            "w": 1, "h": 3, "x": 0, "y": 9, "i": "space-pane-ChordOptionLists", "moved": false, "static": false
        }, {
            "w": 8, "h": 3, "x": 4, "y": 9, "i": "space-pane-OnDeckPallettes", "moved": false, "static": false
        }, {
            "w": 12, "h": 4, "x": 0, "y": 0, "i": "space-pane-QuickInputController", "moved": false, "static": false
        }]
    }
};

const MODIFIER_CONFIG = {
    panels: ['MODIFIER', 'LICKS'],
    space: {
        "lg": [{
            "w": 11, "h": 7, "x": 1, "y": 0, "i": "space-pane-ModifierView", "moved": false, "static": false
        }, {
            "w": 1, "h": 7, "x": 0, "y": 0, "i": "space-pane-ModifierOptionsList", "moved": false, "static": false
        }, {
            "w": 2, "h": 2, "x": 0, "y": 7, "i": "space-pane-MidiLicks", "moved": false, "static": false
        }, {
            "w": 6, "h": 4, "x": 2, "y": 7, "i": "space-pane-MidiLickDetails", "moved": false, "static": false
        }]
    }
}