import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormGroup } from '../../components/FormGroup';

import {
    addNewChordInstance,
    updateTempo, getTempo,
    swapKeys,
    swapForRandom,
    resetKey,
    toggleStaticPositionOnChordInChordProgression,
    selectItemsInRow,
    onChordProgressionItemClick,
    selectChordProgressionItems,
    updateChordProgressionPositions,
    removeChordFromChordProgression,
    deleteSelectedItems,
    selectBias,
    selectCurrentChordProgression,
    updateChordProgressionsMeasures,
    updateChordProgressionsName,
    moveTones,
    getModifierName,
    saveMidiFileToMachine,
    getChordProgressionSelectedItem,
    playChordProgression,
    stopPlay,
    getModifierLib,
    addCurrentModifierToChordProgression,
    copyTrackToClipboard,
    pasteToTrack,
    toggleSelectTrack,
    getMidiLickDict,
    getSelectedTrackNumber,
    getClipBoard,
    getComposerState,
    getChordProgressionMidi,
    isMusicPlaying,
    splitItem,
    settingSelectedItemChordPosition,
    addModifiersToSelectedItems,
    resizeItem,
    getChordProgressionSelectedItems,
    selectedCurrentModifier,
    setTrackProperty
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import ChordProgressionNonLinearEditor from '../../components/music/ChordProgressionNonLinearEditor';
import { BuildMidiChordProgression, BuildSeratoMidiChordProgression, MidiWriterDefault } from '../../base/midi-writer-service';
import { play } from '../../timer/time-service';
import { TitlesService } from '../../title-service';
import { LoadUserChordProgressionsAsync, PlayMagentaNotesAsnyc, StopMagentaPlayerAsync, StoreChordProgressionInCloudAsync } from './magenta-thunks';
import { getUserId } from '../user/userSlice';
import { getSelectorZoomSize, selectZoomSize } from '../application/applicationSlice';
import { doActiveSelect } from '../../base/dragstateservice';
import { getMidiFilePPQ } from '../../base/midi-service';

export default function ChordProgressionNonLinearEditorView() {
    const dispatch = useDispatch();
    const chordProgression = useSelector(selectCurrentChordProgression);
    const bias = useSelector(selectBias);
    const owner = useSelector(getUserId);
    const tempo = useSelector(getTempo);
    const selectedItem = useSelector(getChordProgressionSelectedItem);
    const selectedItems = useSelector(getChordProgressionSelectedItems);
    const zoomSize = useSelector(getSelectorZoomSize);
    const selectedTrackNumber = useSelector(getSelectedTrackNumber);
    const clipBoard = useSelector(getClipBoard);
    const modifiers = useSelector(getModifierLib);
    const is_music_playing = useSelector(isMusicPlaying)
    const lickDic = useSelector(getMidiLickDict);
    const composerState = useSelector(getComposerState)
    let selected_modifier = useSelector(selectedCurrentModifier);
    let [local_tempo, setLocalTempo] = useState(tempo);
    return (
        <Panel stretch title={'Non-linear Editor'} onBodyDrop={(ev) => {
            console.log(ev);
            const data = ev.dataTransfer.getData("text/plain");
            let offset = null;
            if (data) {
                if (ev.nativeEvent.target) {
                    let layoutContext = ev.nativeEvent.target.querySelector('.react-grid-layout-context');
                    if (!layoutContext) {
                        layoutContext = ev.nativeEvent.target.parentElement;
                    }
                    if (layoutContext) {
                        let layoutRect = ev.nativeEvent.target.getBoundingClientRect();
                        let { offsetX, offsetY } = ev.nativeEvent;
                        offset = { x: (offsetX - layoutRect.x) / layoutRect.width, y: (offsetY - layoutRect.y) / layoutRect.height }
                    }
                }
                //
                let chordData = JSON.parse(data);
                dispatch(addNewChordInstance({ offset, chordData }));
            }
        }}>
            <div className="multi-selectable-area" style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
                flexFlow: 'column nowrap',
                alignItems: 'stretch',
                height: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    flex: '87px 0 0'
                }}>

                    {chordProgression ? <div className="row"
                        style={{
                            display: 'flex',
                            marginLeft: 10
                        }}>
                        <div>
                            <label>{TitlesService('Export/Save')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    <button title={TitlesService("StoreInMyCloud")} className="btn btn-success" onClick={() => {
                                        dispatch(StoreChordProgressionInCloudAsync({
                                            ...chordProgression,
                                            originalId: chordProgression.originalId || chordProgression.id,
                                            owner
                                        })).then(() => {
                                            return dispatch(LoadUserChordProgressionsAsync({ uid: owner }));
                                        })
                                    }}><i className="fas fa-cloud-upload-alt"></i></button>
                                    <button title={TitlesService("ExportExplaination")} className="btn btn-info" onClick={() => {
                                        let res = BuildMidiChordProgression(chordProgression, {
                                            measureDivisions: 64,
                                            measures: 8,
                                            start: null,
                                            tempo,
                                            stop: null,
                                            lickDic,
                                            modifiers
                                        });
                                        saveMidiFileToMachine(res, `${chordProgression.name}.mid`);
                                    }}><i className="fas fa-download"></i></button>
                                    <button title={TitlesService("SeratoExportExplaination")} className="btn btn-info" onClick={() => {
                                        let files = BuildSeratoMidiChordProgression(chordProgression, {
                                            measureDivisions: 64,
                                            measures: 8,
                                            start: null,
                                            stop: null,
                                            lickDic,
                                            modifiers
                                        });
                                        files.map((file, index) => {
                                            saveMidiFileToMachine(file, `${chordProgression.name}_${index + 1}.mid`);
                                        })
                                    }}><i className="fas fa-cloud-upload-alt"></i></button>
                                </div>
                            </FormGroup>
                        </div>
                        <div>
                            <FormGroup>
                                <label>{TitlesService('Name')}</label>
                                <input className="form-control" value={chordProgression.name} onChange={(val) => {
                                    console.log(val);
                                    dispatch(updateChordProgressionsName(val.currentTarget.value))
                                }}>
                                </input>
                            </FormGroup>
                        </div>
                        <div>
                            <FormGroup>
                                <label>{TitlesService('Measures')}</label>
                                <select className="form-control" value={chordProgression.measures} onChange={(val) => {
                                    dispatch(updateChordProgressionsMeasures(parseInt(val.currentTarget.value)));
                                }}>
                                    {Array.apply(null, Array(50)).map(function (_, x) { return { title: x, value: x }; }).map(v => {
                                        return <option key={v.title} value={v.value}>{v.title}</option>
                                    })}
                                </select>
                            </FormGroup>
                        </div>
                        <div>
                            <FormGroup>
                                <label>{TitlesService('Tempo')} {local_tempo}</label>
                                <input onBlur={(e) => {
                                    dispatch(updateTempo(local_tempo));
                                }} onChange={(e) => {
                                    setLocalTempo(parseInt(e.currentTarget.value));
                                }} type="text" value={local_tempo} className="form-control" placeholder="Enter ..." />
                            </FormGroup>
                        </div>
                        <div>
                            <label>{TitlesService('Chord Operations')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    <button type="button" className={selectedItems && selectedItems.length ? "btn btn-success" : "btn btn-disabled"} onClick={() => {
                                        dispatch(swapKeys())
                                    }}><i className="fas fa-exchange-alt"></i>
                                    </button>
                                    <button type="button" className={selectedItems && selectedItems.length ? "btn btn-success" : "btn btn-disabled"} onClick={() => {
                                        dispatch(swapForRandom())
                                    }}><i className="fas fa-random"></i>
                                    </button>
                                    <button type="button" className={selectedItems && selectedItems.length ? "btn btn-success" : "btn btn-disabled"} onClick={() => {
                                        dispatch(resetKey())
                                    }}><i className="fas fa-toilet-paper"></i>
                                    </button>
                                    <button type="button" className={selectedItems && selectedItems.length ? "btn btn-danger" : "btn btn-disabled"} onClick={() => {
                                        if (selectedItems && selectedItems.length) {
                                            dispatch(deleteSelectedItems(selectedItems))
                                        }
                                    }}><i className="fas fa-times"></i></button>
                                </div>
                            </FormGroup>
                        </div>
                        <div>
                            <label>{TitlesService('Resize')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    {[{ name: `16th`, value: .0625 },
                                    { name: `8th`, value: .125 },
                                    { name: `Q`, value: .25 },
                                    { name: `H`, value: .5 }, 1, 2, 3, 4, 5, 6, 7, 8].map((size, size_index) => {
                                        return (
                                            <button type="button" key={`button-size-${size_index}`} className={!selectedItem ? "btn btn-disabled" : "btn btn-success"} onClick={() => {
                                                if (selectedItem) {
                                                    dispatch(resizeItem({ size: size.value || size }))
                                                }
                                            }}>
                                                {size.name || size}
                                            </button>

                                        )
                                    })}
                                </div>
                            </FormGroup>
                        </div>
                        <div>
                            <label>{TitlesService('Split')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((size, size_index) => {
                                        return (
                                            <button type="button" key={`button-split-size-${size_index}`}
                                                className={!selectedItem ? "btn btn-disabled" : "btn btn-success"}
                                                onClick={() => {
                                                    if (selectedItem) {
                                                        dispatch(splitItem({ size: size.value || size }))
                                                    }
                                                }}>
                                                {size.name || size}
                                            </button>

                                        )
                                    })}
                                </div>
                            </FormGroup>
                        </div>
                        <div>
                            <label>{TitlesService('Chord')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    {[{ name: 'I', value: 0 }, { name: 'ii', value: 1 }, { name: 'II', value: 2 }, { name: 'iii', value: 3 }, { name: 'III', value: 4 },
                                    { name: 'iv', value: 1 }, { name: 'IV', value: 1 }, { name: 'v', value: 1 }, { name: 'V', value: 1 }, { name: 'vi', value: 1 },
                                    { name: 'VI', value: 1 }, { name: 'vii', value: 1 }, { name: 'VII', value: 1 }].map((chordRelPosition, chordRelPosition_index) => {
                                        return (
                                            <button type="button" key={`button-split-size-${chordRelPosition_index}`}
                                                className={!selectedItem ? "btn btn-disabled" : "btn btn-success"}
                                                onClick={() => {
                                                    if (selectedItem) {
                                                        dispatch(settingSelectedItemChordPosition({ position: chordRelPosition.value }))
                                                    }
                                                }}>
                                                {chordRelPosition.name || chordRelPosition}
                                            </button>

                                        )
                                    })}
                                </div>
                            </FormGroup>
                        </div>
                        {selected_modifier ? <div>
                            <label>{TitlesService('Modifier')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    <button type="button" className={"btn btn-success"} onClick={() => {
                                        dispatch(addModifiersToSelectedItems());
                                    }}>
                                        {selected_modifier.name} &nbsp;<i className="fa fa-plus" />
                                    </button>
                                </div>
                            </FormGroup>
                        </div> : null}
                        <div>
                            <label>{TitlesService('Zoom')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    {[{
                                        value: 1, name: TitlesService('SmallKey')
                                    }, {
                                        value: 3, name: TitlesService('MediumKey')
                                    }, {
                                        value: 5, name: TitlesService('LargeKey')
                                    }].map((size, size_index) => {
                                        return (
                                            <button type="button" key={`button-zoomSize-size-${size_index}`}
                                                className={zoomSize === (size.value || size) ? "btn btn-info" : "btn btn-success"}
                                                onClick={() => {
                                                    dispatch(selectZoomSize(size.value || size))
                                                }}>
                                                {size.name || size}
                                            </button>

                                        )
                                    })}
                                </div>
                            </FormGroup>
                        </div>
                        <div>
                            <label>{TitlesService('Controls')}</label>
                            <FormGroup>
                                <div className="btn-group">
                                    {is_music_playing ? null : <button type="button" className={"btn btn-success"} onClick={() => {
                                        let midi = getChordProgressionMidi(composerState);
                                        let chordProgression = composerState.chordProgressions[composerState.currentProgression];
                                        dispatch(PlayMagentaNotesAsnyc({ midi, tracks: chordProgression.tracks }))
                                    }}>
                                        <i className="fa fa-play" />
                                    </button>}
                                    {is_music_playing ? <button type="button" className={"btn btn-info"} onClick={() => {
                                        dispatch(stopPlay())
                                        dispatch(StopMagentaPlayerAsync());
                                    }}>
                                        <i className="fa fa-stop" />
                                    </button> : null}
                                </div>
                            </FormGroup>
                        </div>
                    </div> : null
                    }
                </div>
                <div style={{
                    display: 'flex',
                    flex: '200px 1 0',
                    overflow: 'auto'
                }}>
                    <ChordProgressionNonLinearEditor
                        onItemsSelected={(args) => {
                            dispatch(selectChordProgressionItems(args));
                        }}
                        deleteSelectedItems={(args) => {
                            dispatch(deleteSelectedItems(args))
                        }}
                        setTrackProperty={(args) => {
                            dispatch(setTrackProperty(args))
                        }}
                        bias={bias}
                        tempo={tempo}
                        onDelete={(d) => {
                            dispatch(removeChordFromChordProgression(d))
                        }}
                        modifiers={modifiers}
                        onToggleStatic={(d) => {
                            dispatch(toggleStaticPositionOnChordInChordProgression(d))
                        }}
                        selectItemsInRow={(d) => {
                            dispatch(selectItemsInRow(d))
                        }}
                        onItemClick={(d) => {
                            dispatch(onChordProgressionItemClick(d))
                        }}
                        updateChordProgressionPositions={(args) => {
                            dispatch(updateChordProgressionPositions(args))
                        }}
                        addModifierToRow={(d) => {
                            dispatch(addCurrentModifierToChordProgression(d))
                        }}
                        onTrackSelect={(d) => {
                            dispatch(toggleSelectTrack(d));
                        }}
                        copyToClipboard={(d) => {
                            dispatch(copyTrackToClipboard(d))
                        }}
                        pasteToTrack={(d) => {
                            dispatch(pasteToTrack(d))
                        }}
                        zoomSize={zoomSize}
                        clipBoard={clipBoard}
                        selectedTrackNumber={selectedTrackNumber}
                        selectedItem={selectedItem}
                        selectedItems={selectedItems}
                        chordProgression={chordProgression} /></div>
            </div>
        </Panel >
    );
}