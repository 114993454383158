export default class FontService {
    static loaded = {}
    static init() {
        let fonts = [`PaytoneOne-Regular.ttf`];

        fonts.map(rel_url => {
            let f = new FontFace('test', `url(font/${rel_url})`);

            f.load().then(function () {
                // Ready to use the font in a canvas context
                FontService.loaded[rel_url] = true;
            });
        })
    }
}

FontService.init();