import React from 'react';

export default class SidebarMenu extends React.Component {

    render() {
        return (<nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                {this.props.children}
            </ul>
        </nav>
        )
    }
}