import React from 'react';
import InfoBox from '../../components/InfoBox';
import DrumMachine from '../../components/music/DrumMachine';
import DrumPatterns from '../../components/music/DrumPatterns';
import Panel from '../../components/panel/Panel';

export default class DrumGenerator extends React.Component {
    render() {
        let set_func = null;
        let getPatternFunc = () => {
            return set_func;
        }
        return (
            <Panel stretch title={'Drum Generator'} sidepanel={<DrumPatterns getPatternFunc={getPatternFunc} />}>
                <DrumMachine onSetPattern={(func) => {
                    set_func = func;
                }} />
            </Panel>
        );
    }
}