import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createDispatchHook } from 'react-redux';
import * as fireApp from '../../base/firebase-app';
import { AddPatternAsync, LoadUserChordProgressionsAsync, LoadUserPatternsAsync, StoreChordProgressionInCloudAsync } from '../composer/magenta-thunks';

const initialState = {
    failed: {},
    fulfilled: {},
    pending: {},
    zoomSize: 3
};

export const applicationSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        selectZoomSize: (state, action) => {
            state.zoomSize = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(LoadUserChordProgressionsAsync.pending, (state, action) => {
            state.pending.load_chord_progressions = true;
        });

        builder.addCase(LoadUserChordProgressionsAsync.rejected, (state, action) => {
            state.pending.load_chord_progressions = false;
            state.failed.load_chord_progressions = true;
        });
        builder.addCase(LoadUserChordProgressionsAsync.fulfilled, (state, action) => {
            state.pending.load_chord_progressions = false;
            state.failed.load_chord_progressions = false;
        });


        builder.addCase(StoreChordProgressionInCloudAsync.pending, (state, action) => {
            state.pending.store_chord_progression = true;
        });

        builder.addCase(StoreChordProgressionInCloudAsync.rejected, (state, action) => {
            state.pending.store_chord_progression = false;
            state.failed.store_chord_progression = true;
        });
        builder.addCase(StoreChordProgressionInCloudAsync.fulfilled, (state, action) => {
            state.pending.store_chord_progression = false;
            state.failed.store_chord_progression = false;
        });
    }
});

export const {
    selectZoomSize
} = applicationSlice.actions;
export const getFailed = (type) => (state) => {
    return state.application.failed[type];
}
export const getPending = (type) => (state) => {
    return state.application.pending[type];
}
export const getFullfilled = (type) => (state) => {
    return state.application.fulfilled[type];
}
export const getSelectorZoomSize = (state) => {
    return state.application.zoomSize;
}
export default applicationSlice.reducer;
