import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import userReducer, { loadedKitSamples, loadingKitSamples } from '../features/user/userSlice';
import applicationReducer from '../features/application/applicationSlice';
import serviceSideReducer from '../features/server/serviceSlice';
import composerReducer, {
  currentMidiCommand, getMagentaService, midiEvent, midiInputs,
  chordProgressionPlaying,
  generateMusicStateChange
} from '../features/composer/composerSlice';
import SeratoComposer, { SeratoEventTypes } from '../features/composer/seratoComposer';
import { LoadMagentaAsync } from '../features/composer/magenta-thunks';
import { GENERATE_MUSIC, LOADED_SAMPLES, LOADING_SAMPLES, PLAYING_CHORD_PROGRESSION } from '../base/magenta';
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    composer: composerReducer,
    user: userReducer,
    application: applicationReducer,
    server: serviceSideReducer
  },
});

store.dispatch((dispatch) => {
  dispatch(LoadMagentaAsync()).then(() => {
    let service = getMagentaService();
    service.listen(PLAYING_CHORD_PROGRESSION, (args) => {
      dispatch(chordProgressionPlaying(args))
    })
    service.listen(GENERATE_MUSIC, (args) => {
      dispatch(generateMusicStateChange(args))
    })
    service.listen(LOADING_SAMPLES, (args) => {
      dispatch(loadingKitSamples(args))
    })
    service.listen(LOADED_SAMPLES, (args) => {
      dispatch(loadedKitSamples(args))
    })
  })
  SeratoComposer.loadMidi((type, value) => {
    switch (type) {
      case SeratoEventTypes.MidiInputChange:
        dispatch(midiInputs(value))
        break;
      case SeratoEventTypes.MidiEvents:
        dispatch(midiEvent(value))
        break;
      case SeratoEventTypes.MidiCommandEvents:
        dispatch(currentMidiCommand(value));
        break;
    }

  });
})