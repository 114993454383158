import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    removeBankItem,
    moveTones,
    buildChordOption,
    onAddToBank,
    play,
    selectBias,
    selectBanks,
    selectNextBank,
    selectBankName,
    updateBankName
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import OnDeckPalletteList from '../../components/music/OnDeckPalletteList';
import Banks from '../../components/music/Banks';
import { FormGroup } from '../../components/FormGroup';

export function ChordPallette() {
    const dispatch = useDispatch();
    let bias = useSelector(selectBias);
    let banks = useSelector(selectBanks);
    let nextBank = useSelector(selectNextBank);
    let bankName = useSelector(selectBankName);
    return (
        <Panel stretch title={'Pallette'}>
            <Banks
                banks={banks}
                nameInput={(
                    <FormGroup>
                        <label>Name</label>
                        <input onChange={(e) => {
                            dispatch(updateBankName((e.currentTarget.value)));
                        }} type="text" value={bankName} className="form-control" placeholder="Enter ..." />
                    </FormGroup>)}
                onPlay={(payload) => {
                    dispatch(play({ value: payload, play: true }))
                }}
                onStop={(payload) => {
                    dispatch(play({ value: payload, play: false }))
                }}
                onRemove={(bankKey) => {
                    dispatch(removeBankItem(bankKey));
                }}
                onMoveTones={(args) => {
                    dispatch(moveTones(args));
                }}
                nextBank={nextBank} />
        </Panel>
    );
}