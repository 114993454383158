import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default class MidiSpace extends React.Component {
    constructor(props) {
        super(props);

        let loadedLayout = localStorage.getItem('midi-space-pane');
        if (loadedLayout) {
            loadedLayout = JSON.parse(loadedLayout);
        }
        else {
            loadedLayout = {
                "lg": []
            }
        }

        this.state = {
            layout: {},
            currentLayoutKey: 'lg',
            loadedLayout
        }
    }
    componentDidMount() {
    }
    render() {
        let spaces = this.props.spaces.map((d, index) => {
            let dataGridValue = null;
            if (this.state.currentLayoutKey && this.state.loadedLayout) {
                let tempLayout = this.state.loadedLayout[this.state.currentLayoutKey];
                if (tempLayout) {
                    dataGridValue = tempLayout.find(v => v.i === `midi-space-pane-${index}`);
                }
            }
            let temp = d.element();
            if (temp)
                return (
                    <div key={`midi-space-pane-${index}`} style={{ display: 'flex' }} data-grid={dataGridValue || { x: 0, y: 0, w: 12, h: 4 }}>
                        {temp}
                    </div>
                )
        }).filter(x => x);
        return (
            <div className="react-grid-layout-context">
                <section className="content">
                    <ResponsiveGridLayout
                        className="layout"
                        draggableHandle=".midi-space-pane-item-header"
                        layout={this.state.loadedLayout}
                        measureBeforeMount={false}
                        onLayoutChange={(currentLayout, allLayouts) => {
                            let currentLayoutKey = Object.keys(allLayouts).filter(x => allLayouts[x] === currentLayout)[0];
                            let updated = { ...this.state.loadedLayout, ...allLayouts }
                            this.setState({ currentLayoutKey, loadedLayout: updated });
                            if (this.props.onSpaceUpdate) {
                                this.props.onSpaceUpdate({ currentLayoutKey, loadedLayout: updated });
                            }
                        }}
                        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}>
                        {spaces}
                    </ResponsiveGridLayout>
                </section>
            </div >
        )
    }
}