import React from 'react';


export default class InfoBox extends React.Component {
    render() {
        return (
            <div className="info-box" onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick();
                }
            }}>
                <span className={`info-box-icon ${this.props.selected ? 'bg-success' : 'bg-info'} elevation-1`}><i className={this.props.selected ? "fas fa-star" : "fas fa-cog"}></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">{this.props.text}</span>
                    <span className="info-box-number">
                        {this.props.subtext}
                    </span>
                </div>
            </div>
        )
    }
}