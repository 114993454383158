import { createAsyncThunk } from '@reduxjs/toolkit';
import { delete_space_configuration, load_space_configurations, store_space_configuration } from '../../base/firebase_models/space_configuration';

export const LoadSpaceConfigurations = createAsyncThunk(
    'composer/load/space/configurations',
    async (params) => {
        return await load_space_configurations(params)
    }
)

export const CreateSpaceConfigurationAsync = createAsyncThunk(
    'composer/create/space/configuration',
    async (params) => {
        return await store_space_configuration(params);
    }
)

export const DeleteSpacePanelConfigurationAsync = createAsyncThunk(
    'composer/delete/space/configuration',
    async (params) => {
        return await delete_space_configuration(params);
    }
)