import { SSL_OP_NO_TLSv1_1 } from 'constants';
import React from 'react';
import { ArpeggioOptions, ModifierTypes, StepTonalOptions_Step } from '../../../base/modifier-service';
import { TitlesService } from '../../../title-service';
import { FormGroup } from '../../FormGroup';
import * as modifierservice from '../../../base/modifier-service'
import * as midiservice from '../../../base/midi-service';
import MidiTrack from '../MidiTrack';
import { BuildMidiModifierLick } from '../../../base/midi-writer-service';

export class ModifierMusicStaff extends React.Component {
    reader = null;
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { modifier, item } = this.props;
        let extra = {}
        let flowNotation;
        let defaultNotes = [{
            midi: 60,
            time: 0,
            duration: null
        }, {
            midi: 64,
            time: 0,
            duration: null
        }, {
            midi: 67,
            time: 0,
            duration: null
        }];
        let startTicks = 0;
        let ppq = 120;
        let endTicks = 0;
        let midiData;
        if (modifier) {
            let modifierItem = modifier.spaces.find(x => x.id === item);
            if (modifierItem) {
                flowNotation = modifierservice.getModifierNotation(modifier, modifierItem, defaultNotes, this.props.licks);
                let midi = BuildMidiModifierLick(flowNotation);
                midiData = midiservice.readMidiFile(midi);
                endTicks = midiData.durationTicks;
                ppq = midiservice.getPPQ(midiData);
            }
        }
        if (!midiData) {
            return <div className="col-md-12" style={{ height: 400 }}></div>
        }
        return (
            <div className="col-md-12" style={{ height: 400 }}>
                <MidiTrack
                    start={startTicks}
                    end={endTicks}
                    height={400}
                    ppq={ppq}
                    track={midiData.tracks[0]}
                />
            </div>
        )
    }
}