
export function readFile(file) {
    return new Promise((resolve, fail) => {
        let reader = new FileReader();
        reader.onload = (ev => {
            resolve(reader.result);
        });

        reader.readAsArrayBuffer(file);
    })
}