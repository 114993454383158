import React from "react";
import { flat, sharp } from "../../base/base-lib";

export default class NoteType extends React.Component {
    render() {
        let details = [];
        if (this.props.title) {
            let { title } = this.props;
            let currentdetails = '';
            title.split('').map((v, index) => {

                if (flat === v || sharp === v) {
                    details.push(<span key={`title-${currentdetails}`}>{currentdetails}</span>);
                    currentdetails = '';
                    details.push(<sup key={`title-${index}`}>{v}</sup>);
                }
                else {
                    currentdetails += v;
                }
            })
            if (currentdetails) {
                details.push(<span key={`title-${currentdetails}-end$$$`}>{currentdetails}</span>);
            }
        }
        let eprops = {};
        if (this.props.fontSize) {
            eprops.style = {
                fontSize: this.props.fontSize
            };
        }
        return <span {...eprops}>{details}</span>
    }
}

export function WriteNoteType(title) {
    let currentdetails = '';
    let details = [];
    title.split('').map((v, index) => {

        if (flat === v || sharp === v) {
            details.push(`${currentdetails}`);
            currentdetails = '';
            details.push(`<sup>${v}</sup>`);
        }
        else {
            currentdetails += v;
        }
    })
    if (currentdetails) {
        details.push(`${currentdetails}`);
    }
    return details.join('');
}