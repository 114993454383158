import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SidebarMenu from '../../components/main/SideBarMenu';
import SidebarMenuItem from '../../components/main/SideBarMenuItem';
import Panel from '../../components/panel/Panel';
import { TitlesService } from '../../title-service';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { getCurrentSelectedSpaceConfiguration, getSpaceConfigurations, getUserId, getUserSignedIn, selectCurrentSpaceConfiguration, setCurrentSelectedSpaceConfig } from '../user/userSlice';
import { TrackerHeaderActions, TrackerHeaderDropDownMenu } from '../../components/music/ChordProgressionNonLinearEditor';
import { DeleteSpacePanelConfigurationAsync, LoadSpaceConfigurations } from './user-thunks';
import { addKeyServiceEventHandler, isParentOf } from '../../base/key-service';
const ResponsiveGridLayout = WidthProvider(Responsive);

export function SpaceConfigurations(props) {
    const dispatch = useDispatch();
    const space_configurations = useSelector(getSpaceConfigurations);
    const currentMidiFile = useSelector(selectCurrentSpaceConfiguration);
    let current_config = useSelector(getCurrentSelectedSpaceConfiguration);


    return (
        <Panel stretch title={TitlesService('Panel Configurations')}>
            <SidebarMenu>
                {
                    space_configurations.map((space_configuration, i) => {
                        return <SidebarMenuItem icon={
                            current_config === space_configuration.id ? 'fas fa-check-circle' : 'fas far fa-circle'}
                            title={space_configuration.name} onClick={() => {
                                dispatch(setCurrentSelectedSpaceConfig(space_configuration.id))
                            }}></SidebarMenuItem>
                    })
                }
            </SidebarMenu>
        </Panel >
    );
}


export function SpacePanelConfiguration(props) {
    let current_config = useSelector(getCurrentSelectedSpaceConfiguration);
    let configurations = useSelector(getSpaceConfigurations);
    let currentconfig = configurations.find(x => x.id === current_config) || {};
    const isSignedIn = useSelector(getUserSignedIn);
    const uid = useSelector(getUserId);
    const dispatch = useDispatch();
    let stored_spaces = currentconfig.space || localStorage.getItem('space-pane');
    const [open, setOpen] = useState(false);
    let spaces = null;
    if (!isSignedIn) {
        return (
            <Panel stretch title={TitlesService('Current Panel Configuration')} ></Panel>
        )
    }
    if (stored_spaces) {
        try {
            stored_spaces = JSON.parse(stored_spaces)
            let colors = '00c2d1-f26419-3d5a6c-ff5a5f-32de8a-6369d1-92374d-93b7be-f9dc5c-98ce00'.split('-').map(x => `#${x}`);
            spaces = Object.values(stored_spaces.lg).map((d, index) => {
                let dataGridValue = d;

                return (
                    <div style={{
                        backgroundColor: 'red'
                    }} title={d.i.split('space-pane-').join('')} key={`space-pane-preview-${d.i}-${d.w}-${d.h}-${d.x}-${d.y}`}
                        style={{
                            position: 'absolute',
                            left: `${(d.x / 12) * 100}%`,
                            top: `${(d.y / 12) * 100}%`,
                            width: `${(d.w / 12) * 100}%`,
                            height: `${(d.h / 12) * 100}%`,
                            backgroundColor: colors[index % colors.length]
                        }}
                        data-grid={{ ...dataGridValue, static: true } || { x: 0, y: 2, w: 6, h: 2 }}>
                        <div>{d.i.split('space-pane-').join('')}</div>
                    </div>
                )
            }).filter(x => x);
        } catch (e) {
            console.error(e);
        }
    }
    addKeyServiceEventHandler('click', 'space-config-current', (evt) => {
        let headerDom = document.body.querySelector(`.current-panel-configuration`);
        if (!isParentOf(headerDom, evt.target)) {
            setOpen(false)
        }
    })
    return (
        <Panel cls={'current-panel-configuration'} stretch title={TitlesService('Current Panel Configuration')} buttons={() => {
            return (
                [
                    <button type="button"
                        className="btn btn-tool"
                        onClick={() => {
                            setOpen(!open);
                        }}>
                        <i className="fas fa-bars"></i>
                    </button>,
                    <TrackerHeaderDropDownMenu show={open} >
                        <TrackerHeaderActions
                            style={{ color: '#00bc8c' }}
                            icon={'far fa-times'}
                            onClick={() => {
                                dispatch(DeleteSpacePanelConfigurationAsync({ uid, id: current_config })).then(() => {
                                    return dispatch(LoadSpaceConfigurations({ uid }))
                                })
                                setOpen(false);
                            }}
                            title={`${TitlesService('Delete Configuration')}`} />
                    </TrackerHeaderDropDownMenu>
                ]
            )
        }}>
            <div className="react-grid-layout-context" style={{
                width: 'auto',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                minHeight: '100%'
            }}>
                <div style={{
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    minHeight: '100%'
                }}></div>
                {spaces}
            </div >
        </Panel>
    )
}