import React from 'react';
import ChordMaster from '../../base/chord-master';
import Vex from 'vexflow';
import { convertToMelodyNote, flat, sharp, uuidv4 } from '../../base/base-lib';
import { StaffNotePlayerBoard } from './StaffNotes';
const VF = Vex.Flow;
const RENDER_HEIGHT = 200;
const RENDER_WIDTH = 700;
export default class ScaleNotes extends React.Component {
    notes = null;
    constructor(props) {
        super(props);
        let _state = { id: `s_${uuidv4()}`, note: null };
        this.state = _state;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps && nextProps.value) {

            throttle(() => {
                try {
                    this.updateRenderer(nextProps);
                } catch (e) {
                    console.error(e)
                }
            }, 250)
        }
        return false;
    }
    updateRenderer(nextProps) {
        if (nextProps && nextProps.value) {
            let keys = [];
            let lowestOctave = 100;
            Object.keys(nextProps.value).filter(v => v !== 'NaN').map((v, index) => {
                if (nextProps.value[v]) {
                    let res = convertToMelodyNote(v, this.props.bias);
                    let key = (res.key || '').toLowerCase();
                    switch (res.accidental) {
                        case sharp:
                            key += '#';
                            break;
                        case flat:
                            key += 'b';
                            break;
                    }
                    key += "/" + res.octave;
                    lowestOctave = Math.min(res.octave, lowestOctave);
                    keys.push(key);
                }
            })

            if (this.notes !== keys.join('-')) {

                // Create a stave at position 10, 40 of width RENDER_WIDTH on the canvas.

                let { context } = this.state;
                context.clear();
                var stave = new VF.Stave(10, 40, RENDER_WIDTH);
                let clef = lowestOctave >= 4 ? 'treble' : 'bass';
                // Add a clef and time signature.
                stave.addClef(clef).addTimeSignature("4/4");

                // Connect it to the rendering context and draw!
                stave.setContext(context).draw();

                if (keys.length) {
                    let notes = [];
                    Object.keys(nextProps.value).filter(v => v !== 'NaN').map((v, index) => {
                        let accidentals = [];
                        if (nextProps.value[v]) {
                            let res = convertToMelodyNote(v, this.props.bias);
                            let key = (res.key || '').toLowerCase();
                            switch (res.accidental) {
                                case sharp:
                                    key += '#';
                                    accidentals.push({ index, accidental: new VF.Accidental('#') })
                                    break;
                                case flat:
                                    key += 'b';
                                    accidentals.push({ index, accidental: new VF.Accidental('b') })
                                    break;
                            }
                            key += "/" + res.octave;
                            keys.push(key);


                            let staveNote = new VF.StaveNote({ clef, keys: [key], duration: '4' });
                            let accidental = accidentals[0];
                            if (accidental) {
                                staveNote = staveNote.addAccidental(0, accidental.accidental);
                            }
                            // Create a voice in 4/4 and add the notes from above
                            notes.push(staveNote);
                        }
                    });


                    var voices = [];
                    voices.push(new VF.Voice({ num_beats: notes.length, beat_value: 4 }).addTickables(notes));

                    // Format and justify the notes to RENDER_WIDTH pixels.
                    var formatter = new VF.Formatter().joinVoices(voices).format(voices, RENDER_WIDTH);

                    // Render voices
                    voices.forEach(function (v) { v.draw(context, stave); })
                }
                this.notes = keys.join('-');
            }
        }
    }
    componentDidMount() {
        setTimeout(() => {

            this.createRenderer();
        }, 100)
    }

    createRenderer() {
        let renderer = new VF.Renderer(document.getElementById(`${this.state.id}`), VF.Renderer.Backends.CANVAS);
        renderer.resize(RENDER_WIDTH, RENDER_HEIGHT);
        let context = renderer.getContext();

        this.setState({
            context,
            renderer
        });
    }

    render() {
        let props = this.props;
        return (
            <div style={{ backgroundColor: 'white' }}>
                <StaffNotePlayerBoard skipbank={this.props.skipbank} onPlay={() => {
                    if (props.onPlay) {
                        props.onPlay();
                    }
                }} onStop={() => {
                    if (props.onStop) {
                        props.onStop();
                    }
                }} onAddToBank={() => {
                    if (props.onAddToBank) {
                        props.onAddToBank();
                    }
                }} value={this.currentProps ? this.currentProps.value : null} />
                <h3 style={{ color: 'black' }}>{this.props.title}</h3>
                <canvas width={RENDER_WIDTH} height={RENDER_HEIGHT} id={this.state.id}></canvas>
            </div>
        )
    }
}
function throttle(fn, ms) {
    let timeout;
    function exec() {
        fn.apply()
    }
    function clear() {
        if (timeout == undefined) { }
        else { clearTimeout(timeout); }
    }
    if (fn !== undefined && ms !== undefined) {
        timeout = setTimeout(exec, ms)
    } else {
        console.error('callback function and the timeout must be supplied')
    }
    // API to clear the timeout
    throttle.clearTimeout = function () {
        clear();
    }
}