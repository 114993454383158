import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import * as modifierService from '../../base/modifier-service';
import MagentaService from '../../base/magenta';
import { getLineAndCharacterOfPosition, ScriptElementKindModifier } from 'typescript';
import { BuildChordScale, convertToMelodyNote, getLibray, parseMidiMessage, uuidv4 } from '../../base/base-lib';
import ChordMaster, { calculateMidi, calculateV } from '../../base/chord-master';
import * as playService from '../../base/play-service';
import * as timerService from '../../timer/time-service';
import FileSaver from 'file-saver';
import { generateScene, generateSceneTrack, generateSceneTrackNote, generateSeratoInstrument, getSeratoTemplate } from '../../base/serato-export';
import * as midiService from '../../base/midi-service';
import { TitlesService } from '../../title-service';
import ChordProgressionsList from './chordprogressions';
import { BuildMidiChordProgression } from '../../base/midi-writer-service';
import * as nameService from '../../base/nameService';
import { AddPatternAsync, GenerateMusicAsync, GetSequenceAsMidi, LoadMagentaAsync, PlayMagentaMusicAsync, PlayMagentaNotesAsnyc, setMagentaService, StopMagentaPlayerAsync, StoreChordProgressionInCloudAsync } from './magenta-thunks';
import { useDispatch } from 'react-redux';
import { createDispatchHook } from 'react-redux';
import { isAltOn } from '../../base/key-service';
const MEASURE_SUB_DIVISION = 30720.0 / 8;
export const CHORD_PROGRESSION_SUB_DIVISIONS = 64;
const BANK_ACCESS_MODES = {
    MIDI: 'MIDI',// Each note will map to a different chord, no octave adjustments are made.
    NOTE: 'NOTE'// For Example, all the C notes on the keyboard will map to the same chord, at different octaves
}
export const GridTypes = {
    MODIFIER: 'MODIFIER',
    CHORD: 'CHORD'
}
export const TrackProperties = {
    INSTRUMENT: 'INSTRUMENT',
    VOLUME: 'VOLUME'
}
export const QuantiesOptions = [{
    name: 'W', value: '1'
}, {
    name: 'H', value: '.5'
}, {
    name: 'Q', value: '.25'
}, {
    name: '8', value: '.125'
}, {
    name: '16', value: '.0625'
}, {
    name: '32', value: '.03125'
}, {
    name: '64', value: '0.015625'
}, {
    name: 'Off', value: null
}];
const appServices = {
    magentService: null
}
export const ChordProgressions = [
    ...ChordProgressionsList,

].filter(v => v).sort((a, b) => {
    return a.name.localeCompare(b.name);
})
export const ChordProgressModeValues = {
    PALETTE: 'PALETTE',
    NEAREST_NOTE: 'NEAREST_NOTE',
    MAP_TO_ALL: 'MAP_TO_ALL',
    MAP_TO_WHITE: 'MAP_TO_WHITE'
}
const CACHE = {};
let sceneIndex = 0;
export const ChordProgressionMode = [
    //     {
    //     name: 'Map to White',
    //     description: 'Map chord to all the white keys on a loop.',
    //     value: ChordProgressModeValues.MAP_TO_WHITE
    // }, 
    {
        name: 'Map to All',
        description: 'Map chord to all the keys on a loop',
        value: ChordProgressModeValues.MAP_TO_ALL
    },
    //  {
    //     name: 'Nearest Note',
    //     description: 'Map the note to the nearest',
    //     value: ChordProgressModeValues.NEAREST_NOTE
    // }, 
    {
        name: 'Pallette',
        description: 'Use mapping described by pallette',
        value: ChordProgressModeValues.PALETTE
    }, {
        name: 'Off',
        description: 'Off',
        value: null
    }]

function handleBreaks(state_breaks, breaks) {
    let break_start = Math.min(...breaks.map(v => v.val));
    let break_end = Math.max(...breaks.map(v => v.val));
    let original_break_start = Math.min(...state_breaks.map(v => v.val));
    let original_break_end = Math.max(...state_breaks.map(v => v.val));
    if (break_end !== original_break_end) {
        let break_end_difference = Math.round((original_break_end - break_end) * 10) / 10
        breaks = [{
            val: Math.round((break_start - break_end_difference) * 10) / 10,
            lbl: 'Start'
        }, {
            val: break_end,
            lbl: 'End'
        }]
    }
    else if (break_start !== original_break_start) {
        let break_start_difference = Math.round((original_break_start - break_start) * 10) / 10;
        breaks = [{
            val: break_start,
            lbl: 'Start'
        }, {
            val: Math.round((break_end - break_start_difference) * 10) / 10,
            lbl: 'End'
        }]
    }
    return breaks;
}
const initialState = {
    value: 0,
    currentMidiFile: '',
    currentLick: '',
    failed: {},
    scenes: {},
    tempo: 100,
    instruments: [],
    optionFilter: [],
    midiInputs: {},
    selectedChordProgressionGap: null,
    generating_music: false,
    selectedChordProgressionBreak: 0,
    quantizeMode: null,
    quantizeDurationMode: null,
    playing: false,
    recording: false,
    currentScene: null,
    currentInstrument: null,
    onDeckPallettes: [],
    currentMidiCommand: {},
    // If the input field is focused, then it should be listening
    chordInputListening: {},
    chordShouldClear: {},
    midiLicks: {},
    chordInputValues: {},
    chordProgressions: {},
    modifiers: {},
    currentModifier: null,
    currentProgression: null,
    notes: {},
    chordMatches: {},
    // Keeps track of which note tracks to each set of notes.
    banks: {},
    bankStorage: {},
    bankName: '',
    nextBank: null,
    targetBankKey: null,
    bankAccessMode: BANK_ACCESS_MODES.MIDI,
    scaleMatches: {
        'Major Scale': true,
        'Melodic Minor': true
    },
    bias: false,
    midis: {}
};
const staticState = {
    playing: false,
    tempo: 100,
    currentScene: null,
    recording: false,
    scenes: {},
    recordingBar: {}
}
var reader = new ChordMaster.Reader({ skipRenderer: true });
export function FindExactMatch(v, scales) {
    return reader.findMatches(v, scales, 0, 1000, {}, true);
}
function createChordProgression(id) {
    let name = nameService.getSongName();
    return {
        id,
        name: name || TitlesService('unknown'),
        spaces: [],
        tracks: {},
        measures_data: {},
        measures: 4
    }
}

function createNewModifier(state) {
    let new_modifier = {
        id: `item-${uuidv4()}`,
        spaces: [],
        name: nameService.createModifierName()
    }
    state.modifiers[new_modifier.id] = new_modifier;
}

function createNewSpaceItem({ chordData, offset }, progression) {
    return {
        id: `item-${uuidv4()}`,
        position: {
            x: Math.floor(progression.measures * 64 * offset.x),
            y: Math.floor(6 * offset.y),
            w: 16,
            h: 1
        },
        originalChordData: JSON.parse(JSON.stringify(chordData)),
        chordData,
        type: 'chord'
    }
}
export function saveMidiFileToMachine(data, name) {
    var bb = new Blob([data], { type: 'audio/midi' });
    FileSaver.saveAs(bb, name || `example.mid`);
}
function updateChordProgressionMeasuresLength(state, action) {
    state.chordProgressions[state.currentProgression].measures = action.payload;
}
function getChordProgressionModifiers(state) {
    if (state.currentModifier && state.modifiers[state.currentModifier]) {
        return state.modifiers[state.currentModifier].spaces;
    }
    return null;
}
function getChordProgressionMeasure(state, selected) {
    if (state.chordProgressions[state.currentProgression] && state.chordProgressions[state.currentProgression].spaces && state.chordProgressions[state.currentProgression].spaces[selected]) {
        return state.chordProgressions[state.currentProgression].spaces[selected]
    }
    return null;
}
function _chordInputSet(state, action) {
    state.chordInputValues[action.payload.id] = {};
    state.chordInputListening[action.payload.id] = true;
    for (let note in action.payload.data.value) {
        _currentMidiCommand(state, {
            payload: {
                override: true,
                command: {
                    note,
                    value: true,
                    command: 8
                }
            }
        });
    }
    state.chordInputListening[action.payload.id] = false;
}

export const composerSlice = createSlice({
    name: 'composer',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setCurrentScene: (state, action) => {
            state.currentScene = action.payload;
            staticState.currentScene = action.payload;
            setOutOfBandScene([state.currentScene])
        },
        updateChordProgressionsMeasures: (state, action) => {
            ensureChordProgression(state);
            updateChordProgressionMeasuresLength(state, action);
        },
        updateChordProgressionsName: (state, action) => {
            state.chordProgressions[state.currentProgression].name = action.payload;
        },
        removeChordFromChordProgression: (state, action) => {
            state.chordProgressions[state.currentProgression].spaces = state.chordProgressions[state.currentProgression].spaces.filter(v => v.id !== action.payload);
        },
        deleteSelectedItems: (state, action) => {
            state.chordProgressions[state.currentProgression].spaces = state.chordProgressions[state.currentProgression].spaces.filter(v => action.payload.indexOf(v.id) === -1);
        },
        updateModifier: (state, action) => {
            if (state.modifiers && state.modifiers[state.currentModifier] && state.modifiers[state.currentModifier]) {
                state.modifiers[state.currentModifier][action.payload.key] = action.payload.value;
            }
        },
        removeModifierClick: (state, action) => {
            if (state.modifiers && state.modifiers[state.currentModifier] && state.modifiers[state.currentModifier].spaces) {
                let item = state.modifiers[state.currentModifier].spaces.find(v => v.id === action.payload);
                if (item) {
                    if (item.deleteDependents) {
                        state.modifiers[state.currentModifier].spaces = state.modifiers[state.currentModifier].spaces.filter(v => item.deleteDependents.indexOf(v.id) === -1);
                    }
                    else {
                        state.modifiers[state.currentModifier].spaces = state.modifiers[state.currentModifier].spaces.filter(v => v.id !== action.payload);
                    }
                    if (item.isRowWide) {
                        state.modifiers[state.currentModifier].spaces.filter(x => x.position.y > item.position.y).map(v => v.position.y--);
                    }
                    if (state.modifiers[state.currentModifier].selectedItem === action.payload) {
                        state.modifiers[state.currentModifier].selectedItem = null;
                    }
                }
            }
        },
        addModifierType: (state, action) => {
            if (state.currentModifier && state.modifiers && state.modifiers[state.currentModifier]) {
                let new_modifiers = modifierService.createModifier(action.payload, state);
                state.modifiers[state.currentModifier].spaces.push(...new_modifiers);
                state.modifiers[state.currentModifier].selectedItem = new_modifiers[0].id;
            }
        },
        onModifierItemClicked: (state, action) => {
            if (state.currentModifier && state.modifiers && state.modifiers[state.currentModifier]) {
                state.modifiers[state.currentModifier].selectedItem = action.payload;
            }
        },
        toggleStaticPositionOnChordInChordProgression: (state, action) => {
            state.chordProgressions[state.currentProgression].spaces[action.payload].position.static = !state.chordProgressions[state.currentProgression].spaces[action.payload].position.static;
        },
        addAndSelectChordProgression: (state, action) => {
            let progression = action.payload;
            if (progression) {
                if (progression.originalId) {
                    delete state.chordProgressions[progression.originalId]
                }
                state.chordProgressions[progression.id] = JSON.parse(JSON.stringify(progression));
                state.currentProgression = progression.id;
            }
        },
        toggleSelectedChordProgression: (state, action) => {
            state.currentProgression = action.payload.id;
        },
        deleteChordProgression: (state, action) => {
            delete state.chordProgressions[action.payload.id]
            if (state.currentProgression === action.payload.id) {
                state.currentProgression = Object.keys(state.chordProgressions)[0] || null;
            }
        },
        selectChordProgressionItems: (state, action) => {
            if (state.chordProgressions && state.currentProgression && state.chordProgressions[state.currentProgression]) {
                let merge_on = false;
                if ((state.chordProgressions[state.currentProgression].selectedItem && isAltOn())) {
                    merge_on = true;
                }
                if (action.payload.item && action.payload.item.id) {
                    let { items, item } = action.payload;
                    if (item && item.chordData) {
                        state.chordProgressions[state.currentProgression].selectedItem = item.id;
                        if (merge_on) {
                            state.chordProgressions[state.currentProgression].selectedItems = [...(state.chordProgressions[state.currentProgression].selectedItems || []), ...items].unique();
                        }
                        else {
                            state.chordProgressions[state.currentProgression].selectedItems = items;
                        }
                        _chordInputSet(state, {
                            payload: {
                                id: 'chord-input',
                                data: {
                                    value: item.chordData.values
                                }
                            }
                        });
                    }
                }
            }
        },
        onChordProgressionItemClick: (state, action) => {
            if (state.chordProgressions && state.currentProgression && state.chordProgressions[state.currentProgression]) {
                if (action.payload.chordData) {
                    if (!state.chordProgressions[state.currentProgression].selectedItem ||
                        (state.chordProgressions[state.currentProgression].selectedItem && isAltOn())) {
                        state.chordProgressions[state.currentProgression].selectedItem = action.payload.id;
                        state.chordProgressions[state.currentProgression].selectedItems = [
                            ...(state.chordProgressions[state.currentProgression].selectedItems || []),
                            action.payload.id
                        ].unique();
                        _chordInputSet(state, {
                            payload: {
                                id: 'chord-input',
                                data: {
                                    value: action.payload.chordData.values
                                }
                            }
                        })
                    }
                    else {
                        if (action.payload.id === state.chordProgressions[state.currentProgression].selectedItem) {
                            state.chordProgressions[state.currentProgression].selectedItem = null;
                            state.chordProgressions[state.currentProgression].selectedItems = [];
                            _chordInputSet(state, {
                                payload: {
                                    id: 'chord-input',
                                    data: {
                                        value: {}
                                    }
                                }
                            })
                        }
                        else {
                            state.chordProgressions[state.currentProgression].selectedItem = action.payload.id;
                            state.chordProgressions[state.currentProgression].selectedItems = [
                                action.payload.id
                            ].unique();
                            _chordInputSet(state, {
                                payload: {
                                    id: 'chord-input',
                                    data: {
                                        value: action.payload.chordData.values
                                    }
                                }
                            })
                        }
                    }
                }
            }
        },
        updateModifierItem: (state, action) => {
            let modifierItem = modifierService.getSelectedModifierItem(state);
            if (modifierItem) {
                modifierItem.args[action.payload.key] = action.payload.value;
            }
        },
        updateModifierItemPosition: (state, action) => {
            let { payload } = action;
            if (payload) {
                if (state.modifiers[state.currentModifier]) {
                    let modifiers = getChordProgressionModifiers(state);
                    if (modifiers) {
                        payload.forEach((item) => {
                            modifiers.filter(sp => sp.id === item.i).map(sp => {
                                sp.position = {
                                    ...sp.position,
                                    x: item.x,
                                    y: item.y,
                                    w: item.w,
                                    h: item.h
                                }
                            });
                        });
                    }
                }
            }
        },
        stopPlay: () => {
            timerService.play(false);
            timerService.clearQueue();
            clearNotesInQueue();
        },
        playChordProgression: (state, action) => {
            let { payload } = action;
            if (state.chordProgressions && state.currentProgression && state.chordProgressions[state.currentProgression]) {
                let chordProgression = state.chordProgressions[state.currentProgression];
                let lickDic = state.midiLicks;
                let modifiers = state.modifiers
                let res = BuildMidiChordProgression(chordProgression, {
                    measureDivisions: 64,
                    measures: 8,
                    tempo: state.tempo,
                    start: null,
                    stop: null,
                    lickDic,
                    modifiers
                });
                let midiFile = midiService.readMidiFile(res)

                let notes = [];
                let magenta_notes = []
                if (midiFile && midiFile.tracks) {
                    midiFile.tracks.forEach((track) => {
                        let m_track_notes = [];
                        track.notes.map(note => {
                            notes.push({
                                note: note.midi,
                                velocity: note.velocity,
                                duration: note.duration,
                                start: note.time
                            })
                        })

                    })
                }
                timerService.play(false);
                timerService.clearQueue();
                clearNotesInQueue();
                if (appServices.magentService.playComposerProgression && res) {
                    // appServices.magentService.playComposerProgression(res)
                }
                else {
                    notes = notes.sort((a, b) => {
                        return a.start - b.start;
                    });
                    let { time } = timerService.getAccurateNow()
                    addNotesToQueue(notes.map(d => ({
                        ...d,
                        start: d.start + time
                    })))
                    timerService.play(true);
                }
            }
        },
        updateChordProgressionPositions: (state, action) => {
            let { payload } = action;
            if (payload) {
                payload.filter(v => v.i && !v.i.startsWith('measure')).forEach((item) => {
                    if (state.chordProgressions && state.currentProgression && state.chordProgressions[state.currentProgression])
                        state.chordProgressions[state.currentProgression].spaces.filter(sp => sp.id === `${item.i}`.split('$')[0]).map(sp => {
                            if (sp.position.x !== item.x ||
                                sp.position.y !== item.y ||
                                sp.position.w !== item.w ||
                                sp.position.h !== item.h) {
                                sp.position = {
                                    ...sp.position,
                                    x: item.x,
                                    y: item.y,
                                    w: item.w,
                                    h: item.h
                                };
                                sp.version = (sp.version || 0) + 1
                            }
                        })
                })
            }
        },
        addNewChordInstance: (state, action) => {
            ensureChordProgression(state);

            if (state.chordProgressions)
                state.chordProgressions[state.currentProgression].spaces.push(createNewSpaceItem(action.payload,
                    state.chordProgressions[state.currentProgression]))
        },
        setTrackProperty: (state, action) => {
            ensureChordProgression(state);
            state.currentInstrument = action.payload;
            state.chordProgressions[state.currentProgression].tracks[action.payload.track] = state.chordProgressions[state.currentProgression].tracks[action.payload.track] || {};
            state.chordProgressions[state.currentProgression].tracks[action.payload.track] = {
                ...state.chordProgressions[state.currentProgression].tracks[action.payload.track],
                [action.payload.property]: action.payload.value
            }
        },
        exportToSerato: (state, action) => {
            let template = getSeratoTemplate(
                function setClips() {
                    let id = Object.keys(state.scenes)[0]
                    let measureLength = state.scenes[id].length;
                    let tempo = state.tempo / 60;
                    let duration = (measureLength * BEATS_PER_MEASURE / tempo)
                    return [
                        {
                            "name": "",
                            "type": "scene",
                            "clips":
                                [
                                    {
                                        "start": 0,
                                        "length": Math.round(duration * 10000) / 10,
                                        "scene_slot_number": 0
                                    }
                                ]
                        }]
                },
                function setScenes() {


                    let scenes = Object.keys(state.scenes).map((id) => {
                        let measureLength = state.scenes[id].length;
                        let seratoMeasureDuration = 30720.0 / 8;
                        let seratoMeasureLength = seratoMeasureDuration * measureLength;

                        let tempo = state.tempo / 60;
                        let duration = (measureLength * BEATS_PER_MEASURE / tempo)

                        let scene = state.scenes[id];

                        let tracks = scene.tracks.map((track) => {
                            return generateSceneTrack(track.notes.map((note) => {
                                let start = (note.start / duration) * seratoMeasureLength;
                                let sduration = (note.duration / duration) * seratoMeasureLength;
                                return generateSceneTrackNote(start, sduration, note.note, note.velocity * 127)
                            }));
                        })
                        return generateScene(id, seratoMeasureLength, tracks)
                    });
                    return scenes;
                },
                function setInstruments() {
                    return state.instruments.map((instrument) => {
                        return generateSeratoInstrument()
                    });
                }, state.tempo);


            let cc_storage = JSON.stringify(template, null, 4)
            var bb = new Blob([cc_storage], { type: 'application/json' });
            FileSaver.saveAs(bb, `${action.payload || 'serato'}.ssp`);
        },
        addNewScene: (state, action) => {
            sceneIndex = Math.max(...Object.keys(state.scenes).map(v => parseInt(v)), 0);
            let id = `Scene_${sceneIndex++}`;
            state.scenes[id] = createNewScene(id, state);
            addNewInstrument(state);
            staticState.scenes = JSON.parse(JSON.stringify(state.scenes));
        },
        removeCurrentScene: (state, action) => {
            if (state.currentScene) {
                delete state.scenes[state.currentScene]
                state.currentScene = null;
            }
        },
        buildSceneProgressions: (state, action) => {
            let payload = action.payload;
            state.currentProgression = `cp-${uuidv4()}`;
            ensureChordProgression(state);
            updateChordProgressionMeasuresLength(state, { payload: Math.ceil(payload.chords.length * payload.measureLength) })
            for (let i = 0; i < payload.chords.length; i++) {
                let chord = payload.chords[i];
                let item = createNewSpaceItem({
                    chordData: chord,
                    offset: { x: 1, y: 1 }
                }, state.chordProgressions[state.currentProgression]);
                item.position.x = payload.measureLength * i * CHORD_PROGRESSION_SUB_DIVISIONS;
                item.position.y = 1;
                item.position.w = payload.measureLength * CHORD_PROGRESSION_SUB_DIVISIONS;
                item.position.h = 1;
                state.chordProgressions[state.currentProgression].spaces.push(item)
            }
        },
        removeBankItem: (state, action) => {
            delete state.banks[action.payload];
        },
        moveTones: (state, action) => {
            console.log(JSON.stringify(state.banks[action.payload.key], null, 4));
            let { direction, key } = action.payload;
            let { notes, noteLetter } = state.banks[action.payload.key];
            let newNotes = {}
            let newNoteKeys = [];
            Object.keys(notes).map((v) => {
                let te = parseInt(v) + direction;
                newNotes[te] = true;
            });
            let library = getLibray();
            let offset = library[noteLetter.key + noteLetter.accidental];
            if (direction > 0) {
                offset += direction;
            }
            else {
                offset += 12 + direction;
            }
            offset = offset % 12;

            let melodyNote = convertToMelodyNote(offset + ((noteLetter.octave + 1) * 12));
            state.banks[key].noteLetter = {
                ...melodyNote
            };
            state.banks[key].notes = {
                ...newNotes
            };

            let currentName = state.banks[key].chord.name;
            let notesInName = [];
            Object.keys(library).sort((a, b) => {
                return b.length - a.length
            }).map(key => {
                if (currentName.split(key).length > 1) {
                    notesInName.push(key);
                    currentName = currentName.split(key).join('')
                }
            });
            currentName = state.banks[key].chord.name;
            notesInName.forEach((nin) => {
                let temp = (library[nin] + direction) % 12;
                if (temp < -1) {
                    temp += 12;
                }
                let convertedTemp = convertToMelodyNote(temp);
                currentName = currentName.split(nin).join(convertedTemp.key + convertedTemp.accidental);
            })
            state.banks[key].chord = {
                ...state.banks[key].chord,
                ...melodyNote,
                userMade: true,
                name: currentName
            };
        },
        toggleProjectPlay: (state, action) => {
            state.playing = !state.playing;
            staticState.playing = state.playing;
            if (!staticState.playing) {
                staticState.recording = false;
                state.recording = false;
            }
            timerService.play(state.playing)
        },
        updateOptionFilter: (state, action) => {
            state.optionFilter = action.payload.split(' ');
        },
        updateSceneMeasureLength: (state, action) => {
            state.scenes[state.currentScene].length = action.payload;
            staticState.scenes[state.currentScene] = staticState.scenes[state.currentScene] || {};
            staticState.scenes[state.currentScene].length = action.payload;
        },
        updateChordProgressionBreaks: (state, action) => {
            state.scenes[state.currentScene].chordProgression.breaks = action.payload;
        },
        updateBankName: (state, action) => {
            state.bankName = `${action.payload}`.trim();
        },
        storeBank: (state, action) => {
            if (state.bankName) {
                state.bankStorage[state.bankName] = JSON.parse(JSON.stringify(state.banks));
            }
        },
        saveBankStorage: (state, action) => {
            var bb = new (window.BlobBuilder)();
            let bank_storage = JSON.stringify(state.bankStorage, null, 4)
            bb.append(bank_storage);
            var blob = bb.getBlob("application/json;charset=" + document.characterSet);
            window.saveAs(blob, `${action.payload || 'document'}.ccbanks`);
        },
        loadBankStorage: (state, action) => {
            let { merge, data } = action.payload;
            if (merge) {
                state.bankStorage = { ...state.bankStorage, ...JSON.parse(data) }
            }
            else {
                state.bankStorage = { ...JSON.parse(data) }
            }
        },
        loadComposerCompanionStorage: (state, action) => {
            let temp = { ...initialState, ... (JSON.parse(action.payload.data)) };
            for (let i in temp) {
                state[i] = temp[i];
            }
            for (let i in staticState) {
                if (state.hasOwnProperty(i))
                    staticState[i] = JSON.parse(JSON.stringify(state[i]));
            }
            staticState.recordingBar = {};
        },
        loadMidiFile: (state, action) => {
            loadMidiFileHelper(action, state);
        },
        setMidiFileBreaks: (state, action) => {
            let { min, max, midi, key } = action.payload;
            if (state.midis && state.midis[midi]) {
                state.midis[midi][key][0].val = max;
                state.midis[midi][key][1].val = min;
            }
        },
        onMidiTrackChange: (state, action) => {
            let {
                trackIndex,
                top,
                left,
                vZoom,
                midi,
                horizontalZoom
            } = action.payload;
            if (state.midis && state.midis[midi]) {
                const songLength = midiService.getSongLength(midi);
                state.midis[midi] = { ...state.midis[midi], trackIndex, top, left, vZoom, horizontalZoom }
                state.midis[midi].verticalZoom[0].val = top;
                state.midis[midi].verticalZoom[1].val = top + ((127 - top) * vZoom);
                state.midis[midi].breaks[0].val = left * songLength;
                state.midis[midi].breaks[1].val = left * songLength + (horizontalZoom * songLength);
            }
        },
        updateMidiFileTrackSelection: (state, action) => {
            let { index, value, midi } = action.payload;
            if (state.midis && state.midis[midi]) {
                state.midis[midi].selectedTracks = state.midis[midi].selectedTracks || {};
                state.midis[midi].selectedTracks[index] = value;
            }
        },
        updateMidiFileSelectionLocks: (state, action) => {
            let { key, value, midi } = action.payload;
            if (state.midis && state.midis[midi]) {
                state.midis[midi].locks[key] = value;
            }
        },
        resize: (state, action) => {
            state.resize = state.resize || 0;
            state.resize++;
        },
        chopMidiFile: (state, action) => {
            let { midi, index, notes } = action.payload;
            if (state.midis && state.midis[midi]) {
                let { selections, breaks } = state.midis[midi];
                if (notes) {
                    midiService.createMidiLick(notes, midi, index, state, action.payload.args);
                }
                else {
                    let tracks = midiService.getTracks(midi).filter(track => track && track.notes && track.notes.length);
                    if (tracks) {
                        let track = tracks[index];
                        if (track) {
                            let break_start = Math.min(...breaks.map(v => v.val));
                            let break_end = Math.max(...breaks.map(v => v.val));
                            let selections_start = Math.min(...selections.map(v => v.val)) / 100;
                            let selections_end = Math.max(...selections.map(v => v.val)) / 100;
                            let total_time = break_end - break_start;
                            let start = (total_time * selections_start) + break_start;
                            let end = (total_time * selections_end) + break_start;
                            let notesForChop = track.notes.filter(v => {
                                if ((v.ticks + v.durationTicks) >= start && (v.ticks) < end) {
                                    return true;
                                }
                            });

                            if (notesForChop && notesForChop.length) {
                                midiService.createMidiLick(notesForChop, midi, index, state, state.midis[midi]);
                            }
                        }
                    }
                }
            }
        },
        updateMidiFileSelection: (state, action) => {
            let { midi, breaks } = action.payload;
            if (state.midis && state.midis[midi]) {
                if (state.midis[midi].locks.selections) {
                    breaks = handleBreaks(state.midis[midi].selections, breaks);
                }
                state.midis[midi].selections = breaks;
            }
        },
        updateMidiFileVerticalZoom: (state, action) => {
            let { midi, breaks } = action.payload;
            if (state.midis && state.midis[midi]) {
                if (state.midis[midi].locks.verticalZoom) {
                    breaks = handleBreaks(state.midis[midi].verticalZoom, breaks);
                }
                state.midis[midi].verticalZoom = breaks;
            }
        },
        updateLick: (state, action) => {
            let { key, value } = action.payload;
            if (state.currentLick && state.midiLicks && state.midiLicks[state.currentLick]) {
                state.midiLicks[state.currentLick][key] = value;
                state.midiLicks[state.currentLick].version = state.midiLicks[state.currentLick].version || 0;
                state.midiLicks[state.currentLick].version++;
            }
        },
        updateMidiFileBreaks: (state, action) => {
            let { midi, breaks } = action.payload;
            if (state.midis && state.midis[midi]) {
                if (state.midis[midi].locks.breaks) {
                    breaks = handleBreaks(state.midis[midi].breaks, breaks);
                }
                state.midis[midi].breaks = breaks;
            }
        },
        saveComposerCompanionStorage: (state, action) => {
            let cc_storage = JSON.stringify(state, null, 4)
            var bb = new Blob([cc_storage], { type: 'application/json' });
            FileSaver.saveAs(bb, `${action.payload || 'document'}.cc`);
        },
        newComposerCompanionStorage: (state, action) => {
            for (let i in state) {
                state[i] = initialState[i];
            }
            for (let i in staticState) {
                if (initialState.hasOwnProperty(i))
                    staticState[i] = JSON.parse(JSON.stringify(initialState[i]));
            }
        },
        deleteStoredBank: (state, action) => {
            delete state.bankStorage[state.bankName]
        },
        addProgressionBreak: (state, action) => {
            _addProgressionBreak(state.scenes[state.currentScene]);
        },
        setSelectedChordProgressionType: (state, action) => {
            state.scenes[state.currentScene].chordProgression.gaps.map((gap) => {
                if (gap && gap.value === state.selectedChordProgressionGap) {
                    gap.type = action.payload;
                }
            });

        },
        clearRecording: (state, action) => {
            let currentTrack = state.instruments.findIndex(x => x.id === state.currentInstrument);
            if (state.scenes && state.scenes[state.currentScene] && state.scenes[state.currentScene].tracks
                && state.scenes[state.currentScene].tracks[currentTrack]) {
                state.scenes[state.currentScene].tracks[currentTrack].notes.length = 0;
            }
        },
        setCurrentLick: (state, action) => {
            state.currentLick = action.payload;
        },
        setCurrentMidiFile: (state, action) => {
            state.currentMidiFile = action.payload;
        },
        removeScene: (state, action) => {
            let id = action.payload;

            delete state.scenes[id];
        },
        updateCurrentSceneName: (state, action) => {
            if (state.scenes && state.scenes[state.currentScene]) {
                state.scenes[state.currentScene].name = action.payload;
            }

        },
        addInstrument: (state, action) => {
            addNewInstrument(state);

        },
        removeInstrument: (state, action) => {
            let instrumentIndex = state.instruments.findIndex(v => v.id === action.payload);
            if (instrumentIndex !== -1) {
                state.instruments.splice(instrumentIndex, 1);
                Object.keys(state.scenes).map(v => state.scenes[v].tracks.splice(instrumentIndex, 1));
            }
        },
        chordProgressionPlaying: (state, action) => {
            let { chordIdx } = action.payload;
            state.currentChordIndexPlaying = chordIdx;
        },
        generateMusicStateChange: (state, action) => {

            state.generating_music = !action.payload.complete;
            state.error_generating_music = !!action.payload.error;
        },
        midiEvent: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            playMidiEvent(state, action);

        },
        midiInputs: (state, action) => {
            if (action.payload.state === 'disconnected') {
                delete state.midiInputs[action.payload.id]
            }
            else {
                state.midiInputs[action.payload.id] = action.payload;
            }

        },
        setQuantizeMode: (state, action) => {
            state.quantizeMode = action.payload;
        },
        setQuantizeDurationMode: (state, action) => {
            state.quantizeDurationMode = action.payload;
        },
        setSelectedChordProgressionGap: (state, action) => {
            state.selectedChordProgressionGap = action.payload;
        },
        currentMidiCommand: (state, action) => {
            console.log(action);
            _currentMidiCommand(state, action);
        },
        chordInputClear: (state, action) => {
            state.chordInputValues[action.payload.id] = {};
        },
        replaceChordInput: (state, action) => {
            state.chordInputValues[action.payload.id] = action.payload.value;
            state.chordInputValues['chord-input'] = {
                ...action.payload.value
            };
        },
        chordInputSelect: (state, action) => {
            if (action.payload.clear) {
                state.chordShouldClear[action.payload.id] = true;
            }
            state.chordInputListening[action.payload.id] = action.payload.focused;

        },
        chordInputSet: (state, action) => {
            _chordInputSet(state, action);
        },
        moveChordInput: (state, action) => {
            let { direction, selected } = action.payload;
            if (isNaN(direction)) {

            }
            else if (selected && Object.keys(selected).length && Object.values(selected).filter(x => x).length) {
                let new_chords = {};
                Object.keys(state.chordInputValues['chord-input']).map((v, _index) => {
                    if (selected[_index]) {
                        if (!state.chordInputValues['chord-input'][parseInt(v) + direction]) {
                            new_chords[parseInt(v) + direction] = true;
                        }
                        else {
                            new_chords[parseInt(v)] = true;
                        }
                    }
                    else {
                        new_chords[parseInt(v)] = true;
                    }

                });
                state.chordInputValues['chord-input'] = new_chords;
            }
            else {
                let new_chords = {};
                Object.keys(state.chordInputValues['chord-input']).map((v) => {
                    new_chords[parseInt(v) + direction] = true;
                });
                state.chordInputValues['chord-input'] = new_chords;
            }
        },
        swapForRandom: (state, action) => {
            if (state.chordProgressions && state.currentProgression &&
                state.chordProgressions[state.currentProgression] &&
                state.chordProgressions[state.currentProgression].selectedItem) {
                let selectedItem = state.chordProgressions[state.currentProgression].spaces.find(v => {
                    return v.id === state.chordProgressions[state.currentProgression].selectedItem;
                });
                if (selectedItem) {
                    selectedItem.originalChordData = selectedItem.originalChordData ||
                        JSON.parse(JSON.stringify(selectedItem.chordData));
                    let originalChordData = selectedItem.originalChordData;
                    let res = calculateV(Object.keys(originalChordData.value_voicing || {})
                        .filter(v => originalChordData.value_voicing[v]).map(v => convertToMelodyNote(v)));
                    let matches;
                    if (!CACHE[res.join('-')]) {
                        matches = reader.findMatches(res, [], 0, 1000, {}, false);
                        CACHE[res.join('-')] = matches;
                    }
                    else {
                        matches = CACHE[res.join('-')]
                    }
                    if (matches.length) {
                        let randomMatch = matches[Math.floor(Math.random() * matches.length)];
                        if (randomMatch && randomMatch.smartinfo && randomMatch.smartinfo.voice) {
                            let voice = {};
                            let midiNote = calculateMidi(originalChordData.chordInfo)
                            randomMatch.smartinfo.voice.map(v => { voice[midiNote + v] = true });
                            selectedItem.chordData.value_voicing = { ...voice }
                            selectedItem.chordData.values = { ...voice }
                            let temp = updateChordDataInSelectedItem(selectedItem);
                            console.log(temp);
                        }
                    }
                }
                console.log(JSON.stringify(selectedItem, null, 4));
            }
        },
        resetKey: (state, action) => {

            if (state.chordProgressions && state.currentProgression &&
                state.chordProgressions[state.currentProgression] &&
                state.chordProgressions[state.currentProgression].selectedItem) {
                let selectedItem = state.chordProgressions[state.currentProgression].spaces.find(v => {
                    return v.id === state.chordProgressions[state.currentProgression].selectedItem;
                });
                if (selectedItem && selectedItem.originalChordData) {
                    selectedItem.chordData = JSON.parse(JSON.stringify(selectedItem.originalChordData))
                }
            }

        },
        swapKeys: (state, action) => {

            if (state.chordProgressions && state.currentProgression &&
                state.chordProgressions[state.currentProgression] &&
                state.chordProgressions[state.currentProgression].selectedItem) {
                let selectedItem = state.chordProgressions[state.currentProgression].spaces.find(v => {
                    return v.id === state.chordProgressions[state.currentProgression].selectedItem;
                });
                if (selectedItem) {
                    selectedItem.chordData.value_voicing = { ...state.chordInputValues['chord-input'] }
                    selectedItem.chordData.values = { ...state.chordInputValues['chord-input'] }
                    let temp = updateChordDataInSelectedItem(selectedItem);
                    console.log(temp);
                }
                console.log(JSON.stringify(selectedItem, null, 4));
            }
        },
        resizeItem: (state, action) => {
            let { size } = action.payload;
            if (state.chordProgressions && state.currentProgression &&
                state.chordProgressions[state.currentProgression] &&
                state.chordProgressions[state.currentProgression].selectedItems &&
                state.chordProgressions[state.currentProgression].selectedItems.length) {
                for (let i = 0; i < state.chordProgressions[state.currentProgression].selectedItems.length; i++) {
                    let { spaces } = state.chordProgressions[state.currentProgression];
                    let itemId = state.chordProgressions[state.currentProgression].selectedItems[i];
                    let selectedItem = state.chordProgressions[state.currentProgression].spaces.find(v => {
                        return v.id === itemId;
                    });
                    let selectedIndex = spaces.indexOf(selectedItem);
                    if (selectedItem) {
                        let item = selectedItem;
                        // item.position.x = payload.measureLength * i * CHORD_PROGRESSION_SUB_DIVISIONS;
                        // item.position.y = 1;
                        let oldsize = item.position.w;
                        let newsize = size * CHORD_PROGRESSION_SUB_DIVISIONS;
                        let size_change = newsize - oldsize;
                        for (let i = selectedIndex + 1; i < spaces.length; i++) {
                            if (spaces[i].position.y === selectedItem.position.y) {
                                spaces[i].position.x = spaces[i].position.x + size_change;
                                spaces[i].version = (spaces[i].version || 0) + 1;
                            }
                        }
                        item.version = (item.version || 0) + 1;
                        item.position.w = newsize;
                        // item.position.h = 1;
                        state.chordProgressions[state.currentProgression] = JSON.parse(JSON.stringify(state.chordProgressions[state.currentProgression]));
                    }
                }
                state.chordProgressions[state.currentProgression].spaces = state.chordProgressions[state.currentProgression].spaces.sort((a, b) => {
                    return a.position.x - b.position.x
                });
                let { spaces } = state.chordProgressions[state.currentProgression];
                let end = spaces[spaces.length - 1].position.x + spaces[spaces.length - 1].position.w;
                let newMeasures = Math.ceil(end / CHORD_PROGRESSION_SUB_DIVISIONS);
                state.chordProgressions[state.currentProgression].measures = newMeasures;
            }
        },
        addModifiersToSelectedItems: (state, action) => {
            if (state.chordProgressions && state.currentProgression &&
                state.chordProgressions[state.currentProgression] &&
                state.chordProgressions[state.currentProgression].selectedItems &&
                state.chordProgressions[state.currentProgression].selectedItems.length) {
                for (let i = 0; i < state.chordProgressions[state.currentProgression].selectedItems.length; i++) {
                    let { spaces } = state.chordProgressions[state.currentProgression];
                    let itemId = state.chordProgressions[state.currentProgression].selectedItems[i];
                    let selectedItem = state.chordProgressions[state.currentProgression].spaces.find(v => {
                        return v.id === itemId;
                    });
                    if (selectedItem) {
                        let modifiersInRow = state.chordProgressions[state.currentProgression].spaces.filter(v => {
                            return v.type === 'MODIFIER' && v.position && v.position.y === selectedItem.position.y + 1;
                        });
                        let new_modifier_item = createModifierItem(
                            1,
                            state.currentModifier,
                            state.chordProgressions[state.currentProgression],
                            selectedItem.position.y,
                            0
                        );
                        new_modifier_item.limits = null;
                        new_modifier_item.position = {
                            ...selectedItem.position,
                            y: selectedItem.position.y + 1
                        }
                        let items_in_the_way = modifiersInRow.filter(item => {
                            return modifierService.doItemsOverlap(item, new_modifier_item)
                        });

                        if (items_in_the_way && items_in_the_way.length === 0) {
                            spaces.push(new_modifier_item);
                        }
                    }
                }
            }
        },
        removeMeasureData: (state, action) => {
            let { index, property } = action.payload;
            if (state.chordProgressions && state.currentProgression &&
                state.chordProgressions[state.currentProgression]) {
                let chordProgression = state.chordProgressions[state.currentProgression];
                chordProgression.measures_data = chordProgression.measures_data || {};
                chordProgression.measures_data.chordProgression = chordProgression.measures_data.chordProgression || {};
                delete chordProgression.measures_data.chordProgression[index][property]
            }
        },
        settingSelectedItemChordPosition: (state, action) => {
            let { position } = action.payload;
            if (state.chordProgressions && state.currentProgression &&
                state.chordProgressions[state.currentProgression] &&
                state.chordProgressions[state.currentProgression].selectedItem) {
                let itemId = state.chordProgressions[state.currentProgression].selectedItem;
                let selectedItem = state.chordProgressions[state.currentProgression].spaces.find(v => {
                    return v.id === itemId;
                });
                if (selectedItem) {
                    let chordProgression = state.chordProgressions[state.currentProgression];
                    chordProgression.measures_data = chordProgression.measures_data || {};
                    chordProgression.measures_data.chordProgression = chordProgression.measures_data.chordProgression || {};
                    chordProgression.measures_data.chordProgression[selectedItem.position.x] = {
                        ...(chordProgression.measures_data.chordProgression[selectedItem.position.x] || {}),
                        position: position
                    };
                }
            }
        },
        selectItemsInRow: (state, action) => {
            if (state.chordProgressions && state.currentProgression &&
                state.chordProgressions[state.currentProgression]) {
                let { spaces } = state.chordProgressions[state.currentProgression];
                let new_selections = spaces.filter(x => x.position.y === ((action.payload.track - 1) * 2 + 1)).sort((a, b) => a.position.x - b.position.x).map(v => v.id);
                state.chordProgressions[state.currentProgression].selectedItems = new_selections;
                state.chordProgressions[state.currentProgression].selectedItem = new_selections[0] || null;
            }
        },
        splitItem: (state, action) => {
            let { size } = action.payload;
            if (state.chordProgressions && state.currentProgression &&
                state.chordProgressions[state.currentProgression] &&
                state.chordProgressions[state.currentProgression].selectedItems &&
                state.chordProgressions[state.currentProgression].selectedItems.length) {
                let previous_widths = 0;
                for (let i = 0; i < state.chordProgressions[state.currentProgression].selectedItems.length; i++) {
                    let { spaces } = state.chordProgressions[state.currentProgression];
                    let itemId = state.chordProgressions[state.currentProgression].selectedItems[i];
                    let selectedItem = state.chordProgressions[state.currentProgression].spaces.find(v => {
                        return v.id === itemId;
                    });
                    let selectedIndex = spaces.indexOf(selectedItem);
                    let oldsize = selectedItem.position.w;
                    let newsize = oldsize / size;
                    for (let j = 0; j < size; j++) {
                        let item = JSON.parse(JSON.stringify(selectedItem));
                        item.id = uuidv4();
                        item.position.x = selectedItem.position.x + (newsize * j) + (previous_widths);
                        item.version = (selectedItem.version || 0) + 1;
                        item.position.w = Math.floor(newsize);
                        spaces.push(item);
                    }

                    spaces.splice(selectedIndex, 1);


                    // item.position.h = 1;
                    state.chordProgressions[state.currentProgression] = JSON.parse(JSON.stringify(state.chordProgressions[state.currentProgression]));
                    state.chordProgressions[state.currentProgression].spaces = state.chordProgressions[state.currentProgression].spaces.sort((a, b) => {
                        return a.position.x - b.position.x
                    });
                }
                let { spaces } = state.chordProgressions[state.currentProgression];
                let end = spaces[spaces.length - 1].position.x + spaces[spaces.length - 1].position.w;
                let newMeasures = Math.ceil(end / CHORD_PROGRESSION_SUB_DIVISIONS);
                state.chordProgressions[state.currentProgression].measures = newMeasures;
            }
        },
        updateTempo: (state, action) => {
            if (!isNaN(action.payload)) {
                state.tempo = action.payload;
                staticState.tempo = state.tempo;
            }
        },
        toggleRecord: (state, action) => {
            state.recording = !state.recording;
            staticState.recording = state.recording;
            if (staticState.recording) {
                state.playing = true;
                timerService.play(state.playing)
                staticState.playing = true;
                timerService.record();
            }
        },
        toggleChordMatch: (state, action) => {
            state.chordMatches[action.payload.id] = !!!state.chordMatches[action.payload.id];
        },
        toggleScaleMatch: (state, action) => {
            state.scaleMatches[action.payload.id] = !!!state.scaleMatches[action.payload.id];
            state.chordMatches = {};
        },
        createModifier: (state, action) => {
            state.modifiers = state.modifiers || {};
            createNewModifier(state);
        },
        selectCurrentModifier: (state, action) => {
            if (state.modifiers && state.modifiers[action.payload.id]) {
                if (state.currentModifier === action.payload.id) {
                    state.currentModifier = null;
                }
                else {
                    state.currentModifier = action.payload.id;
                }
            }
            else {
                state.currentModifier = null;
            }
        },
        toggleSelectTrack: (state, action) => {
            if (state.currentProgression && state.chordProgressions && state.chordProgressions[state.currentProgression]) {
                if (action.payload.track) {
                    state.selectedTrackNumber = action.payload.track
                }
            }
        },
        pasteToTrack: (state, action) => {
            if (state.currentProgression && state.chordProgressions && state.chordProgressions[state.currentProgression]) {
                if (action.payload.track) {
                    switch (action.payload.type) {
                        case 'track':
                            if (state.clipboard.copyTrack) {
                                let { spaces } = state.chordProgressions[state.currentProgression];
                                let items = spaces.filter((item) => item.position.track === action.payload.track);
                                let max_x = Math.max(items.map(x => x.position.w + x.position.x))
                                let header_buffer = 1;
                                let y = (action.payload.track - 1) * 2 + header_buffer;
                                let new_items = state.clipboard.copyTrack.map(x => {
                                    x.position.y = y;
                                    x.position.x = x.position.x + max_x;
                                    x.id = `item-${uuidv4()}`
                                    x.limits = null;
                                    // {
                                    //     y: {
                                    //         max: (action.payload.track - 1) * 2 + header_buffer,
                                    //         min: (action.payload.track - 1) * 2 + header_buffer
                                    //     }
                                    // }
                                    return x;
                                })
                                state.chordProgressions[state.currentProgression].measures = Math.max(
                                    state.chordProgressions[state.currentProgression].measures,
                                    new_items.length
                                );
                                spaces.push(...new_items);
                                state.clipboard.copyTrack = null;
                                state.selectedTrackNumber = null;
                            }
                            break;
                    }
                }
            }
        },
        copyTrackToClipboard: (state, action) => {
            if (state.currentProgression && state.chordProgressions && state.chordProgressions[state.currentProgression]) {
                if (action.payload.track) {
                    let { spaces } = state.chordProgressions[state.currentProgression];
                    let track_y = convertToTrackY(action.payload.track);
                    let items = spaces.filter((item) => item.position.y === track_y);
                    state.clipboard = {
                        ...(state.clipboard || {}),
                        copyTrack: JSON.parse(JSON.stringify(items.map(x => {
                            x.id = `item-${uuidv4()}`
                            return x;
                        })))
                    }
                }
            }
        },
        addAndSelectModifier: (state, action) => {
            let modifier = action.payload;
            if (modifier) {
                if (modifier.originalId) {
                    delete state.modifiers[modifier.originalId];
                }
                state.modifiers[modifier.id] = JSON.parse(JSON.stringify(modifier));
                state.currentModifier = modifier.id;
            }
        },
        addAndSelectLicc: (state, action) => {
            let midiLick = action.payload;
            if (midiLick) {
                if (midiLick.originalId) {
                    delete state.midiLicks[midiLick.originalId];
                }
                state.midiLicks[midiLick.id] = JSON.parse(JSON.stringify(midiLick));
                state.currentLick = midiLick.id;
            }
        },
        addCurrentModifierToChordProgression: (state, action) => {
            if (state.currentProgression && state.chordProgressions && state.chordProgressions[state.currentProgression]) {
                if (action.payload.track) {
                    let { spaces } = state.chordProgressions[state.currentProgression];
                    let items = spaces.filter((item) => item.position.track === action.payload.track);
                    let header_buffer = 1;
                    let item = createModifierItem(
                        header_buffer,
                        state.currentModifier,
                        state.chordProgressions[state.currentProgression],
                        action.payload.track,
                        items.length
                    );
                    spaces.push(item);
                }
            }
        },
        toggleBias: (state, action) => {
            state.bias = !state.bias;
        },
        buildChordOptions: (state) => {
            let selectedMatches = reader.getRows(state.chordMatches);
            state.onDeckPallettes = selectedMatches.map(v => buildChordPallette(state, { payload: { match: v.smartinfo } })).flat();
        },
        buildChordOption: (state, action) => {
            state.onDeckPallettes = buildChordPallette(state, action)
        },
        initialize: (state, action) => {

            timerService.init();
        },
        onAddToBank: (state, action) => {
            if (state.targetBankKey !== null) {
                switch (state.bankAccessMode) {
                    case BANK_ACCESS_MODES.MIDI:
                        for (let i = 1; i < 128; i++) {
                            if (Math.abs(state.targetBankKey - i) % 12 === 0) {
                                state.banks[i] = action.payload;
                            }
                        }
                        break;
                    case BANK_ACCESS_MODES.NOTE:
                        state.banks[state.targetBankKey] = action.payload;
                        break;
                }

            }
            else {
                state.nextBank = action.payload;
            }
        },
        applyNextBankToChordProgression: (state, action) => {
            console.log(action.payload);
            let { segment } = action.payload;
            let gap = state.scenes[state.currentScene].chordProgression.gaps.find(v => v.value === segment);
            if (gap) {
                gap.bank = state.nextBank;
                console.log(segment);
                console.log(JSON.parse(JSON.stringify(state.nextBank)));
            }
            state.nextBank = null;
        },
        setGapBank: (state, action) => {
            let { segment, bank } = action.payload;
            let gap = state.scenes[state.currentScene].chordProgression.gaps.find(v => v.value === segment);
            if (gap) {
                gap.bank = bank;
                console.log(segment);
                console.log(JSON.parse(JSON.stringify(state.nextBank)));
            }
        },
        setTargetBankKey: (state, action) => {
            state.targetBankKey = action.payload;
        },
        play: (state, action) => {
            console.log(action);
            let { payload } = action;
            let { value, play } = payload;
            playSound(value, play, 1, null, null, state);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PlayMagentaNotesAsnyc.pending, (state, action) => {
            state.music_playing = true;
        })
        builder.addCase(PlayMagentaNotesAsnyc.fulfilled, (state, action) => {
            state.music_playing = false;
            state.currentChordIndexPlaying = null;
        });
        builder.addCase(PlayMagentaNotesAsnyc.rejected, (state, action) => {
            state.music_playing = false;
            state.currentChordIndexPlaying = null;
        });
        builder.addCase(GetSequenceAsMidi.pending, (state, action) => {
            state.getting_sequence_as_midi = true;
        });
        builder.addCase(GetSequenceAsMidi.fulfilled, (state, action) => {
            let midi = action.payload;
            state.getting_sequence_as_midi = true;
            let name = nameService.getSongName();
            midiService.loadMidi(name, midi);
            loadMidiFileHelper({ payload: { name } }, state)
        });
        builder.addCase(PlayMagentaMusicAsync.pending, (state, action) => {
            state.music_playing = true;
        });
        builder.addCase(PlayMagentaMusicAsync.fulfilled, (state, action) => {
            state.music_playing = action.payload;
            if (!action.payload) {
                state.currentChordIndexPlaying = null;
            }
        });
        builder.addCase(LoadMagentaAsync.pending, (state, action) => {
            state.loading_magenta = true;
        })
        builder.addCase(StopMagentaPlayerAsync.fulfilled, (state, action) => {
            state.music_playing = false;
            state.currentChordIndexPlaying = null;
        });

        builder.addCase(LoadMagentaAsync.fulfilled, (state, action) => {
            state.loading_magenta = false;
            state.magenta_loaded = true;
            state.loading_magenta_failed = false;
            appServices.magentService = action.payload;
        });
        builder.addCase(LoadMagentaAsync.rejected, (state, action) => {
            state.loading_magenta = false;
            state.loading_magenta_failed = true;
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(GenerateMusicAsync.pending, (state, action) => {
            // Add user to the state array
        });

        builder.addCase(GenerateMusicAsync.fulfilled, (state, action) => {
            // Add user to the state array
        });
        builder.addCase(AddPatternAsync.fulfilled, (state, action) => {
            if (action.payload) {
                state.failed.adding_pattern = false;
            }
            else {
                state.failed = state.failed || {};
                state.failed.adding_pattern = true;
            }
        });

    }
});

function convertToTrackY(track) {
    return (track * 2) - 1;
}

function createModifierItem(header_buffer, currentModifier, progression, track, offset_x) {
    let item = createNewSpaceItem({
        chordData: null,
        offset: { x: offset_x, y: 0 }
    }, progression);
    item.type = GridTypes.MODIFIER;
    item.modifierId = currentModifier;
    item.position.y = (track - 1) * 2 + 1 + header_buffer;
    item.limits = {
        y: {
            max: (track - 1) * 2 + 1 + header_buffer,
            min: (track - 1) * 2 + 1 + header_buffer
        }
    };
    return item;
}

function updateChordDataInSelectedItem(selectedItem) {
    let midiNotes = Object.keys(selectedItem.chordData.values);
    let v_temp = midiNotes.map((v, index) => {
        return `${(parseInt(v) - parseInt(midiNotes[0])).toString(12)}`;
    });
    let temp = FindExactMatch(v_temp, []);
    let melodyNote = convertToMelodyNote(midiNotes[0]);
    selectedItem.chordData.value_letter = melodyNote;
    selectedItem.chordData.voicing_letter = melodyNote;
    if (temp.length) {
        selectedItem.chordData.chordInfo = {
            ...temp[0].smartinfo,
            name: melodyNote.key + melodyNote.accidental + temp[0].smartinfo.name
        };
        selectedItem.chordData.options = temp;
        selectedItem.chordData.droppedNotes = [];
    }
    else {
        selectedItem.chordData.options = null;
        selectedItem.chordData.unknown = true;
        selectedItem.chordData.droppedNotes = [];
        selectedItem.chordData.chordInfo = {
            name: melodyNote.key + melodyNote.accidental + v_temp.filter((_, i) => i > 0).map(v => `+${v}`).join('')
        };
    }
    return temp;
}

function loadMidiFileHelper(action, state) {
    let { name } = action.payload;
    state.midis[name] = {
        locks: {
            breaks: false,
            selections: false,
            verticalZoom: false
        },
        breaks: [
            { val: 0, lbl: 'Start' },
            { val: 100, lbl: 'End' }
        ],
        selections: [
            { val: 45, lbl: 'Start' },
            { val: 55, lbl: 'End' }
        ],
        verticalZoom: [
            { val: 0, lbl: 'Start' },
            { val: 127, lbl: 'End' }
        ]
    };
}

function ensureChordProgression(state) {
    if (!state.currentProgression) {
        state.currentProgression = `cp-${uuidv4()}`;
    }
    if (!state.chordProgressions) {
        state.chordProgressions = {};
    }
    if (!state.chordProgressions[state.currentProgression]) {
        state.chordProgressions[state.currentProgression] = createChordProgression(state.currentProgression);
    }
}

function _currentMidiCommand(state, action) {
    state.currentMidiCommand[action.payload.command.note] = action.payload;
    Object.keys(state.chordInputListening).map((inputId) => {
        if (state.chordInputListening[inputId]) {
            state.chordInputValues[inputId] = state.chordInputValues[inputId] || {};
            if (state.chordShouldClear[inputId]) {
                let newval = state.chordInputValues[inputId][action.payload.command.note] =
                    action.payload.command.command === 8 ? state.chordInputValues[inputId][action.payload.command.note] : !!!state.chordInputValues[inputId][action.payload.command.note];
                state.chordInputValues[inputId] = { [action.payload.command.note]: newval };
            }
            else {
                if (action.payload.override) {
                    state.chordInputValues[inputId][action.payload.command.note] = action.payload.command.value;
                }
                else {
                    state.chordInputValues[inputId][action.payload.command.note] = action.payload.command.command === 8 ? state.chordInputValues[inputId][action.payload.command.note] : !!!state.chordInputValues[inputId][action.payload.command.note];
                }
            }
        }
    });
}

function getNotesToPlay(state, message) {
    let duration = getSceneDuration();
    let instrumentIndex = state && state.instruments ? state.instruments.findIndex(v => v.id === state.currentInstrument) : -1;
    let track = state.recording && state.scenes[state.currentScene] ? state.scenes[state.currentScene].tracks[instrumentIndex] : null;
    if (state.playing && state.scenes[state.currentScene]) {
        let { chordProgression } = state.scenes[state.currentScene]
        if (chordProgression) {
            let { gaps, breaks } = chordProgression;
            if (breaks && gaps) {
                let { time, start } = timerService.getAccurateNow();
                let currentMessageTimePercentage = ((time - start) % duration) / duration;
                let sceneLength = state.scenes[staticState.currentScene].length;
                let total = sceneLength * 32;

                breaks = breaks.sort((a, b) => {
                    return a.val - b.val;
                });
                let breakIndex = breaks.findIndex(v => (v.val / total) >= (currentMessageTimePercentage));
                let gap = null;
                if (breakIndex === -1) {
                    gap = gaps[gaps.length - 1];
                }
                else {
                    gap = gaps[breakIndex];
                }
                if (gap) {
                    console.log(JSON.parse(JSON.stringify(gap)));
                    if (gap.type) {
                        switch (gap.type) {
                            case ChordProgressModeValues.PALETTE:
                                if (state.banks && state.banks[message.note]) {
                                    let bankInfo = state.banks[message.note];
                                    return bankInfo;
                                }
                                break;
                            case ChordProgressModeValues.MAP_TO_ALL:
                                if (gap.bank) {
                                    let { notes } = gap.bank;
                                    let temp_notes = Object.keys(notes).filter(v => notes[v]);
                                    let temp_note_index = message.note % temp_notes.length;
                                    let x = temp_notes[temp_note_index];
                                    var octave = Math.floor(message.note / 12) - 1;
                                    var note = message.note % temp_notes.length;
                                    note = parseInt(temp_notes[note], 10) % 12
                                    return {
                                        notes: {
                                            [(octave * 12) + (note)]: true
                                        }
                                    }
                                }
                                break;
                            // case ChordProgressModeValues.MAP_TO_WHITE:

                            //     break;
                            // case ChordProgressModeValues.NEAREST_NOTE:

                            //     break;
                        }
                    }
                }
            }
        }
    }
    else if (!state.playing) {
        if (state.banks && state.banks[message.note]) {
            let bankInfo = state.banks[message.note];
            return bankInfo;
        }
    }
}


function playMidiEvent(state, action) {
    state.notes[action.payload[1]] = action.payload;

    let bankInfo = null;
    let message = parseMidiMessage({ data: action.payload });
    switch (message.command) {
        case 8:
            // playService.play(message.note, 'off', 0);
            bankInfo = getNotesToPlay(state, message);
            if (bankInfo) {
                // let bankInfo = state.banks[message.note];

                playSound(bankInfo.notes, false, 0, message, staticState.recordingBar, state);
                if (state.recording)
                    sortTrackNotes(state);
            }
            else {
                playSound({ [message.note]: true }, false, 0, message, staticState.recordingBar, state);

                if (state.recording)
                    sortTrackNotes(state);
            }
            break;
        case 9:
            bankInfo = getNotesToPlay(state, message);
            if (bankInfo) {
                // let bankInfo = state.banks[message.note];
                playSound(bankInfo.notes, true, 1, message, staticState.recordingBar, state);
                if (state.recording)
                    sortTrackNotes(state);
            }
            else {
                playSound({ [message.note]: true }, true, 1, message, staticState.recordingBar, state);

                if (state.recording)
                    sortTrackNotes(state);
            }
            // playService.play(message.note, 'on', message.velocity * 127);
            if (state.nextBank) {
                state.banks[message.note] = state.nextBank;
                state.nextBank = null;
            }
            break;
    }
}
function directPlayMidiEvents(noteObject) {
    if (!noteObject.on) {
        playService.play(noteObject.note, 'off', noteObject.velocity * 127);
    }
    else {
        playService.play(noteObject.note, 'on', noteObject.velocity * 127);
    }
}
let instrumentNameIndex = 0;
function addNewInstrument(state) {
    let id = `instrument_${instrumentNameIndex++}`;
    state.instruments.push({
        id,
    });
    Object.keys(state.scenes).map(v => state.scenes[v].tracks.push(createTrack()));
    state.currentInstrument = id;
}

function createNewScene(id, state) {
    return {
        id,
        name: id,
        length: 1,
        tracks: [...state.instruments.map(v => createTrack())],
        chordProgression: {
            breaks: [],
            gaps: [{
                name: 'A',
                value: 'A',
                bank: null,
                type: null
            }]
        }
    }
}

function createTrack() {
    return {
        notes: []
    }
}
function playSound(value, play, velocity, message, recordingBar, state) {
    if (value) {
        let duration = getSceneDuration();
        let instrumentIndex = state && state.instruments ? state.instruments.findIndex(v => v.id === state.currentInstrument) : -1;
        let track = state.recording && state.scenes[state.currentScene] ? state.scenes[state.currentScene].tracks[instrumentIndex] : null;

        Object.keys(value).filter(v => value[v]).map(v => {
            playService.play(v, play ? 'on' : 'off', velocity * 127);
            if (recordingBar && track) {
                if (!play && recordingBar[v]) {
                    let sceneLength = state.scenes[state.currentScene].length;

                    let scene_duration = getSceneDuration();
                    let { start } = timerService.getAccurateNow();
                    let time = message.time || getMeasurePlayCurrentTime();
                    let recording_duration = time - recordingBar[v].start;
                    let measure_duration = (recording_duration / scene_duration) * (sceneLength * MEASURE_SUB_DIVISION);
                    recordingBar[v].duration = measure_duration;
                    recordingBar[v].start = (((recordingBar[v].start - start) % scene_duration) / scene_duration) * (sceneLength * MEASURE_SUB_DIVISION);
                    // If the duration is less than zero, the we looped around.

                    if (state.quantizeMode) {
                        let qm = parseFloat(state.quantizeMode);
                        let nearstValue = measure_duration * (qm / sceneLength)
                        let quantizedUnits = Math.round(recordingBar[v].start / nearstValue);
                        recordingBar[v].start = nearstValue * quantizedUnits;
                    }


                    if (recordingBar[v].duration > 0) {
                        if (state.quantizeDurationMode) {
                            let qm = parseFloat(state.quantizeDurationMode);
                            let nearstValue = measure_duration * (qm / sceneLength)
                            let quantizedUnits = Math.round(recordingBar[v].duration / nearstValue);
                            recordingBar[v].duration = nearstValue * quantizedUnits;
                        }
                        track.notes.push(recordingBar[v]);
                    }
                    else {
                        let measure_duration = (recording_duration / scene_duration) * (sceneLength * MEASURE_SUB_DIVISION);

                        recordingBar[v].duration = measure_duration;


                        if (recordingBar[v].duration > 0) {
                            track.notes.push(recordingBar[v]);
                        }
                    }
                    delete recordingBar[v];
                }
                else if (play && !recordingBar[v]) {

                    let time = message.time || getMeasurePlayCurrentTime();
                    recordingBar[v] = {
                        ...message,
                        note: v,
                        start: time
                    };
                }
                else {
                    console.log('playSound - this shouldnt happen');
                }
            }
        })
    }
}
function getChordProgressionAtIndex(sceneObj, index) {
    return sceneObj.chordProgression.gaps[index];
}
function _addProgressionBreak(sceneObj, progressionBreak = 0, type = ChordProgressModeValues.MAP_TO_ALL) {
    let source = 'ABCDEFGHIJKLMNOPQRSTUVWXZ';

    let uniqueName = source.split('').find(v => !sceneObj.chordProgression.gaps.some(b => b.name === v));
    if (uniqueName) {
        let _break = {
            name: uniqueName,
            val: progressionBreak || 0
        };
        sceneObj.chordProgression.breaks.push(_break);
        let gap = {
            name: uniqueName,
            value: uniqueName,
            bank: null,
            type: type || null
        };
        sceneObj.chordProgression.gaps.push(gap);
        return { gap, break: _break };
    }
    return null;
}
const BEATS_PER_MEASURE = 4;
export function getPlayPercentage() {
    if (staticState.scenes && staticState.currentScene && staticState.scenes[staticState.currentScene]) {
        let measureLength = staticState.scenes[staticState.currentScene].length;
        let tempo = staticState.tempo / 60;
        let durationInMinutes = (measureLength * BEATS_PER_MEASURE / tempo);

        return ((getMeasurePlayCurrentTime()) / durationInMinutes) * 100;
    }
    return 0;
}
export function getSceneDurationStatic() {
    if (staticState.scenes && staticState.currentScene && staticState.scenes[staticState.currentScene]) {
        let measureLength = staticState.scenes[staticState.currentScene].length;
        let duration = (measureLength * MEASURE_SUB_DIVISION);
        return duration;
    }
    return 0;
}
export function getSceneDuration() {
    if (staticState.scenes && staticState.currentScene && staticState.scenes[staticState.currentScene]) {
        let measureLength = staticState.scenes[staticState.currentScene].length;
        let tempo = staticState.tempo / 60;
        let duration = (measureLength * BEATS_PER_MEASURE / tempo);
        return duration;
    }
    return 0;
}

export function getDurationForScene(scene) {
    let measureLength = scene.length;
    let tempo = staticState.tempo / 60;
    let duration = (measureLength * BEATS_PER_MEASURE / tempo);
    return duration;

}
export function getBeatDurationInTime(beat, bpm = BEATS_PER_MEASURE) {
    let duration = beat * (MEASURE_SUB_DIVISION / bpm);
    return duration;
}
export function getBeatOffsetInTime(measureIndex, bpm = BEATS_PER_MEASURE) {
    let duration = (measureIndex * (MEASURE_SUB_DIVISION / bpm));
    return duration;

}
export function addChordToTrack(args) {
    let { scene, trackIndex, value_voicing, measureIndex, durationInBeats, pattern } = args;
    let start = getBeatOffsetInTime(measureIndex * BEATS_PER_MEASURE);
    let duration = getBeatDurationInTime(durationInBeats);
    if (scene.tracks[trackIndex]) {
        let value_vocing_keys = Object.keys(value_voicing);
        if (pattern) {
            switch (pattern) {
                case 'arpeggio-up':

                    break;
            }
        }
        else {
            value_vocing_keys.filter(v => value_voicing[v]).map((v) => {

                scene.tracks[trackIndex].notes.push({
                    start,
                    duration,
                    note: parseInt(v, 10),
                    velocity: 1
                })
            })
        }
    }
}
let _outOfBandScenes = [];
export function setOutOfBandScene(scenes) {
    _outOfBandScenes = scenes.filter(v => v.id === staticState.currentScene);
}
let lastCuedNoteTime = null;

export function getChordProgressionMidi(state) {
    let chordProgression = state.chordProgressions[state.currentProgression];
    let lickDic = state.midiLicks;
    let modifiers = state.modifiers
    let res = BuildMidiChordProgression(chordProgression, {
        measureDivisions: 64,
        measures: 8,
        start: null,
        stop: null,
        tempo: state.tempo,
        lickDic,
        modifiers
    });
    return res;
}
export function cueNotes(scheduleAheadTime) {
    let duration = getSceneDuration();
    let notesToCue = [];
    let { time, start } = timerService.getAccurateNow();
    let currentMessageTime = ((time - start) % duration);
    let fullRevolution = Math.floor((time - start) / duration);
    let lastMessageTime = (((time + scheduleAheadTime) - start) % duration);
    let wrappedAroundTime = undefined;
    // if the note measure is wrapping around to the beginning
    if (currentMessageTime > lastMessageTime) {
        // Set the cutoff to the end of the scene.
        wrappedAroundTime = lastMessageTime;
        lastMessageTime = duration;
    }

    // If there has been a note cued before.
    if (lastCuedNoteTime) {
        // If the last cued note is after the lastMessageTime
        // the assume that the time has looped around.
        if (lastCuedNoteTime > lastMessageTime) {
            // set it to the first message;
            lastCuedNoteTime = 0;
        }
    }
    else if (lastCuedNoteTime === null) {
        lastCuedNoteTime = currentMessageTime;
    }
    let nextLastCuedNoteTime = currentMessageTime;
    let wrappedAroundLastCued = undefined;
    let sceneDuration = getSceneDurationStatic();
    let tempoSceneDuration = duration;
    _outOfBandScenes.map(scene => {
        scene.tracks.map((track) => {
            track.notes.map((note) => {

                let note_start = (note.start / sceneDuration) * tempoSceneDuration;
                if (note_start <= lastMessageTime && note_start >= lastCuedNoteTime) {
                    nextLastCuedNoteTime = Math.max(note_start, nextLastCuedNoteTime);
                    notesToCue.push({
                        ...note,
                        start: (fullRevolution * duration) + note_start
                    });
                }
            })
        });
    });
    lastCuedNoteTime = Math.max(lastMessageTime, nextLastCuedNoteTime);

    // if (wrappedAroundLastCued !== undefined) {
    //     lastCuedNoteTime = Math.min(lastMessageTime, wrappedAroundLastCued);
    // }
    return notesToCue;
}
function addNotesToQueue(notes) {
    ENQUEUED_NOTES.push(...notes);
}
function clearNotesInQueue() {
    ENQUEUED_NOTES.length = 0;
}
const ENQUEUED_NOTES = [];
export function enqueueNotePlaying() {
    let temp = [...ENQUEUED_NOTES];
    clearNotesInQueue();
    return temp;
}
export function playOrchestration(note) {
    directPlayMidiEvents(note);
}
// timerService.setCueNotesFunction(cueNotes);
timerService.setCueNotesFunction(enqueueNotePlaying);
timerService.setPlayFunction(playOrchestration);
//The time relative to the begining of the measure.
export function getMeasurePlayCurrentTime() {
    if (staticState.scenes && staticState.currentScene && staticState.scenes[staticState.currentScene] && staticState.playing) {
        let duration = getSceneDuration();

        let { time, start } = timerService.getAccurateNow();
        return ((time - start) % duration);
    }
    return 0;
}

export const selectCurrentMidiFile = (state) => {
    return state.composer.currentMidiFile || '';
}
function buildChordPallette(state, action) {
    let { match } = action.payload;
    try {
        let baseNote = JSON.parse(JSON.stringify(selectChordInputValue({ composer: state }, 'chord-input')));
        let selectedScales = JSON.parse(JSON.stringify(selectScaleMatches({ composer: state })));
        selectedScales = Object.keys(selectedScales).filter(x => selectedScales[x]);
        baseNote = Object.keys(baseNote).filter(x => baseNote[x]);
        if (baseNote.length) {
            baseNote = parseInt(baseNote[0]);
            let voice = match.voice;
            let melodylist = voice.map(v => convertToMelodyNote(baseNote + v, state.bias));
            return selectedScales.map(selectedScale => {
                let chordscale = BuildChordScale(voice, selectedScale, melodylist, baseNote, state.bias);
                chordscale.chord = match;
                return chordscale;
            });
        }
    } catch (e) { }
    return []
}
playService.init();
export const {
    removeBankItem,
    moveTones,
    toggleSelectedChordProgression,
    addAndSelectChordProgression,
    deleteChordProgression,
    addNewChordInstance,
    toggleStaticPositionOnChordInChordProgression,
    selectItemsInRow,
    splitItem,
    settingSelectedItemChordPosition,
    addModifiersToSelectedItems,
    resizeItem,
    onModifierItemClicked,
    setTrackProperty,
    addModifierType,
    onChordProgressionItemClick,
    selectChordProgressionItems,
    updateChordProgressionPositions,
    playChordProgression,
    stopPlay,
    updateModifierItemPosition,
    updateModifierItem,
    removeChordFromChordProgression,
    deleteSelectedItems,
    removeModifierClick,
    updateModifier,
    updateChordProgressionsMeasures,
    updateChordProgressionsName,
    buildSceneProgressions,
    updateBankName,
    exportToSerato,
    updateChordProgressionBreaks,
    addProgressionBreak,
    updateOptionFilter,
    removeCurrentScene,
    setQuantizeMode,
    saveComposerCompanionStorage,
    newComposerCompanionStorage,
    clearRecording,
    updateSceneMeasureLength,
    updateTempo,
    swapKeys,
    swapForRandom,
    resetKey,
    onAddToBank,
    addNewScene,
    addInstrument,
    setCurrentInstrument,
    setCurrentScene,
    initialize,
    removeMeasureData,
    generateMusic,
    playGeneratedMusic,
    removeScene,
    removeInstrument,
    setSelectedChordProgressionGap,
    updateCurrentSceneName,
    setSelectedChordProgressionType,
    setTargetBankKey,
    midiEvent,
    chordProgressionPlaying,
    generateMusicStateChange,
    loadMagenta,
    loadMidiFile,
    loadComposerCompanionStorage,
    setGapBank,
    applyNextBankToChordProgression,
    toggleChordMatch,
    toggleScaleMatch,
    selectCurrentModifier,
    addCurrentModifierToChordProgression,
    addAndSelectModifier,
    addAndSelectLicc,
    toggleSelectTrack,
    copyTrackToClipboard,
    pasteToTrack,
    createModifier,
    play,
    midiInputs,
    replaceChordInput,
    currentMidiCommand,
    toggleBias,
    buildChordOption,
    buildChordOptions,
    chordInputClear,
    chordInputSelect,
    setCurrentMidiFile,
    setCurrentLick,
    chordInputSet,
    moveChordInput,
    setQuantizeDurationMode,
    updateMidiFileBreaks,
    updateLick,
    updateMidiFileSelection,
    chopMidiFile,
    resize,
    updateMidiFileSelectionLocks,
    updateMidiFileTrackSelection,
    onMidiTrackChange,
    setMidiFileBreaks,
    updateMidiFileVerticalZoom,
    toggleProjectPlay,
    toggleRecord
} = composerSlice.actions;

export const selectNotes = (state) => state.composer.notes;
export const selectMidiInputs = (state) => {
    return Object.values(state.composer.midiInputs)
}
export const selectTargetBankKey = (state) => {
    return state.composer.targetBankKey;
}
export const selectCurrentChordProgression = (state) => {
    return state.composer.chordProgressions && state.composer.currentProgression ? state.composer.chordProgressions[state.composer.currentProgression] : null;
}
export const selectedCurrentModifier = (state) => {
    return state.composer.modifiers && state.composer.currentModifier ? state.composer.modifiers[state.composer.currentModifier] : null;
}
export const getModifierDics = (state) => {
    return state.composer.modifiers
}
export const selectCurrentChordProgressionChord = (state) => {
    let progression = state.composer.chordProgressions && state.composer.currentProgression ? state.composer.chordProgressions[state.composer.currentProgression] : null;;
    if (state.composer.currentProgression) {
        if (progression) {
            let selectedItem = state.composer.chordProgressions[state.composer.currentProgression].selectedItem;
            if (selectedItem) {
                return progression.spaces.find(v => v.id === selectedItem) || null;
            }
        }
    }
    return null;
}
export const selectMidiFileName = (state) => {
    return Object.keys(state.composer.midis).filter(key => state.composer.midis[key]);
}
export const getMidiFileData = (state) => (name) => {
    return state.composer.midis[name];
}
export const getSelectedMidiTracks = (midi) => (state) => {
    return state.composer && state.composer.midis && state.composer.midis[midi] && state.composer.midis[midi].selectedTracks;
}
export const getCurrentChordPlaying = (state) => {
    return state.composer.currentChordIndexPlaying;
}
export const getMagentaService = () => {
    return appServices.magentService
}

export const selectBanks = (state) => {
    return state.composer.banks;
}
export const getSelectedTrackNumber = (state) => {
    return state.composer.selectedTrackNumber;
}
export const getClipBoard = (state) => {
    return state.composer.clipboard;
}
export const getChordProgressionSelectedItem = (state) => {
    if (state.composer.currentProgression && state.composer.chordProgressions && state.composer.chordProgressions[state.composer.currentProgression]) {
        return state.composer.chordProgressions[state.composer.currentProgression].selectedItem;
    }
    return null;
}

export const getChordProgressionSelectedItems = (state) => {
    if (state.composer.currentProgression && state.composer.chordProgressions && state.composer.chordProgressions[state.composer.currentProgression]) {
        return state.composer.chordProgressions[state.composer.currentProgression].selectedItems || [];
    }
    return null;
}

export const getModifierName = (id) => (state) => {
    if (state.composer.modifiers && state.composer.modifiers[id]) {
        return state.composer.modifiers[id].name
    }
    return '';
}
export const getIsRecording = (state) => {
    return state.composer.recording;
}

export const getSelectedChordProgressionGap = (state) => {
    return state.composer.selectedChordProgressionGap;
}
export const getSceneLength = (state) => {
    if (state.composer.scenes &&
        state.composer.currentScene &&
        state.composer.scenes[state.composer.currentScene])
        return state.composer.scenes[state.composer.currentScene].length;
    return 0;
}
export const getQuantizeMode = (state) => {
    return state.composer.quantizeMode;
}
export const getQuantizeDurationMode = (state) => {
    return state.composer.quantizeDurationMode;
}
export const getTempo = (state) => {
    return state.composer.tempo;
}
export const getOptionFilter = (state) => {
    return state.composer.optionFilter
}
export const getSelectedScene = (state) => {
    return state.composer.currentScene;
}
export const getSelectedChordProgressionBreak = (state) => {
    return state.composer.selectedChordProgressionBreak;
}
export const getCurrentScene = (state) => {
    if (state.composer.scenes)
        return state.composer.scenes[state.composer.currentScene];
    return null;
}
export const isMusicPlaying = (state) => {
    return state.composer.music_playing;
}
export const isMagentaLoading = (state) => {
    return state.composer.loading_magenta
}
export const isMagentaLoaded = (state) => {
    return state.composer.magenta_loaded;
}
export const isMagentaGenerating = (state) => {
    return state.composer.generating_music;
}
export const anErrorOccuredDuringGeneration = (state) => {
    return state.composer.error_generating_music;
}
export const getAllScenes = (state) => {
    if (state.composer && state.composer.scenes)
        return state.composer.scenes;
    return {};
}
export const getCurrentInstrument = (state) => {
    return state.composer.currentInstrument;
}
export const getInstruments = (state) => {
    return state.composer.instruments;
}
export const selectBankName = (state) => {
    return state.composer.bankName;
}
function sortTrackNotes(state) {
    if (state.scenes && state.currentScene && state.scenes[state.currentScene] && state.scenes[state.currentScene].tracks && state.scenes[state.currentScene].tracks[state.currentInstrument])
        state.scenes[state.currentScene].tracks[state.currentInstrument] = state.scenes[state.currentScene].tracks[state.currentInstrument].sort((a, v) => { return a.start - v.start });
}
export const getCurrentSelectedInstrumentIndex = (state) => {
    let currentInstrument = state.composer.currentInstrument;
    if (state.composer.instruments) {
        return state.composer.instruments.findIndex(v => v.id === currentInstrument);
    }
    return -1;
}
export const selectCurrentTrack = (state) => {
    let currentInstrument = state.composer.currentInstrument;
    if (state.composer.instruments) {
        let currentInstrumentIndex = state.composer.instruments.findIndex(v => v.id === currentInstrument);
        let currentScene = state.composer.currentScene;
        if (state.composer.scenes) {
            let scene = state.composer.scenes[currentScene];
            if (scene && scene.tracks) {
                return scene.tracks[currentInstrumentIndex];
            }
        }
    }
    return null;
}
export const getChordProgrssions = (state) => {
    return [...ChordProgressions]
}
export const selectNextBank = (state) => {
    return state.composer.nextBank;
}
export const selectChordInputListening = (state, id) => {
    return !!state.composer.chordInputListening[id];
}
export const selectChordInputValue = (state, id) => {
    return state.composer.chordInputValues[id];
}
export const selectChordMatches = (state) => {
    return state.composer.chordMatches;
}
export const selectChordProgressions = (state) => {
    return Object.values(state.composer.chordProgressions || {})
}
export const getCurrentChordProgression = (state) => {
    return state.composer.currentProgression
}
export const selectScaleMatches = (state) => {
    return state.composer.scaleMatches;
}
export const getModifierLib = (state) => {
    return state.composer.modifiers;
}
export const getModifiers = (state) => {
    return Object.values(state.composer.modifiers || {})
}
export const getSelectedModifier = (state) => {
    return state.composer.currentModifier
}
export const getCanSplitModifier = (state) => {
    return modifierService.canSplitModifier(state.composer);
}
export const getMidiLicks = (state) => {
    return Object.values(state.composer.midiLicks);
}
export const getMidiLickDict = (state) => {
    return (state.composer.midiLicks);
}
export const getComposerState = (state) => {
    return state.composer;
}
export const getCurrentLick = (state) => {
    return state.composer.currentLick
}
export const getCurrentLickObj = (state) => {
    return state.composer.currentLick && state.composer.midiLicks[state.composer.currentLick];
}
export const getAddAModifier = (state) => {
    return modifierService.canAddModifier(state.composer);
}
export const getDeepestModifers = (state) => {
    return modifierService.getDeepestModifers(state.composer);
}
export const getAddTonalModifier = (state) => {
    return modifierService.canAddTonalModifier(state.composer);
}
export const getSelectedModifierItem = (state) => {
    return state.composer.currentModifier && state.composer.modifiers && state.composer.modifiers[state.composer.currentModifier] && state.composer.modifiers[state.composer.currentModifier].selectedItem
}
export const selectCurrentMidiCommand = (state) => {
    return (state.composer.currentMidiCommand)
}
export const selectScenes = (state) => {
    return state.composer.scenes;
}
export const selectBias = (state) => {
    return state.composer.bias;
}
export const getIsPlaying = (state) => {
    return state.composer.playing
}
export const selectOnDeckPallette = (state) => {
    return state.composer.onDeckPallettes;
}
export default composerSlice.reducer;