import React from 'react';
import ChordMaster from '../../base/chord-master';
import { TitlesService } from '../../title-service';
import SidebarMenuItem from '../main/SideBarMenuItem';


export default class ModifierList extends React.Component {
    reader = null;
    constructor(props) {
        super(props);
        this.state = { currentpage: 0, pagingsize: 10000 };
    }
    render() {
        const { selected, cloud_modifiers, modifiers } = this.props;
        return (
            <ul className="nav nav-pills nav-sidebar flex-column">
                <SidebarMenuItem
                    title={`${TitlesService('Local Modifiers')} (${(modifiers || []).length})`}
                    open={this.state.local_modifiers} onClick={() => {
                        this.setState({ local_modifiers: !this.state.local_modifiers });
                    }}>
                    {(modifiers || []).map((modifier, index) => {
                        let temp = new Date((modifier.updated ? modifier.updated.seconds : (modifier.created ? modifier.created.seconds : (Date.now() / 1000))) * 1000);
                        let moment = window.moment;
                        let timing = moment(temp).fromNow();
                        return (<SidebarMenuItem
                            key={'modifier' + index}
                            active={modifier.id === selected}
                            title={modifier.name} itemTitle={timing}
                            icon={`nav-icon fas fa-puzzle-piece`}
                            onClick={() => {
                                if (this.props.onSelectToggle) {
                                    this.props.onSelectToggle(modifier.id);
                                }
                            }} />)
                    })}
                </SidebarMenuItem>
                <SidebarMenuItem
                    title={`${TitlesService('Saved Modifiers')} (${(cloud_modifiers || []).length})`}
                    open={this.state.cloud_modifiers} onClick={() => {
                        this.setState({ cloud_modifiers: !this.state.cloud_modifiers });
                    }}>
                    {(cloud_modifiers || []).map((modifier, index) => {
                        let temp = new Date((modifier.updated ? modifier.updated.seconds : (modifier.created ? modifier.created.seconds : (Date.now() / 1000))) * 1000);
                        let moment = window.moment;
                        let timing = moment(temp).fromNow();
                        return (<SidebarMenuItem
                            key={'cloud-modifier' + index}
                            active={modifier.id === selected}
                            title={modifier.name}
                            itemTitle={timing}
                            icon={`nav-icon fas fa-puzzle-piece`}
                            onClick={() => {
                                let alreadySet = modifiers.find(x => x.id === modifier.id);
                                if (alreadySet) {
                                    if (window.confirm(TitlesService(`YouwillLoseChangesToModifier`))) {
                                        if (this.props.onAddAndSelect) {
                                            this.props.onAddAndSelect(modifier)
                                        }
                                    }
                                }
                                else {
                                    if (this.props.onAddAndSelect) {
                                        this.props.onAddAndSelect(modifier)
                                    }
                                }
                            }} />)
                    })}
                </SidebarMenuItem>
            </ul>
        )
    }
}
class OptionListItem extends React.Component {
    render() {
        let { selected } = this.props;
        let { name } = this.props.modifier;
        return (
            <tr onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick();
                }
            }}>
                <td>{name}</td>
                <td><input type="checkbox" disabled {...(selected ? { checked: true } : { checked: false })} /></td>
            </tr>
        )
    }
}