import React from 'react';
import NoteType from '../../components/music/NoteType';
import { getSceneDuration, getSceneDurationStatic } from './composerSlice';
import styles from './MidiNotes.module.css';
const PADDING_LEFT = 137;
export default class MidiNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { low: true };
    }
    buildProgressionOverlay(chordProgression) {
        if (chordProgression) {
            let { gaps, breaks } = chordProgression;
            if (gaps && breaks && breaks.length) {
                let orderedBreaks = breaks.map(v => v)
                let max = this.props.sceneLength * 32;
                let min = 1;
                let total = max - min;
                orderedBreaks = orderedBreaks.sort((b, a) => b.val - a.val);
                return (
                    <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            paddingLeft: PADDING_LEFT,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            zIndex: 10//this.state.low ? 0 : 10
                        }} className={`${styles.timeKeeper}`}>
                            {gaps.map((_, i) => {
                                let width;

                                let progressionBreak = chordProgression && chordProgression.gaps ? chordProgression.gaps.find(v => v.name === _.name) : null;
                                let chordName = '';
                                if (progressionBreak) {
                                    let { bank } = progressionBreak;
                                    if (bank) {
                                        let { chord, noteLetter } = bank;
                                        if (chord && noteLetter) {
                                            chordName = <NoteType fontSize={50} title={`${chord.userMade ? '' : noteLetter.key}${chord.userMade ? '' : noteLetter.accidental} ${chord.name}`} />;
                                        }
                                    }
                                }

                                if (i === 0) {
                                    width = (orderedBreaks[i].val - 1) / total;
                                }
                                else if (i === gaps.length - 1) {
                                    width = (total - orderedBreaks[i - 1].val) / total;
                                }
                                else {
                                    width = (orderedBreaks[i].val - orderedBreaks[i - 1].val) / total;
                                }
                                return (
                                    <div className={`${styles.chordProgressionStrips}`}
                                        onDragOver={(ev) => {
                                            this.setState({
                                                low: false
                                            })
                                            if (this.props.onBodyDragOver) {
                                                this.props.onBodyDragOver({ gap: _.name, ev });
                                            }
                                        }} onDragExit={() => {
                                            this.setState({ low: true })
                                        }} onDragEnd={() => {
                                            this.setState({ low: true })
                                        }} onDrop={(ev) => {
                                            console.log(_.name);
                                            if (this.props.onBodyDrop) {
                                                this.props.onBodyDrop({ gap: _.name, ev });
                                            }
                                            this.setState({
                                                low: true
                                            })
                                        }} key={`gap-${i}`} style={{
                                            height: '100%',
                                            position: 'relative',
                                            width: `${width * 100}%`
                                        }}>
                                        <div style={{ position: 'sticky', top: 100, left: 0 }}><h1>{_.name}</h1> {chordName}</div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                )
            }
        }
        return null;
    }
    render() {
        let notes = [];
        let trackNotes = {};
        if (this.props.track) {

            (this.props.track.notes || []).map(t => {
                trackNotes[t.note] = trackNotes[t.note] || [];
                trackNotes[t.note].push(t);
            });
        }
        for (let i = 0; i < 128; i++) {
            if (this.props.notes && this.props.notes[i] && isOn(this.props.notes[i].command)) {
                notes.push({ midiId: i, value: true })
            }
            else {
                notes.push({ midiId: i, value: 0 })
            }
        }
        let scene_duration = getSceneDurationStatic();
        let { chordProgression } = this.props;
        let chordProgressionOverlay = this.buildProgressionOverlay(chordProgression);
        if (scene_duration) {
            return (
                <div style={{ position: 'relative' }} >{
                    [1, 2].map((factor) => {
                        return (
                            <div key={`factor-${factor}`} style={{ position: 'absolute', height: '100%', width: '100%' }}>
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    paddingLeft: PADDING_LEFT,
                                    left: 0,
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    pointerEvents: 'none'
                                }} className={`${styles.timeKeeper}`}>
                                    {(new Array((this.props.sceneLength || 1) * (32 / factor)).fill(1)).map((_, i) => {
                                        return (
                                            <div className={`${styles.timeStrips}`} key={`time-keeper-${i}`} style={{
                                                height: '100%',
                                                position: 'relative',
                                                width: `${(1 / ((this.props.sceneLength || 1) * (32 / factor))) * 100}%`
                                            }}>
                                                {(i % 32 === 0) && factor === 1 ? (<div style={{ position: 'sticky', top: 0, left: 0 }}><h1>{(i / 32) + 1}</h1></div>) : ''}
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                        )
                    })
                }
                    {chordProgressionOverlay}
                    <div className={styles.notes}>
                        {notes.map((note, i) => {
                            return <div className={`${styles.whitekey} ${note.value ? styles.notekey : null}`} key={`${this.props.selected ? 'selec' : ''}-${i}midi-id-${note.midiId}`}>
                                <div className={`${styles.whitekeyname}`}>{note.midiId}</div>
                                <div className={styles.notesPlayed}>
                                    <div className={styles.notesPlayedInner}>
                                        {trackNotes && trackNotes[note.midiId] ? trackNotes[note.midiId].map(v => (<div key={`${note.midiId}-${v.duration}=${v.start}`} style={{
                                            position: 'absolute',
                                            backgroundColor: 'red',
                                            height: '100%',
                                            width: `${(v.duration / scene_duration) * 100}%`,
                                            left: `${(v.start / scene_duration) * 100}%`
                                        }}></div>)) : null}
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            );
        }
        return <div></div>
    }
}
export function isPercussionOn(message) {
    // Parse the MIDIMessageEvent.
    const { command, channel, note, velocity } = (message)
    if (command === 11) {
        return true
    }
}
export function isOn(message) {

    // Parse the MIDIMessageEvent.
    const { command, channel, note, velocity } = (message)

    // Stop command.
    // Negative velocity is an upward release rather than a downward press.
    if (command === 8) {
        if (channel === 0) return false;
        return true;
    }

    // Start command.
    else if (command === 9) {
        return true;
    }

    // Knob command.
    else if (command === 11) {
        return false
    }

    // Pitch bend command.
    else if (command === 14) {
        // onPitchBend(velocity)
    }
}
// function onModWheel() { }
// function onPitchBend() { }
