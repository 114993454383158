import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { flat, sharp } from '../../base/base-lib';
import StaffNotes from './StaffNotes';
import { FormGroup } from '../FormGroup';
import { CHORD_PROGRESSION_SUB_DIVISIONS } from '../../features/composer/composerSlice';
import { MODIFIER_VIEW_DIVISIONS } from '../../base/modifier-service';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default class ModifierEditor extends React.Component {
    constructor(props) {
        super(props);

        let loadedLayout = {};

        this.state = {
            layout: {},
            currentLayoutKey: 'lg',
            loadedLayout
        }
    }
    componentDidMount() {

    }
    render() {
        let spaces = [];
        let measureDivisions = CHORD_PROGRESSION_SUB_DIVISIONS;

        let total_divisions = MODIFIER_VIEW_DIVISIONS;
        if (this.props.modifier && this.props.modifier.spaces) {
            spaces.push(...this.props.modifier.spaces.map((d, index) => {
                return (
                    <div key={`${d.id}`} data-grid={{ maxH: 1, ...d.position }}>
                        <ModifierItem data={d}
                            selected={this.props.selectedItem === d.id}
                            deepest={this.props.deepest.some(v => v.id === d.id)}
                            onClick={() => {
                                if (this.props.onItemClick) {
                                    this.props.onItemClick(d.id);
                                }
                            }}
                            onDelete={() => {
                                if (this.props.onDelete) {
                                    if (this.state.loadedLayout) {

                                        this.setState({
                                            loadedLayout: {
                                                ... this.state.loadedLayout,
                                                [this.state.currentLayoutKey]: (this.state.loadedLayout[this.state.currentLayoutKey] || []).filter(v => v.i !== d.id)
                                            }
                                        }, () => {
                                            this.props.onDelete(d.id);
                                        });
                                    }
                                }
                            }}
                            bias={this.props.bias} />
                    </div>
                )
            }));
        }
        return (
            <div className="react-grid-layout-context" style={{ minHeight: 400 }}>
                <section className="content">
                    <ResponsiveGridLayout
                        className="layout"
                        draggableHandle=".drag-item"
                        verticalCompact={true}
                        compactType={'vertical'}
                        preventCollision={true}
                        measureBeforeMount={false}
                        rowHeight={50}
                        onLayoutChange={(currentLayout, allLayouts) => {
                            currentLayout.removeWhere(x => x.i === null);

                            if (this.props.updateModifierLayout) {
                                this.props.updateModifierLayout(currentLayout);
                            }
                        }}
                        onDrop={(layout, item) => {
                            console.log(layout);
                            console.log(item);
                        }}
                        breakpoints={{ lg: 1200 }}
                        cols={{ lg: total_divisions }}>
                        <div style={{ backgroundColor: '#2d2d2d' }} data-grid={{ x: 0, y: 0, w: total_divisions, h: 1, static: true }}>
                            <span className="text"><h1>Chord</h1></span>
                        </div>
                        {spaces}
                    </ResponsiveGridLayout>
                </section>
            </div >
        )
    }
}

class ModifierItem extends React.Component {
    render() {
        let chord_name = '';
        let chordInfoEl = null;
        let { props } = this;
        if (this.props.data) {
            chord_name = this.props.data.name;
        }
        return (
            <div onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick()
                }
            }}
                className={`card direct-chat direct-chat-warning box-header ${props.data.position.static ? '' : ' drag-item'}`}
                style={{
                    display: 'flex',
                    height: '100%',
                    paddingTop: 1,
                    paddingBottom: 1,
                    cursor: 'pointer'
                }}>
                <div className="card-body" title={chord_name} style={{
                    alignItems: 'center',
                    display: 'flex',
                    flex: 1,
                    alignContent: 'center',
                    justifyContent: 'center'
                }} >
                    <i className="fa fa-times" onClick={() => {
                        if (props.onDelete) {
                            props.onDelete();
                        }
                    }} style={{ position: 'absolute', right: 10, top: 0 }} />
                    {this.props.selected ? <i className="fa fa-star" style={{
                        position: 'absolute', left: 10, top: 0, color: '#f14411'
                    }} /> : null}
                    <h3 style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>{chord_name}</h3>
                    {this.props.deepest ? <div style={{
                        position: 'absolute',
                        bottom: 2,
                        width: '100%',
                        height: 4,
                        backgroundColor: 'rgb(241, 68, 17)'
                    }}></div> : null}
                </div>
            </div>
        )
    }
}