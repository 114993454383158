import React from 'react';

export default class Sidebar extends React.Component {

    render() {
        return (
            <div className="sidebar" style={{ overflow: 'auto' }}>
                {this.props.children}
            </div>
        )
    }
}