export class ClipboardService {
    static available = false;
    static checked = false;
    static write(data) {
        return this.init().then(() => {
            return this.writeTo(data);
        })
    }
    static init() {
        if (!this.checked)
            return navigator.permissions.query({ name: "clipboard-write" }).then(result => {
                if (result.state == "granted" || result.state == "prompt") {
                    this.available = true;
                }
            });
        return Promise.resolve();
    }
    static writeTo(data) {
        if (this.available) {
            let blob = new Blob(data, { type: 'audio/midi' });
            return navigator.clipboard.write([
                new window.ClipboardItem({
                    [blob.type]: blob
                })
            ]).then((res) => {
                return true;
            }).catch((args) => {
                console.log(args);
                return false
            })
        }
        return Promise.resolve(false);
    }
}