import { createAsyncThunk } from '@reduxjs/toolkit';
import MagentaService from '../../base/magenta';
import * as firebasefuncs from '../../base/firebase-app';

let service = null;
export const GenerateMusicAsync = createAsyncThunk(
    'composer/generate/music',
    async (params) => {
        let { progression, chords } = params;
        if (progression && progression.length) {
            service.setupProgression(chords, progression);
            return service.generate();
        }
        return null;
    }
)


export const PlayMagentaMusicAsync = createAsyncThunk(
    'composer/play/music',
    async () => {
        service.togglePlaying();
        return service.playing;
    }
)

export const PlayMagentaNotesAsnyc = createAsyncThunk(
    'composer/play/magenta/notes',
    async ({ midi, selected_tracks, tracks, track_selection }) => {
        await service.playComposerProgression(midi, selected_tracks, tracks, track_selection)
    }
)

export const GetSequenceAsMidi = createAsyncThunk(
    'composer/get/sequence/as/midi',
    async () => {
        return await service.getSequenceAsMidi();
    }
)

export const StopMagentaPlayerAsync = createAsyncThunk(
    'composer/stop/magenta/notes',
    async () => {
        service.stopPlaying()
    }
)
export const LoadUserPatternsAsync = createAsyncThunk(
    'composer/user/fetch/patterns',
    async (args) => {
        return await firebasefuncs.getUserPatterns(args);
    }
)
export const AddPatternAsync = createAsyncThunk(
    'composer/pattern/add',
    async (args) => {
        return await firebasefuncs.addPattern(args);
    }
)
export const StoreCloudLiccAsync = createAsyncThunk(
    'composer/licc/store',
    async (args) => {
        return await firebasefuncs.storeUserLicc(args);
    }
)
export const LoadCloudLiccsAsync = createAsyncThunk(
    'composer/licc/loads',
    async (args) => {
        return await firebasefuncs.loadUserLiccs(args);
    }
)
export const QueryMidisAsync = createAsyncThunk(
    'composer/query/midis/async',
    async (args) => {
        return await firebasefuncs.queryMidisAsync(args)
    }
)

let midi_structure = {};
export const GetMidiFolderStructure = createAsyncThunk(
    'composer/get/midi/folder/structure',
    async (args) => {
        if (midi_structure[JSON.stringify(args)]) {
            return midi_structure[JSON.stringify(args)];
        }

        midi_structure[JSON.stringify(args)] = await firebasefuncs.getMidiFolderStructure(args);

        return midi_structure[JSON.stringify(args)];
    }
)

export const LoadUserChordProgressionsAsync = createAsyncThunk(
    'composer/load/chord/progressions',
    async (args) => {
        return await firebasefuncs.loadUserChordProgressions(args);
    }
)
export const UpdatePatternAsync = createAsyncThunk(
    'composer/pattern/update',
    async (args) => {
        return await firebasefuncs.updatePattern(args)
    }
)

export const DeletePatternAsync = createAsyncThunk(
    'composer/pattern/delete',
    async (args) => {
        return await firebasefuncs.deletePattern(args)
    }
)
export const StoreChordProgressionInCloudAsync = createAsyncThunk(
    'composer/store/progression/in/cloud',
    async (args) => {
        return await firebasefuncs.storeChordProgression(args);
    }
)
export const LoadCloudProgressionsAsync = createAsyncThunk(
    'composer/load/cloud/progressions',
    async (args) => {
        return await firebasefuncs.loadUserChordProgressions(args);
    }
)
export const LoadCloudModifiersAsync = createAsyncThunk(
    'composer/load/cloud/modifiers',
    async (args) => {
        return await firebasefuncs.loadUserModifiers(args);
    }
)
export const StoreCloudModifiersAsync = createAsyncThunk(
    'composer/store/cloud/modifiers',
    async (args) => {
        return await firebasefuncs.storeModifier(args);
    }
)
export const LoadMagentaAsync = createAsyncThunk(
    'composer/load/magenta',
    async () => {
        let magentaService = new MagentaService();
        setMagentaService(magentaService)
        await magentaService.init()
        return magentaService
    });

export function setMagentaService(magenta) {
    service = magenta
}

export function getMagentaService() {
    return service;
}