import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    buildChordOptions,
    getOptionFilter,
    selectChordInputValue,
    selectChordMatches,
    selectScaleMatches,
    toggleChordMatch,
    updateOptionFilter
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import OptionsList from '../../components/music/OptionsList';
import { convertToMelodyNote } from '../../base/base-lib';

export function ChordOptionLists() {
    const dispatch = useDispatch();
    const chordInputValue = useSelector((state) => selectChordInputValue(state, 'chord-input'));
    const selectedChords = useSelector(selectChordMatches);
    const optionFilter = useSelector(getOptionFilter);
    let selectedScales = useSelector(selectScaleMatches);
    let res = Object.keys(chordInputValue || {}).filter(v => chordInputValue[v]).map(v => convertToMelodyNote(v));
    return (
        <Panel stretch title={'Chord Options'}>
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative'
            }}>
                <div style={{ display: 'flex' }}>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">@</span>
                        </div>
                        <input type="text" className="form-control" onChange={(value) => {
                            dispatch(updateOptionFilter(value.currentTarget.value))
                        }} placeholder="Username" />
                    </div>
                </div>
                <div style={{ flex: 1, overflow: 'hidden' }}>
                    <OptionsList
                        filter={optionFilter}
                        scales={Object.keys(selectedScales).filter(x => selectedScales[x])}
                        selected={selectedChords} melodylist={res} onSelectToggle={(chordMatch) => {
                            dispatch(toggleChordMatch({ id: chordMatch._info.id }))
                            dispatch(buildChordOptions())
                        }} />
                </div>
            </div>
        </Panel>
    );
}