import React from 'react';

export default class Preloader extends React.Component {
    render() {
        if (this.props.hide) {
            return <span style={{ display: 'none' }}></span>
        }
        return (<div className="preloader flex-column justify-content-center align-items-center">
            <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
        </div>);
    }
}