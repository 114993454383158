import React from 'react';
import ChordMaster from '../../base/chord-master';
import { TitlesService } from '../../title-service';
import SidebarMenuItem from '../main/SideBarMenuItem';


export default class ChordProgressionItems extends React.Component {
    reader = null;
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { selected, progressions, cloud_progressions, logged_in } = this.props;
        return (
            <ul className="nav nav-pills nav-sidebar flex-column">
                <SidebarMenuItem
                    title={TitlesService('Local Progressions')}
                    open={this.state.local_progressions} onClick={() => {
                        this.setState({ local_progressions: !this.state.local_progressions });
                    }}>
                    {(progressions || []).map((progression, index) => {
                        let temp = new Date((progression.updated ? progression.updated.seconds : (progression.created ? progression.created.seconds : (Date.now() / 1000))) * 1000);
                        let moment = window.moment;
                        let timing = moment(temp).fromNow();
                        return (<SidebarMenuItem
                            key={'progression' + index}
                            active={progression.id === selected}
                            title={progression.name} itemTitle={timing}
                            icon={`nav-icon fas fa-guitar`}
                            onClick={() => {
                                if (this.props.onSelectToggle) {
                                    this.props.onSelectToggle(progression.id)
                                }
                            }} />)
                    })}
                </SidebarMenuItem>
                <SidebarMenuItem
                    icon={`nav-icon fas fa-cloud`}
                    title={`${TitlesService('My Cloud Progressions')} ${(cloud_progressions || []).length}`}
                    open={this.state.my_cloud_progressions} onClick={() => {
                        this.setState({ my_cloud_progressions: !this.state.my_cloud_progressions });
                    }}>
                    {logged_in ? ((cloud_progressions || []).map((cloud_progression, index) => {
                        let temp = new Date((cloud_progression.updated ? cloud_progression.updated.seconds : (cloud_progression.created ? cloud_progression.created.seconds : (Date.now() / 1000))) * 1000);
                        let moment = window.moment;
                        let timing = moment(temp).fromNow();
                        return (<SidebarMenuItem
                            key={'cloud-progression' + index}
                            title={cloud_progression.name} itemTitle={timing}
                            icon={`nav-icon fas fa-guitar`}
                            onClick={() => {
                                let alreadySet = progressions.find(x => x.id === cloud_progression.id);
                                if (alreadySet) {
                                    if (window.confirm(TitlesService(`YouwillLoseChangesToChordProgression`))) {
                                        if (this.props.onAddAndSelect) {
                                            this.props.onAddAndSelect(cloud_progression)
                                        }
                                    }
                                }
                                else {
                                    if (this.props.onAddAndSelect) {
                                        this.props.onAddAndSelect(cloud_progression)
                                    }
                                }
                            }} />)
                    })) : <SidebarMenuItem title={TitlesService('Log in to see your progressions')} />}
                </SidebarMenuItem>
                {/* <SidebarMenuItem
                    icon={`nav-icon fas fa-cloud-sun`}
                    title={TitlesService('Community Cloud Progressions')}
                    open={this.state.community_progressions} onClick={() => {
                        this.setState({ community_progressions: !this.state.community_progressions });
                    }}>
                    <SidebarMenuItem title={TitlesService('Log in to see community progressions')} />
                </SidebarMenuItem> */}
            </ul>
        )
    }
}
class OptionListItem extends React.Component {
    render() {
        let { selected } = this.props;
        let { name } = this.props.progression;
        return (
            <tr onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick();
                }
            }}>
                <td>{name}</td>
                <td><input type="checkbox" disabled {...(selected ? { checked: true } : { checked: false })} /></td>
                <td onClick={() => {
                    if (this.props.onDeleteItem) {
                        this.props.onDeleteItem();
                    }
                }}><i className="fa fa-times" /></td>
            </tr>
        )
    }
}