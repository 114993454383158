// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import "firebase/firestore";
import { QueryBy } from './base-lib';
import { loadMidi } from './midi-service';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = {
    apiKey: "AIzaSyDrSpqjhhQSHMo_jJ9nSv_IL0ZoU0aWqG0",
    authDomain: "composercompanion-9ab4b.firebaseapp.com",
    databaseURL: "https://composercompanion-9ab4b-default-rtdb.firebaseio.com",
    projectId: "composercompanion-9ab4b",
    storageBucket: "composercompanion-9ab4b.appspot.com",
    messagingSenderId: "101825248895",
    appId: "1:101825248895:web:d0e4fec442117e0e42094a",
    measurementId: "G-KWJNF76EG8"
};
// gsutil cors set cors.json gs://composercompanion-9ab4b.appspot.com
// if (window.location.hostname === 'localhost') {
//     firebaseConfig = {
//         databaseURL: 'http://localhost:9000?ns=composercompanion-9ab4b'
//     }
// }
let app = firebase.initializeApp(firebaseConfig);
let db = firebase.firestore(app);
var storage = firebase.storage(app);
export function getApp() {
    return app;
}
export function getReqs() {
    return { db, app, storage };
}
export async function searchTheoryProgressions(query) {
    let progressions = db.collection('theorytab')
        .where("authorNormalized", "==", `${query}`.toLowerCase())
        .limit(100);

    let info = [];
    await progressions.get().then((querySnapShot) => {
        querySnapShot.forEach(doc => info.push(doc.data()));
    })
    return info;
}
export const PROVIDERS = {
    GOOGLE: 'GOOGLE',
    FACEBOOK: 'FACEBOOK'
}
export async function signOut() {
    return firebase.auth().signOut().then(() => {
    }).catch(error => {
        return error;
    })
}
let _lastState;
let _authStateChangedHandler;
export function setAuthStateChangedHandler(func) {
    _authStateChangedHandler = func;
    let temp = _lastState;
    _lastState = null;
    return temp;
}

export async function addPattern(patternObj) {
    // Add a new document with a generated id.
    if (patternObj.owner) {

        let pattern_collection = db.collection(`patterns`).doc(`${patternObj.owner}`).collection('pattern');
        let temp = {
            ...patternObj,
            version: (patternObj.version || 0) + 1,
            pattern: JSON.stringify(patternObj.pattern)
        }
        const docRef = await pattern_collection.add({
            ...temp,
            created: firebase.firestore.FieldValue.serverTimestamp()
        });

        console.log("Document written with ID: ", docRef.id);
        return true;
    }
    return false;
}

export async function updatePattern(patternObj) {
    // Add a new document with a generated id.
    if (patternObj.owner) {

        let pattern_collection = db.collection(`patterns`).doc(`${patternObj.owner}`).collection('pattern').doc(patternObj.id);
        let temp = {
            ...patternObj,
            version: (patternObj.version || 0) + 1,
            pattern: JSON.stringify(patternObj.pattern)
        };
        await pattern_collection.update({
            ...temp,
            updated: firebase.firestore.FieldValue.serverTimestamp()
        });

        return true;
    }
    return false;
}

export async function loadUserModifiers(args) {
    let { uid } = args;
    let pattern_collection = db.collection(`modifiers`).doc(`${uid}`).collection('modifier');
    let result = [];
    await pattern_collection.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            result.push(convertToModifier(doc));
        });
    }).catch((error) => {
        console.log("Error getting documents: ", error);
    });
    return result.filter(x => x);
}

let queryNexts = {
    [QueryBy.INSTRUMENTS]: {},
    [QueryBy.TEXT]: {}
}
export function clearQuery(args) {
    if (queryNexts[args.queryType]) {
        if (queryNexts[args.queryType][JSON.stringify(args)]) {
            delete queryNexts[args.queryType][JSON.stringify(args)];
        }
    }
}

export async function getMidiFolderStructure(args) {
    let { root } = args;
    root = root || 'midis';
    let res = []
    await db.collection(`midifolders`).where('id', '==', root).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            res.push(doc.data())
        });
    });
    return res[0] || null;
}

export async function queryMidisAsync(args) {
    let res = [];
    let temp = null;
    switch (args.queryType) {
        case QueryBy.INSTRUMENTS:
            temp = db.collection(`mididata`).where('instruments', 'array-contains-any', [...args.instruments])
            break;
        case QueryBy.TEXT:
            temp = db.collection(`mididata`).where('all_words', 'array-contains-any', `${args.text}`.toLowerCase().split(' '))
            break;
    }
    if (temp) {
        if (queryNexts[args.queryType][JSON.stringify(args)]) {
            temp = temp.startAfter(queryNexts[args.queryType][JSON.stringify(args)]);
        }

        await temp.limit(args.limit || 100).get().then((querySnapshot) => {
            // Get the last visible document
            var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            queryNexts[args.queryType][JSON.stringify(args)] = lastVisible;
            querySnapshot.forEach((doc) => {
                res.push(doc.data())
            });
        }).catch((error) => {
            console.log("Error getting documents: ", error);
        });
    }
    return res;
}
export async function firebaseLoadMidi(temp) {
    // gs://composercompanion-9ab4b.appspot.com/midis//AmerEnglFolk/AMEF03.mid
    // gs://composercompanion-9ab4b.appspot.com/midis//AncientNearEast/15engan.mid
    let url = `gs://composercompanion-9ab4b.appspot.com/${temp}`
    return storage.refFromURL(url).getDownloadURL()
        .then((url) => {
            return fetch(url).then(res => {
                return res.arrayBuffer().then(buffer => {
                    loadMidi(temp, buffer);
                    return { name: temp };
                });
            })
        })
        .catch((error) => {
            // Handle any errors
            console.log(error);
        });
}
export async function loadUserLiccs(args) {
    let { uid } = args;
    let licc_collection = db.collection(`liccs`).doc(`${uid}`).collection('licc');
    let result = [];
    await licc_collection.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            result.push(convertToLicc(doc));
        });
    }).catch((error) => {
        console.log("Error getting documents: ", error);
    });
    return result.filter(x => x);
}

export async function storeUserLicc(args) {
    let { owner, fire_id } = args;
    if (fire_id) {
        // update
        let licc_collection = db.collection(`liccs`).doc(`${owner}`).collection('licc').doc(fire_id);
        let temp = {
            version: (args.version || 0) + 1,
            owner,
            licc: JSON.stringify(args)
        };
        await licc_collection.update({
            ...temp,
            updated: firebase.firestore.FieldValue.serverTimestamp()
        });

        return true;
    }
    else {
        // add 
        let licc_collection = db.collection(`liccs`).doc(`${owner}`).collection('licc');
        let temp = {
            version: (args.version || 0) + 1,
            owner,
            licc: JSON.stringify(args)
        }
        const docRef = await licc_collection.add({
            ...temp,
            created: firebase.firestore.FieldValue.serverTimestamp()
        });

        console.log("Document written with ID: ", docRef.id);
        return true;
    }
}

export async function storeModifier(args) {
    let { owner, fire_id } = args;
    if (fire_id) {
        // update
        let modifier_collection = db.collection(`modifiers`).doc(`${owner}`).collection('modifier').doc(fire_id);
        let temp = {
            version: (args.version || 0) + 1,
            owner,
            modifier: JSON.stringify(args)
        };
        await modifier_collection.update({
            ...temp,
            updated: firebase.firestore.FieldValue.serverTimestamp()
        });

        return true;
    }
    else {
        // add 
        let modifier_collection = db.collection(`modifiers`).doc(`${owner}`).collection('modifier');
        let temp = {
            version: (args.version || 0) + 1,
            owner,
            modifier: JSON.stringify(args)
        }
        const docRef = await modifier_collection.add({
            ...temp,
            created: firebase.firestore.FieldValue.serverTimestamp()
        });

        console.log("Document written with ID: ", docRef.id);
        return true;
    }
}

export async function loadUserChordProgressions(args) {
    let { uid } = args;
    let pattern_collection = db.collection(`chord_progressions`).doc(`${uid}`).collection('chord_progression');
    let result = [];
    await pattern_collection.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            result.push(convertToChordProgression(doc));
        });
    }).catch((error) => {
        console.log("Error getting documents: ", error);
    });
    return result.filter(x => x);
}

export async function storeChordProgression(args) {
    let { owner, fire_id } = args;
    if (fire_id) {
        // update
        let chord_progression_collection = db.collection(`chord_progressions`).doc(`${owner}`).collection('chord_progression').doc(fire_id);
        let temp = {
            version: (args.version || 0) + 1,
            owner,
            progression: JSON.stringify(args)
        };
        await chord_progression_collection.update({
            ...temp,
            updated: firebase.firestore.FieldValue.serverTimestamp()
        });

        return true;
    }
    else {
        // add 
        let pattern_collection = db.collection(`chord_progressions`).doc(`${owner}`).collection('chord_progression');
        let temp = {
            version: (args.version || 0) + 1,
            owner,
            progression: JSON.stringify(args)
        }
        const docRef = await pattern_collection.add({
            ...temp,
            created: firebase.firestore.FieldValue.serverTimestamp()
        });

        console.log("Document written with ID: ", docRef.id);
        return true;
    }
}

export async function deletePattern(args) {
    if (args.uid) {
        let patternObj = db.collection(`patterns`).doc(`${args.uid}`).collection('pattern').doc(args.patternId);
        await patternObj.delete();
        return true;
    }
    return false;
}

export async function getUserPatterns(owner) {
    let pattern_collection = db.collection(`patterns`).doc(`${owner}`).collection('pattern');
    let result = [];
    await pattern_collection.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            result.push(convertToPattern(doc));
        });
    }).catch((error) => {
        console.log("Error getting documents: ", error);
    });
    return result.filter(x => x);
}

function convertToPattern(doc) {
    let temp = {
        id: doc.id,
        ...doc.data()
    }
    try {
        temp.pattern = JSON.parse(temp.pattern);
        setupCreateUpdateClocks(temp);

    }
    catch (e) {
        return false;
    }
    return temp;
}

function convertServerTime(obj) {
    return {
        seconds: obj.seconds,
        nanoseconds: obj.nanoseconds
    }
}
function convertToLicc(doc) {
    let temp = {
        id: doc.id,
        ...doc.data()
    }
    let prog = JSON.parse(temp.licc);
    temp = {
        ...prog,
        ...temp,
        fire_id: doc.id,
        id: doc.id,
        originalId: prog.originalId || prog.id
    }

    setupCreateUpdateClocks(temp);

    return temp;
}
function convertToModifier(doc) {
    let temp = {
        id: doc.id,
        ...doc.data()
    }
    let prog = JSON.parse(temp.modifier);
    temp = {
        ...prog,
        ...temp,
        fire_id: doc.id,
        id: doc.id,
        originalId: prog.originalId || prog.id
    }

    setupCreateUpdateClocks(temp);

    return temp;
}
export function setupCreateUpdateClocks(temp) {
    temp.created = convertServerTime(temp.created);
    if (temp.updated)
        temp.updated = convertServerTime(temp.updated);
}

function convertToChordProgression(doc) {
    let temp = {
        id: doc.id,
        ...doc.data()
    }
    let prog = JSON.parse(temp.progression);
    temp = {
        ...prog,
        ...temp,
        fire_id: doc.id,
        id: doc.id,
        originalId: prog.originalId || prog.id
    }
    setupCreateUpdateClocks(temp);


    return temp;
}

function authStateListener() {
    // [START auth_state_listener]
    firebase.auth().onAuthStateChanged((user) => {
        console.log(user);
        _lastState = user;
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            var uid = user.uid;
            if (_authStateChangedHandler) {
                _authStateChangedHandler({
                    signedIn: true,
                    user
                })
            }
            // ...
        } else {
            // User is signed out
            // ...
            if (_authStateChangedHandler) {
                _authStateChangedHandler({
                    signedIn: false,
                    user
                })
            }
        }
    });
    // [END auth_state_listener]
}
authStateListener();
export async function signinWith(providerId) {
    let provider;
    switch (providerId) {
        case PROVIDERS.GOOGLE:
            provider = new firebase.auth.GoogleAuthProvider();
            break;
        case PROVIDERS.FACEBOOK:
            provider = new firebase.auth.FacebookAuthProvider();
            break;
        default:
            throw 'not supported provider : ' + providerId;
    }
    return firebase.auth()
        .signInWithPopup(provider)
        .then((result) => {
            /** @type {firebase.auth.OAuthCredential} */
            var credential = result.credential;

            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            console.log(result);
            // ...
        }).catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
            console.log(error);
        });
}

