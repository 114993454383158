import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SeratoComposer from './seratoComposer';
import MidiInputDevice from './MidiInputDevice';
import { SeratoEventTypes } from './seratoComposer';

import {
    midiEvent,
    midiInputs,
    selectCurrentMidiCommand,
    selectMidiInputs,
    selectNotes
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import SidebarMenuItem, { SideBarMenuHolder } from '../../components/main/SideBarMenuItem';
import { FormGroup } from '../../components/FormGroup';
import { MidiInstrumentData } from '../../base/instrument-data/instruments';
import { getDeviceOptions, setDeviceOptions } from '../user/userSlice';
import { TitlesService } from '../../title-service';

export function ComposerHeader(props) {
    //   const count = useSelector(selectCount);
    const devices = useSelector(selectMidiInputs);
    const notes = useSelector(selectCurrentMidiCommand);
    const deviceOptions = useSelector(getDeviceOptions);
    const dispatch = useDispatch();

    return (
        <Panel stretch title={TitlesService('Midi Instruments')}>

            <ul className="nav nav-pills nav-sidebar flex-column">
                {devices.map((device, i) => {

                    return (
                        <SidebarMenuItem childrenStyle={{ paddingLeft: 10 }} key={`device-${i}`} title={`${device.name} ${device.manufacturer} ${device.version}`}>
                            <SideBarMenuHolder>
                                <label>Kit</label>
                                <div style={{ display: 'flex' }}>
                                    <FormGroup>
                                        <div className="btn-group">
                                            <select className="form-control" value={deviceOptions && deviceOptions[device.name] ? deviceOptions[device.name].kit || 1 : 1} onChange={(val) => {
                                                if (val && val.currentTarget) {
                                                    dispatch(setDeviceOptions({ name: device.name, key: 'kit', value: val.currentTarget.value }));
                                                }
                                            }}>
                                                {['jazz_kit', 'sgm_plus', 'salamander'].map((v, index) => {
                                                    return <option key={`option-${v}`} value={index}>{v}</option>
                                                })}
                                            </select>
                                        </div>
                                    </FormGroup>
                                </div>
                            </SideBarMenuHolder>
                            <SideBarMenuHolder>
                                <label>Instrument</label>
                                <div style={{ display: 'flex' }}>
                                    <FormGroup>
                                        <div className="btn-group">
                                            <select className="form-control" value={deviceOptions && deviceOptions[device.name] ? deviceOptions[device.name].instrument || '0x00' : '0x00'} onChange={(val) => {
                                                console.log(val);
                                                if (val && val.currentTarget) {
                                                    if (val && val.currentTarget) {
                                                        dispatch(setDeviceOptions({ name: device.name, key: 'instrument', value: val.currentTarget.value }));
                                                    }
                                                }
                                            }}>
                                                {MidiInstrumentData.map(v => {
                                                    return <option key={`option-${v.hexcode}-${v.instrument}`} value={v.hexcode}>{v.instrument}</option>
                                                })}
                                            </select>
                                        </div>
                                    </FormGroup>
                                </div>
                            </SideBarMenuHolder>
                        </SidebarMenuItem>
                    )
                })}
            </ul>
        </Panel>
    );
}