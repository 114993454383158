import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    getChordProgrssions,
    onAddToBank,
    play,
    selectBias,
    buildSceneProgressions,
    isMusicPlaying
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import { QuickInput } from '../../components/QuickInput';
import { getProgressionList, getUserSignedIn, searchForTheoryProgressions } from '../user/userSlice';
import { HelpLib } from '../../base/help-service';
import { Help } from '../../components/Help';
import { StopMagentaPlayerAsync } from './magenta-thunks';
import { isPanelOpen } from '../../base/key-service';
import { APP_PANELS } from '../../base/space-panel-config-service';
import { ADD_SCENE_PROGRESSION, SendCommandToMidiTrackerEditor } from './MidiTrackerEditor';

export function QuickInputController() {
    const dispatch = useDispatch();
    let bias = useSelector(selectBias);
    let chordProgressions = useSelector(getChordProgrssions);
    const isSignedIn = useSelector(getUserSignedIn);
    const progressionList = useSelector(getProgressionList);

    const is_music_playing = useSelector(isMusicPlaying)
    const [showHelp, setShowHelp] = useState(false);
    return (
        <Panel stretch title={'Quick Chord Progression Input'} buttons={() => {
            return [<button type="button" key={'buton'} onClick={() => {
                setShowHelp(!showHelp)
            }} className="btn btn-tool" data-card-widget="collapse">
                <i className="fas fa-question"></i>
            </button>]
        }}>
            {showHelp ? <Help onClose={() => {
                setShowHelp(false);
            }} type={HelpLib.QuickChordProgressionInput} /> : null}
            <QuickInput onSubmit={(val) => {
                console.log(val);
            }}
                is_music_playing={is_music_playing}
                progressionList={progressionList}
                onSearchTheoryProgressions={(query) => {
                    dispatch(searchForTheoryProgressions(query))
                }}
                isSignedIn={isSignedIn}
                chordProgressions={chordProgressions}
                bias={bias}
                onPlay={(payload) => {
                    dispatch(play(payload))
                }}
                buildSceneProgressions={(payload) => {
                    if (isPanelOpen(APP_PANELS.MIDI_TRACK_EDITOR)) {
                        SendCommandToMidiTrackerEditor(ADD_SCENE_PROGRESSION, { payload });
                    }
                    else {
                        dispatch(buildSceneProgressions(payload));
                    }
                }}
                onStop={(payload) => {
                    dispatch(play(payload))
                    dispatch(StopMagentaPlayerAsync());
                }}
                onAddToBank={(notes) => {
                    dispatch(onAddToBank(notes));
                }}
            />
        </Panel>
    );
}