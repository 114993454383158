import { useDispatch } from "react-redux";
import { removeToast } from "../../features/user/userSlice";


export function ToastContainer(props) {
    const dispatch = useDispatch();
    return (
        <div className="cc">
            <div id="toast-container" className="toast-top-right">
                {
                    props.toasts.map(toast => {
                        return (
                            <ToastItem key={`toast-${toast.id}`} id={toast.id} onDone={() => {
                                dispatch(removeToast({ id: toast.id }))
                            }}>
                                {toast.text}
                            </ToastItem>
                        );
                    })
                }
            </div>
        </div>
    )
}
const toasts = {}
export function ToastItem(props) {
    if (!toasts[props.id]) {
        toasts[props.id] = {
            handler: () => {
                if (props.onDone) {
                    props.onDone();
                }
                delete toasts[props.id];
            }
        }
        setTimeout(() => {
            toasts[props.id].handler();
        }, 7000);

        setTimeout(() => {
            let item = document.body.querySelector(`[data-toast="${props.id}"]`)
            if (item) {
                item.animate(
                    [
                        {
                            transform: 'translateZ(-100px)',
                            opacity: 1
                        },

                        {
                            transform: 'translateZ(50px)',
                            offset: 0.7
                        },

                        {
                            transform: 'translateZ(0)',
                            opacity: 0
                        }],

                    { duration: 2, easing: 'cubic-bezier(0.23, 1, 0.32, 1)' });
            }
        }, 5000);
    }
    return (
        <div data-toast={props.id} className="toast toast-info"
            aria-live="polite"
            style={{}}>
            <div className="toast-body">{props.children}</div>
        </div>
    )
}