import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    toggleScaleMatch,
    selectScaleMatches
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import ScaleList from '../../components/music/ScaleList';

export function ScaleOptionList() {
    const dispatch = useDispatch();
    let selected = useSelector(selectScaleMatches);
    return (
        <Panel stretch title={'Scale Options'}>
            <ScaleList selected={selected} onSelectToggle={(chordMatch) => {
                dispatch(toggleScaleMatch({ id: chordMatch }))
            }} />
        </Panel>
    );
}