export function getSongName() {
    return `${source.adjectives[Math.floor(Math.random() * source.adCount)]} ${source.adjectives[Math.floor(Math.random() * source.adCount)]} ${source.nouns[Math.floor(Math.random() * source.nounCount)]}`;
}
export function getSongFileName() {
    return `${source.adjectives[Math.floor(Math.random() * source.adCount)]}-${source.adjectives[Math.floor(Math.random() * source.adCount)]}-${source.nouns[Math.floor(Math.random() * source.nounCount)]}.mid`;
}
export function createModifierName() {
    return `${source.adjectives[Math.floor(Math.random() * source.adCount)]}-${source.adjectives[Math.floor(Math.random() * source.adCount)]}-mod`;
}

export function getPatternName() {
    return `${source.adjectives[Math.floor(Math.random() * source.adCount)]}-${source.adjectives[Math.floor(Math.random() * source.adCount)]}-${source.nouns[Math.floor(Math.random() * source.nounCount)]}.pattern`;
}


let adjectives = `able
above
absent
absolute
abstract
abundant
academic
acceptable
accepted
accessible
accurate
accused
active
actual
acute
added
additional
adequate
adjacent
administrative
adorable
advanced
adverse
advisory
aesthetic
afraid
afraid
african
aggregate
aggressive
agreeable
agreed
agricultural
alert
alive
alive
alleged
allied
alone
alright
alternative
amateur
amazing
ambitious
american
amused
ancient
ancient
angry
angry
annoyed
annual
anonymous
anxious
anxious
appalling
apparent
applicable
appropriate
arab
arbitrary
architectural
armed
arrogant
artificial
artistic
ashamed
ashamed
asian
asleep
assistant
associated
atomic
attractive
australian
automatic
autonomous
available
average
average
average
awake
aware
awful
awful
awkward
back
bad
bad
bad
balanced
bare
basic
beautiful
beautiful
beautiful
beneficial
better
bewildered
big
big
big
binding
biological
bitter
bitter
bizarre
black
black
blank
blind
blonde
bloody
bloody
blue
blue
blushing
boiling
bold
bored
bored
boring
bottom
brainy
brave
brave
breakable
breezy
brief
brief
bright
bright
bright
brilliant
british
broad
broad
broken
broken
brown
bumpy
burning
busy
busy
calm
calm
canadian
capable
capitalist
careful
careful
casual
catholic
causal
cautious
cautious
central
certain
changing
characteristic
charming
charming
cheap
cheerful
cheerful
chemical
chief
chilly
chinese
chosen
christian
chronic
chubby
circular
civic
civil
civilian
classic
classical
clean
clean
clear
clear
clever
clever
clinical
close
closed
cloudy
clumsy
coastal
cognitive
coherent
cold
cold
collective
colonial
colorful
colossal
coloured
colourful
combative
combined
comfortable
comfortable
coming
commercial
common
communist
compact
comparable
comparative
compatible
competent
competitive
complete
complex
complicated
comprehensive
compulsory
conceptual
concerned
concerned
concrete
condemned
confident
confidential
confused
confused
conscious
conservation
conservative
considerable
consistent
constant
constitutional
contemporary
content
continental
continued
continuing
continuous
controlled
controversial
convenient
conventional
convinced
convincing
cooing
cool
cool
cooperative
corporate
correct
corresponding
costly
courageous
crazy
crazy
crazy
creative
creepy
creepy
criminal
critical
crooked
crooked
crowded
crucial
crude
cruel
cruel
cuddly
cultural
curious
curious
curly
current
curved
cute
daily
damaged
damp
damp
dangerous
dangerous
dark
dark
dead
dead
deaf
deafening
dear
decent
decisive
deep
deep
defeated
defensive
defiant
definite
deliberate
delicate
delicious
delicious
delighted
delightful
delightful
democratic
dependent
depressed
depressed
desirable
desperate
detailed
determined
determined
developed
developing
devoted
different
different
difficult
difficult
digital
diplomatic
direct
dirty
dirty
disabled
disappointed
disastrous
disciplinary
disgusted
distant
distinct
distinct
distinctive
distinguished
disturbed
disturbing
diverse
divine
dizzy
domestic
dominant
double
doubtful
doubtful
drab
dramatic
dreadful
driving
drunk
dry
dry
dual
due
dull
dull
dull
dusty
dutch
dying
dynamic
eager
eager
early
early
eastern
easy
easy
economic
educational
eerie
effective
efficient
elaborate
elated
elderly
eldest
electoral
electric
electrical
electronic
elegant
elegant
eligible
embarrassed
embarrassed
embarrassing
emotional
empirical
empty
empty
enchanting
encouraging
encouraging
endless
energetic
english
enormous
enthusiastic
enthusiastic
entire
entitled
envious
environmental
equal
equivalent
essential
established
estimated
ethical
ethnic
european
eventual
everyday
evident
evil
evil
evolutionary
exact
excellent
exceptional
excess
excessive
excited
excited
exciting
exclusive
existing
exotic
expected
expensive
experienced
experimental
explicit
extended
extensive
external
extra
extraordinary
extreme
exuberant
faint
faint
fair
fair
faithful
faithful
familiar
famous
famous
fancy
fantastic
fantastic
far
fascinating
fashionable
fast
fast
fat
fat
fat
fatal
favourable
favourite
federal
fellow
female
feminist
few
fierce
fierce
filthy
filthy
final
financial
fine
fine
firm
fiscal
fit
fixed
flaky
flat
flat
flexible
fluffy
fluttering
flying
following
fond
foolish
foolish
foreign
formal
formidable
forthcoming
fortunate
forward
fragile
frail
frantic
free
french
frequent
fresh
fresh
friendly
friendly
friendly
frightened
frightened
front
frozen
fucking
full
full-time
fun
functional
fundamental
funny
funny
funny
furious
future
fuzzy
gastric
gay
general
generous
genetic
gentle
gentle
genuine
geographical
german
giant
giant
gigantic
gigantic
given
glad
glamorous
gleaming
global
glorious
glorious
golden
good
good
gorgeous
gothic
governing
graceful
gradual
grand
grateful
greasy
great
great
great
greek
green
green
grey
grieving
grim
gross
grotesque
growing
grubby
grumpy
guilty
handicapped
handsome
handsome
happy
happy
happy
hard
hard
harsh
harsh
head
healthy
healthy
heavy
heavy
helpful
helpful
helpful
helpless
helpless
hidden
high
high
high-pitched
hilarious
hissing
historic
historical
hollow
holy
homeless
homeless
homely
hon
honest
horizontal
horrible
horrible
hostile
hot
hot
huge
huge
human
hungry
hungry
hurt
hushed
husky
icy
ideal
identical
ideological
ill
ill
illegal
imaginative
immediate
immense
immense
imperial
implicit
important
important
impossible
impossible
impressed
impressive
improved
inadequate
inappropriate
inc
inclined
increased
increasing
incredible
independent
indian
indirect
individual
industrial
inevitable
influential
informal
inherent
initial
injured
inland
inner
innocent
innocent
innovative
inquisitive
instant
institutional
insufficient
intact
integral
integrated
intellectual
intelligent
intense
intensive
interested
interesting
interim
interior
intermediate
internal
international
intimate
invisible
involved
iraqi
irish
irrelevant
islamic
isolated
israeli
italian
itchy
japanese
jealous
jewish
jittery
joint
jolly
jolly
joyous
judicial
juicy
junior
just
keen
key
kind
kind
kind
known
korean
labour
large
large
large-scale
late
late
latin
lazy
leading
left
legal
legislative
legitimate
lengthy
lesser
level
lexical
liable
liberal
light
light
light
like
likely
limited
linear
linguistic
liquid
literary
little
little
live
lively
lively
living
local
logical
lonely
lonely
long
long
long
long
long-term
loose
loose
lost
loud
loud
lovely
lovely
low
low
loyal
ltd
lucky
lucky
mad
magenta
magic
magnetic
magnificent
magnificent
main
major
male
mammoth
managerial
managing
manual
many
many
marginal
marine
marked
married
marvellous
marxist
mass
massive
massive
mathematical
mature
maximum
mean
meaningful
mechanical
medical
medieval
melodic
melted
mental
mere
metropolitan
mid
middle
middle-class
mighty
mighty
mild
military
miniature
minimal
minimum
ministerial
minor
miserable
misleading
missing
misty
mixed
moaning
mobile
moderate
modern
modern
modern
modest
molecular
monetary
monthly
moral
motionless
muddy
multiple
mushy
musical
mute
mutual
mysterious
mysterious
naked
narrow
narrow
nasty
nasty
national
native
natural
naughty
naval
near
nearby
neat
necessary
negative
neighbouring
nervous
nervous
net
neutral
new
new
nice
nice
nineteenth-century
noble
noisy
noisy
normal
northern
nosy
notable
novel
nuclear
numerous
numerous
nursing
nutritious
nutty
obedient
objective
obliged
obnoxious
obvious
occasional
occupational
odd
odd
official
ok
okay
old
old
old-fashioned
old-fashioned
olympic
only
open
open
operational
opposite
optimistic
oral
orange
orange
ordinary
ordinary
organic
organisational
original
orthodox
other
outdoor
outer
outrageous
outside
outstanding
outstanding
overall
overseas
overwhelming
painful
pale
palestinian
panicky
parallel
parental
parliamentary
part-time
partial
particular
passing
passive
past
patient
payable
peaceful
peculiar
perfect
perfect
permanent
persistent
personal
petite
philosophical
physical
pink
plain
plain
planned
plastic
pleasant
pleasant
pleased
poised
polish
polite
political
poor
poor
popular
positive
possible
post-war
potential
powerful
powerful
practical
precious
precious
precise
preferred
pregnant
preliminary
premier
prepared
present
presidential
pretty
pretty
previous
prickly
prickly
primary
prime
primitive
principal
printed
prior
private
probable
productive
professional
profitable
profound
progressive
prominent
promising
proper
proposed
prospective
protective
protestant
proud
proud
provincial
psychiatric
psychological
public
puny
pure
purple
purple
purring
puzzled
puzzled
quaint
quaint
qualified
quick
quick
quick
quickest
quiet
quiet
quiet
racial
radical
rainy
rainy
random
rapid
rapid
rare
rare
raspy
rational
ratty
raw
ready
real
real
realistic
rear
reasonable
recent
red
red
reduced
redundant
regional
registered
regular
regulatory
related
relative
relaxed
relevant
reliable
relieved
relieved
religious
reluctant
remaining
remarkable
remote
renewed
representative
repulsive
required
resident
residential
resonant
respectable
respective
responsible
resulting
retail
retired
revolutionary
rich
rich
ridiculous
right
rigid
ripe
rising
rival
roasted
robust
rolling
roman
romantic
rotten
rough
rough
round
round
round
royal
rubber
rude
ruling
running
rural
russian
sacred
sad
sad
safe
salty
satisfactory
satisfied
scared
scary
scary
scattered
scientific
scornful
scottish
scrawny
screeching
secondary
secret
secure
select
selected
selective
selfish
semantic
senior
sensible
sensitive
separate
serious
severe
sexual
shaggy
shaky
shallow
shallow
shared
sharp
sharp
sheer
shiny
shivering
shocked
short
short
short
short
short-term
shrill
shy
shy
sick
significant
silent
silent
silky
silly
silly
silly
similar
simple
single
skilled
skinny
sleepy
slight
slim
slimy
slippery
slow
slow
small
small
smart
smiling
smoggy
smooth
smooth
so-called
social
socialist
soft
soft
soft
solar
sole
solid
solid
sophisticated
sore
sorry
sound
sound
sour
southern
soviet
spanish
spare
sparkling
spatial
special
specific
specified
spectacular
spicy
spiritual
splendid
splendid
spontaneous
sporting
spotless
spotty
square
square
squealing
stable
stale
standard
static
statistical
statutory
steady
steady
steep
steep
sticky
sticky
stiff
still
stingy
stormy
straight
straight
straightforward
strange
strange
strange
strategic
strict
striking
striped
strong
strong
structural
stuck
stupid
stupid
subjective
subsequent
substantial
subtle
successful
successful
successive
sudden
sufficient
suitable
sunny
super
super
superb
superior
supporting
supposed
supreme
sure
surprised
surprising
surrounding
surviving
suspicious
sweet
sweet
swift
swiss
symbolic
sympathetic
systematic
tall
tall
tall
tame
tame
tan
tart
tasteless
tasty
technical
technological
teenage
temporary
tender
tender
tender
tense
terrible
terrible
territorial
testy
then
theoretical
thick
thin
thirsty
thorough
thoughtful
thoughtless
thundering
tight
tight
tiny
tiny
tired
tired
top
tory
total
tough
tough
toxic
traditional
tragic
tremendous
tricky
tropical
troubled
turkish
typical
ugliest
ugly
ugly
ugly
ultimate
unable
unacceptable
unaware
uncertain
unchanged
uncomfortable
unconscious
underground
underlying
unemployed
uneven
unexpected
unfair
unfortunate
unhappy
uniform
uninterested
unique
united
universal
unknown
unlikely
unnecessary
unpleasant
unsightly
unusual
unusual
unwilling
upper
upset
upset
uptight
urban
urgent
used
useful
useless
usual
vague
valid
valuable
variable
varied
various
varying
vast
vast
verbal
vertical
very
victorian
victorious
video-taped
violent
visible
visiting
visual
vital
vivacious
vivid
vocational
voiceless
voluntary
vulnerable
wandering
warm
wasteful
watery
weak
weak
wealthy
weary
wee
weekly
weird
welcome
well
well-known
welsh
western
wet
wet
wet
whispering
white
whole
wicked
wicked
wide
wide
wide-eyed
widespread
wild
wild
willing
wise
witty
wonderful
wonderful
wonderful
wooden
wooden
working
working-class
worldwide
worried
worried
worrying
worthwhile
worthy
written
wrong
wrong
yellow
yellow
young
young
yummy
zany
zany
zealous`;
let nouns = `aardvark
abacus
abbey
abdomen
abolishment
abroad
abyssinian
accelerant
accelerator
accompanist
accordion
account
accountant
achieve
achiever
acid
acknowledgment
acoustic
acoustics
acrylic
act
action
active
activity
actor
actress
acupuncture
adapter
addiction
addition
address
adjustment
administration
adrenalin
adult
advancement
advantage
advertisement
advertising
advice
affair
affect
afghanistan
africa
aftermath
afternoon
aftershave
aftershock
afterthought
age
agency
agenda
agent
aglet
agreement
air
airbag
airbus
airfare
airforce
airline
airmail
airplane
airport
airship
alarm
alb
albatross
alcohol
alcove
alder
algebra
algeria
alibi
allergist
alley
alligator
alloy
almanac
almond
alpaca
alpenglow
alpenhorn
alpha
alphabet
alternative
altitude
alto
aluminium
aluminum
ambassador
ambulance
amendment
america
amount
amusement
anagram
analgesia
analog
analysis
analyst
anatomy
anesthesiology
anethesiologist
anger
angiosperm
angle
angora
angstrom
anguish
animal
anime
ankle
anklet
annual
anorak
answer
ant
antarctica
anteater
antechamber
antelope
anthony
anthropology
antler
anybody
anything
anywhere
apartment
ape
aperitif
apology
apparatus
apparel
appeal
appendix
apple
applewood
appliance
approval
april
apron
apse
aquarius
aquifer
arch
archaeology
archeology
archer
architect
architecture
arch-rival
area
argentina
argument
aries
arithmetic
arm
armadillo
armament
armchair
armenian
armoire
armor
arm-rest
army
arrival
arrow
art
artichoke
artificer
ascot
ash
ashram
ashtray
asia
asparagus
asphalt
assignment
assistance
associate
association
asterisk
astrakhan
astrolabe
astrologer
astrology
astronomy
atelier
athelete
athlete
atm
atmosphere
atom
atrium
attachment
attack
attempt
attendant
attention
attenuation
attic
attitude
attorney
attraction
audience
auditorium
august
aunt
australia
australian
author
authorisation
authority
authorization
automaton
avalanche
avenue
average
azimuth
babe
babies
baboon
babushka
baby
back
backbone
backdrop
backpack
bacon
bad
badge
badger
bafflement
bag
bagel
bagpipe
bagpipes
bail
bait
bake
baker
bakery
bakeware
balaclava
balalaika
balance
balcony
balinese
ball
balloon
ballpark
bamboo
banana
band
bandana
bandanna
bandolier
bangladesh
bangle
banjo
bank
bankbook
banker
banquette
baobab
bar
barbara
barbeque
barber
barbiturate
barge
baritone
barium
barn
barometer
barracks
barstool
base
baseball
basement
basin
basis
basket
basketball
bass
bassinet
bassoon
bat
bath
bather
bathhouse
bathrobe
bathroom
bathtub
batter
battery
batting
battle
battleship
bay
bayou
beach
bead
beak
beam
bean
beanie
beanstalk
bear
beard
beast
beat
beautician
beauty
beaver
bed
bedroom
bee
beech
beef
beer
beet
beetle
beggar
beginner
begonia
behavior
beheading
behest
belfry
belgian
belief
believe
bell
belligerency
bellows
belly
belt
bench
bend
beneficiary
benefit
bengal
beret
berry
bestseller
best-seller
betty
beverage
beyond
bibliography
bicycle
bid
bidet
bifocals
big
big-rig
bijou
bike
bikini
bill
billboard
bin
biology
biplane
birch
bird
birdbath
birdcage
birdhouse
bird-watcher
birth
birthday
bit
bite
black
blackberry
blackboard
blackfish
bladder
blade
blame
blank
blanket
blazer
blight
blinker
blister
blizzard
block
blocker
blood
bloodflow
bloom
bloomers
blossom
blouse
blow
blowgun
blowhole
blue
blueberry
boar
board
boat
boat-building
boatload
boatyard
bobcat
body
bog
bolero
bolt
bomb
bomber
bondsman
bone
bongo
bonnet
bonsai
bonus
boogeyman
book
bookcase
bookend
booklet
booster
boot
bootee
bootie
boots
booty
border
bore
bosom
botany
bottle
bottling
bottom
bottom-line
boudoir
bough
boundary
bow
bower
bowl
bowler
bowling
bowtie
box
boxer
boxspring
boy
bra
brace
bracelet
bracket
brain
brake
branch
brand
brandy
brass
brassiere
bratwurst
brazil
bread
breadcrumb
break
breakfast
breakpoint
breast
breastplate
breath
breeze
brian
bribery
brick
bricklaying
bridge
brief
briefs
brilliant
british
broccoli
brochure
broiler
broker
brome
bronchitis
bronco
bronze
brooch
brood
brook
broom
brother
brother-in-law
brow
brown
brush
brushfire
brushing
bubble
bucket
buckle
bud
budget
buffer
buffet
bug
buggy
bugle
building
bulb
bull
bulldozer
bullet
bull-fighter
bumper
bun
bunch
bungalow
bunghole
bunkhouse
burglar
burlesque
burma
burn
burn-out
burst
bus
bush
business
bust
bustle
butane
butcher
butter
button
buy
buyer
buzzard
cabana
cabbage
cabin
cabinet
cable
caboose
cacao
cactus
caddy
cadet
cafe
caftan
cake
calcification
calculation
calculator
calculus
calendar
calf
calico
call
calm
camel
cameo
camera
camp
campaign
campanile
can
canada
canadian
canal
cancel
cancer
candelabra
candidate
candle
candy
cane
cannon
canoe
canon
canopy
canteen
canvas
cap
cape
capital
capitulation
capon
cappelletti
cappuccino
capricorn
captain
caption
car
caravan
carbon
card
cardboard
cardigan
care
cargo
carload
carnation
carol
carotene
carp
carpenter
carpet
carport
carriage
carrier
carrot
carry
cart
cartilage
cartload
cartoon
cartridge
cascade
case
casement
cash
cashier
casino
casserole
cassock
cast
castanet
castanets
castle
cat
catacomb
catamaran
category
caterpillar
cathedral
catsup
cattle
cauliflower
cause
caution
cave
c-clamp
cd
ceiling
celebration
celeriac
celery
celeste
cell
cellar
cello
celsius
cement
cemetery
cenotaph
census
cent
centenarian
center
centimeter
centurion
century
cephalopod
ceramic
cereal
certification
cesspool
chador
chafe
chain
chainstay
chair
chairlift
chairman
chairperson
chairwoman
chaise
chalet
chalice
chalk
champion
chance
chandelier
change
channel
chap
chapel
character
chard
charge
charity
charlatan
charles
charm
chart
chastity
chasuble
chateau
chauffeur
chauvinist
check
checkroom
cheek
cheese
cheetah
chef
chemistry
cheque
cherries
cherry
chess
chest
chick
chicken
chicory
chief
chiffonier
child
childhood
children
chill
chime
chimpanzee
chin
china
chinese
chino
chipmunk
chit-chat
chivalry
chive
chocolate
choice
choker
chop
chopstick
chord
chowder
christmas
christopher
chrome
chromolithograph
chronograph
chronometer
chub
chug
church
churn
cicada
cinema
circle
circulation
circumference
cirrus
citizenship
city
civilisation
clam
clank
clapboard
clarinet
clasp
class
claus
clave
clavicle
clavier
cleaner
cleat
cleavage
clef
cleric
clerk
click
client
cliff
climb
clip
clipper
cloak
cloakroom
clock
clockwork
clogs
cloister
close
closet
cloth
clothes
clothing
cloud
cloudburst
cloudy
clove
clover
club
clutch
coach
coal
coast
coat
cob
cobweb
cockpit
cockroach
cocktail
cocoa
cod
codon
codpiece
coevolution
coffee
coffin
coil
coin
coinsurance
coke
cold
coliseum
collar
collection
college
collision
colloquia
colombia
colon
colonisation
colony
color
colt
column
columnist
comb
combat
combination
comfort
comfortable
comic
comma
command
commercial
commission
committee
communicant
communication
community
company
comparison
competition
competitor
complement
complex
component
comportment
composer
composition
compost
compulsion
computer
comradeship
concert
concrete
condition
condominium
condor
conductor
cone
confectionery
conference
confidence
confirmation
conflict
conga
congo
congressman
congressperson
congresswoman
conifer
connection
consent
consequence
console
consonant
conspirator
constant
constellation
construction
consul
consulate
contact lens
contagion
contest
context
continent
contract
contrail
contrary
contribution
control
convection
convert
convertible
cook
cookie
cooking
coonskin
cope
cop-out
copper
co-producer
copy
copyright
copywriter
cord
corduroy
cork
cormorant
corn
cornerstone
cornet
corral
correspondent
corridor
corsage
cost
costume
cot
cottage
cotton
couch
cougar
cough
council
councilman
councilor
councilperson
councilwoman
counter
counter-force
countess
country
couple
courage
course
court
cousin
covariate
cover
coverall
cow
cowbell
cowboy
crab
crack
cracker
crackers
cradle
craftsman
crash
crate
cravat
craw
crawdad
crayfish
crayon
cream
creative
creator
creature
creche
credenza
credit
creditor
creek
creme brulee
crest
crew
crib
cribbage
cricket
cricketer
crime
criminal
crinoline
criteria
criterion
criticism
crocodile
crocus
croissant
crook
crop
cross
cross-contamination
cross-stitch
crotch
croup
crow
crowd
crown
crude
crush
cry
crystallography
cub
cuban
cuckoo
cucumber
cuff-links
cultivar
cultivator
culture
culvert
cummerbund
cup
cupboard
cupcake
cupola
curio
curl
curler
currency
current
cursor
curtain
curve
cushion
custard
custodian
customer
cut
cuticle
cutlet
cutover
cutting
cyclamen
cycle
cyclone
cylinder
cymbal
cymbals
cynic
cyst
cytoplasm
dad
daffodil
dagger
dahlia
daisy
damage
dame
dance
dancer
danger
daniel
dark
dart
dash
dashboard
database
date
daughter
david
day
daybed
dead
deadline
deal
dealer
dear
death
deathwatch
deborah
debt
debtor
decade
december
decimal
decision
deck
declination
decongestant
decrease
decryption
dedication
deer
defense
deficit
deformation
degree
delete
delivery
demand
demur
den
denim
dentist
deodorant
department
dependent
deployment
deposit
depressive
depth
deputy
derby
derrick
description
desert
design
designer
desire
desk
dessert
destiny
destroyer
destruction
detail
detainment
detective
detention
determination
development
deviance
dew
dhow
diadem
diamond
diaphragm
diarist
dibble
dickey
dictaphone
diction
dictionary
diet
dietician
difference
differential
difficulty
digestion
digger
digital
dilapidation
dill
dime
dimension
dimple
diner
dinghy
dinner
dinosaur
diploma
dipstick
direction
director
dirndl
dirt
disadvantage
disarmament
disaster
disco
disconnection
discount
discovery
discrepancy
discussion
disease
disembodiment
disengagement
disguise
disgust
dish
dishes
dishwasher
disk
display
disposer
distance
distribution
distributor
district
divan
diver
divide
divider
diving
division
dock
doctor
document
doe
dog
dogsled
dogwood
doll
dollar
dolman
dolphin
domain
donald
donkey
donna
door
doorknob
doorpost
dorothy
dory
dot
double
doubling
doubt
doubter
downforce
downgrade
downtown
draft
dragon
dragonfly
dragster
drain
drake
drama
dramaturge
draw
drawbridge
drawer
drawing
dream
dredger
dress
dresser
dressing
drill
drink
drive
driver
driveway
driving
drizzle
dromedary
drop
drug
drum
drummer
drunk
dry
dryer
duck
duckling
dud
duffel
dugout
dulcimer
dumbwaiter
dump truck
dune buggy
dungarees
dungeon
duplexer
dust
dust storm
duster
duty
dwarf
dwelling
dynamo
eagle
ear
eardrum
earmuffs
earplug
earrings
earth
earthquake
earthworm
ease
easel
east
eave
eavesdropper
e-book
ecclesia
eclipse
ecliptic
economics
ecumenist
eddy
edge
edger
editor
editorial
education
edward
eel
effacement
effect
effective
efficacy
efficiency
effort
egg
egghead
eggnog
eggplant
egypt
eight
ejector
elbow
election
electrocardiogram
element
elephant
elevator
elixir
elizabeth
elk
ellipse
elm
elongation
embossing
emergence
emergent
emery
emotion
employ
employee
employer
employment
empowerment
emu
encirclement
encyclopedia
end
endothelium
enemy
energy
engine
engineer
engineering
english
enigma
enquiry
entertainment
entrance
entry
environment
epauliere
epee
ephemera
ephemeris
epoch
eponym
epoxy
equinox
equipment
era
e-reader
error
escape
espadrille
espalier
establishment
estate
estimate
estrogen
estuary
ethernet
ethiopia
euphonium
eurocentrism
europe
evaluator
evening
evening-wear
event
eviction
evidence
evocation
examination
examiner
example
exchange
exclamation
excuse
executor
exhaust
ex-husband
exile
existence
exit
expansion
expansionism
experience
expert
explanation
exposition
extension
extent
extreme
ex-wife
eye
eyeball
eyebrow
eyebrows
eyeglasses
eyelash
eyelashes
eyelid
eyelids
eyeliner
eyestrain
face
facelift
facet
facilities
facsimile
fact
factor
factory
faculty
fahrenheit
fairies
fairy
fall
falling-out
familiar
family
fan
fang
fanlight
fanny
fanny-pack
farm
farmer
fascia
fat
father
father-in-law
fatigues
faucet
fault
fawn
fax
fear
feast
feather
feature
february
fedelini
fedora
feed
feedback
feeling
feet
felony
female
fen
fence
fencing
fender
ferry
ferryboat
fertilizer
few
fiber
fiberglass
fibre
fiction
fiddle
field
fifth
fight
fighter
figurine
file
fill
filly
filth
final
finance
find
fine
finger
fingernail
finisher
fir
fire
fireman
fireplace
firewall
fish
fishbone
fisherman
fishery
fishmonger
fishnet
fisting
fix
fixture
flag
flame
flanker
flare
flash
flat
flatboat
flavor
flax
fleck
fleece
flesh
flight
flintlock
flip-flops
flock
flood
floor
floozie
flower
flu
flugelhorn
fluke
flute
fly
flytrap
foam
fob
focus
fog
fold
folder
fondue
font
food
foot
football
footnote
footrest
foot-rest
footstool
foray
force
forearm
forebear
forecast
forehead
forest
forestry
forgery
fork
form
formal
format
former
fort
fortnight
fortress
forum
foundation
fountain
fowl
fox
foxglove
fragrance
frame
france
fratricide
fraudster
frazzle
freckle
freeplay
freeze
freezer
freight
freighter
french
freon
fresco
friction
friday
fridge
friend
friendship
frigate
fringe
frock
frog
front
frost
frown
fruit
frustration
fuel
fulfillment
full
function
fundraising
funny
fur
furnace
furniture
fusarium
futon
future
gaffer
gaiters
gale
gall-bladder
galleon
gallery
galley
gallon
galoshes
game
gamebird
gamma-ray
gander
gap
garage
garb
garbage
garden
garlic
garment
garter
gas
gasoline
gastropod
gate
gateway
gather
gauge
gauntlet
gazebo
gazelle
gear
gearshift
geese
gelding
gem
gemini
gemsbok
gender
gene
general
genetics
geography
geology
geometry
george
geranium
gerbil
geriatrician
german
germany
geyser
ghana
gherkin
ghost
giant
gigantism
ginseng
giraffe
girdle
girl
git
glad
gladiolus
gland
glass
glasses
glen
glider
gliding
glockenspiel
glove
gloves
glue
glut
goal
goat
gobbler
godmother
goggles
go-kart
gold
goldfish
golf
gondola
gong
good
goodbye
good-bye
goodie
goose
gopher
gore-tex
gorilla
gosling
governance
government
governor
gown
grab-bag
grade
grain
gram
granddaughter
grandfather
grandmom
grandmother
grandson
granny
grape
grapefruit
graph
graphic
grass
grasshopper
grassland
gray
grease
great
great-grandfather
great-grandmother
greece
greek
green
greenhouse
grenade
grey
grief
grill
grip
grit
ground
group
grouper
grouse
growth
guarantee
guatemalan
guest
guestbook
guide
guilty
guitar
guitarist
gum
gumshoes
gun
gutter
guy
gym
gymnast
gynaecology
gyro
hacienda
hacksaw
hackwork
hail
hair
haircut
half
half-brother
half-sister
halibut
hall
hallway
hamaki
hamburger
hammer
hammock
hamster
hand
handball
hand-holding
handicap
handle
handlebar
handmaiden
handsaw
hang
harbor
harbour
hardboard
hardcover
hardening
hardhat
hard-hat
hardware
harm
harmonica
harmony
harp
harpooner
harpsichord
hassock
hat
hatbox
hatchet
hate
haunt
haversack
hawk
hay
head
headlight
headline
headrest
health
hearing
heart
heartache
hearth
hearthside
heart-throb
heartwood
heat
heater
heaven
heavy
hedge
hedgehog
heel
height
heirloom
helen
helicopter
helium
hell
hellcat
helmet
helo
help
hemp
hen
herb
heron
herring
hexagon
heyday
hide
high
highlight
high-rise
highway
hill
himalayan
hip
hippodrome
hippopotamus
historian
history
hit
hive
hobbies
hobbit
hobby
hockey
hoe
hog
hold
hole
holiday
home
homogenate
homonym
honey
honeybee
honoree
hood
hoof
hook
hope
hops
horn
hornet
horse
hose
hosiery
hospice
hospital
host
hostel
hostess
hot
hot-dog
hotel
hour
hourglass
house
houseboat
hovel
hovercraft
howitzer
hub
hubcap
hugger
human
humidity
humor
hunger
hurdler
hurricane
hurry
hurt
husband
hut
hutch
hyacinth
hybridisation
hydrant
hydraulics
hydrofoil
hydrogen
hyena
hygienic
hyphenation
hypochondria
hypothermia
ice
icebreaker
icecream
ice-cream
icicle
icon
idea
ideal
igloo
ikebana
illegal
image
imagination
impact
implement
importance
impress
imprisonment
improvement
impudence
impulse
inbox
incandescence
inch
income
increase
independent
index
india
indigence
indonesia
industry
infancy
inflammation
inflation
information
infusion
inglenook
ingrate
initial
initiative
in-joke
injury
ink
in-laws
inlay
inn
innervation
innocent
input
inquiry
inscription
insect
inside
insolence
inspector
instruction
instrument
instrumentalist
instrumentation
insulation
insurance
insurgence
intelligence
intention
interactive
interest
interferometer
interior
interloper
internal
internet
interpreter
intervenor
interview
interviewer
intestine
intestines
invention
inventor
inventory
invite
invoice
iPad
iran
iraq
iridescence
iris
iron
ironclad
island
israel
issue
italian
italy
jackal
jacket
jaguar
jail
jailhouse
jam
james
january
japan
japanese
jar
jasmine
jason
jaw
jeans
jeep
jeff
jelly
jellyfish
jennifer
jet
jewel
jewelry
jiffy
job
jockey
jodhpurs
joey
jogging
john
join
joke
joseph
jot
journey
judge
judo
juggernaut
juice
july
jumbo
jump
jumper
jumpsuit
june
junior
junk
junker
junket
jury
justice
jute
kale
kamikaze
kangaroo
karate
karen
kayak
kazoo
kendo
kenneth
kenya
ketch
ketchup
kettle
kettledrum
kevin
key
keyboard
keyboarding
keystone
kick
kick-off
kid
kidney
kidneys
kielbasa
kill
kilogram
kilometer
kilt
kimberly
kimono
kind
king
kingfish
kiosk
kiss
kitchen
kite
kitten
kitty
kleenex
klomps
knee
kneejerk
knickers
knife
knife-edge
knight
knitting
knot
knowledge
knuckle
koala
kohlrabi
korean
lab
laborer
lace
lacquerware
ladder
ladybug
lake
lamb
lamp
lan
lanai
land
landform
landmine
language
lantern
lap
laparoscope
lapdog
laptop
larch
larder
lark
laryngitis
lasagna
latency
latex
lathe
latte
laugh
laundry
laura
law
lawn
lawsuit
lawyer
layer
lead
leader
leaf
league
leaker
learning
leash
leather
leaver
lecture
leek
leg
legal
legging
legume
lei
lemon
lemonade
lemur
length
lentil
leo
leopard
leotard
leprosy
let
letter
lettuce
level
lever
leverage
libra
librarian
library
license
lier
life
lift
light
lighting
lightning
lilac
lily
limit
limo
line
linen
liner
link
linseed
lion
lip
lipstick
liquid
liquor
lisa
list
literature
litigation
litter
liver
living
lizard
llama
loaf
loafer
loan
lobotomy
lobster
lock
locker
locket
locomotive
locust
loft
log
loggia
loincloth
look
loss
lot
lotion
lounge
lout
love
low
loyalty
luck
luggage
lumber
lumberman
lunch
luncheonette
lunchroom
lung
lunge
lute
luttuce
lycra
lye
lymphocyte
lynx
lyocell
lyre
lyric
macadamia
macaroni
machine
macrame
macrofauna
maelstrom
maestro
magazine
magic
magician
maid
maiden
mail
mailbox
mailman
maintenance
major
major-league
makeup
malaysia
male
mall
mallet
mambo
mammoth
man
management
manager
mandarin
mandolin
mangrove
manhunt
maniac
manicure
manner
manor
mansard
manservant
mansion
mantel
mantle
mantua
manufacturer
manx
map
maple
maraca
maracas
marble
march
mare
margaret
margin
maria
mariachi
marimba
mark
market
marketing
marksman
marriage
marsh
marshland
marxism
mary
mascara
mask
mass
massage
master
mastication
mastoid
mat
match
material
math
mattock
mattress
maximum
may
maybe
mayonnaise
mayor
meal
meaning
measure
meat
mechanic
medicine
medium
meet
meeting
megalomaniac
melody
member
membership
memory
men
menorah
mention
menu
mercury
mess
message
metal
metallurgist
meteor
meteorology
meter
methane
method
methodology
metro
metronome
mexican
mexico
mezzanine
mice
michael
michelle
microlending
microwave
mid-course
middle
middleman
midi
midline
midnight
midwife
might
migrant
mile
milk
milkshake
millennium
millimeter
millisecond
mime
mimosa
mind
mine
mini
minibus
minion
mini-skirt
minister
minor
minor-league
mint
minute
mirror
miscarriage
miscommunication
misfit
misogyny
misplacement
misreading
missile
mission
mist
mistake
mister
miter
mitten
mix
mixer
mixture
moat
mobile
moccasins
mocha
mode
model
modem
mole
mom
moment
monastery
monasticism
monday
money
monger
monitor
monkey
monocle
monotheism
monsoon
monster
month
mood
moon
moonscape
moonshine
mop
Mormon
morning
morocco
morsel
mortise
mosque
mosquito
most
motel
moth
mother
mother-in-law
motion
motor
motorboat
motorcar
motorcycle
mound
mountain
mouse
mouser
mousse
moustache
mouth
mouton
move
mover
movie
mower
mug
mukluk
mule
multimedia
muscle
musculature
museum
music
music-box
musician
music-making
mustache
mustard
mutt
myanmar
mycoplasma
nail
name
naming
nancy
nanoparticle
napkin
narcissus
nation
naturalisation
nature
neat
neck
necklace
necktie
necromancer
need
needle
negligee
neologism
neon
nepal
nephew
nerve
nest
net
netball
netbook
netsuke
network
neurobiologist
neuropathologist
neuropsychiatry
news
newspaper
newsprint
newsstand
nexus
nic
nicety
niche
nickel
niece
nigeria
night
nightclub
nightgown
nightingale
nightlight
nitrogen
node
noise
nonbeliever
nonconformist
nondisclosure
noodle
normal
norse
north
north america
north korea
norwegian
nose
note
notebook
notice
notify
notoriety
nougat
novel
november
nudge
number
numeracy
numeric
numismatist
nurse
nursery
nurture
nut
nylon
oak
oar
oasis
oatmeal
obi
objective
obligation
oboe
observation
observatory
occasion
occupation
ocean
ocelot
octagon
octave
octavo
octet
october
octopus
odometer
oeuvre
offence
offer
office
official
off-ramp
oil
okra
oldie
olive
omega
omelet
oncology
one
onion
open
opening
opera
operation
ophthalmologist
opinion
opium
opossum
opportunist
opportunity
opposite
option
orange
orangutan
orator
orchard
orchestra
orchid
order
ordinary
ordination
organ
organisation
organization
original
ornament
osmosis
osprey
ostrich
others
otter
ottoman
ounce
outback
outcome
outfit
outhouse
outlay
output
outrigger
outset
outside
oval
ovary
oven
overcharge
overclocking
overcoat
overexertion
overflight
overnighter
overshoot
owl
owner
ox
oxen
oxford
oxygen
oyster
pacemaker
pack
package
packet
pad
paddle
paddock
page
pagoda
pail
pain
paint
painter
painting
paintwork
pair
pajama
pajamas
pakistan
paleontologist
paleontology
palm
pamphlet
pan
pancake
pancreas
panda
panic
pannier
panpipe
pansy
panther
panties
pantry
pants
pantsuit
panty
pantyhose
paper
paperback
parable
parachute
parade
parallelogram
paramedic
parcel
parchment
parent
parentheses
park
parka
parrot
parsnip
part
participant
particle
particular
partner
partridge
party
passage
passbook
passenger
passive
pasta
paste
pastor
pastoralist
pastry
patch
path
patient
patina
patio
patriarch
patricia
patrimony
patriot
patrol
pattern
paul
pavement
pavilion
paw
pawnshop
payee
payment
pea
peace
peach
peacoat
peacock
peak
peanut
pear
pearl
pedal
pedestrian
pediatrician
peen
peer
peer-to-peer
pegboard
pelican
pelt
pen
penalty
pencil
pendant
pendulum
penicillin
pension
pentagon
peony
people
pepper
percentage
perch
perfume
period
periodical
peripheral
permafrost
permission
permit
perp
persian
person
personality
peru
pest
pet
petal
petticoat
pew
pharmacist
pharmacopoeia
phase
pheasant
philippines
philosopher
philosophy
phone
photo
photographer
phrase
physical
physician
pianist
piano
piccolo
pick
pickax
picket
pickle
picture
pie
piece
pier
piety
pig
pigeon
pike
pile
pilgrimage
pillbox
pillow
pilot
pimp
pimple
pin
pinafore
pince-nez
pine
pineapple
pinecone
ping
pink
pinkie
pinstripe
pint
pinto
pinworm
pioneer
pipe
piracy
piranha
pisces
piss
pitch
pitching
pith
pizza
place
plain
plane
planet
plant
plantation
planter
plaster
plasterboard
plastic
plate
platform
platinum
platypus
play
player
playground
playroom
pleasure
pleated
plier
plot
plough
plover
plow
plowman
plume
plunger
plywood
pneumonia
pocket
pocketbook
pocket-watch
poet
poignance
point
poison
poisoning
poland
pole
polenta
police
policeman
policy
polish
politician
pollution
polo
polyester
pompom
poncho
pond
pony
poof
pool
popcorn
poppy
popsicle
population
populist
porch
porcupine
port
porter
portfolio
porthole
position
positive
possibility
postage
postbox
poster
pot
potato
potential
potty
pouch
poultry
pound
pounding
powder
power
precedent
precipitation
preface
prelude
premeditation
premier
preoccupation
preparation
presence
presentation
president
pressroom
pressure
pressurisation
price
pride
priest
priesthood
primary
primate
prince
princess
principal
print
printer
priority
prison
prize
prizefight
probation
problem
procedure
process
processing
produce
producer
product
production
professional
professor
profit
program
project
promotion
prompt
proof-reader
propane
property
prose
prosecution
protest
protocol
prow
pruner
pseudoscience
psychiatrist
psychoanalyst
psychologist
psychology
ptarmigan
publisher
pudding
puddle
puffin
pull
pulley
puma
pump
pumpkin
pumpkinseed
punch
punishment
pupa
pupil
puppy
purchase
puritan
purple
purpose
purse
push
pusher
put
pvc
pyjama
pyramid
quadrant
quail
quality
quart
quarter
quartz
queen
question
quicksand
quiet
quill
quilt
quince
quit
quiver
quotation
rabbi
rabbit
raccoon
race
racer
racing
racist
rack
radar
radiator
radio
radiosonde
radish
raffle
raft
rag
rage
rail
railway
raiment
rain
rainbow
raincoat
rainmaker
rainstorm
raise
rake
ram
rambler
ramie
ranch
random
randomisation
range
rank
raspberry
rat
rate
ratio
raven
ravioli
raw
rawhide
ray
rayon
reactant
reaction
read
reading
reamer
rear
reason
receipt
reception
recess
recliner
recognition
recommendation
record
recorder
recover
recruit
rectangle
red
redesign
rediscovery
reduction
reef
refectory
reflection
refrigerator
refund
refuse
region
register
regret
regular
regulation
reindeer
reinscription
reject
relation
relative
religion
relish
reminder
rent
repair
reparation
repeat
replace
replication
reply
report
representative
reprocessing
request
resale
research
resident
resist
resolution
respect
respite
responsibility
rest
restaurant
result
retailer
rethinking
retina
retouch
return
reveal
revenant
review
revolution
revolve
revolver
reward
rheumatism
rhinoceros
rhyme
rhythm
rice
richard
riddle
ride
rider
ridge
rifle
right
rim
ring
ringworm
ripple
rise
riser
risk
river
riverbed
rivulet
road
roadway
roast
robe
robert
robin
rock
rocker
rocket
rocket-ship
rod
role
roll
roller
romania
romanian
ronald
roof
room
rooster
root
rope
rose
rostrum
rotate
roundabout
route
router
routine
row
rowboat
royal
rub
rubber
rubric
ruckus
ruffle
rugby
rule
run
runaway
runner
russia
russian
rutabaga
ruth
sabre
sack
sad
saddle
safe
safety
sage
sagittarius
sail
sailboat
sailor
salad
salary
sale
salesman
salmon
salon
saloon
salt
samovar
sampan
sample
samurai
sand
sandals
sandbar
sandra
sandwich
santa
sarah
sardine
sari
sarong
sash
satellite
satin
satire
saturday
sauce
saudi arabia
sausage
save
saving
savior
saviour
saw
saxophone
scale
scallion
scanner
scarecrow
scarf
scarification
scene
scent
schedule
scheme
schizophrenic
schnitzel
school
schoolhouse
schooner
science
scimitar
scissors
scooter
score
scorn
scorpio
scorpion
scow
scraper
screamer
screen
screenwriting
screw
screwdriver
screw-up
scrim
scrip
sculpting
sculpture
sea
seagull
seal
seaplane
search
seashore
season
seat
second
secretariat
secretary
section
sectional
sector
secure
security
seed
seeder
segment
select
selection
self
sell
semicircle
semicolon
senator
sense
sentence
sepal
september
septicaemia
series
servant
server
service
session
set
setting
settler
sewer
sex
shack
shade
shadow
shadowbox
shake
shakedown
shaker
shallot
shame
shampoo
shanty
shape
share
shark
sharon
shawl
shearling
shears
sheath
shed
sheep
sheet
shelf
shell
sherry
shield
shift
shin
shine
shingle
ship
shirt
shirtdress
shoat
shock
shoe
shoehorn
shoe-horn
shoelace
shoemaker
shoes
shoestring
shofar
shoot
shootdown
shop
shopper
shopping
shore
shortage
shorts
shortwave
shot
shoulder
shovel
show
shower
show-stopper
shred
shrimp
shrine
siamese
siberian
sibling
sick
side
sideboard
sideburns
sidecar
sidestream
sidewalk
siding
sign
signature
signet
significance
signup
silica
silk
silkworm
sill
silo
silver
simple
sing
singer
single
sink
sister
sister-in-law
sit
sitar
size
skate
skiing
skill
skin
skirt
skulduggery
skull
skullcap
skullduggery
skunk
sky
skylight
skyscraper
skywalk
slapstick
slash
slave
sled
sledge
sleep
sleet
sleuth
slice
slider
slime
slip
slipper
slippers
slope
sloth
smash
smell
smelting
smile
smock
smog
smoke
smuggling
snail
snake
snakebite
sneakers
sneeze
snob
snorer
snow
snowboarding
snowflake
snowman
snowmobiling
snowplow
snowstorm
snowsuit
snuggle
soap
soccer
society
sociology
sock
socks
soda
sofa
softball
softdrink
softening
software
soil
soldier
solid
solitaire
sombrero
somersault
somewhere
son
song
songbird
sonnet
soot
soprano
sorbet
sort
soulmate
sound
soup
source
sourwood
sousaphone
south
south africa
south america
south korea
sow
soy
soybean
space
spacing
spade
spaghetti
spain
spandex
spank
spark
sparrow
spasm
speaker
speakerphone
spear
special
specialist
specific
spectacle
spectacles
spectrograph
speedboat
spend
sphere
sphynx
spider
spike
spinach
spine
spiral
spirit
spiritual
spite
spleen
split
sponge
spoon
sport
spot
spotlight
spray
spread
spring
sprinter
sprout
spruce
spume
spur
spy
square
squash
squatter
squeegee
squid
squirrel
stable
stack
stacking
stadium
staff
stag
stage
stain
stair
staircase
stallion
stamen
stamina
stamp
stance
standoff
star
start
starter
state
statement
station
station-wagon
statistic
statistician
steak
steal
steam
steamroller
steel
steeple
stem
stencil
step
step-aunt
step-brother
stepdaughter
step-daughter
step-father
step-grandfather
step-grandmother
stepmother
step-mother
stepping-stone
steps
step-sister
stepson
step-son
step-uncle
steven
stew
stick
stiletto
still
stinger
stitch
stock
stocking
stockings
stock-in-trade
stole
stomach
stone
stonework
stool
stop
stopsign
stopwatch
store
storey
storm
story
storyboard
story-telling
stove
strait
stranger
strap
straw
strawberry
stream
street
streetcar
stress
stretch
strike
string
strip
structure
struggle
stud
student
studio
study
stuff
stumbling
sturgeon
style
styling
stylus
subcomponent
subconscious
submarine
subroutine
subsidence
substance
suburb
subway
success
suck
sudan
suede
suffocation
sugar
suggestion
suit
suitcase
sultan
summer
sun
sunbeam
sunbonnet
sunday
sundial
sunflower
sunglasses
sunlamp
sunroom
sunshine
supermarket
supply
support
supporter
suppression
surface
surfboard
surgeon
surgery
surname
surprise
susan
sushi
suspect
suspenders
sustainment
SUV
swallow
swamp
swan
swath
sweat
sweater
sweats
sweatshirt
sweatshop
sweatsuit
swedish
sweets
swell
swim
swimming
swimsuit
swing
swiss
switch
switchboard
swivel
sword
swordfish
sycamore
syndicate
synergy
synod
syria
syrup
system
tabby
tabernacle
table
tablecloth
tabletop
tachometer
tackle
tadpole
tail
tailor
tailspin
taiwan
tale
talk
tam
tambour
tambourine
tam-o'-shanter
tandem
tangerine
tank
tanker
tankful
tank-top
tanzania
tap
target
tassel
taste
tatami
tattler
tattoo
taurus
tavern
tax
taxi
taxicab
tea
teacher
teaching
team
tear
technician
technologist
technology
teen
teeth
telephone
telescreen
teletype
television
teller
temp
temper
temperature
temple
tempo
temporariness
temptress
tendency
tenement
tennis
tenor
tent
tepee
term
terracotta
terrapin
territory
test
text
textbook
texture
thailand
thanks
thaw
theater
theism
theme
theoretician
theory
therapist
thermals
thermometer
thigh
thing
thinking
thistle
thomas
thong
thongs
thorn
thought
thread
thrill
throat
throne
thrush
thumb
thunder
thunderbolt
thunderhead
thunderstorm
thursday
tiara
tic
ticket
tie
tiger
tight
tights
tile
till
timbale
time
timeline
timeout
timer
timpani
tin
tinderbox
tinkle
tintype
tip
tire
tissue
titanium
title
toad
toast
toe
toenail
toga
togs
toilet
tom
tomato
tomography
tomorrow
tom-tom
ton
tongue
toot
tooth
toothbrush
toothpaste
toothpick
top
top-hat
topsail
toque
torchiere
toreador
tornado
torso
tortellini
tortoise
tosser
total
tote
touch
tough
tough-guy
tour
tourist
towel
tower
town
townhouse
tow-truck
toy
trachoma
track
tracksuit
tractor
trade
tradition
traditionalism
traffic
trail
trailer
train
trainer
training
tram
tramp
transaction
translation
transmission
transom
transport
trapdoor
trapezium
trapezoid
trash
travel
tray
treatment
tree
trellis
tremor
trench
trial
triangle
tribe
trick
trigonometry
trim
trinket
trip
tripod
trolley
trombone
trooper
trouble
trousers
trout
trove
trowel
truck
truckit
trumpet
trunk
trust
try
t-shirt
tsunami
tub
tuba
tube
tuesday
tugboat
tulip
tummy
tuna
tune
tune-up
tunic
tunnel
turban
turkey
turkish
turn
turnip
turnover
turnstile
turret
turtle
tussle
tutu
tuxedo
tv
twig
twilight
twine
twist
twister
two
typewriter
typhoon
tyvek
uganda
ukraine
ukrainian
ukulele
umbrella
unblinking
uncle
underclothes
underground
underneath
underpants
underpass
undershirt
underwear
underwire
unibody
uniform
union
unit
united kingdom
urn
use
usher
utensil
uzbekistan
vacation
vacuum
vagrant
valance
valley
valuable
value
van
vane
vanity
variation
variety
vase
vast
vault
vaulting
veal
vegetable
vegetarian
vehicle
veil
vein
veldt
vellum
velodrome
velvet
venezuela
venezuelan
venom
veranda
verdict
vermicelli
verse
version
vertigo
verve
vessel
vest
vestment
veterinarian
vibe
vibraphone
vibration
vietnam
view
villa
village
vineyard
vinyl
viola
violet
violin
virginal
virgo
virtue
viscose
vise
vision
visit
visitor
visor
vixen
voice
volcano
volleyball
voyage
vulture
wad
wafer
waffle
waist
waistband
waiter
waitress
walk
walker
walkway
wall
wallaby
wallet
walnut
walrus
wampum
wannabe
war
warden
warlock
warm-up
warning
wash
washbasin
washcloth
washer
washtub
wasp
waste
wastebasket
watch
watchmaker
water
waterbed
waterfall
waterskiing
waterspout
wave
wax
way
weakness
wealth
weapon
weasel
weather
web
wedge
wednesday
weed
weeder
weedkiller
week
weekend
weekender
weight
weird
well
west
western
wet-bar
wetsuit
whale
wharf
wheel
whip
whirlpool
whirlwind
whisker
whiskey
whistle
white
whole
wholesale
wholesaler
whorl
wife
wilderness
will
william
willow
wind
windage
wind-chime
window
windscreen
windshield
wine
wing
wingman
wingtip
winter
wire
wiseguy
wish
wisteria
witch
witch-hunt
withdrawal
witness
wolf
woman
wombat
women
wood
woodland
woodshed
woodwind
wool
woolen
word
work
workbench
worker
workhorse
worklife
workshop
world
worm
worthy
wound
wrap
wraparound
wrecker
wren
wrench
wrestler
wrinkle
wrist
writer
writing
wrong
xylophone
yacht
yak
yam
yard
yarmulke
yarn
yawl
year
yellow
yesterday
yew
yin
yogurt
yoke
young
youth
yugoslavian
yurt
zampone
zebra
zebrafish
zephyr
ziggurat
zinc
zipper
zither
zone
zoo
zoologist
zoology
zoot-suit
zucchini`;
let source = {};
(function () {
    source.adjectives = adjectives.split('\n').filter(x => x);
    source.adCount = source.adjectives.length;
    source.nouns = nouns.split('\n').filter(x => x);
    source.nounCount = source.nouns.length;
})()