import ModifierEditor from "../components/music/ModifierEditor";
import { TitlesService } from "../title-service";
import { convertToMelody, convertToMelodyNote, flat, uuidv4 } from "./base-lib";
import Vex from 'vexflow'
import { createModifierName } from "./nameService";
const VF = Vex.Flow;

export const MODIFIER_VIEW_DIVISIONS = Math.pow(2, 6) * Math.pow(3, 3) * Math.pow(5, 2) * Math.pow(7, 2);
export const ModifierTypes = {
    Beat: 'beat',
    Arpeggio: 'arpeggio',
    Split: 'split',
    Rest: 'rest',
    StepTone: 'steptone',
    Midi: 'midi'
}
export const StepTonalOptions_Step = [...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reverse().map(v => -v), 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((t) => {
    return {
        value: t,
        title: () => { return t; }
    }
});
export const MIDI_LINK_MAPPING = {
    VoiceMapping: 'voice-mapping',
    ModuloVoiceMapping: 'modulo-voice-mapping',
    LiccMapping: 'licc-mapping'
}
export const MidiLinkMapping = [{
    value: 'default',
    title: () => TitlesService('DefaultMapping')
}, {
    value: MIDI_LINK_MAPPING.VoiceMapping,
    title: () => TitlesService('VoiceMapping')
}, {
    value: MIDI_LINK_MAPPING.ModuloVoiceMapping,
    title: () => TitlesService('ModuloVoiceMapping')
}]
export const ArpeggioOptions = [{
    value: 'up',
    title: () => TitlesService('up')
}, {
    value: 'down',
    title: () => TitlesService('down')
}];
export const UseOptions = [{
    value: false,
    title: () => TitlesService('Disabled')
}, {
    value: true,
    title: () => TitlesService('Enabled')
}]
export const ArpeggioDivisions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(x => {
    return {
        value: x,
        title: () => x
    }
});
export const getModifierNotation = (modifier, modifierItem, defaultNotes, licks) => {

    let deepest_items = getDeepestModifierItems(modifier, modifierItem);

    console.log(deepest_items)
    return deepest_items.map((deepItem) => {
        return getVexFlowNotationForItem(deepItem, modifier, defaultNotes, licks);
    }).flat()

}

const NoteType = {
    w: 1,
    dh: .5 + .25,
    h: .5,
    dq: .25 + .125,
    q: .25,
    d8: .125 + .125 / 2,
    '8': .125,
    '16d': .125 / 2 + .125 / 4,
    '16': .125 / 2,
    '32d': .125 / 4 + .125 / 8,
    '32': .125 / 4
}
const getVexFlowNotationForItem = (item, modifier, defaultNotes, licks = null) => {
    let notes = getNotesForItem(item, modifier, defaultNotes, licks);

    return notes;
}

const getNotesForItem = (item, modifier, defaultNotes, licks) => {
    let itemAbove = getItemAbove(item, modifier);
    console.log(itemAbove);
    let notes;
    if (itemAbove) {
        notes = getNotesForItem(itemAbove, modifier, defaultNotes, licks);
    }
    else {
        notes = defaultNotes;
    }
    return transformNotes(notes, item, modifier, licks);
}
function transformNotes(notes, item, modifier, licks) {
    notes = JSON.parse(JSON.stringify(notes));
    switch (item.type) {
        case ModifierTypes.Rest:
            return transformNotesToRest(notes, item, modifier);
        case ModifierTypes.Midi:
            return transformNotesToMidi(notes, item, modifier, licks);
        case ModifierTypes.Arpeggio:
            return transformNotesToArpeggio(notes, item, modifier);
        case ModifierTypes.Split:
            return transformNotesToSplit(notes, item, modifier);
        case ModifierTypes.StepTone:
            return transformNotesToStepTone(notes, item, modifier);
    }
}
function transformNotesToStepTone(notes, item, modifier) {
    if (isNaN(item.args.type)) {
        return notes;
    }
    return notes.map((note) => {
        return ({
            ...note,
            midi: note.midi + parseInt(item.args.type)
        })
    });
}
/// Splits are only performed on other splits, so the notes 
/// are assumed to be moved to the beginning of the time period of the item.
function transformNotesToSplit(notes, item, modifier) {

    return notes.map((note) => {
        return ({
            ...note,
            time: item.position.x,
            duration: item.position.w
        })
    });
}
function transformNotesToArpeggio(notes, item, modifier) {
    let noteCount = notes.length;
    //This may be not great for perfect timing.
    let divisions = noteCount;
    if (item.args.useDivisions) {
        if (!isNaN(item.args.divisions) && parseInt(item.args.divisions) > 0)
            divisions = parseInt(item.args.divisions)
    }
    let durationPerNote = Math.floor(item.position.w / divisions);
    notes = notes.sort((b, a) => {
        switch (item.args.type) {
            case 'up':
                return b.midi - a.midi;
            case 'down':
            default:
                return a.midi - b.midi;
        }

    })
    let result = [];
    for (let i = 0; i < divisions; i++) {
        let v = notes[i % Math.min(noteCount, divisions)];
        result.push({
            ...v,
            time: item.position.x + (i * durationPerNote),
            duration: durationPerNote
        })
    }
    return result;
}
function transformNotesToMidi(notes, item, modifier, licks) {
    if (item?.args?.lick && licks) {
        let lick = licks[item?.args?.lick];
        if (lick) {
            let voices = {};
            let midi_notes = [];
            lick.notes.map((note) => {
                voices[note.midi] = true;
                if (midi_notes.indexOf(note.midi) === -1) {
                    midi_notes.push(note.midi);
                }
            });
            let min_note = Math.min(...midi_notes);
            let max_note = Math.max(...midi_notes);
            let notes_in_notes = notes.map(x => x.midi).unique();
            let min_note_in_notes = Math.min(...notes_in_notes);
            let voice_indexes = Object.keys(voices);
            let maximumTime = lick.notes.maximum(v => v.durationTicks + v.ticks);
            let minimumTime = lick.notes.minimum(v => v.ticks);
            let totalTime = maximumTime - minimumTime;
            let itemStartTime = item.position.x;
            let itemPlayTime = item.position.w;
            switch (item.args.midiMap) {
                case MIDI_LINK_MAPPING.ModuloVoiceMapping:
                    return lick.notes.map((note) => {
                        let voice = (note.midi - min_note) % 12;
                        if (notes_in_notes.indexOf(min_note_in_notes + voice) !== -1) {
                            let noteDuration = itemPlayTime * (note.durationTicks / totalTime);
                            let noteStart = itemPlayTime * (note.ticks / totalTime);
                            let vi = min_note_in_notes + voice;
                            let n_ = notes.find(v => v.midi === vi)
                            return {
                                ...n_,
                                time: itemStartTime + noteStart,
                                duration: noteDuration
                            }
                        }
                    }).filter(x => x)
                    break;
                case MIDI_LINK_MAPPING.VoiceMapping:
                    return lick.notes.map((note) => {
                        let voice = note.midi - min_note;
                        if (notes_in_notes.indexOf(min_note_in_notes + voice) !== -1) {
                            let noteDuration = itemPlayTime * (note.durationTicks / totalTime);
                            let noteStart = itemPlayTime * (note.ticks / totalTime);
                            let vi = min_note_in_notes + voice;
                            let n_ = notes.find(v => v.midi === vi)
                            return {
                                ...n_,
                                time: itemStartTime + noteStart,
                                duration: noteDuration
                            }
                        }
                    }).filter(x => x)
                default:
                    return lick.notes.map((note) => {
                        let vi = voice_indexes.indexOf(`${note.midi}`);
                        if (notes[vi]) {
                            let noteDuration = itemPlayTime * (note.durationTicks / totalTime);
                            let noteStart = itemPlayTime * (note.ticks / totalTime)
                            return {
                                ...notes[vi],
                                time: itemStartTime + noteStart,
                                duration: noteDuration
                            }
                        }
                    }).filter(x => x);
            }
        }
    }
    else if (item.args.liccData) {
        let voices = {};
        let midi_notes = [];
        let lick = item.args.liccData;
        lick.notes.map((note) => {
            voices[note.midi] = true;
            if (midi_notes.indexOf(note.midi) === -1) {
                midi_notes.push(note.midi);
            }
        });

        let min_note = Math.min(...midi_notes);
        let max_note = Math.max(...midi_notes);
        let notes_in_notes = notes.map(x => x.midi).unique();
        let min_note_in_notes = Math.min(...notes_in_notes);
        let voice_indexes = Object.keys(voices);
        let maximumTime = lick.notes.maximum(v => v.durationTicks + v.ticks);
        let minimumTime = lick.notes.minimum(v => v.ticks);
        let totalTime = maximumTime - minimumTime;
        let itemStartTime = item.position.x;
        let itemPlayTime = item.position.w;
        switch (item.args.midiMap) {
            case MIDI_LINK_MAPPING.LiccMapping:
                console.log(modifier);
                return [...notes.filter(x => notes_in_notes.indexOf(x.midi) > midi_notes.length), ...lick.notes.map(note => {
                    let noteDuration = itemPlayTime * (note.durationTicks / totalTime);
                    let noteStart = itemPlayTime * (note.ticks / totalTime)
                    return {
                        ...note,
                        time: itemStartTime + noteStart,
                        duration: noteDuration,
                        midi: notes_in_notes[midi_notes.indexOf(note.midi)]
                    }
                })]
                break;
        }
    }
    return notes;
}
function transformNotesToRest(notes, item) {
    return [{
        midi: 60,
        rest: true,
        time: item.position.x,
        duration: item.position.w
    }]
}
function getItemAbove(item, modifier) {
    let overlappingItems = getOverlappingItems(modifier, item);
    let sortedOverlappingItems = overlappingItems.sort((b, a) => {
        return a.position.y - b.position.y;
    });
    let itemsAbove = sortedOverlappingItems.filter(x => x.position.y < item.position.y);

    return itemsAbove[0] || null;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
function newModifier(type) {
    let temp = {
        id: `modifier-item-${uuidv4()}`,
        position: {
            x: 0,
            y: 0,
            w: 16,
            h: 1
        },
        type,
        args: {},
        name: capitalizeFirstLetter(type)
    }
    temp.i = temp.id;
    return temp;
}
function getOverlappingDeeperItems(modifier, modifierItem, floorItem = null) {
    return modifier.spaces.filter(x => {
        return (x.id !== modifierItem.id) &&
            (floorItem === null || (floorItem !== null && floorItem.position.y >= x.position.y))
    }).filter(x => x.id !==
        modifierItem.id &&
        (
            (
                (x.position.y > modifierItem.position.y) &&
                doOverlap(
                    convertItemToPoint('l', modifierItem),
                    convertItemToPoint('r', modifierItem),
                    convertItemToPoint('l', x),
                    convertItemToPoint('r', x)
                )
            )
        )
    )
}
export function doItemsOverlap(item1, item2) {
    return doOverlap(
        convertItemToPoint('l', item1),
        convertItemToPoint('r', item1),
        convertItemToPoint('l', item2),
        convertItemToPoint('r', item2)
    );
}
function getOverlappingItems(modifier, modifierItem) {
    return modifier.spaces.filter(x =>
        x.id !== modifierItem.id &&
        doOverlap(
            convertItemToPoint('l', modifierItem),
            convertItemToPoint('r', modifierItem),
            convertItemToPoint('l', x),
            convertItemToPoint('r', x)
        )
    )
}
function convertItemToPoint(lORr, item) {
    switch (lORr) {
        case 'l':
            return { x: item.position.x, y: 10 };
        case 'r':
            return { x: item.position.x + item.position.w, y: 0 };

    }
}
export function isOverlapping(y, x) {
    return doOverlap(
        convertItemToPoint('l', y),
        convertItemToPoint('r', y),
        convertItemToPoint('l', x),
        convertItemToPoint('r', x)
    )
}
export function getOverlapAmount(y, x) {
    let d1x = convertItemToPoint('l', y).x;
    let d1xMax = d1x + Math.abs(convertItemToPoint('r', y).x - d1x);

    let d2x = convertItemToPoint('l', x).x;
    let d2xMax = d2x + Math.abs(convertItemToPoint('r', x).x - d2x);

    return Math.max(0, Math.min(d1xMax, d2xMax) - Math.max(d1x, d2x));
}
export function doOverlap(l1, r1, l2, r2) {

    // To check if either rectangle is actually a line
    // For example :  l1 ={-1,0}  r1={1,1}  l2={0,-1}
    // r2={0,1}

    if (l1.x == r1.x || l1.y == r1.y || l2.x == r2.x
        || l2.y == r2.y) {
        // the line cannot have positive overlap
        return false;
    }

    // If one rectangle is on left side of other
    if (l1.x >= r2.x || l2.x >= r1.x)
        return false;

    // If one rectangle is above other
    if (r1.y >= l2.y || r2.y >= l1.y)
        return false;

    return true;
}
export const isModifierReady = (state) => {
    return !!(state.currentModifier && state.modifiers && state.modifiers[state.currentModifier]);
}
export const getCurrentModifier = (state) => {
    return state.currentModifier && state.modifiers && state.modifiers[state.currentModifier];
}
export const getSelectedModifierItem = (state) => {
    let modifier = getCurrentModifier(state);
    if (modifier && modifier.selectedItem) {
        return modifier.spaces.find(x => x.id === modifier.selectedItem);
    }
    return null;
}
export const canAddTonalModifier = (state) => {
    if (isModifierReady(state)) {
        let item = getSelectedModifierItem(state);
        if (item) {
            switch (item.type) {
                case ModifierTypes.Rest:
                case ModifierTypes.Midi:
                case ModifierTypes.Arpeggio:
                case ModifierTypes.Split:
                case ModifierTypes.StepTone:
                    return true;
            }
        }
    }
    return false;
}
export const canAddModifier = (state) => {
    if (isModifierReady(state)) {
        let item = getSelectedModifierItem(state);
        if (item) {
            switch (item.type) {
                case ModifierTypes.Rest:
                case ModifierTypes.Midi:
                case ModifierTypes.Arpeggio:
                    return false;
                case ModifierTypes.Split:
                    return canSplitModifier(state);
            }
        }
        return true;
    }
    return false;
}
export const canSplitModifier = (state) => {
    if (isModifierReady(state)) {
        let modifier = getCurrentModifier(state);
        if (modifier && modifier.spaces.length === 0) {
            return true;
        }
        else if (modifier) {
            let modifierItem = getSelectedModifierItem(state)
            if (modifierItem && modifierItem.type === ModifierTypes.Split) {
                let items = getOverlappingDeeperItems(modifier, modifierItem)
                return items.length === 0;
            }
        }
    }
    return false;
};
export const getDeepestModifers = (state) => {
    if (isModifierReady(state)) {
        let modifier = getCurrentModifier(state);
        if (modifier && modifier.spaces.length === 0) {
            return [];
        }
        else if (modifier) {
            return getDeepestModifierItems(modifier);
        }
    }
    return [];
}
export const getDeepestModifier = (state) => {
    let deepest = state.modifiers[state.currentModifier].spaces.maxSelection(x => {
        return x.position.y;
    });
    return deepest;
}
export const createLiccModifier = (liccData) => {
    let licc_guid = uuidv4();
    let split_guid = uuidv4();
    let id = uuidv4();
    let name = createModifierName();
    let data = {
        "id": `item-${id}`,
        "spaces": [{
            "id": `modifier-item-${split_guid}`,
            "position": {
                "x": 0, "y": 1, "w": 2116800, "h": 1, "isDraggable": false, "isResizable": false
            },
            "type": "split",
            "args": {},
            "name": "Split",
            "i": `modifier-item-${split_guid}`,
            "deleteDependents": [`modifier-item-${split_guid}`], "isRowWide": true
        }, {
            "id": `modifier-item-${licc_guid}`,
            "position": {
                "x": 0, "y": 2, "w": 2116800, "h": 1
            },
            "type": "midi",
            "args": {
                liccData,
                "midiMap": "licc-mapping"
            },
            "name": "Midi",
            "i": `modifier-item-${licc_guid}`
        }],
        "name": name,
        "selectedItem": `modifier-item-${licc_guid}`
    };

    return data;
}
export const createModifier = (args, state) => {
    let { type, value } = args;
    let modifier = newModifier(type);
    let currentModifierItem;
    let modifiers = [];
    switch (type) {
        case ModifierTypes.Beat:
            modifier.args = {
                division: value
            }
            modifiers.push(modifier)
            break;
        case ModifierTypes.StepTone:
            modifier.args.type = StepTonalOptions_Step[0].value;
            currentModifierItem = addStandard(currentModifierItem, state, modifier, modifiers);
            break;
        case ModifierTypes.Arpeggio:
            modifier.args.type = ArpeggioOptions[0].value;
        case ModifierTypes.Rest:
        case ModifierTypes.Midi:
            currentModifierItem = getSelectedModifierItem(state);
            currentModifierItem = addStandard(currentModifierItem, state, modifier, modifiers);
            break;
        case ModifierTypes.Split:
            let total = 0;
            let deepest = getDeepestModifier(state);
            let splitModifiers = args.ratios.map((t, index) => {
                total += t;
                return newModifier(type);
            });
            let singleColumnValue = MODIFIER_VIEW_DIVISIONS / total;
            let leftOver = MODIFIER_VIEW_DIVISIONS - (Math.floor(singleColumnValue) * total);

            let posX = 0;
            let posY = 0;
            currentModifierItem = getSelectedModifierItem(state);
            if (currentModifierItem) {
                let modifier_view_size = currentModifierItem.position.w;
                posX = currentModifierItem.position.x;
                singleColumnValue = modifier_view_size / total;
                leftOver = modifier_view_size - (Math.floor(singleColumnValue) * total);
            }

            singleColumnValue = Math.floor(singleColumnValue);
            if (leftOver === 0) {
                console.log('--- perfect ratio ---');
            }
            if (deepest) {
                posY = deepest.position.y + 1;
            }
            splitModifiers.map((mod, index) => {
                mod.position.x += posX;
                mod.position.w = args.ratios[index] * singleColumnValue;
                mod.position.y = posY;
                mod.position.isDraggable = false;
                mod.position.isResizable = false;
                mod.deleteDependents = splitModifiers.map(v => v.id);
                mod.isRowWide = true; // This modifier must take the whole row, and it is not moveable.
                posX = mod.position.x + mod.position.w;
                if (index === splitModifiers.length - 1 && leftOver) {
                    mod.position.w += leftOver;
                }
            });
            modifiers = [...splitModifiers]
            break;
        default:
            throw 'undefined modifier case';
    }
    return modifiers
}

function getDeepestModifierItems(modifier, floorItem = null) {
    return modifier.spaces.filter(modifierItem => {
        let items = getOverlappingDeeperItems(modifier, modifierItem, floorItem);
        if (floorItem) {
            return items.length === 0 && floorItem.position.y >= modifierItem.position.y;
        }
        return items.length === 0;
    });
}

function addStandard(currentModifierItem, state, modifier, modifiers) {
    currentModifierItem = getSelectedModifierItem(state);
    if (currentModifierItem) {
        modifier.position.w = currentModifierItem.position.w;
        modifier.position.x = currentModifierItem.position.x;
    }

    modifier.position.y = Infinity;
    modifiers.push(modifier);
    return currentModifierItem;
}
