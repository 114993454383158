export default [
    "drums/Dancehall and Latin Pattern 1.mid",
    "drums/Dancehall and Latin Pattern 10.mid",
    "drums/Dancehall and Latin Pattern 11.mid",
    "drums/Dancehall and Latin Pattern 12.mid",
    "drums/Dancehall and Latin Pattern 13.mid",
    "drums/Dancehall and Latin Pattern 14.mid",
    "drums/Dancehall and Latin Pattern 15.mid",
    "drums/Dancehall and Latin Pattern 16.mid",
    "drums/Dancehall and Latin Pattern 17.mid",
    "drums/Dancehall and Latin Pattern 18.mid",
    "drums/Dancehall and Latin Pattern 19.mid",
    "drums/Dancehall and Latin Pattern 2.mid",
    "drums/Dancehall and Latin Pattern 20.mid",
    "drums/Dancehall and Latin Pattern 21.mid",
    "drums/Dancehall and Latin Pattern 22.mid",
    "drums/Dancehall and Latin Pattern 23.mid",
    "drums/Dancehall and Latin Pattern 24.mid",
    "drums/Dancehall and Latin Pattern 25.mid",
    "drums/Dancehall and Latin Pattern 26.mid",
    "drums/Dancehall and Latin Pattern 27.mid",
    "drums/Dancehall and Latin Pattern 28.mid",
    "drums/Dancehall and Latin Pattern 29.mid",
    "drums/Dancehall and Latin Pattern 3.mid",
    "drums/Dancehall and Latin Pattern 30.mid",
    "drums/Dancehall and Latin Pattern 31.mid",
    "drums/Dancehall and Latin Pattern 32.mid",
    "drums/Dancehall and Latin Pattern 33.mid",
    "drums/Dancehall and Latin Pattern 34.mid",
    "drums/Dancehall and Latin Pattern 35.mid",
    "drums/Dancehall and Latin Pattern 36.mid",
    "drums/Dancehall and Latin Pattern 37.mid",
    "drums/Dancehall and Latin Pattern 38.mid",
    "drums/Dancehall and Latin Pattern 39.mid",
    "drums/Dancehall and Latin Pattern 4.mid",
    "drums/Dancehall and Latin Pattern 40.mid",
    "drums/Dancehall and Latin Pattern 41.mid",
    "drums/Dancehall and Latin Pattern 5.mid",
    "drums/Dancehall and Latin Pattern 6.mid",
    "drums/Dancehall and Latin Pattern 7.mid",
    "drums/Dancehall and Latin Pattern 8.mid",
    "drums/Dancehall and Latin Pattern 9.mid",
    "drums/Drum & Bass Pattern 1.mid",
    "drums/Drum & Bass Pattern 10.mid",
    "drums/Drum & Bass Pattern 11.mid",
    "drums/Drum & Bass Pattern 12.mid",
    "drums/Drum & Bass Pattern 13.mid",
    "drums/Drum & Bass Pattern 14.mid",
    "drums/Drum & Bass Pattern 15.mid",
    "drums/Drum & Bass Pattern 16.mid",
    "drums/Drum & Bass Pattern 17.mid",
    "drums/Drum & Bass Pattern 18.mid",
    "drums/Drum & Bass Pattern 19.mid",
    "drums/Drum & Bass Pattern 2.mid",
    "drums/Drum & Bass Pattern 20.mid",
    "drums/Drum & Bass Pattern 21.mid",
    "drums/Drum & Bass Pattern 22.mid",
    "drums/Drum & Bass Pattern 23.mid",
    "drums/Drum & Bass Pattern 24.mid",
    "drums/Drum & Bass Pattern 25.mid",
    "drums/Drum & Bass Pattern 26.mid",
    "drums/Drum & Bass Pattern 27.mid",
    "drums/Drum & Bass Pattern 3.mid",
    "drums/Drum & Bass Pattern 4.mid",
    "drums/Drum & Bass Pattern 5.mid",
    "drums/Drum & Bass Pattern 6.mid",
    "drums/Drum & Bass Pattern 7.mid",
    "drums/Drum & Bass Pattern 8.mid",
    "drums/Drum & Bass Pattern 9.mid",
    "drums/Dubstep Pattern 1.mid",
    "drums/Dubstep Pattern 10.mid",
    "drums/Dubstep Pattern 11.mid",
    "drums/Dubstep Pattern 12.mid",
    "drums/Dubstep Pattern 13.mid",
    "drums/Dubstep Pattern 14.mid",
    "drums/Dubstep Pattern 15.mid",
    "drums/Dubstep Pattern 16.mid",
    "drums/Dubstep Pattern 17.mid",
    "drums/Dubstep Pattern 18.mid",
    "drums/Dubstep Pattern 19.mid",
    "drums/Dubstep Pattern 2.mid",
    "drums/Dubstep Pattern 20.mid",
    "drums/Dubstep Pattern 21.mid",
    "drums/Dubstep Pattern 22.mid",
    "drums/Dubstep Pattern 23.mid",
    "drums/Dubstep Pattern 24.mid",
    "drums/Dubstep Pattern 25.mid",
    "drums/Dubstep Pattern 26.mid",
    "drums/Dubstep Pattern 27.mid",
    "drums/Dubstep Pattern 28.mid",
    "drums/Dubstep Pattern 29.mid",
    "drums/Dubstep Pattern 3.mid",
    "drums/Dubstep Pattern 30.mid",
    "drums/Dubstep Pattern 31.mid",
    "drums/Dubstep Pattern 32.mid",
    "drums/Dubstep Pattern 33.mid",
    "drums/Dubstep Pattern 34.mid",
    "drums/Dubstep Pattern 35.mid",
    "drums/Dubstep Pattern 36.mid",
    "drums/Dubstep Pattern 37.mid",
    "drums/Dubstep Pattern 38.mid",
    "drums/Dubstep Pattern 39.mid",
    "drums/Dubstep Pattern 4.mid",
    "drums/Dubstep Pattern 40.mid",
    "drums/Dubstep Pattern 41.mid",
    "drums/Dubstep Pattern 42.mid",
    "drums/Dubstep Pattern 43.mid",
    "drums/Dubstep Pattern 44.mid",
    "drums/Dubstep Pattern 45.mid",
    "drums/Dubstep Pattern 5.mid",
    "drums/Dubstep Pattern 6.mid",
    "drums/Dubstep Pattern 7.mid",
    "drums/Dubstep Pattern 8.mid",
    "drums/Dubstep Pattern 9.mid",
    "drums/EDM Pattern 1.mid",
    "drums/EDM Pattern 10.mid",
    "drums/EDM Pattern 11.mid",
    "drums/EDM Pattern 12.mid",
    "drums/EDM Pattern 13.mid",
    "drums/EDM Pattern 14.mid",
    "drums/EDM Pattern 15.mid",
    "drums/EDM Pattern 16.mid",
    "drums/EDM Pattern 17.mid",
    "drums/EDM Pattern 18.mid",
    "drums/EDM Pattern 2.mid",
    "drums/EDM Pattern 3.mid",
    "drums/EDM Pattern 4.mid",
    "drums/EDM Pattern 5.mid",
    "drums/EDM Pattern 6.mid",
    "drums/EDM Pattern 7.mid",
    "drums/EDM Pattern 8.mid",
    "drums/EDM Pattern 9.mid",
    "drums/Hip hop and Rap Pattern 1.mid",
    "drums/Hip hop and Rap Pattern 10.mid",
    "drums/Hip hop and Rap Pattern 11.mid",
    "drums/Hip hop and Rap Pattern 12.mid",
    "drums/Hip hop and Rap Pattern 13.mid",
    "drums/Hip hop and Rap Pattern 14.mid",
    "drums/Hip hop and Rap Pattern 15.mid",
    "drums/Hip hop and Rap Pattern 16.mid",
    "drums/Hip hop and Rap Pattern 17.mid",
    "drums/Hip hop and Rap Pattern 18.mid",
    "drums/Hip hop and Rap Pattern 19.mid",
    "drums/Hip hop and Rap Pattern 2.mid",
    "drums/Hip hop and Rap Pattern 20.mid",
    "drums/Hip hop and Rap Pattern 21.mid",
    "drums/Hip hop and Rap Pattern 22.mid",
    "drums/Hip hop and Rap Pattern 23.mid",
    "drums/Hip hop and Rap Pattern 24.mid",
    "drums/Hip hop and Rap Pattern 25.mid",
    "drums/Hip hop and Rap Pattern 26.mid",
    "drums/Hip hop and Rap Pattern 27.mid",
    "drums/Hip hop and Rap Pattern 28.mid",
    "drums/Hip hop and Rap Pattern 29.mid",
    "drums/Hip hop and Rap Pattern 3.mid",
    "drums/Hip hop and Rap Pattern 30.mid",
    "drums/Hip hop and Rap Pattern 31.mid",
    "drums/Hip hop and Rap Pattern 32.mid",
    "drums/Hip hop and Rap Pattern 33.mid",
    "drums/Hip hop and Rap Pattern 34.mid",
    "drums/Hip hop and Rap Pattern 35.mid",
    "drums/Hip hop and Rap Pattern 36.mid",
    "drums/Hip hop and Rap Pattern 37.mid",
    "drums/Hip hop and Rap Pattern 38.mid",
    "drums/Hip hop and Rap Pattern 39.mid",
    "drums/Hip hop and Rap Pattern 4.mid",
    "drums/Hip hop and Rap Pattern 40.mid",
    "drums/Hip hop and Rap Pattern 41.mid",
    "drums/Hip hop and Rap Pattern 42.mid",
    "drums/Hip hop and Rap Pattern 43.mid",
    "drums/Hip hop and Rap Pattern 44.mid",
    "drums/Hip hop and Rap Pattern 45.mid",
    "drums/Hip hop and Rap Pattern 46.mid",
    "drums/Hip hop and Rap Pattern 47.mid",
    "drums/Hip hop and Rap Pattern 48.mid",
    "drums/Hip hop and Rap Pattern 49.mid",
    "drums/Hip hop and Rap Pattern 5.mid",
    "drums/Hip hop and Rap Pattern 50.mid",
    "drums/Hip hop and Rap Pattern 51.mid",
    "drums/Hip hop and Rap Pattern 52.mid",
    "drums/Hip hop and Rap Pattern 53.mid",
    "drums/Hip hop and Rap Pattern 54.mid",
    "drums/Hip hop and Rap Pattern 55.mid",
    "drums/Hip hop and Rap Pattern 56.mid",
    "drums/Hip hop and Rap Pattern 57.mid",
    "drums/Hip hop and Rap Pattern 58.mid",
    "drums/Hip hop and Rap Pattern 59.mid",
    "drums/Hip hop and Rap Pattern 6.mid",
    "drums/Hip hop and Rap Pattern 60.mid",
    "drums/Hip hop and Rap Pattern 61.mid",
    "drums/Hip hop and Rap Pattern 62.mid",
    "drums/Hip hop and Rap Pattern 63.mid",
    "drums/Hip hop and Rap Pattern 7.mid",
    "drums/Hip hop and Rap Pattern 8.mid",
    "drums/Hip hop and Rap Pattern 9.mid",
    "drums/House and Techno Pattern 1.mid",
    "drums/House and Techno Pattern 10.mid",
    "drums/House and Techno Pattern 11.mid",
    "drums/House and Techno Pattern 12.mid",
    "drums/House and Techno Pattern 13.mid",
    "drums/House and Techno Pattern 14.mid",
    "drums/House and Techno Pattern 15.mid",
    "drums/House and Techno Pattern 16.mid",
    "drums/House and Techno Pattern 17.mid",
    "drums/House and Techno Pattern 18.mid",
    "drums/House and Techno Pattern 19.mid",
    "drums/House and Techno Pattern 2.mid",
    "drums/House and Techno Pattern 20.mid",
    "drums/House and Techno Pattern 21.mid",
    "drums/House and Techno Pattern 22.mid",
    "drums/House and Techno Pattern 23.mid",
    "drums/House and Techno Pattern 24.mid",
    "drums/House and Techno Pattern 25.mid",
    "drums/House and Techno Pattern 26.mid",
    "drums/House and Techno Pattern 27.mid",
    "drums/House and Techno Pattern 28.mid",
    "drums/House and Techno Pattern 29.mid",
    "drums/House and Techno Pattern 3.mid",
    "drums/House and Techno Pattern 30.mid",
    "drums/House and Techno Pattern 31.mid",
    "drums/House and Techno Pattern 32.mid",
    "drums/House and Techno Pattern 33.mid",
    "drums/House and Techno Pattern 34.mid",
    "drums/House and Techno Pattern 35.mid",
    "drums/House and Techno Pattern 36.mid",
    "drums/House and Techno Pattern 37.mid",
    "drums/House and Techno Pattern 38.mid",
    "drums/House and Techno Pattern 39.mid",
    "drums/House and Techno Pattern 4.mid",
    "drums/House and Techno Pattern 40.mid",
    "drums/House and Techno Pattern 41.mid",
    "drums/House and Techno Pattern 42.mid",
    "drums/House and Techno Pattern 43.mid",
    "drums/House and Techno Pattern 44.mid",
    "drums/House and Techno Pattern 45.mid",
    "drums/House and Techno Pattern 46.mid",
    "drums/House and Techno Pattern 47.mid",
    "drums/House and Techno Pattern 48.mid",
    "drums/House and Techno Pattern 49.mid",
    "drums/House and Techno Pattern 5.mid",
    "drums/House and Techno Pattern 50.mid",
    "drums/House and Techno Pattern 51.mid",
    "drums/House and Techno Pattern 52.mid",
    "drums/House and Techno Pattern 53.mid",
    "drums/House and Techno Pattern 54.mid",
    "drums/House and Techno Pattern 55.mid",
    "drums/House and Techno Pattern 56.mid",
    "drums/House and Techno Pattern 57.mid",
    "drums/House and Techno Pattern 58.mid",
    "drums/House and Techno Pattern 59.mid",
    "drums/House and Techno Pattern 6.mid",
    "drums/House and Techno Pattern 60.mid",
    "drums/House and Techno Pattern 61.mid",
    "drums/House and Techno Pattern 62.mid",
    "drums/House and Techno Pattern 63.mid",
    "drums/House and Techno Pattern 64.mid",
    "drums/House and Techno Pattern 65.mid",
    "drums/House and Techno Pattern 66.mid",
    "drums/House and Techno Pattern 67.mid",
    "drums/House and Techno Pattern 68.mid",
    "drums/House and Techno Pattern 69.mid",
    "drums/House and Techno Pattern 7.mid",
    "drums/House and Techno Pattern 8.mid",
    "drums/House and Techno Pattern 9.mid",
    "drums/Indie and Rock Pattern 1.mid",
    "drums/Indie and Rock Pattern 10.mid",
    "drums/Indie and Rock Pattern 11.mid",
    "drums/Indie and Rock Pattern 12.mid",
    "drums/Indie and Rock Pattern 13.mid",
    "drums/Indie and Rock Pattern 14.mid",
    "drums/Indie and Rock Pattern 15.mid",
    "drums/Indie and Rock Pattern 16.mid",
    "drums/Indie and Rock Pattern 17.mid",
    "drums/Indie and Rock Pattern 18.mid",
    "drums/Indie and Rock Pattern 19.mid",
    "drums/Indie and Rock Pattern 2.mid",
    "drums/Indie and Rock Pattern 20.mid",
    "drums/Indie and Rock Pattern 21.mid",
    "drums/Indie and Rock Pattern 22.mid",
    "drums/Indie and Rock Pattern 23.mid",
    "drums/Indie and Rock Pattern 24.mid",
    "drums/Indie and Rock Pattern 25.mid",
    "drums/Indie and Rock Pattern 26.mid",
    "drums/Indie and Rock Pattern 27.mid",
    "drums/Indie and Rock Pattern 28.mid",
    "drums/Indie and Rock Pattern 29.mid",
    "drums/Indie and Rock Pattern 3.mid",
    "drums/Indie and Rock Pattern 30.mid",
    "drums/Indie and Rock Pattern 31.mid",
    "drums/Indie and Rock Pattern 32.mid",
    "drums/Indie and Rock Pattern 33.mid",
    "drums/Indie and Rock Pattern 34.mid",
    "drums/Indie and Rock Pattern 35.mid",
    "drums/Indie and Rock Pattern 36.mid",
    "drums/Indie and Rock Pattern 4.mid",
    "drums/Indie and Rock Pattern 5.mid",
    "drums/Indie and Rock Pattern 6.mid",
    "drums/Indie and Rock Pattern 7.mid",
    "drums/Indie and Rock Pattern 8.mid",
    "drums/Indie and Rock Pattern 9.mid"
];