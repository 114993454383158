import React from 'react';

export default class ContentMain extends React.Component {

    render() {
        return (
            <section className="content">
                {this.props.children}
            </section>
        )
    }
}