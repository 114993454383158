import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createDispatchHook } from 'react-redux';
import * as fireApp from '../../base/firebase-app';
import { LoadCloudLiccsAsync, LoadCloudModifiersAsync, LoadUserChordProgressionsAsync, LoadUserPatternsAsync, QueryMidisAsync, StoreCloudLiccAsync } from '../composer/magenta-thunks';

const initialState = {
    patterns: [],
    chord_progressions: [],
    modifiers: [],
    midis: [],
    liccs: [],
    loading: {
        modifiers: false,
        patterns: false
    },
    failed: {
        modifiers: false,
        patterns: false
    }
}

export const serviceSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        selectPattern: (state, action) => {
            state.selected_pattern = action.payload;
        },
        clearSelectedPattern: (state, action) => {
            state.selected_pattern = null;
        },
        selectFirstPattern: (state, action) => {
            if (state && state.patterns && state.patterns.length) {
                state.selected_pattern = state.patterns[0].id;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(LoadUserPatternsAsync.pending, (state, action) => {
            state.loading.patterns = true;
        })
        builder.addCase(LoadUserPatternsAsync.rejected, (state, action) => {
            state.loading.patterns = false;
            state.failed.patterns = true;
        })
        builder.addCase(LoadUserPatternsAsync.fulfilled, (state, action) => {
            if (action.payload) {
                state.patterns = action.payload.sort((b, a) => {
                    return (a.updated || a.created).seconds - (b.updated || b.created).seconds;
                });
            }
            state.loading.patterns = false;
            state.failed.patterns = false;
        });
        builder.addCase(LoadUserChordProgressionsAsync.fulfilled, (state, action) => {
            if (action.payload) {
                state.chord_progressions = action.payload.sort((b, a) => {
                    return (a.updated || a.created).seconds - (b.updated || b.created).seconds;
                });
            }
        });
        //Modifiers
        builder.addCase(LoadCloudModifiersAsync.pending, (state, action) => {
            state.loading.modifiers = true;
        }).addCase(LoadCloudModifiersAsync.rejected, (state, action) => {
            state.loading.modifiers = false;
            state.failed.modifiers = true;
        }).addCase(LoadCloudModifiersAsync.fulfilled, (state, action) => {
            state.failed.modifiers = false;
            state.loading.modifiers = false;
            if (action.payload) {
                state.modifiers = action.payload.sort((b, a) => {
                    return (a.updated || a.created).seconds - (b.updated || b.created).seconds;
                });
            }
        });


        // Liccs
        builder.addCase(StoreCloudLiccAsync.pending, (state, action) => {
            state.loading.store_liccs = true;
        }).addCase(StoreCloudLiccAsync.rejected, (state, action) => {
            state.failed.store_liccs = true;
        }).addCase(StoreCloudLiccAsync.fulfilled, (state, action) => {
            state.failed.store_liccs = false;
            state.loading.store_liccs = false;
        });

        builder.addCase(LoadCloudLiccsAsync.pending, (state, action) => {
            state.loading.liccs = true;
        }).addCase(LoadCloudLiccsAsync.rejected, (state, action) => {
            state.failed.liccs = true;
        }).addCase(LoadCloudLiccsAsync.fulfilled, (state, action) => {
            state.failed.liccs = false;
            state.loading.liccs = false;
            if (action.payload) {
                state.liccs = action.payload.sort((b, a) => {
                    return (a.updated || a.created).seconds - (b.updated || b.created).seconds;
                });
            }

        })

        builder.addCase(QueryMidisAsync.pending, (state, action) => {
            state.loading.query_midis = true;
        }).addCase(QueryMidisAsync.rejected, (state, action) => {
            state.failed.query_midis = true;
        }).addCase(QueryMidisAsync.fulfilled, (state, action) => {
            state.failed.query_midis = false;
            state.loading.query_midis = false;
            if (action.payload) {
                state.midis = action.payload.map(x => x);
            }
        })
    }
});

export default serviceSlice.reducer;

export const {
    selectFirstPattern,
    clearSelectedPattern,
    selectPattern
} = serviceSlice.actions;

export const getSelectedPattern = (state) => {
    return state.server.selected_pattern;
}
export const getPatterns = (state) => {
    return state.server.patterns;
}
export const getCloudLiccs = (state) => {
    return state.server.liccs;
}
export const getCloudModifiers = (state) => {
    return state.server.modifiers;
}
export const getFailure = (key) => (state) => {
    return state.server.failure[key];
}
export const getPending = (key) => (state) => {
    return state.server.loading[key];
}
export const getCloudChordProgressons = (state) => {
    return state.server.chord_progressions;
}
export const getMidiReferences = (state) => {
    return state.server.midis || [];
}