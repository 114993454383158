import { TitlesService } from "../title-service";

export const COMMON_BASE_CHORDS = [{
    name: TitlesService('Major'),
    value: 'major',
    offset: [0, 4, 7]
}, {
    name: TitlesService('Minor'),
    value: 'minor',
    offset: [0, 3, 7]
}, {
    name: TitlesService('Sus'),
    value: 'sus',
    offset: [0, 5, 7]
}, {
    name: TitlesService('Dim'),
    value: 'dim',
    offset: [0, 3, 6]
}];