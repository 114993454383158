import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MidiFile from './MidiFile';

import {
    selectCurrentMidiFile,
    selectMidiFileName,
    setCurrentMidiFile,
    setMidiFileBreaks
} from './composerSlice';
import {
    exportToSerato,
    loadComposerCompanionStorage,
    newComposerCompanionStorage,
    saveComposerCompanionStorage,
    loadMidiFile
} from './composerSlice';
import * as midiService from '../../base/midi-service';
import Panel from '../../components/panel/Panel';
import { readFile } from '../../base/util';
import { TitlesService } from '../../title-service';
import { FormGroup } from '../../components/FormGroup';
import { TrackerHeaderActions, TrackerHeaderDropDownMenu } from '../../components/music/ChordProgressionNonLinearEditor';
import { QueryBy } from '../../base/base-lib';
import SidebarMenu from '../../components/main/SideBarMenu';
import SidebarMenuItem from '../../components/main/SideBarMenuItem';
import { MidiInstrumentData } from '../../base/instrument-data/instruments';
import { GetMidiFolderStructure, QueryMidisAsync } from './magenta-thunks';
import { getMidiReferences } from '../server/serviceSlice';
import { firebaseLoadMidi } from '../../base/firebase-app';
import {
    getCurrentMidiFiles, getCurrentMidiFolders,
    getCurrentMidiRoot, hasParentFolder,
    setCurrentMidiFolder, setCurrentMidiFolderRoot,
    setMidiNotes
} from '../user/userSlice';
import { setNotes } from '../../base/note-service';

export function MidiFiles() {
    const dispatch = useDispatch();
    const midiFiles = useSelector(selectMidiFileName);
    const currentMidiFile = useSelector(selectCurrentMidiFile)


    return (
        <Panel stretch title={TitlesService('Midi Files')}
            onBodyDrop={(ev) => {
                console.log('File(s) dropped');

                // Prevent default behavior (Prevent file from being opened)
                ev.preventDefault();

                if (ev.dataTransfer.items) {
                    // Use DataTransferItemList interface to access the file(s)
                    let lastFile = null;
                    let droppedFiles = [];
                    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                        // If dropped items aren't files, reject them
                        if (ev.dataTransfer.items[i].kind === 'file') {
                            var file = ev.dataTransfer.items[i].getAsFile();
                            lastFile = file;
                            console.log('... file[' + i + '].name = ' + file.name);
                            droppedFiles.push(file);
                        }
                    }
                    if (lastFile) {
                        if (lastFile.name.endsWith('.cc')) {
                            lastFile.text().then((txt) => {
                                dispatch(loadComposerCompanionStorage({ data: txt }))
                            }).catch(e => console.error(e))
                        }
                    }
                    if (droppedFiles) {
                        for (let i = 0; i < droppedFiles.length; i++) {
                            readFile(droppedFiles[i]).then((data) => {
                                midiService.loadMidi(droppedFiles[i].name, data);
                                dispatch(loadMidiFile({ name: droppedFiles[i].name }))
                            })
                        }
                    }
                } else {
                    // Use DataTransfer interface to access the file(s)
                    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                        console.log('...file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
                    }
                }

            }} onBodyDragOver={(ev) => {

                console.log('File(s) in drop zone');

                // Prevent default behavior (Prevent file from being opened)
                ev.preventDefault();
            }}>
            <SidebarMenu>
                {
                    midiFiles.map((midiFile, i) => {
                        return <SidebarMenuItem icon={midiFile === currentMidiFile ? "fas fa-star" : ''} selected={midiFile === currentMidiFile} onClick={() => {
                            dispatch(setCurrentMidiFile(midiFile));
                            const tracks = midiFile ? midiService.getTracks(midiFile) : null;
                            if (tracks) {
                                let notes = [];
                                tracks.forEach((track, trackIndex) => {
                                    track.notes.forEach(note => {
                                        notes.push({ ...note, track: trackIndex });
                                    })
                                })
                                let max_note = tracks.maximum(track => {
                                    return track.notes.maximum(v => v.midi)
                                })
                                let min_note = tracks.minimum(track => {
                                    return track.notes.minimum(v => v.midi)
                                });
                                // dispatch(setMidiNotes(notes))
                                setNotes('MIDI_VIEWER', notes);
                                dispatch(setMidiFileBreaks({
                                    midi: midiFile,
                                    max: max_note,
                                    min: min_note,
                                    key: 'verticalZoom'
                                }))
                            }

                        }} key={`device-${i}`} title={midiFile} />
                    })
                }
            </SidebarMenu>

        </Panel >
    );
}

export function MidiFolderSystem() {
    let dispatch = useDispatch();
    let hasParent = useSelector(hasParentFolder);
    let currentRoot = useSelector(getCurrentMidiRoot);
    let items = useSelector(getCurrentMidiFiles)
    let folders = useSelector(getCurrentMidiFolders)
    return (
        <Panel stretch title={'Search Midi Files'}
            onBodyDrop={(ev) => {
                console.log('File(s) dropped');

                // Prevent default behavior (Prevent file from being opened)
                ev.preventDefault();

                if (ev.dataTransfer.items) {
                    // Use DataTransferItemList interface to access the file(s)
                    let lastFile = null;
                    let droppedFiles = [];
                    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                        // If dropped items aren't files, reject them
                        if (ev.dataTransfer.items[i].kind === 'file') {
                            var file = ev.dataTransfer.items[i].getAsFile();
                            lastFile = file;
                            console.log('... file[' + i + '].name = ' + file.name);
                            droppedFiles.push(file);
                        }
                    }
                    if (lastFile) {
                        if (lastFile.name.endsWith('.cc')) {
                            lastFile.text().then((txt) => {
                                dispatch(loadComposerCompanionStorage({ data: txt }))
                            }).catch(e => console.error(e))
                        }
                    }
                    if (droppedFiles) {
                        for (let i = 0; i < droppedFiles.length; i++) {
                            readFile(droppedFiles[i]).then((data) => {
                                midiService.loadMidi(droppedFiles[i].name, data);
                                dispatch(loadMidiFile({ name: droppedFiles[i].name }))
                            })
                        }
                    }
                } else {
                    // Use DataTransfer interface to access the file(s)
                    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                        console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
                    }
                }

            }} onBodyDragOver={(ev) => {

                console.log('File(s) in drop zone');

                // Prevent default behavior (Prevent file from being opened)
                ev.preventDefault();
            }}>

            {currentRoot ? <SidebarMenu>
                {hasParent ? <SidebarMenuItem icon={'fas fa-level-up-alt'} onClick={() => {
                    let parts = currentRoot.id.split('\\').slice(0, -1).join('\\');
                    dispatch(setCurrentMidiFolderRoot(parts))
                }}></SidebarMenuItem> : null}
                <SidebarMenuItem childrenStyle={{ paddingLeft: 15 }} icon={"fas fa-folder"} title={`${currentRoot.id}`} >
                    {folders.map((item, index) => {
                        let titles = item.split('/');
                        return (<SidebarMenuItem icon={"fas fa-folder"} keys={`${currentRoot.id}df-${item}=${index}`} title={titles[titles.length - 1]} onClick={() => {
                            // firebaseLoadMidi((`midis${item.split('\\').join('/')}`)).then((args) => {
                            //     if (args) {
                            //         let { name } = args;
                            //         // dispatch(loadMidiFile({ name }));
                            //     }
                            // })
                            dispatch(GetMidiFolderStructure({
                                root: item.split('/').join('\\')
                            })).then(() => {
                                dispatch(setCurrentMidiFolderRoot(item.split('/').join('\\')))
                            });
                        }} />)
                    })}
                    {items.map((item, index) => {
                        return (<SidebarMenuItem icon={"fas fa-file-audio"} keys={`${currentRoot.id}-${item}=${index}`} title={item} onClick={() => {
                            firebaseLoadMidi((`${currentRoot.id.split('\\').join('/')}/${item}`)).then((args) => {
                                if (args) {
                                    let { name } = args;
                                    dispatch(loadMidiFile({ name }));
                                }
                            })
                        }} />)
                    })}
                </SidebarMenuItem>
            </SidebarMenu> : null}
        </Panel >
    );
}

export function SearchMidiFiles() {
    const dispatch = useDispatch();
    const [search_text, setSearchText] = useState(null);
    const [open, setOpen] = useState(false);
    const [queryType, setQueryType] = useState(QueryBy.INSTRUMENTS);
    const [selected_instruments, setSelectedInstruments] = useState([]);
    const [searching, setSearching] = useState(false);
    const midi_references = useSelector(getMidiReferences);

    return (
        <Panel stretch title={'Search Midi Files'}
            footer={() => {
                if (searching) {
                    return (
                        [<i key={"spinner-"} className="fas fa-spinner fa-pulse"></i>]
                    )
                }
                if ((queryType === QueryBy.TEXT) || (queryType === QueryBy.INSTRUMENTS && selected_instruments.length)) {
                    return [
                        <FormGroup key={'query-instruments'}>
                            <div className="btn btn-group">
                                <button type="button"
                                    onClick={() => {
                                        setSearching(true)
                                        dispatch(QueryMidisAsync({
                                            queryType,
                                            text: search_text,
                                            instruments: selected_instruments.map(x => parseInt(x))
                                        })).then(() => {
                                            setSearching(false);
                                        });
                                    }}
                                    className="btn btn-info btn-flat">{TitlesService('Search')}</button>
                            </div>
                        </FormGroup>
                    ]
                }
                return null;
            }}
            buttons={() => {
                return (
                    [
                        <button type="button"
                            className="btn btn-tool"
                            onClick={() => {
                                setOpen(!open);
                            }}>
                            <i className="fas fa-bars"></i>
                        </button>,
                        <TrackerHeaderDropDownMenu show={open} >
                            <TrackerHeaderActions style={{ color: '#00bc8c' }} icon={queryType === QueryBy.INSTRUMENTS ? 'fas fa-circle' : 'far fa-circle'}
                                onClick={() => {
                                    setQueryType(QueryBy.INSTRUMENTS);
                                }}
                                title={`${TitlesService('Search by instruments')}`} />
                            <TrackerHeaderActions style={{ color: '#00bc8c' }} icon={queryType === QueryBy.TEXT ? 'fas fa-circle' : 'far fa-circle'}
                                onClick={() => {
                                    setQueryType(QueryBy.TEXT);
                                }}
                                title={`${TitlesService('Search by text')}`} />
                        </TrackerHeaderDropDownMenu>
                    ]
                )
            }}
            onBodyDrop={(ev) => {
                console.log('File(s) dropped');

                // Prevent default behavior (Prevent file from being opened)
                ev.preventDefault();

                if (ev.dataTransfer.items) {
                    // Use DataTransferItemList interface to access the file(s)
                    let lastFile = null;
                    let droppedFiles = [];
                    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                        // If dropped items aren't files, reject them
                        if (ev.dataTransfer.items[i].kind === 'file') {
                            var file = ev.dataTransfer.items[i].getAsFile();
                            lastFile = file;
                            console.log('... file[' + i + '].name = ' + file.name);
                            droppedFiles.push(file);
                        }
                    }
                    if (lastFile) {
                        if (lastFile.name.endsWith('.cc')) {
                            lastFile.text().then((txt) => {
                                dispatch(loadComposerCompanionStorage({ data: txt }))
                            }).catch(e => console.error(e))
                        }
                    }
                    if (droppedFiles) {
                        for (let i = 0; i < droppedFiles.length; i++) {
                            readFile(droppedFiles[i]).then((data) => {
                                midiService.loadMidi(droppedFiles[i].name, data);
                                dispatch(loadMidiFile({ name: droppedFiles[i].name }))
                            })
                        }
                    }
                } else {
                    // Use DataTransfer interface to access the file(s)
                    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                        console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
                    }
                }

            }} onBodyDragOver={(ev) => {

                console.log('File(s) in drop zone');

                // Prevent default behavior (Prevent file from being opened)
                ev.preventDefault();
            }}>
            {queryType === QueryBy.TEXT ? <FormGroup padding={15}>
                <div className="input-group">
                    <input onChange={(e) => {
                        setSearchText(e.currentTarget.value);
                    }} type="text" value={search_text} className="form-control" placeholder="Search ..." />
                    <div className="input-group-append" onClick={() => {
                        setSearching(true)
                        dispatch(QueryMidisAsync({
                            queryType,
                            text: search_text,
                            instruments: selected_instruments.map(x => parseInt(x))
                        })).then(() => {
                            setSearching(false);
                        });
                    }}>
                        <button type="button" className="btn btn-info btn-flat">{TitlesService('Search')}</button>
                    </div>
                </div>
            </FormGroup> : null}
            {queryType === QueryBy.INSTRUMENTS ? <InstrumentSelection selected={selected_instruments}
                onChange={(args) => {
                    setSelectedInstruments(selected_instruments.indexOf(args) !== -1 ?
                        [...selected_instruments].filter(x => x !== args) :
                        [args, ...selected_instruments].slice(0, 10).unique());
                }} /> : null}
            <MidiReferences items={midi_references} />
        </Panel >
    );
}

function MidiReferences(props) {
    let { items = [] } = props;
    let dispatch = useDispatch();
    return (
        <SidebarMenu>
            <SidebarMenuItem title={`Midi ${items.length ? `(${items.length})` : ''}`} >
                {items.map(item => {
                    let temp = item.filename.split('\\');
                    return (<SidebarMenuItem title={item.filename} onClick={() => {
                        firebaseLoadMidi((`midis${item.filename.split('\\').join('/')}`)).then((args) => {
                            if (args) {
                                let { name } = args;
                                dispatch(loadMidiFile({ name }));
                            }
                        })
                    }} />)
                })}
            </SidebarMenuItem>
        </SidebarMenu>
    )
}
function InstrumentSelection(props) {
    let res = null;
    let selected = props.selected || [];
    let groups = MidiInstrumentData.groupBy(v => {
        return v.family;
    });
    let anything_active = false;
    res = Object.keys(groups).map(v => {
        let items = groups[v];
        let is_active = false;
        let count = 0;
        let web_items = items.map(item => {
            let temp = selected.indexOf(item.hexcode) !== -1;
            is_active = is_active || temp;
            if (temp) {
                count++;
            }
            return <SidebarMenuItem
                iconStyle={{ color: temp ? '#00bc8c' : '#ffffff' }}
                onClick={() => {
                    if (props.onChange) {
                        props.onChange(item.hexcode)
                    }
                }}
                key={`${v}-family-${item.instrument}`} title={item.instrument}
            />;
        })
        anything_active = anything_active || is_active;
        return (
            <SidebarMenuItem iconStyle={{ color: is_active ? '#00bc8c' : '#ffffff' }} key={`${v}-family`} title={`${v} ${count ? `(${count})` : ''}`} >
                {web_items}
            </SidebarMenuItem>
        )
    })

    return (<SidebarMenu>
        <SidebarMenuItem iconStyle={{ color: anything_active ? '#00bc8c' : '#ffffff' }} title={`Instruments ${selected.length ? `(${selected.length})` : ''}`} >
            {res}
        </SidebarMenuItem>
    </SidebarMenu>)
}