import { useState } from "react";
import { mm, START_PLAYING } from "../../base/magenta";
import DrumData from '../../base/instrument-data/drum-data';
import { convertMidiToPattern, getMidi, loadMidi } from "../../base/midi-service";
import { useDispatch } from "react-redux";
import { saveMidiFileToMachine, updateTempo } from "../../features/composer/composerSlice";
import { AddPatternAsync, DeletePatternAsync, getMagentaService, LoadUserPatternsAsync, UpdatePatternAsync } from "../../features/composer/magenta-thunks";
import { TitlesService } from "../../title-service";
import { BuildMidiFromPattern } from "../../base/midi-writer-service";
import { getPatternName, getSongFileName, getSongName } from "../../base/nameService";
import { getUserId, getUserSignedIn } from "../../features/user/userSlice";
import { useSelector } from "react-redux";
import { clearSelectedPattern, selectFirstPattern } from "../../features/server/serviceSlice";
import { isPanelOpen } from "../../base/key-service";
import { APP_PANELS } from "../../base/space-panel-config-service";
const DRUM_CLASSES = [
    'Kick',
    'Snare',
    'Hi-hat closed',
    'Hi-hat open',
    'Tom low',
    'Tom mid',
    'Tom high',
    'Clap',
    'Rim'
];

const TIME_HUMANIZATION = 0.01;

let Tone = mm.Player.tone;

let sampleBaseUrl = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/969699';

let reverb = new Tone.Convolver(
    `${sampleBaseUrl}/small-drum-room.wav`
).toMaster();
reverb.wet = 0.75;

let snarePanner = new Tone.Panner().connect(reverb);
new Tone.LFO(0.13, -0.25, 0.25).connect(snarePanner.pan).start();

let drumKit = [
    new Tone.Players({
        high: `${sampleBaseUrl}/808-kick-vh.mp3`,
        med: `${sampleBaseUrl}/808-kick-vm.mp3`,
        low: `${sampleBaseUrl}/808-kick-vl.mp3`
    }).toMaster(),
    new Tone.Players({
        high: `${sampleBaseUrl}/flares-snare-vh.mp3`,
        med: `${sampleBaseUrl}/flares-snare-vm.mp3`,
        low: `${sampleBaseUrl}/flares-snare-vl.mp3`
    }).connect(snarePanner),
    new Tone.Players({
        high: `${sampleBaseUrl}/808-hihat-vh.mp3`,
        med: `${sampleBaseUrl}/808-hihat-vm.mp3`,
        low: `${sampleBaseUrl}/808-hihat-vl.mp3`
    }).connect(new Tone.Panner(-0.5).connect(reverb)),
    new Tone.Players({
        high: `${sampleBaseUrl}/808-hihat-open-vh.mp3`,
        med: `${sampleBaseUrl}/808-hihat-open-vm.mp3`,
        low: `${sampleBaseUrl}/808-hihat-open-vl.mp3`
    }).connect(new Tone.Panner(-0.5).connect(reverb)),
    new Tone.Players({
        high: `${sampleBaseUrl}/slamdam-tom-low-vh.mp3`,
        med: `${sampleBaseUrl}/slamdam-tom-low-vm.mp3`,
        low: `${sampleBaseUrl}/slamdam-tom-low-vl.mp3`
    }).connect(new Tone.Panner(-0.4).connect(reverb)),
    new Tone.Players({
        high: `${sampleBaseUrl}/slamdam-tom-mid-vh.mp3`,
        med: `${sampleBaseUrl}/slamdam-tom-mid-vm.mp3`,
        low: `${sampleBaseUrl}/slamdam-tom-mid-vl.mp3`
    }).connect(reverb),
    new Tone.Players({
        high: `${sampleBaseUrl}/slamdam-tom-high-vh.mp3`,
        med: `${sampleBaseUrl}/slamdam-tom-high-vm.mp3`,
        low: `${sampleBaseUrl}/slamdam-tom-high-vl.mp3`
    }).connect(new Tone.Panner(0.4).connect(reverb)),
    new Tone.Players({
        high: `${sampleBaseUrl}/909-clap-vh.mp3`,
        med: `${sampleBaseUrl}/909-clap-vm.mp3`,
        low: `${sampleBaseUrl}/909-clap-vl.mp3`
    }).connect(new Tone.Panner(0.5).connect(reverb)),
    new Tone.Players({
        high: `${sampleBaseUrl}/909-rim-vh.wav`,
        med: `${sampleBaseUrl}/909-rim-vm.wav`,
        low: `${sampleBaseUrl}/909-rim-vl.wav`
    }).connect(new Tone.Panner(0.5).connect(reverb))
];
let midiDrums = [36, 38, 42, 46, 41, 43, 45, 49, 51];
let reverseMidiMapping = new Map([
    [36, 0],
    [35, 0],
    [38, 1],
    [27, 1],
    [28, 1],
    [31, 1],
    [32, 1],
    [33, 1],
    [34, 1],
    [37, 1],
    [39, 1],
    [40, 1],
    [56, 1],
    [65, 1],
    [66, 1],
    [75, 1],
    [85, 1],
    [42, 2],
    [44, 2],
    [54, 2],
    [68, 2],
    [69, 2],
    [70, 2],
    [71, 2],
    [73, 2],
    [78, 2],
    [80, 2],
    [46, 3],
    [67, 3],
    [72, 3],
    [74, 3],
    [79, 3],
    [81, 3],
    [45, 4],
    [29, 4],
    [41, 4],
    [61, 4],
    [64, 4],
    [84, 4],
    [48, 5],
    [47, 5],
    [60, 5],
    [63, 5],
    [77, 5],
    [86, 5],
    [87, 5],
    [50, 6],
    [30, 6],
    [43, 6],
    [62, 6],
    [76, 6],
    [83, 6],
    [49, 7],
    [55, 7],
    [57, 7],
    [58, 7],
    [51, 8],
    [52, 8],
    [53, 8],
    [59, 8],
    [82, 8]
]);

let temperature = 1.0;

let outputs = {
    internal: {
        play: (drumIdx, velocity, time) => {
            if (drumKit[drumIdx]) {
                let player = drumKit[drumIdx].player(velocity);
                player.loop = false;
                player.start(time);
            }
        }
    }
};
export function getDrumOutput() {
    return outputs.internal;
}
let MusicRNN = window.music_rnn.MusicRNN;
let rnn = new MusicRNN(
    'https://storage.googleapis.com/download.magenta.tensorflow.org/tfjs_checkpoints/music_rnn/drum_kit_rnn'
);
function toNoteSequence(pattern) {
    return mm.sequences.quantizeNoteSequence(
        {
            ticksPerQuarter: 220,
            totalTime: pattern.length / 2,
            timeSignatures: [
                {
                    time: 0,
                    numerator: 4,
                    denominator: 4
                }
            ],
            tempos: [
                {
                    time: 0,
                    qpm: 120
                }
            ],
            notes: pattern.map((step, index) =>
                step.map(d => ({
                    pitch: midiDrums[d],
                    startTime: index * 0.5,
                    endTime: (index + 1) * 0.5
                })).flat()
            )
        },
        1
    );
}
function generatePattern(seed, length) {
    let seedSeq = toNoteSequence(seed);
    return rnn
        .continueSequence(seedSeq, length, temperature)
        .then(r => seed.concat(fromNoteSequence(r, length)));
}

function fromNoteSequence(seq, patternLength) {
    let res = [].interpolate(0, patternLength, () => []);
    for (let { pitch, quantizedStartStep } of seq.notes) {
        res[quantizedStartStep].push(reverseMidiMapping.get(pitch));
    }
    return res;
}
function regenerate(pattern, patternLength, seedLength) {
    let seed = pattern.slice(pattern, seedLength);
    // renderPattern(true);
    DrumMachineService.seedLength = seed.length;
    return generatePattern(seed, patternLength - seed.length).then(
        result => {
            DrumMachineService.pattern = result;
            return result;
        }
    );
}
export default function DrumMachine(props) {
    let Tone = mm.Player.tone;
    var dispatch = useDispatch();
    var [patternLength, setPatternLength] = useState(32);
    var [seedLength, setSeedLength] = useState(4);
    var [swing, setSwing] = useState(.55);
    let [patternId, setPatternId] = useState(null);
    var [selectedPattern, setSelectedPattern] = useState(null);
    var [temperature, setTemperature] = useState(1.1);
    var [pattern, setPattern] = useState([[0], [], [2]].concat([].interpolate(0, 32, i => [])));
    var [tempo, setTempo] = useState(120);
    var [patternName, setPatternName] = useState(null);
    var [processing, setProcessing] = useState(false);
    var [patternVersion, setPatternVersion] = useState(0);
    var [hasBeenStarted, setHasBeenStarted] = useState(false)
    var [patternObj, setPatternObj] = useState(null);
    var [stepCounter, setStepCounter] = useState(null);
    var [midiClockStartSent, setMidiClockStartSent] = useState(false);
    let uid = useSelector(getUserId);
    var [playing, setPlaying] = useState(false)
    const isSignedIn = useSelector(getUserSignedIn);
    var [oneEighth, setOneEighth] = useState(Tone.Time('8n').toSeconds());
    if (props.onSetPattern) {
        props.onSetPattern((arg) => {
            setPattern(JSON.parse(JSON.stringify(arg.pattern)));
            setPatternObj(arg);
            setPatternName(arg.name);
            setPatternLength(arg.pattern.length)
            setPatternId(arg.id);
            setPatternVersion(arg.version)
            DrumMachineService.pattern = JSON.parse(JSON.stringify(arg.pattern));
            DrumMachineService.patternLength = JSON.parse(JSON.stringify(arg.pattern)).length;
        })
    }
    let stepEls = [],
        activeOutput = 'internal',
        midiClockSender = null,
        activeClockInput = 'none',
        currentSchedulerId;
    function startPattern() {
        setStepCounter(-1)
        DrumMachineService.stepCounter = -1;
        setMidiClockStartSent(false);
        setPlaying(true)
    }

    function stopPattern() {
        setStepCounter(null);
        // updatePlayPauseIcons();
        setPlaying(false)
    }
    return (
        <div className="beat-maker">
            <div>
                {processing ? <div className="progress pink">
                    <div className="indeterminate white"></div>
                </div> : null}
                <div className="app">
                    <div className="sequencer">
                        <Steps
                            pattern={pattern}
                            patternLength={patternLength}
                            stepEls={stepEls}
                            seedLength={seedLength}
                            toggleStep={(args) => {
                                let { stepIdx, cellIdx } = args;
                                if (pattern && pattern[stepIdx]) {
                                    let index = pattern[stepIdx].indexOf(cellIdx);
                                    let isOn = index !== -1;
                                    if (isOn) {
                                        pattern[stepIdx].splice(index, 1);
                                    } else {
                                        pattern[stepIdx].push(cellIdx);
                                    }

                                    let new_pattern = JSON.parse(JSON.stringify(pattern));
                                    DrumMachineService.pattern = new_pattern;
                                    DrumMachineService.patternLength = patternLength;
                                    setPattern(new_pattern);
                                }
                            }} />
                    </div>
                    <a className="regenerate btn-floating btn-large waves-effect waves-light pink darken-2 pulse">
                        <i className="material-icons">refresh</i>
                    </a>
                    <div className="controls" style={{
                        justifyContent: 'start'
                    }}>
                        <div className="input-field grey-text">
                            <input className="browser-default" style={{ color: 'white', width: 280 }} value={patternName} onChange={(evt) => {
                                if (!patternId) {
                                    setPatternName(evt.target.value);
                                }
                            }}>
                            </input>
                        </div>
                        {!patternId ? <i className="fas fa-star" title={TitlesService('NotSaved')}></i> : null}
                    </div>
                    <div className="controls" style={{
                        justifyContent: 'space-between'
                    }}>
                        <div style={{ flex: 0, alignContent: 'space-around', justifyContent: 'space-around', display: 'flex' }}>
                            <div className="control" style={{ paddingRight: 10 }}>
                                <a className="playpause btn-floating btn-large waves-effect waves-light blue"
                                    onClick={() => {
                                        setPattern([[0], [], [2]].concat([].interpolate(0, 32, i => [])));
                                        setPatternName('');
                                        setPatternLength(32)
                                        setPatternId(null);
                                        DrumMachineService.pattern = [[0], [], [2]].concat([].interpolate(0, 32, i => []));
                                        DrumMachineService.patternLength = 32;
                                        dispatch(clearSelectedPattern())
                                    }}>
                                    <i className="fas fa-plus"></i>
                                </a>
                            </div>
                            <div className="control" style={{ paddingRight: 10 }}>
                                <a onClick={() => {
                                    if (playing) {
                                        stopPattern();
                                        Tone.Transport.stop();
                                    } else {
                                        Tone.context.resume();
                                        Tone.Transport.start();
                                        startPattern();
                                        setHasBeenStarted(true);
                                    }
                                }} className="playpause btn-floating btn-large waves-effect waves-light blue">
                                    <i style={{ display: playing ? 'none' : '' }} className="fa fa-play"></i>
                                    <i className="fa fa-pause" style={{ display: !playing ? 'none' : '' }}></i>
                                </a>
                            </div>
                            <div className="control" style={{ paddingRight: 10 }}>
                                <a className="playpause btn-floating btn-large waves-effect waves-light blue"
                                    onClick={() => {
                                        stopPattern();
                                        Tone.Transport.pause();
                                        setProcessing(true)
                                        regenerate(pattern, patternLength, seedLength).then((result) => {
                                            setPattern(JSON.parse(JSON.stringify(result)))
                                            if (!hasBeenStarted) {
                                                Tone.context.resume();
                                                Tone.Transport.start();
                                                setHasBeenStarted(true);
                                            }
                                            if (Tone.Transport.state === 'started') {
                                                setTimeout(startPattern, 0);
                                            }
                                            setProcessing(false)
                                        });
                                    }}>
                                    <i className="fa fa-recycle"></i>
                                </a>
                            </div>
                            {isSignedIn ? <div className="control" style={{ paddingRight: 10 }}>
                                <a title={TitlesService('StorePattern')} className="playpause btn-floating btn-large waves-effect waves-light blue"
                                    onClick={() => {
                                        if (patternId) {
                                            dispatch(UpdatePatternAsync({
                                                ...patternObj,
                                                owner: uid,
                                                version: patternVersion,
                                                pattern: pattern,
                                                name: patternName || getPatternName()
                                            })).then(res => {
                                                dispatch(LoadUserPatternsAsync(uid));
                                            });
                                        }
                                        else {
                                            var temp = dispatch(AddPatternAsync({
                                                owner: uid,
                                                pattern: pattern,
                                                name: patternName || getPatternName()
                                            }));
                                            temp.then(res => {
                                                if (res.payload) {
                                                    dispatch(LoadUserPatternsAsync(uid));
                                                }
                                            });
                                        }
                                    }}>
                                    <i className="fas fa-cloud-upload-alt"></i>
                                </a>
                            </div> : null
                            }
                            <div className="control" style={{ paddingRight: 10 }}>
                                <a title={TitlesService('ExportExplaination')} className="playpause btn-floating btn-large waves-effect waves-light blue"
                                    onClick={() => {
                                        let res = pattern.map((step, index) =>
                                            step.map(d => ({
                                                pitch: midiDrums[d],
                                                startTime: index,
                                                endTime: (index + 1)
                                            })).flat());
                                        let midi = BuildMidiFromPattern(res, tempo)
                                        saveMidiFileToMachine(midi, getSongFileName());
                                    }}>
                                    <i className="far fa-arrow-alt-circle-down"></i>
                                </a>
                            </div>
                            {patternId ? <div className="control" style={{ paddingRight: 10 }}>
                                <a title={TitlesService('DeletePattern')} className="playpause btn-floating btn-large waves-effect waves-light red"
                                    onClick={() => {
                                        if (patternId) {
                                            dispatch(DeletePatternAsync({
                                                uid,
                                                patternId
                                            })).then((res) => {
                                                if (res) {
                                                    dispatch(LoadUserPatternsAsync(uid));
                                                    setPattern([[0], [], [2]].concat([].interpolate(0, 32, i => [])));
                                                    setPatternName('');
                                                    setPatternLength(32)
                                                    setPatternId(null);
                                                    DrumMachineService.pattern = [[0], [], [2]].concat([].interpolate(0, 32, i => []));
                                                    DrumMachineService.patternLength = 32;
                                                    dispatch(clearSelectedPattern())
                                                }
                                            })
                                        }
                                    }}>
                                    <i className="fas fa-trash"></i>
                                </a>
                            </div> : null}
                        </div>
                        <div className="input-field grey-text">
                            <select className="browser-default" value={selectedPattern} onChange={(evt) => {
                                setSelectedPattern(evt.target.value)
                                let temp = evt.target.value;
                                if (evt.target.value) {
                                    setProcessing(true)
                                    Promise.resolve().then(() => {
                                        if (temp) {
                                            if (!getMidi(temp)) {
                                                return fetch('./' + temp).then(res => {
                                                    return res.arrayBuffer().then(buffer => {
                                                        loadMidi(temp, buffer);
                                                        return true;
                                                    });
                                                })
                                            }
                                            else {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }).then((ok) => {
                                        if (ok) {
                                            let beat_midi = getMidi(temp);
                                            let new_pattern = convertMidiToPattern(beat_midi);
                                            DrumMachineService.pattern = new_pattern;
                                            DrumMachineService.patternLength = 32;
                                            setPatternLength(32);
                                            setPattern(JSON.parse(JSON.stringify(new_pattern)));
                                        }
                                        setProcessing(false)
                                    })
                                }
                            }}>
                                <option>{TitlesService('Select')}</option>
                                {DrumData.map(op => {
                                    return <option value={op}>{op}</option>
                                })}
                            </select>
                        </div>
                        <div className="control">
                            <div className="input-field grey-text">
                                <select className="browser-default" id="pattern-length" value={patternLength} onChange={(evt) => {
                                    setPatternLength(+evt.target.value)
                                    DrumMachineService.patternLength = +evt.target.value;
                                }}>
                                    <option>4</option>
                                    <option>8</option>
                                    <option>16</option>
                                    <option>32</option>
                                </select>
                                Pattern length
                            </div>
                        </div>
                        <div className="control">
                            <p className="range-field grey-text">
                                <input type="range" min="20" max="240" onChange={(evt) => {
                                    setTempo(+evt.currentTarget.value);
                                    dispatch(updateTempo(+evt.currentTarget.value));
                                    Tone.Transport.bpm.value = +evt.currentTarget.value;
                                    let one_eighth = Tone.Time('8n').toSeconds();
                                    setOneEighth(one_eighth);
                                    DrumMachineService.oneEighth = one_eighth;
                                }} value={tempo} step="1" /> Tempo
                            </p>
                        </div>
                        <div className="control">
                            <p className="range-field grey-text">
                                <input type="range" id="swing" min="0.5" max="0.7" onChange={(evt) => {
                                    setSwing(evt.currentTarget.value);
                                    DrumMachineService.swing = evt.currentTarget.value;
                                }} value={swing} step="0.05" /> Swing
                            </p>
                        </div>
                        <div className="control">
                            <p className="range-field grey-text">
                                <input type="range" id="temperature" className="tooltipped"
                                    min="0.5" max="2" value={temperature} step="0.1"
                                    onChange={(evt) => {
                                        setTemperature(evt.currentTarget.value);
                                        DrumMachineService.temperature = evt.currentTarget.value;
                                    }}
                                    data-tooltip="Higher temperatures will make the neural network generates wilder patterns"
                                    data-delay="500" /> Temperature
                            </p>
                        </div>
                    </div>
                    <div className="controls" style={{ justifyContent: 'start' }}>

                    </div>
                </div>
                <div className="info">
                </div>
            </div>
        </div>
    )
}

function Steps(props) {
    var _ = window._;
    let { regenerating, pattern, toggleStep } = props;
    function renderPattern(regenerating = false) {
        let seqEl = []; // document.querySelector('.sequencer .steps');

        for (let stepIdx = 0; stepIdx < props.pattern.length; stepIdx++) {
            let step = props.pattern[stepIdx];
            let stepEl, gutterEl;
            let stepEl_style = {
                flex: stepIdx % 2 === 0 ? props.swing : 1 - props.swing
            };
            let cells = [];
            for (let cellIdx = 0; cellIdx < DRUM_CLASSES.length; cellIdx++) {
                let cellEl = <div
                    onClick={() => {
                        if (toggleStep) {
                            toggleStep({ stepIdx, cellIdx });
                        }
                    }}
                    data-cell-id={cellIdx}
                    data-step-id={stepIdx}
                    key={`cell-idx-${cellIdx}-stepIdx-${stepIdx}`}
                    className={`cell ${pattern &&
                        pattern[stepIdx] &&
                        pattern[stepIdx].indexOf(cellIdx) !== -1 ? 'on' : ''} ${step.indexOf(cellIdx) >= 0 ? 'on' : ''} ${_.kebabCase(DRUM_CLASSES[cellIdx])}`}></div>;
                cells.push(cellEl);
            }
            stepEl = <div key={`cell-stepIdx-${stepIdx}`} className="step" style={stepEl_style}>{cells}</div>;
            seqEl.push(stepEl);

            if (!gutterEl && stepIdx < props.pattern.length - 1) {
                gutterEl = <div className={` ${stepIdx === props.seedLength - 1 ? 'seed-marker' : ''} ${stepIdx < props.pattern.length - 1 ? 'gutter' : ''}`}></div>;
                seqEl.push(gutterEl);
            }

        }

        return seqEl;
    }
    return (
        <div className="steps">
            {renderPattern(regenerating)}
        </div>
    )
}
var WebMidi = window.WebMidi;
class DrumMachineService {
    static webMidiEnabled = false;
    static initialized = false;
    static stepCounter = null;
    static pattern = null;
    static swing = .55;
    static seedLength = 4;
    static activeClockOutput = null;
    static activeOutput = null;
    static init() {
        DrumMachineService.onDevicesChanged();

        function loadMagentService() {
            setTimeout(() => {
                DrumMachineService.magentaService = getMagentaService();
                if (!DrumMachineService.magentaService) {
                    loadMagentService();
                }
                else {
                    DrumMachineService.magentaService.listen(START_PLAYING, () => {
                        DrumMachineService.stepCounter = -1;
                        Tone.Transport.stop();
                    })
                }
            }, 1000)
        }
        loadMagentService();
    }
    static onDevicesChanged() {
        DrumMachineService.onActiveOutputChange('internal');
        DrumMachineService.onActiveClockOutputChange('none');
        DrumMachineService.onActiveClockInputChange('none');
    }
    static onActiveClockInputChange(id) {
        let activeClockInput = DrumMachineService.activeClockOutput;
        let currentSchedulerId = DrumMachineService.currentSchedulerId;
        if (activeClockInput === 'none') {
            Tone.Transport.clear(currentSchedulerId);
            currentSchedulerId = null;
            DrumMachineService.currentSchedulerId = currentSchedulerId;
        } else if (activeClockInput) {
            // let input = WebMidi.getInputById(activeClockInput);
            // input.removeListener('start', 'all', incomingMidiClockStart);
            // input.removeListener('stop', 'all', incomingMidiClockStop);
            // input.removeListener('clock', 'all', incomingMidiClockTick);
        }
        activeClockInput = id;
        if (activeClockInput === 'none') {
            currentSchedulerId = Tone.Transport.scheduleRepeat(DrumMachineService.tick, '16n');
            DrumMachineService.currentSchedulerId = currentSchedulerId;
            DrumMachineService.oneEighth = Tone.Time('8n').toSeconds();
        } else {
            // let input = WebMidi.getInputById(id);
            // input.addListener('start', 'all', incomingMidiClockStart);
            // input.addListener('stop', 'all', incomingMidiClockStop);
            // input.addListener('clock', 'all', incomingMidiClockTick);
        }
    }
    static getStepVelocity(step) {
        if (step % 4 === 0) {
            return 'high';
        } else if (step % 2 === 0) {
            return 'med';
        } else {
            return 'low';
        }
    }

    static humanizeTime(time) {
        return time - TIME_HUMANIZATION / 2 + Math.random() * TIME_HUMANIZATION;
    }
    static visualizePlay(time, stepIdx, drumIdx) {
        Tone.Draw.schedule(() => {
            let beatDom = document.querySelector('.beat-maker');
            if (beatDom) {
                let stepCounter = stepIdx;
                let animTime = DrumMachineService.oneEighth * 4 * 1000;
                beatDom.classList.remove(`step-${stepCounter - 1}`)
                beatDom.classList.add(`step-${stepCounter}`)
                let steps = beatDom.querySelectorAll(`[data-step-id="${stepCounter}"].on`);
                for (let i = 0; i < steps.length; i++) {
                    let step = steps[i];
                    let baseColor = stepCounter < DrumMachineService.seedLength ? '#e91e63' : '#64b5f6';
                    step.animate(
                        [
                            {
                                transform: 'translateZ(-100px)',
                                backgroundColor: '#fad1df'
                            },

                            {
                                transform: 'translateZ(50px)',
                                offset: 0.7
                            },

                            { transform: 'translateZ(0)', backgroundColor: baseColor }],

                        { duration: animTime, easing: 'cubic-bezier(0.23, 1, 0.32, 1)' });
                }
            }
        }, time);
    }


    static tick(time = Tone.now() - Tone.context.lookAhead) {
        if (!isPanelOpen(APP_PANELS.DRUM_MACHINE)) {
            return;
        }
        let stepCounter = DrumMachineService.stepCounter;
        if (!isNaN(stepCounter) && DrumMachineService.pattern) {
            stepCounter++;
            // if (midiClockSender) midiClockSender(time, stepCounter);

            let stepIdx = stepCounter % DrumMachineService.pattern.length;
            let isSwung = stepIdx % 2 !== 0;
            if (isSwung) {
                time += (DrumMachineService.swing - 0.5) * DrumMachineService.oneEighth;
            }
            let velocity = DrumMachineService.getStepVelocity(stepIdx);
            let drums = DrumMachineService.pattern[stepIdx];
            drums.forEach(d => {
                let humanizedTime = stepIdx === 0 ? time : DrumMachineService.humanizeTime(time);
                outputs[DrumMachineService.activeOutput].play(d, velocity, humanizedTime);
                DrumMachineService.visualizePlay(humanizedTime, stepIdx, d);
            });
            DrumMachineService.stepCounter = stepCounter;
        }
    }
    static onActiveOutputChange(id) {
        let activeOutput = DrumMachineService.activeOutput;
        if (activeOutput !== 'internal') {
            outputs[activeOutput] = null;
        }
        activeOutput = id;
        DrumMachineService.activeOutput = activeOutput;
        // Maybe for later, output sound throw midi devices
        if (activeOutput !== 'internal') {
            let output = WebMidi.getOutputById(id);
            outputs[id] = {
                play: (drumIdx, velo, time) => {
                    let delay = (time - Tone.now()) * 1000;
                    let duration = (DrumMachineService.oneEighth / 2) * 1000;
                    let velocity = { high: 1, med: 0.75, low: 0.5 };
                    output.playNote(midiDrums[drumIdx], 1, {
                        time: delay > 0 ? `+${delay}` : WebMidi.now,
                        velocity,
                        duration
                    });
                }
            };
        }
    }

    static onActiveClockOutputChange(id) {
        let activeClockOutput = DrumMachineService.activeClockOutput;
        if (activeClockOutput !== 'none') {
            DrumMachineService.stopSendingMidiClock();
        }
        activeClockOutput = id;
        if (activeClockOutput !== 'none') {
            DrumMachineService.startSendingMidiClock();
        }
        // for (let option of Array.from(clockOutputSelector.children)) {
        //     option.selected = option.value === id;
        // }
    }

    static startSendingMidiClock() {
        if (true) {
            console.warn('shouldnt be here unless, we support midi device output now.')
            return;
        }
    }

    static stopSendingMidiClock() {
        DrumMachineService.midiClockSender = null;
        DrumMachineService.midiClockStartSent = false;
    }

}
DrumMachineService.init();
