import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MidiLick from './MidiLick';

import {
    addAndSelectLicc,
    getCurrentLick,
    getMidiLicks,
    setCurrentLick
} from './composerSlice';
import * as midiService from '../../base/midi-service';
import Panel from '../../components/panel/Panel';
import { readFile } from '../../base/util';
import { TitlesService } from '../../title-service';
import SidebarMenuItem from '../../components/main/SideBarMenuItem';
import { useState } from 'react';
import { FormGroup } from '../../components/FormGroup';
import { LoadCloudLiccsAsync, StoreCloudLiccAsync } from './magenta-thunks';
import { getCloudLiccs } from '../server/serviceSlice';
import { getUserId } from '../user/userSlice';

export function MidiLicks() {
    const dispatch = useDispatch();
    const midiLicks = useSelector(getMidiLicks);
    const currentLick = useSelector(getCurrentLick);
    const uid = useSelector(getUserId);
    const cloud_liccs = useSelector(getCloudLiccs);
    const [local_liccs, setLocalLiccs] = useState(false);
    const [cloud_liccs_menu, setCloudLiccs] = useState(false);
    return (
        <Panel stretch title={TitlesService('Liccs')}>
            <FormGroup>
                <div className="btn-group">
                    <button className="btn btn-success" onClick={() => {
                        let licc = midiLicks.find(v => v.id === currentLick)
                        if (licc) {
                            dispatch(StoreCloudLiccAsync({ ...licc, owner: uid })).then(() => {
                                return dispatch(LoadCloudLiccsAsync({ uid }))
                            })
                        }
                    }}><i className="fa fa-save" /></button>
                </div>
            </FormGroup>
            <ul className="nav nav-pills nav-sidebar flex-column">
                <SidebarMenuItem
                    title={`${TitlesService('Local Liccs')} (${(midiLicks || []).length})`}
                    open={local_liccs} onClick={() => {
                        setLocalLiccs(!local_liccs);
                    }}>
                    {(midiLicks || []).map((midilicc, index) => {
                        let temp = new Date((midilicc.updated ? midilicc.updated.seconds : (midilicc.created ? midilicc.created.seconds : (Date.now() / 1000))) * 1000);
                        let moment = window.moment;
                        let timing = moment(temp).fromNow();
                        return (<SidebarMenuItem
                            key={'licc' + index}
                            active={midilicc.id === currentLick}
                            title={midilicc.name} itemTitle={timing}
                            icon={`nav-icon fas fa-compact-disc`}
                            onClick={() => {
                                dispatch(setCurrentLick(midilicc.id));
                            }} />)
                    })}
                </SidebarMenuItem>
                <SidebarMenuItem
                    title={`${TitlesService('Cloud Liccs')} (${(cloud_liccs || []).length})`}
                    open={cloud_liccs_menu} onClick={() => {
                        setCloudLiccs(!cloud_liccs_menu);
                    }}>
                    {(cloud_liccs || []).map((cloudlicc, index) => {
                        let temp = cloudlicc.created ? new Date((cloudlicc.updated ? cloudlicc.updated.seconds : (cloudlicc.created ? cloudlicc.created.seconds : (Date.now() / 1000))) * 1000) : false;
                        let moment = window.moment;
                        let timing = temp ? moment(temp).fromNow() : TitlesService('NotSavedToCloud');
                        return (<SidebarMenuItem
                            key={'licc' + index}
                            active={cloudlicc.id === currentLick}
                            title={cloudlicc.name} itemTitle={timing}
                            icon={`nav-icon fas fa-compact-disc`}
                            onClick={() => {
                                let alreadySet = midiLicks.find(x => x.id === cloudlicc.id);
                                if (alreadySet) {
                                    if (window.confirm(TitlesService(`YouwillLoseChangesToLicc`))) {
                                        dispatch(addAndSelectLicc(cloudlicc))
                                    }
                                }
                                else {
                                    dispatch(addAndSelectLicc(cloudlicc))
                                }

                                // dispatch(setCurrentLick(cloudlicc.id));
                            }} />)
                    })}
                </SidebarMenuItem>
                {/* {midiLicks.map((lick, i) => {
                    return <MidiLick selected={lick.id === currentLick} onClick={() => {
                        dispatch(setCurrentLick(lick.id));
                    }} key={`device-${i}`} name={lick.name} />
                })} */}
            </ul>
        </Panel>
    );
}