import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { CHORD_PROGRESSION_SUB_DIVISIONS, GridTypes, removeMeasureData, TrackProperties } from '../../features/composer/composerSlice';
import { TitlesService } from '../../title-service';
import { FormGroup } from '../../components/FormGroup';
import ProgressionWidthProvider from './ProgressionWidthProvider';
import { onDragState, setDragStateListener } from '../../base/dragstateservice';
import { doOverlap } from '../../base/modifier-service';
import { MidiInstrumentData } from '../../base/instrument-data/instruments';
import { addTickEndListener, addTickListener, addTickStartListener } from '../../base/magenta';
import { convertToMilliseconds, getDurationNum, getStartTick } from '../../base/midi-writer-service';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addKeyServiceEventHandler, isParentOf } from '../../base/key-service';
import { library, RelativeChordValue } from '../../base/base-lib';
const ResponsiveGridLayout = ProgressionWidthProvider(Responsive);

let play_start_time = null;
export default class ChordProgressionNonLinearEditor extends React.Component {
    constructor(props) {
        super(props);

        let loadedLayout = {};

        this.state = {
            layout: {},
            currentLayoutKey: 'lg',
            loadedLayout,
            measure_width: 150
        }
    }
    componentDidMount() {

    }
    componentWillUnmount() {
        this.unmounted = true;
    }
    render() {
        let spaces = [];
        let track_headers = [];
        let trackCount = 16;
        let { measure_width } = this.state;
        measure_width *= this.props.zoomSize;
        let measureDivisions = CHORD_PROGRESSION_SUB_DIVISIONS;
        let { selectedItems } = this.props;
        let measures = this.props.chordProgression ? this.props.chordProgression.measures || 4 : 4;
        let total_divisions = measureDivisions * (measures + 1);

        setDragStateListener('chor-progression-non-linear-delete', 'delete', () => {
            if (!this.unmounted) {
                if (this.props.deleteSelectedItems) {
                    this.props.deleteSelectedItems(selectedItems)
                }
            }
        });
        setDragStateListener('chord-progression-non-linear-editor', 'select', (rect1) => {
            if (!this.unmounted) {
                let items = document.querySelectorAll('.selectable-container .chord-item.selectable');
                let overlapped = [];
                let non_modifiers = [];
                for (let i = 0; i < items.length; i++) {
                    let item = items[i];
                    let rect2 = item.getBoundingClientRect();
                    var overlap = !(rect1.right < rect2.left ||
                        rect1.left > rect2.right ||
                        rect1.bottom < rect2.top ||
                        rect1.top > rect2.bottom)
                    if (overlap) {
                        overlapped.push(item.getAttribute('data-chord-id'));
                        if (item.getAttribute('data-chord-type') !== GridTypes.MODIFIER)
                            non_modifiers.push(item.getAttribute('data-chord-id'))
                    }
                }
                if (overlapped.length && non_modifiers.length) {
                    if (this.props.onItemsSelected) {
                        let temp = Object.values(allspaces).filter(x => x.id === non_modifiers[0])[0];
                        this.props.onItemsSelected({ items: overlapped, item: temp })
                    }
                }
            }
        })
        for (let i = 0; i < measures; i++) {

            spaces.push((
                <div key={`measures${i}`} style={{ backgroundColor: '#2d2d2d' }}
                    data-grid={{
                        x: i * measureDivisions,
                        y: 0, w: measureDivisions,
                        h: 1, static: true
                    }}>
                    <MeasureHeader i={i} chordProgression={this.props.chordProgression} measureDivisions={measureDivisions} />
                </div>
            ));
        }
        if (this.props.chordProgression)
            for (let i = 0; i < trackCount; i++) {
                let trackNumber = Math.ceil((i + 1) / 2);
                track_headers.push((
                    <div key={`measures${i}-track-header`} style={(i % 2 == 0) ? {
                        height: 58
                    } : {}} >
                        <TrackerHeader
                            trackNumber={trackNumber}
                            selected={this.props.selectedTrackNumber === trackNumber}
                            clipBoard={this.props.clipBoard}
                            selectItemsInRow={() => {
                                if (this.props.selectItemsInRow) {
                                    this.props.selectItemsInRow({ track: trackNumber })
                                }
                            }}
                            addModifierToRow={() => {
                                if (this.props.addModifierToRow) {
                                    this.props.addModifierToRow({ track: trackNumber })
                                }
                            }}
                            onUpdateVolume={(val) => {
                                if (this.props.setTrackProperty) {
                                    this.props.setTrackProperty({
                                        value: val,
                                        property: TrackProperties.VOLUME,
                                        track: trackNumber
                                    })
                                }
                            }}

                            onSetTrackInstrument={(instrument) => {
                                if (this.props.setTrackProperty) {
                                    this.props.setTrackProperty({
                                        value: instrument,
                                        property: TrackProperties.INSTRUMENT,
                                        track: trackNumber
                                    })
                                }
                            }}
                            onClick={() => {
                                if (this.props.onTrackSelect) {
                                    this.props.onTrackSelect({ track: trackNumber });
                                }
                                onDragState({ dragging: false })
                            }}
                            copyToClipboard={() => {
                                if (this.props.copyToClipboard) {
                                    this.props.copyToClipboard({ track: trackNumber });
                                }
                            }}
                            pasteToTrack={(type) => {
                                if (this.props.pasteToTrack) {
                                    this.props.pasteToTrack({ track: trackNumber, type })
                                }
                            }}
                            instrument={
                                this.props.chordProgression && this.props.chordProgression.tracks && this.props.chordProgression.tracks[trackNumber] ?
                                    this.props.chordProgression.tracks[trackNumber][TrackProperties.INSTRUMENT] :
                                    null
                            }
                            volume={
                                this.props.chordProgression && this.props.chordProgression.tracks && this.props.chordProgression.tracks[trackNumber] ?
                                    this.props.chordProgression.tracks[trackNumber][TrackProperties.VOLUME] :
                                    null
                            }
                            title={(i + 1) % 2 !== 0 ? `${TitlesService('Track')} ${trackNumber}` : 'mods'}
                            trackType={(i + 1) % 2 !== 0 ? GridTypes.CHORD : GridTypes.MODIFIER} />
                    </div>
                ));
            }

        let modifiers = {};
        let allspaces = {};
        if (this.props.chordProgression && this.props.chordProgression.spaces) {
            let chordprogression_spaces = this.props.chordProgression.spaces;
            addTickStartListener('chord-progression-non-linear-editor', () => {
                if (!this.unmounted) {
                    play_start_time = null;
                }
            });

            addTickEndListener('chord-progression-non-linear-editor', () => {
                if (!this.unmounted) {
                    let items = document.body.querySelectorAll(`[data-chord-id]`);
                    for (let i = 0; i < items.length; i++) {
                        let item_dom = items[i];
                        item_dom.classList.remove('playing-item')
                    }
                }
            });

            addTickListener('chord-progression-non-linear-editor', (time) => {
                if (!this.unmounted) {
                    if (play_start_time === null) {
                        play_start_time = time;
                    }
                    let relative_now_time = time - play_start_time;
                    chordprogression_spaces.map(x => {
                        let start_tick = getStartTick(x, { measureDivisions: 64, measures });
                        let duration_num = getDurationNum(x, { measureDivisions: 64, measures });
                        let end_tick = start_tick + duration_num;
                        let ppq = 128;
                        let start_secs = convertToMilliseconds(this.props.tempo, ppq, start_tick) / 1000;
                        let end_secs = convertToMilliseconds(this.props.tempo, ppq, end_tick) / 1000;
                        let item_dom = document.body.querySelector(`[data-chord-id="${x.id}"]`);
                        if (item_dom) {
                            if (relative_now_time >= start_secs && relative_now_time <= end_secs) {
                                item_dom.classList.add('playing-item')
                            }
                            else {
                                item_dom.classList.remove('playing-item')
                            }
                        }
                    })
                }
            });

            spaces.push(...this.props.chordProgression.spaces.map((d, index) => {
                allspaces[`${d.id}$${d.version || 0}`] = d;
                allspaces[`${d.id}`] = d;
                if (d.type === GridTypes.MODIFIER) {
                    modifiers[`${d.id}$${d.version || 0}`] = true;
                    modifiers[`${d.id}`] = true;
                }
                return (
                    <div key={`${d.id}$${d.version || 0}`}
                        data-chord-id={d.id}
                        data-chord-type={d.type}
                        className={`chord-item selectable ${this.props.selectedItem === d.id ? 'active' : ''} ${selectedItems.indexOf(d.id) !== -1 ? 'selected-item' : ''}`}
                        data-grid={{
                            maxH: 1,
                            ...d.position,
                            x: (d.position.x || 0),
                            type: 'chord'
                        }}>
                        <ChordItem data={d}
                            measureDivisions={measureDivisions}
                            selected={this.props.selectedItem === d.id}
                            onClick={() => {
                                if (this.props.onItemClick) {
                                    this.props.onItemClick(d);
                                }

                                onDragState({ dragging: false })
                            }}
                            chordProgression={this.props.chordProgression}
                            modifiers={this.props.modifiers}
                            onDelete={() => {
                                if (this.props.onDelete) {
                                    if (this.state.loadedLayout) {

                                        this.setState({
                                            loadedLayout: {
                                                ... this.state.loadedLayout,
                                                [this.state.currentLayoutKey]: (this.state.loadedLayout[this.state.currentLayoutKey] || []).filter(v => v.i !== d.id)
                                            }
                                        }, () => {
                                            this.props.onDelete(d.id);
                                        });
                                    }
                                }
                            }}
                            onToggleStatic={() => {
                                if (this.props.onToggleStatic) {
                                    this.props.onToggleStatic(index)
                                }
                            }} bias={this.props.bias} />
                    </div>
                )
            }));
        }
        return (
            <div className="react-grid-layout-context selectable-container app-display-select-cc">
                <section className="content">
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: '1 1 auto',
                        flexFlow: 'row nowrap',
                        alignItems: 'stretch',
                        width: '100%'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '200px 0 0',
                            position: 'sticky',
                            left: 0,
                            zIndex: 1000,
                            backgroundColor: '#343a40',
                            marginTop: 72,
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                        }}>
                            {track_headers}
                        </div>
                        <div style={{
                            display: 'flex',
                            flex: '200px 1 0',
                            overflow: 'auto'
                        }}>
                            <ResponsiveGridLayout style={{
                                width: ((measures + 1) * measure_width)
                            }}
                                className="layout"
                                draggableHandle=".drag-item"
                                preventCollision={true}
                                compactType={null}
                                width={((measures + 1) * measure_width)}
                                measureBeforeMount={true}
                                rowHeight={50}
                                onWidthChange={(containerWidth, margn, cols, containerPadding) => {

                                }}
                                onDragStart={() => {
                                    onDragState({ dragging: true })
                                }}
                                onDragStop={() => {
                                    onDragState({ dragging: false })
                                }}
                                onResizeStart={() => {
                                    onDragState({ dragging: true })
                                }}
                                onResizeStop={() => {
                                    onDragState({ dragging: false })
                                }}
                                onDrop={(layout, item, e) => {
                                    if (!modifiers[item.i]) {
                                        if (item.y % 2 && item.y > 0) {
                                            debugger;
                                        }
                                    }
                                }}
                                onLayoutChange={(currentLayout, allLayouts) => {
                                    currentLayout.map(v => {
                                        if (!v.i.startsWith('measu')) {
                                            if (!modifiers[v.i]) {
                                                if (v.y % 2 === 0 && v.y > 0) {
                                                    v.y = v.y + 1
                                                }
                                            }
                                            else {
                                                if (v.y % 2 === 1 && v.y > 0) {
                                                    v.y = v.y - 1
                                                }
                                            }
                                        }
                                        if (allspaces[v.i] && allspaces[v.i].limits) {
                                            let { y } = allspaces[v.i].limits;
                                            if (y) {
                                                if (v.y > y.max) {
                                                    v.y = y.max;
                                                } else if (v.y < y.min) {
                                                    v.y = y.min;
                                                }
                                            }
                                        }
                                    })
                                    if (this.props.updateChordProgressionPositions) {
                                        this.props.updateChordProgressionPositions(currentLayout.map(v => ({
                                            ...v,
                                            x: v.x
                                        })));
                                    }
                                }}
                                breakpoints={{ lg: 1200 }}
                                cols={{ lg: total_divisions }}>
                                {spaces}
                            </ResponsiveGridLayout>

                        </div>
                    </div>
                </section>
            </div >
        )
    }
}
class TrackerHeader extends React.Component {
    render() {
        let { clipBoard } = this.props;
        if (this.props.trackType === GridTypes.MODIFIER) {
            return (<div style={{ display: 'flex' }}>
                <FormGroup>
                    <div className="btn-group">
                        <select className="form-control" value={this.props.instrument || '0x00'} onChange={(val) => {
                            console.log(val);
                            if (val && val.currentTarget) {
                                if (this.props.onSetTrackInstrument) {
                                    this.props.onSetTrackInstrument(val.currentTarget.value);
                                }
                            }
                        }}>
                            {MidiInstrumentData.map(v => {
                                return <option key={`option-${v.hexcode}-${v.instrument}`} value={v.hexcode}>{v.instrument}</option>
                            })}
                        </select>
                    </div>
                </FormGroup>
            </div>)
        }
        return (
            <div className="card-header" style={{ backgroundColor: this.props.selected ? 'rgb(237 68 23)' : '#4d4d4d', cursor: 'pointer' }} onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick();
                }
            }}>
                <h3 className="card-title">{this.props.title}</h3>
                <TrackerHeaderCardTools trackNumber={this.props.trackNumber}>
                    <TrackerHeaderActions icon={'fa fa-copy'} onClick={() => {
                        if (this.props.copyToClipboard) {
                            this.props.copyToClipboard();
                        }
                    }} title={`${TitlesService('Copy')}`} />
                    {clipBoard && clipBoard.copyTrack ? (
                        <TrackerHeaderActions icon={'fa fa-paste'} onClick={() => {
                            if (this.props.pasteToTrack) {
                                this.props.pasteToTrack('track');
                            }
                        }} title={`${TitlesService('Paste')}`} />) : null}
                    <TrackerHeaderDivider />
                    <TrackerHeaderActions icon={'fa fa-plus'} onClick={() => {
                        if (this.props.addModifierToRow) {
                            this.props.addModifierToRow();
                        }
                    }} title={`${TitlesService('Add Modifier To Row')}`} />
                    <TrackHeaderSlider onUpdate={(val) => {
                        if (this.props.onUpdateVolume) {
                            this.props.onUpdateVolume(val)
                        }
                    }} value={this.props.volume} title={TitlesService('Volume')} />
                    <TrackerHeaderActions icon={`far fa-object-group`} onClick={() => {
                        if (this.props.selectItemsInRow) {
                            this.props.selectItemsInRow();
                        }
                    }} title={`${TitlesService('Select Row')}`} />
                    <a href="#" className="dropdown-item dropdown-footer"></a>
                </TrackerHeaderCardTools>
            </div>
        );
    }
}
let clearHeaderTools = {};
function MeasureHeader(props) {
    const dispatch = useDispatch();
    let { measureDivisions, i, chordProgression } = props;
    let chord_change_indices = null;
    let start_of_measure = (i * measureDivisions);
    let end_of_measure = ((i + 1) * measureDivisions);
    if (chordProgression && chordProgression.measures_data && chordProgression.measures_data.chordProgression) {
        let indices;
        // let before_indices = indices.filter(x => ((i * measureDivisions + measureDivisions) >= x)).sort((a, b) => { return a - b });
        // let spaces_in_measure = chordProgression.spaces.filter(x => x.position.x * measureDivisions < start_of_measure && x.position.x< end_of_measure )
        // if (indices.length && chordProgression.measures_data.chordProgression) {
        //     if (chordProgression.measures_data.chordProgression[indices[0]]) {
        //         let { position } = chordProgression.measures_data.chordProgression[indices[0]];
        //         let closestChords = chordProgression.spaces.filter(x =>
        //             x.position.x * measureDivisions <= position &&
        //             (x.position.x + x.position.w) * measureDivisions >= position
        //         ).sort((a, b) => {
        //             return a.position.y - b.position.y;
        //         });
        //         if (closestChords && closestChords.length) {
        //             let closestChord = closestChords[0];
        //             if (closestChord) {
        //                 console.log(closestChord);
        //             }
        //         }
        //     }
        // }
        indices = Object.keys(chordProgression.measures_data.chordProgression)
            .filter(x => chordProgression.measures_data.chordProgression[x].position !== undefined)
            .map(v => parseInt(v))
            .filter(x => (start_of_measure <= x && (start_of_measure + measureDivisions) >= x)).sort((a, b) => { return a - b });
        if (indices.length) {
            chord_change_indices = indices.map((index, ii) => {
                let { position } = chordProgression.measures_data.chordProgression[index]
                return (<div title={RelativeChordValue[position]} onClick={() => {
                    dispatch(removeMeasureData({
                        property: 'position',
                        index
                    }));
                }} key={`${i}-${RelativeChordValue[position]}-${ii}-indeices`} style={{
                    backgroundColor: 'red',
                    height: 10,
                    width: 10,
                    position: 'absolute',
                    zIndex: 1000,
                    left: `${(((index - start_of_measure) / measureDivisions) * 100)}%`,
                    top: 0
                }}></div>);
            })
        }
    }
    return (
        <div style={{ position: 'relative' }} >
            <span className="text"><h1>{i + 1}</h1></span>
            {chord_change_indices}
        </div>
    )
}
function TrackerHeaderCardTools(props) {
    let [open, setOpen] = useState(false);
    clearHeaderTools[props.trackNumber] = function () {
        setOpen(false);
    }
    addKeyServiceEventHandler('click', `trackheader-${props.trackNumber}`, (evt) => {
        let headerDom = document.body.querySelector(`[track-header="${props.trackNumber}"]`);
        if (!isParentOf(headerDom, evt.target)) {
            setOpen(false)
        }
    })
    return (
        <div className="card-tools" track-header={props.trackNumber}>
            <button type="button"
                className="btn btn-tool" data-card-widget="remove"
                onClick={() => {
                    setOpen(!open);
                }}>
                <i className="fas fa-bars"></i>
            </button>
            <TrackerHeaderDropDownMenu show={open} style={{ left: 0 }} >
                {props.children}
            </TrackerHeaderDropDownMenu>
        </div>
    )
}

export function TrackerHeaderDropDownMenu(props) {
    return (
        <div className={`${props.header_cls || ''} dropdown-menu dropdown-menu-lg dropdown-menu-right ${props.show ? 'show' : ''}`} style={props.style || { right: 0 }}>
            {props.children}
            {/* <span className="dropdown-item dropdown-header">15 Notifications</span>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item">
                <i className="fas fa-envelope mr-2"></i> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item">
                <i className="fas fa-users mr-2"></i> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item">
                <i className="fas fa-file mr-2"></i> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a> */}
        </div>
    )
}
export function TrackerHeaderDivider(props) {
    return (
        <div className="dropdown-divider"></div>
    )
}

export function TrackerHeaderActions(props) {
    return (
        <a style={{ cursor: 'pointer' }} className="dropdown-item" onClick={() => {
            if (props.onClick) {
                Object.values(clearHeaderTools).forEach(v => v())
                props.onClick();
            }
        }}>
            <i style={props.style} className={props.icon || "fas fa-envelope mr-2"}></i>&nbsp; {props.title}
            <span className="float-right text-muted text-sm">{props.title_option}</span>
        </a>
    )
}

export function TrackHeaderSlider(props) {
    return (<a className="dropdown-item">
        <p className="range-field grey-text">
            <input type="range" min="1" max="100" onChange={(evt) => {
                if (props.onUpdate) {
                    props.onUpdate(evt.currentTarget.value);
                }
            }} value={props.value || '75'} step="1" /> {props.value || 75}
        </p>
    </a>);
}

class ChordItem extends React.Component {
    render() {
        let chord_name = '';
        let chordInfoEl = null;
        let { props } = this;
        let relative_chord_dom = null;
        let { chordProgression, data, measureDivisions } = this.props;
        if (this.props.data) {
            let { chordData } = this.props.data;
            if (chordData) {
                let { value } = chordData;
                chord_name = chordData.userTitle || chordData.title || chordData.name;
                if (chordData.chordInfo) {
                    chord_name = chord_name || chordData.chordInfo.name;
                }
            }
            if (this.props.data.type === GridTypes.MODIFIER && this.props.modifiers && this.props.modifiers[this.props.data.modifierId]) {
                chord_name = this.props.modifiers[this.props.data.modifierId].name;
            }
        }
        if (chordProgression) {
            if (data && data.position && chordProgression && chordProgression.measures_data && chordProgression.measures_data.chordProgression) {
                let indices;
                let start_of_measure = (data.position.x);
                let end_of_measure = ((data.position.x + data.position.w));
                indices = Object.keys(chordProgression.measures_data.chordProgression)
                    .filter(x => chordProgression.measures_data.chordProgression[x].position !== undefined)
                    .map(v => parseInt(v))
                    .filter(x => start_of_measure >= x).sort((a, b) => { return b - a }); //  && (end_of_measure) >= x)
                // let before_indices = indices.filter(x => ((i * measureDivisions + measureDivisions) >= x)).sort((a, b) => { return a - b });
                // let spaces_in_measure = chordProgression.spaces.filter(x => x.position.x * measureDivisions < start_of_measure && x.position.x< end_of_measure )
                if (indices.length && chordProgression.measures_data.chordProgression) {
                    if (chordProgression.measures_data.chordProgression[indices[0]]) {
                        let { position } = chordProgression.measures_data.chordProgression[indices[0]];
                        let closestChords = chordProgression.spaces.filter(x =>
                            x.position.x <= indices[0] &&
                            (x.position.x + x.position.w) > indices[0]
                        ).sort((a, b) => {
                            return a.position.y - b.position.y;
                        });
                        if (closestChords && closestChords.length) {
                            let closestChord = closestChords[0];
                            if (closestChord) {
                                console.log(closestChord);
                                let base12_note = library[`${closestChord.chordData.chordInfo.key}${closestChord.chordData.chordInfo.accidental}`];
                                let current_base12_note = library[`${data.chordData.chordInfo.key}${data.chordData.chordInfo.accidental}`];
                                let relative_chord = null;
                                if ((current_base12_note - base12_note) < 0) {
                                    relative_chord = current_base12_note - base12_note + 12;
                                }
                                else {
                                    relative_chord = current_base12_note - base12_note;
                                }

                                relative_chord_dom = (
                                    <div style={{
                                        position: 'absolute',
                                        right: 10,
                                        top: 0,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        backgroundColor: 'blue',
                                        borderBottomLeftRadius: 5,
                                        borderBottomRightRadius: 5,
                                    }}>{RelativeChordValue[relative_chord]}
                                    </div>
                                )
                            }
                        }
                    }
                }
            }
        }
        return (
            <div onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick()
                }
            }}
                className={`card direct-chat direct-chat-warning box-header ${props.data.position.static ? '' : ' drag-item'}`}
                style={{
                    display: 'flex',
                    height: '100%',
                    paddingTop: 1,
                    paddingBottom: 1,
                    cursor: 'pointer'
                }}>
                <div className="card-body" title={chord_name} style={{
                    alignItems: 'center',
                    display: 'flex',
                    flex: 1,
                    alignContent: 'center',
                    justifyContent: 'center'
                }} >
                    {relative_chord_dom}
                    {/* <i className="fa fa-times" onClick={() => {
                        if (props.onDelete) {
                            props.onDelete();
                        }
                    }} style={{ position: 'absolute', right: 10, top: 0 }} /> */}
                    <h3 style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>{chord_name}</h3>
                </div>
            </div>
        )
    }
}