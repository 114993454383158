import React from 'react';

export default class Navbar extends React.Component {
    render() {

        return (<nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {this.props.children}
        </nav>
        );
    }
}