import React from 'react';
import { MidiInstrumentData } from '../../base/instrument-data/instruments';
import { FormGroup } from '../FormGroup';

export default class SidebarMenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    active() {
        return this.props.active ? 'active' : '';
    }
    open() {
        return this.state.open ? 'menu-open' : '';
    }
    children() {
        return this.props.children && this.props.children.length ? true : false;
    }
    iconStyle() {
        return this.props.iconStyle || {};
    }
    itemTitle() {
        return this.props.itemTitle;
    }
    render() {
        return (<li className={`nav-item ${this.open()}`} style={{ cursor: 'pointer' }} title={this.itemTitle()}>
            <a className={`nav-link ${this.active()}`} onClick={() => {
                if (this.props.children) {
                    this.setState({ open: !this.state.open });
                }
                if (this.props.onClick) {
                    this.props.onClick();
                }
            }}>
                <i style={this.iconStyle()}
                    className={`nav-icon ${this.props.icon || (this.children() && this.open() ? 'fas far fa-circle' : 'fas far fa-circle')}`}></i>
                <p>
                    <span style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>{this.props.title}</span>
                    {this.children() ? <i className="right fas fa-angle-left"></i> : null}
                </p>
            </a>
            {this.props.children && this.state.open ? (
                <ul className="nav nav-treeview" style={this.props.childrenStyle}>
                    {this.props.children}
                </ul>
            ) : null}
        </li>
        )
    }
}

export function SideBarMenuHolder(props) {
    return (
        <li className={`nav-item`} style={{ cursor: 'pointer' }} >
            <a className={`nav-link`}>
                {props.children}
            </a>
        </li>
    )
}