import { TitlesService } from "../title-service";

export const HelpLib = {
    QuickChordProgressionInput: 'QuickChordProgressionInput'
}

export const HelpLibTopic = {
    QuickChordProgressionInputGeneral: 'QuickChordProgressionInputGeneral',
    QuickChordProgressionInputAddChordProgression: 'QuickChordProgressionInputAddChordProgression'
}

export function getTopic(topic) {
    switch (topic) {
        case HelpLibTopic.QuickChordProgressionInputGeneral:
            return {
                video: "https://www.youtube.com/embed/U57VPog3Fa8"
            }
        case HelpLibTopic.QuickChordProgressionInputAddChordProgression:
            return {
                video: "https://www.youtube.com/embed/efvduUWSBxo"
            }
    }
}
export function getTopics(type) {
    switch (type) {
        case HelpLib.QuickChordProgressionInput:
            return [{
                title: TitlesService('What is this?'),
                id: HelpLibTopic.QuickChordProgressionInputGeneral
            },{
                title: TitlesService('How do I add my chord progression to the Non-Linear Editor'),
                id: HelpLibTopic.QuickChordProgressionInputAddChordProgression
            }]
            break;
    }
}