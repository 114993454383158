import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    chopMidiFile,
    getCurrentLickObj,
    getTempo,
    replaceChordInput,
    saveMidiFileToMachine,
    selectCurrentMidiFile
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import { MidiTrackEditor } from '../../components/music/MidiTrack';
import { TitlesService } from '../../title-service';
import { createMidiMessage, notesOverlap, uuidv4 } from '../../base/base-lib';
import { getActiveTrack, getActiveTracks, getIsPlaying, getIsQuantized, getRepeat, getTrackEditorFileName, getTrackEditorInstrument, getTrackEditorValue, setIsPlaying, trackEditorUpdate } from '../user/userSlice';
import { addTickEndListener, mm, START_PLAYING } from "../../base/magenta";
import { BuildMidiFromEditor, convertToMilliseconds } from '../../base/midi-writer-service';
import SeratoComposer from './seratoComposer';
import { PlayMagentaNotesAsnyc, StopMagentaPlayerAsync } from './magenta-thunks';
import { getSongName } from '../../base/nameService';
import { CaptureChords } from '../../base/chord-progression';
import { COMMON_BASE_CHORDS } from '../../base/chord-constants';
import { ClipboardService } from '../../base/clipboard-service';
export const SPLIT = 'split';
export const CHORDIFY = 'chordify';
export const MODIFY_CHORD = 'MODIFY_CHORD';
export const CHANGE_SELECTED_VELOCITY = 'CHANGE_SELECTED_VELOCITY';
export const SET_REPEAT = 'SET_REPEAT';
export const TOGGLE_PLAY = 'TOGGLE_PLAY';
export const STORE_MIDI = 'STORE_MIDI';
export const STORE_MIDI_CLIPBOARD = 'STORE_MIDI_CLIPBOARD';
export const CREATE_LICC = 'CREATE_LICC';
export const LOAD_CURRENT_LICC = 'LOAD_CURRENT_LICC';
export const ADD_SCENE_PROGRESSION = 'ADD_SCENE_PROGRESSION';
export const SET_INSTRUMENT = 'SET_INSTRUMENT';
export const MAIN_TRACK_EDITOR = 'main-editor';
export function MidiTrackerEditor() {
    const dispatch = useDispatch();
    let tempo = useSelector(getTempo);
    let currentLicc = useSelector(getCurrentLickObj);
    let instrument = useSelector(getTrackEditorInstrument);
    let track_name = useSelector(getTrackEditorFileName);
    let isQuantized = useSelector(getIsQuantized);
    let track_editor_mode = useSelector(getTrackEditorValue('mode'))
    let track_editor_selected = useSelector(getTrackEditorValue('selection'));
    let copy_items = useSelector(getTrackEditorValue('copyItems'))
    let instruments = useSelector(getTrackEditorValue('instruments')) || {};
    let repeat = useSelector(getRepeat);
    const is_playing = useSelector(getIsPlaying);
    let tracks = useSelector(getActiveTracks);
    let name = useSelector(getTrackEditorFileName);
    let currentTrack = useSelector(getActiveTrack);
    let selection_start = 0;
    let selection_end = 0;
    let vertical_start = 0;
    let vertical_end = 127;
    let ppq = 960;
    const [notes, setNotes] = useState([]);
    const [chords, setChords] = useState([]);
    const [step_counter, setStepCounter] = useState(null);
    var [hasBeenStarted, setHasBeenStarted] = useState(false);
    const [track_cut, setTrackCuts] = useState(8);

    listeners['midi-tracker-editor'] = {
        command: SPLIT,
        handler: (args) => {
            let { split } = args;
            let remove = [];
            let add = [];
            (notes || []).map(v => {
                if (args.notes.indexOf(v.id) !== -1) {
                    let new_notes = splitNote(v, split);
                    v.ticks = new_notes[0].ticks;
                    v.durationTicks = new_notes[0].durationTicks;
                    // remove.push(v.id);
                    add.push(...new_notes.slice(1));
                }
            });
            addRemoveNotes(remove, add);
        }
    }
    listeners[CREATE_LICC] = {
        command: CREATE_LICC,
        handler: (args) => {
            if (track_editor_selected) {
                dispatch(chopMidiFile({
                    args: {
                        name: name || getSongName(),
                        ppq: ppq
                    },
                    notes: Object.values(JSON.parse(JSON.stringify(track_editor_selected))).map(v => {
                        v.time = convertToMilliseconds(100, ppq, v.ticks);
                        v.duration = convertToMilliseconds(100, ppq, v.durationTicks);
                        return v;
                    })
                }));
            }
        }
    }
    listeners[MODIFY_CHORD] = {
        command: MODIFY_CHORD,
        handler: (args) => {
            let remove = [];
            let add = [];
            debugger
            (notes || []).map(v => {
                if (args.notes.indexOf(v.id) !== -1) {
                    if (args && args.chord && args.chord.smartinfo) {
                        args.chord.smartinfo.voice.forEach((x, index) => {
                            if (index) {
                                let newnote = duplicateNote(v, v.midi + x);
                                add.push(newnote);
                            }
                        })
                    }
                }
            });
            addRemoveNotes(remove, add);
        }
    }
    MidiTrackPlayer.currentLicc = currentLicc;
    listeners[CHANGE_SELECTED_VELOCITY] = {
        command: CHANGE_SELECTED_VELOCITY,
        handler: (args) => {
            let { velocity } = args;
            if (MidiTrackPlayer.track_editor_selected) {
                let temp = notes.map(v => {
                    if (MidiTrackPlayer.track_editor_selected[v.id]) {
                        v.velocity = parseInt(velocity);
                    }
                    return v;
                });
                setChords(CaptureChords(temp));
                setNotes(temp);
            }
        }
    }
    listeners[LOAD_CURRENT_LICC] = {
        command: LOAD_CURRENT_LICC,
        handler: (args) => {
            if (MidiTrackPlayer.currentLicc) {
                let licc = MidiTrackPlayer.currentLicc;
                let ppq_convert = ppq / licc.ppq;
                let new_notes = licc.notes.map(v => {
                    return {
                        ...v,
                        track: (args && args.track !== undefined) ? args.track || v.track : v.track,
                        velocity: v.velocity < 1 ? v.velocity * 128 : v.velocity,
                        id: uuidv4(),
                        ticks: v.ticks * ppq_convert,
                        durationTicks: v.durationTicks * ppq_convert
                    }
                });
                if ((args && args.track !== undefined)) {
                    setNotes([...notes, ...new_notes]);
                    setChords(CaptureChords([...notes, ...new_notes]));
                }
                else {
                    setNotes(new_notes);
                    setChords(CaptureChords(new_notes));
                }
            }
        }
    }
    listeners['set-instrument'] = {
        command: SET_INSTRUMENT,
        handler: (args) => {
            MidiTrackPlayer.instrument = args.value;
        }
    }
    listeners[STORE_MIDI] = {
        command: STORE_MIDI,
        handler: () => {
            let midi = BuildMidiFromEditor(notes, tempo, ppq, instruments)
            saveMidiFileToMachine(midi, `${track_name || getSongName()}.mid`);
        }
    }
    listeners[STORE_MIDI_CLIPBOARD] = {
        command: STORE_MIDI_CLIPBOARD,
        handler: () => {
            let midi = BuildMidiFromEditor(notes, tempo, ppq, instruments)
            // saveMidiFileToMachine(midi, `${track_name || getSongName()}.mid`);
            ClipboardService.write(midi)
        }
    }
    listeners[CHORDIFY] = {
        command: CHORDIFY,
        handler: (args) => {
            let add = [];
            let remove = [];
            (notes || []).map(v => {
                if (args.notes.indexOf(v.id) !== -1) {
                    let info = COMMON_BASE_CHORDS.find(x => x.value === args.chord);
                    if (info) {
                        info.offset.forEach((x, index) => {
                            if (index) {
                                let newnote = duplicateNote(v, v.midi + x);
                                add.push(newnote);
                            }
                        })
                    }
                }
            });
            let temp = [...notes];
            temp = [...temp.filter(x => remove.indexOf(x.id) === -1), ...add];
            temp = temp.sort((a, b) => a.tick - b.tick);
            setChords(CaptureChords(temp));
            setNotes(temp);
        }
    }
    listeners[ADD_SCENE_PROGRESSION] = {
        command: ADD_SCENE_PROGRESSION,
        handler: (args) => {
            let { payload } = args;
            console.log(payload);
            let { measureLength, chords } = payload;
            let new_notes = [];
            chords.map((chord, index) => {
                let duration = (ppq * 2 * measureLength);
                let tick = duration * index;
                Object.keys(chord.values).map(v => {
                    new_notes.push({
                        id: uuidv4(),
                        midi: parseInt(v),
                        ticks: tick,
                        ppq,
                        velocity: 70,
                        durationTicks: duration,
                        track: currentTrack
                    })
                })
            })
            addRemoveNotes(notes.map(v => v.id), new_notes);
        }
    }
    MidiTrackPlayer.tempo = tempo;
    MidiTrackPlayer.instrument = instrument;
    MidiTrackPlayer.ppq = ppq;
    MidiTrackPlayer.repeat = repeat;
    MidiTrackPlayer.is_playing = is_playing;
    MidiTrackPlayer.hasBeenStarted = hasBeenStarted;
    MidiTrackPlayer.track_editor_selected = track_editor_selected;
    let playHandler = () => {
        if (!MidiTrackPlayer.hasBeenStarted) {
            setHasBeenStarted(true);
            MidiTrackPlayer.init();
            addTickEndListener('midi-track-listener', () => {
                if (MidiTrackPlayer.repeat) {
                    stopPattern();
                    dispatch(StopMagentaPlayerAsync()).then(() => {
                        dispatch(PlayMagentaNotesAsnyc({ midi: MidiTrackPlayer.last_midi, tracks: [] }));
                        startPattern();
                        setHasBeenStarted(true);
                    });
                }
                else {
                    stopPattern();
                    dispatch(StopMagentaPlayerAsync())
                }
            })
        }
        if (MidiTrackPlayer.is_playing) {
            dispatch(StopMagentaPlayerAsync());
            stopPattern();
            // Tone.Transport.stop();
        } else {
            let midi = BuildMidiFromEditor(notes, tempo, ppq, instruments)
            MidiTrackPlayer.last_midi = midi;
            dispatch(PlayMagentaNotesAsnyc({ midi, tracks: [] }));
            startPattern();
            setHasBeenStarted(true);
        }
    }
    listeners['midi-track-play'] = {
        command: TOGGLE_PLAY,
        handler: playHandler
    }
    function addRemoveNotes(remove, add) {
        let temp = [...notes];
        temp = [...temp.filter(x => remove.indexOf(x.id) === -1), ...add];
        temp = temp.sort((a, b) => a.tick - b.tick);
        setChords(CaptureChords(temp));
        setNotes(temp);
    }

    function startPattern() {
        setStepCounter(-1)
        MidiTrackPlayer.stepCounter = -1;
    }

    function stopPattern() {
        setStepCounter(null);
    }
    MidiTrackPlayer.setGetNotes(() => ({
        notes,
        start: 0,
        end: 12000
    }));
    return (
        <Panel stretch title={TitlesService('Midi Track Editor')}>
            <MidiTrackEditor
                start={0}
                id={MAIN_TRACK_EDITOR}
                end={12000}
                height={400}
                ppq={ppq}
                mode={track_editor_mode}
                tracks={tracks}
                currentTrack={currentTrack}
                notes={notes}
                chords={chords}
                copy_notes={(track_editor_mode === 'copy' ? Object.values(JSON.parse(JSON.stringify(copy_items))) : [])}
                deleteSelectedItems={(notes_to_delete) => {
                    let temp = [...notes.filter(x => x.track !== currentTrack || notes_to_delete.indexOf(x.id) === -1)];
                    setNotes(temp);
                    setChords(CaptureChords(temp));
                }}
                onAddNote={(args) => {
                    let new_note = createNote({
                        ...args,
                        track: currentTrack
                    }, {
                        ppq
                    });
                    let same_row = notes.filter(x => x.midi === new_note.midi && x.track === currentTrack && notesOverlap(x, new_note)).map(x => x.id);
                    let temp = [...notes.filter(x => same_row.indexOf(x.id) === -1), new_note];
                    setNotes(temp);
                    setChords(CaptureChords(temp));
                    setTrackCuts(args.cuts);
                }}
                updateNotes={(args) => {
                    let { cut, selectedNotes, dragItem } = args;
                    console.log(args)
                    cut *= 2;
                    let { relativeChange } = dragItem;
                    if (relativeChange) {
                        let { duration, tick, midi } = relativeChange;
                        if (isQuantized) {
                            duration = Math.round(duration * (cut / ppq)) / (cut / ppq);
                            tick = Math.round(tick * (cut / ppq)) / (cut / ppq);
                        }
                        let toremove = [];
                        let toadd = [];
                        let selected_keys = Object.keys(selectedNotes);
                        Object.values(selectedNotes).map(selected_note => {
                            let new_overlaps = notes.filter(x => x.track === currentTrack && selected_keys.indexOf(x.id) === -1).filter(x => x.id !== selected_note.id &&
                                x.midi === (selected_note.midi + relativeChange.midi) &&
                                notesOverlap(x, {
                                    durationTicks: selected_note.durationTicks + duration,
                                    ticks: selected_note.ticks + tick
                                }));
                            //no conflicts make changes
                            if (new_overlaps.length === 0) {
                                selected_note.durationTicks += duration;
                                selected_note.durationTicks = Math.max(selected_note.durationTicks, 0);
                                selected_note.midi += midi;
                                selected_note.ticks += tick;
                            }
                            else {
                                let selected_conflict = new_overlaps.filter(x => selected_keys.indexOf(x) !== -1);
                                if (selected_conflict.length) {
                                    // if the midi note didnt change
                                    if (midi === 0) {
                                        // pulled the items to the left
                                        if (tick < 0) {
                                            let farthestRight = new_overlaps.sort((b, a) => a.ticks - b.ticks)[0];

                                            selected_note.durationTicks = selected_note.durationTicks + (farthestRight.ticks - selected_note.ticks);
                                            selected_note.durationTicks = Math.max(selected_note.durationTicks, 0);
                                            selected_note.midi += midi;
                                            selected_note.ticks = farthestRight.tick + farthestRight.durationTicks;
                                        }
                                        else {
                                            let farthestLeft = new_overlaps.sort((a, b) => a.ticks - b.ticks)[0];

                                            selected_note.durationTicks = farthestLeft.ticks - selected_note.ticks;
                                            selected_note.durationTicks = Math.max(selected_note.durationTicks, 0);
                                            selected_note.midi += midi;
                                            selected_note.ticks += tick;
                                        }

                                    }
                                    else {
                                        selected_note.durationTicks += duration;
                                        selected_note.durationTicks = Math.max(selected_note.durationTicks, 0);
                                        selected_note.midi += midi;
                                        selected_note.ticks += tick;
                                        toremove.push(...new_overlaps.filter(x => selected_keys.indexOf(x) === -1));
                                    }
                                }
                                else {
                                    selected_note.durationTicks += duration;
                                    selected_note.durationTicks = Math.max(selected_note.durationTicks, 0);
                                    selected_note.midi += midi;
                                    selected_note.ticks += tick;
                                    toremove.push(...new_overlaps.filter(x => selected_keys.indexOf(x) === -1));
                                }
                            }
                            if (selected_note) {
                                if (isQuantized) {
                                    selected_note.ticks = Math.round(selected_note.ticks * (cut / ppq)) / (cut / ppq);
                                    selected_note.durationTicks = Math.round(selected_note.durationTicks * (cut / ppq)) / (cut / ppq);
                                }
                            }
                            if (notes.filter(x => x.id === selected_note.id).length === 0) {
                                toadd.push(selected_note);
                            }
                        });
                        dispatch(trackEditorUpdate({ type: 'mode', value: '' }));
                        let temp = [...notes.filter(x => toremove.indexOf(x) === -1), ...toadd.map(v => JSON.parse(JSON.stringify(v)))];
                        setNotes(temp);
                        setTrackCuts(cut);
                        setChords(CaptureChords(temp));
                    }
                }}
                onSelectionComplete={() => {
                    switch (track_editor_mode) {
                        case 'copy':
                            dispatch(trackEditorUpdate({
                                type: 'mode',
                                value: 'complete-copy'
                            }))
                            break;
                    }
                }}
                onSelection={(selection) => {
                    dispatch(trackEditorUpdate({
                        type: 'selection', value: JSON.parse(
                            JSON.stringify(selection)
                        )
                    }));
                    let chord = {};
                    Object.values(selection).map(v => {
                        chord[v.midi] = true;
                    })
                    dispatch(replaceChordInput({ id: 'key-input', value: chord }))
                }}
                verticalStart={vertical_start}
                verticalEnd={vertical_end}
                selection_start={selection_start}
                selection_end={selection_end} />
        </Panel >
    );
}
const listeners = {};
export function SendCommandToMidiTrackerEditor(command, args) {
    return Object.values(listeners).map(v => {
        if (v.command === command) {
            return v.handler(args);
        }
    }).filter(x => x !== undefined)
}
// export function notesOverlap(note1, note2) {
//     return !((note1.ticks + note1.durationTicks - 1) < note2.ticks ||
//         note1.ticks > (note2.ticks + note2.durationTicks - 1))
// }

function createNote(args, { ppq }) {
    let { cuts, midi, tick, track = 0 } = args;
    let ticks = Math.floor(tick / (ppq / cuts)) * (ppq / cuts)
    return {
        id: uuidv4(),
        midi,
        ticks,
        ppq,
        velocity: 70,
        durationTicks: ppq / cuts,
        track: track || 0
    }
}

function duplicateNote(note, midi) {
    return {
        ...note,
        id: uuidv4(),
        midi,
        track: note.track || 0
    }
}

function splitNote(note, split) {
    let durationTicks = (note.durationTicks / split);
    let durationPpq = note.ppq / split;
    let startTick = note.ticks;
    return (new Array(split)).fill(0).map((item, index) => {
        return {
            id: uuidv4(),
            midi: note.midi,
            velocity: note.velocity,
            ticks: (startTick + (index * durationTicks)),
            durationTicks,
            ppq: durationPpq,
            track: note.track || 0
        };
    })
}

const TIME_HUMANIZATION = 0.01;
let Tone = mm.Player.tone;
class MidiTrackPlayer {
    static webMidiEnabled = false;
    static initialized = false;
    static stepCounter = null;
    static instrument = 0;
    static pattern = null;
    static ppq = 960;
    static tempo = 100;
    static swing = .55;
    static seedLength = 4;
    static activeClockOutput = null;
    static activeOutput = null;
    static bpm = 100;
    static init() {
        MidiTrackPlayer.onDevicesChanged();
    }
    static onDevicesChanged() {
        MidiTrackPlayer.onActiveClockInputChange('none');
    }
    static onActiveClockInputChange(id) {
        let activeClockInput = MidiTrackPlayer.activeClockOutput;
        let currentSchedulerId = MidiTrackPlayer.currentSchedulerId;
        if (activeClockInput === 'none') {
            Tone.Transport.clear(currentSchedulerId);
            currentSchedulerId = null;
            MidiTrackPlayer.currentSchedulerId = currentSchedulerId;
        }
        activeClockInput = id;
        if (activeClockInput === 'none') {
            Tone.Transport.ppq = MidiTrackPlayer.ppq;
            Tone.Transport.bpm.value = MidiTrackPlayer.bpm;
            currentSchedulerId = Tone.Transport.scheduleRepeat(MidiTrackPlayer.tick, .05);
            MidiTrackPlayer.currentSchedulerId = currentSchedulerId;
            MidiTrackPlayer.oneEighth = Tone.Time('8n').toSeconds();
        }
    }
    static setGetNotes(getFunc) {
        MidiTrackPlayer.getNotesFunc = getFunc
    }
    static tick(time = Tone.now()) {
        let stepCounter = MidiTrackPlayer.stepCounter;
        let notes = [];
        let end;
        let start;
        if (MidiTrackPlayer.getNotesFunc) {
            notes = MidiTrackPlayer.getNotesFunc();
            if (notes) {
                end = notes.end;
                start = notes.start;
                notes = notes.notes;
            }
        }
        if (!isNaN(stepCounter) && notes && notes.length) {
            if (MidiTrackPlayer.stepCounter === -1) {
                MidiTrackPlayer.start_time = time;
                stepCounter = 0;
            }
            let music_time = (time - MidiTrackPlayer.start_time) * 1000;
            let future_music_time = music_time + (1000);
            let nextnotes = [];
            let last;
            for (let i = 0; i < notes.length; i++) {
                let x = notes[i];
                let ms = convertToMilliseconds(MidiTrackPlayer.bpm, MidiTrackPlayer.ppq, x.ticks)
                let dms = convertToMilliseconds(MidiTrackPlayer.bpm, MidiTrackPlayer.ppq, x.durationTicks);
                if (stepCounter <= i) {
                    if (music_time <= ms && ms < future_music_time) {
                        nextnotes.push(x.id);
                        let note = x;

                        stepCounter = i + 1;
                    }
                }
                if (music_time >= ms && music_time <= (ms + dms)) {
                    nextnotes.push(x.id);
                }
            }
            // MidiTrackEditor.executeForceDraw(MAIN_TRACK_EDITOR, nextnotes);
            if (future_music_time > end) {
                stepCounter = -1;
            }
            MidiTrackPlayer.stepCounter = stepCounter;
        }
    }
}