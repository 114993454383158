import React from 'react';
import Infinite from 'mephistowa-react-infinite';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getPatterns, getSelectedPattern, selectPattern } from '../../features/server/serviceSlice';
import { getUserId, getUserSignedIn } from '../../features/user/userSlice';
import { TitlesService } from '../../title-service';
import SidebarMenuItem from '../main/SideBarMenuItem';

export default function DrumPatterns(props) {
    var dispatch = useDispatch();
    const isSignedIn = useSelector(getUserSignedIn);
    let uid = useSelector(getUserId);
    const patterns = useSelector(getPatterns);
    const selected = useSelector(getSelectedPattern);
    if (!isSignedIn) {
        return (
            <div>{TitlesService('SignIn')}</div>
        )
    }
    return (
        <Infinite styles={{ scrollableStyle: { overflowY: 'auto' } }} useParentContainer elementHeight={43}>
            {patterns.map((pattern, index) => {
                let temp = new Date((pattern.updated ? pattern.updated.seconds : pattern.created.seconds) * 1000);
                let moment = window.moment;
                let timing = moment(temp).fromNow();
                return <ul key={'patterin' + index} className="nav nav-pills nav-sidebar flex-column">
                    <SidebarMenuItem active={pattern.id === selected} title={pattern.name} itemTitle={timing} icon={`nav-icon fas fa-drum`} onClick={() => {
                        dispatch(selectPattern(pattern.id))
                        if (props.getPatternFunc) {
                            props.getPatternFunc()(pattern);
                        }
                    }} />
                </ul>
            })}
        </Infinite>
    )
}

class PatternItem extends React.Component {
    render() {
        let { selected } = this.props;
        let { name, id } = this.props.pattern;
        return (
            <div style={{
                flex: 1,
                flexDirection: 'row',
                height: 43,
                display: 'flex'
            }} onClick={() => {
                if (this.props.onClick) {
                    this.props.onClick(id);
                }
            }}>
                <div style={{ paddingLeft: 4 }}>{name}</div>
            </div>
        )
    }
}