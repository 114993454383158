import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormGroup } from '../../components/FormGroup';
import {
    selectCurrentModifier,
    getSelectedModifier,
    getModifiers,
    createModifier,
    addAndSelectModifier
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import ModifierList from '../../components/music/ModifierList'
import { LoadCloudModifiersAsync, StoreCloudModifiersAsync } from './magenta-thunks';
import { getUserId } from '../user/userSlice';
import { getCloudModifiers } from '../server/serviceSlice';
export function ModifierOptionsList() {
    const dispatch = useDispatch();
    let selected = useSelector(getSelectedModifier);
    let uid = useSelector(getUserId);
    let modifiers = useSelector(getModifiers);
    let cloud_modifiers = useSelector(getCloudModifiers);
    return (
        <Panel stretch title={'Modifiers'}>
            <FormGroup>
                <div className="btn-group">
                    <button className="btn btn-success" onClick={() => {
                        dispatch(createModifier())
                    }}><i className="fa fa-plus" /></button>
                    <button className="btn btn-success" onClick={() => {
                        let modifier = modifiers.find(v => v.id === selected)
                        if (modifier) {
                            dispatch(StoreCloudModifiersAsync({ ...modifier, owner: uid })).then(() => {
                                return dispatch(LoadCloudModifiersAsync({ uid }))
                            })
                        }
                    }}><i className="fa fa-save" /></button>
                </div>
            </FormGroup>
            <ModifierList
                modifiers={modifiers}
                selected={selected}
                cloud_modifiers={cloud_modifiers}
                onAddAndSelect={(modifier) => {
                    dispatch(addAndSelectModifier(modifier))
                }}
                onSelectToggle={(modifier) => {
                    dispatch(selectCurrentModifier({ id: modifier }))
                }} />
        </Panel>
    );
}