import React from 'react';

export default class PanelButton extends React.Component {
    icon() {
        return this.props.icon || "fas fa-minus";
    }
    render() {
        return (
            <button type="button" className="btn btn-tool" onClick={() => {
                if (this.props.onClick) { this.props.onClick(); }
            }} data-card-widget="collapse">
                <i className={this.icon()}></i>
            </button>);
    }
}