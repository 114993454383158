import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    buildChordOption,
    onAddToBank,
    play,
    selectBias,
    selectOnDeckPallette
} from './composerSlice';
import Panel from '../../components/panel/Panel';
import OnDeckPalletteList from '../../components/music/OnDeckPalletteList';

export function OnDeckPallettes() {
    const dispatch = useDispatch();
    let bias = useSelector(selectBias);
    let onDeckPallettes = useSelector(selectOnDeckPallette);

    return (
        <Panel stretch title={'On Deck Pallette'}>
            <OnDeckPalletteList
                onPlay={(payload) => {
                    dispatch(play(payload))
                }} onStop={(payload) => {
                    dispatch(play(payload))
                }}
                onAddToBank={(notes) => {
                    dispatch(onAddToBank(notes));
                }}
                value={onDeckPallettes} bias={bias} />
        </Panel>
    );
}