import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { onDragState } from '../../base/dragstateservice';

const ResponsiveGridLayout = WidthProvider(Responsive);
export function GetSpacePane() {
    return (
        localStorage.getItem('space-pane')
    );
}
export default class SpacePane extends React.Component {
    constructor(props) {
        super(props);

        let loadedLayout =  GetSpacePane() ;
        if (loadedLayout) {
            loadedLayout = JSON.parse(loadedLayout);
        }
        else {
            loadedLayout = {
                "lg": [{
                    "w": 2, "h": 2, "x": 10, "y": 5, "i": "space-pane-0", "moved": false, "static": false
                }, {
                    "w": 12, "h": 5, "x": 0, "y": 0, "i": "space-pane-3", "moved": false, "static": false
                }, {
                    "w": 1, "h": 3, "x": 6, "y": 5, "i": "space-pane-4", "moved": false, "static": false
                }, {
                    "w": 2, "h": 3, "x": 8, "y": 5, "i": "space-pane-6", "moved": false, "static": false
                }, {
                    "w": 1, "h": 3, "x": 7, "y": 5, "i": "space-pane-7", "moved": false, "static": false
                }, {
                    "w": 6, "h": 4, "x": 6, "y": 8, "i": "space-pane-8", "moved": false, "static": false
                }, {
                    "w": 6, "h": 6, "x": 0, "y": 5, "i": "space-pane-10", "moved": false, "static": false
                }],
                "md": [{
                    "w": 2, "h": 2, "x": 0, "y": 0, "i": "space-pane-0", "moved": false, "static": false
                }, {
                    "w": 2, "h": 4, "x": 0, "y": 27, "i": "space-pane-1", "moved": false, "static": false
                }, {
                    "w": 2, "h": 3, "x": 0, "y": 2, "i": "space-pane-2", "moved": false, "static": false
                }, {
                    "w": 2, "h": 3, "x": 0, "y": 10, "i": "space-pane-3", "moved": false, "static": false
                }, {
                    "w": 2, "h": 5, "x": 0, "y": 5, "i": "space-pane-4", "moved": false, "static": false
                }, {
                    "w": 2, "h": 4, "x": 0, "y": 23, "i": "space-pane-5", "moved": false, "static": false
                }, {
                    "w": 2, "h": 10, "x": 0, "y": 13, "i": "space-pane-6", "moved": false, "static": false
                }],
                "sm": [{
                    "w": 2, "h": 2, "x": 0, "y": 0, "i": "space-pane-0", "moved": false, "static": false
                }, {
                    "w": 2, "h": 4, "x": 0, "y": 27, "i": "space-pane-1", "moved": false, "static": false
                }, {
                    "w": 2, "h": 3, "x": 0, "y": 2, "i": "space-pane-2", "moved": false, "static": false
                }, {
                    "w": 2, "h": 3, "x": 0, "y": 10, "i": "space-pane-3", "moved": false, "static": false
                }, {
                    "w": 2, "h": 5, "x": 0, "y": 5, "i": "space-pane-4", "moved": false, "static": false
                }, {
                    "w": 2, "h": 4, "x": 0, "y": 23, "i": "space-pane-5", "moved": false, "static": false
                }, {
                    "w": 2, "h": 10, "x": 0, "y": 13, "i": "space-pane-6", "moved": false, "static": false
                }],
                "xs": [{
                    "w": 2, "h": 2, "x": 0, "y": 0, "i": "space-pane-0", "moved": false, "static": false
                }, {
                    "w": 2, "h": 4, "x": 0, "y": 27, "i": "space-pane-1", "moved": false, "static": false
                }, {
                    "w": 2, "h": 3, "x": 0, "y": 2, "i": "space-pane-2", "moved": false, "static": false
                }, {
                    "w": 2, "h": 3, "x": 0, "y": 10, "i": "space-pane-3", "moved": false, "static": false
                }, {
                    "w": 2, "h": 5, "x": 0, "y": 5, "i": "space-pane-4", "moved": false, "static": false
                }, {
                    "w": 2, "h": 4, "x": 0, "y": 23, "i": "space-pane-5", "moved": false, "static": false
                }, {
                    "w": 2, "h": 10, "x": 0, "y": 13, "i": "space-pane-6", "moved": false, "static": false
                }],
                "xxs": [{
                    "w": 2, "h": 2, "x": 0, "y": 0, "i": "space-pane-0", "moved": false, "static": false
                }, {
                    "w": 2, "h": 4, "x": 0, "y": 27, "i": "space-pane-1", "moved": false, "static": false
                }, {
                    "w": 2, "h": 3, "x": 0, "y": 2, "i": "space-pane-2", "moved": false, "static": false
                }, {
                    "w": 2, "h": 3, "x": 0, "y": 10, "i": "space-pane-3", "moved": false, "static": false
                }, {
                    "w": 2, "h": 5, "x": 0, "y": 5, "i": "space-pane-4", "moved": false, "static": false
                }, {
                    "w": 2, "h": 4, "x": 0, "y": 23, "i": "space-pane-5", "moved": false, "static": false
                }, {
                    "w": 2, "h": 10, "x": 0, "y": 13, "i": "space-pane-6", "moved": false, "static": false
                }]
            }
        }

        this.state = {
            layout: {},
            currentLayoutKey: 'lg',
            loadedLayout
        }
    }
    componentDidMount() {

    }
    storeSelectedViewSetup(setup, layouts) { }
    render() {
        let spaces = this.props.spaces.map((d, index) => {
            let dataGridValue = null;
            if (this.state.currentLayoutKey && this.state.loadedLayout) {
                let tempLayout = this.state.loadedLayout[this.state.currentLayoutKey];
                if (tempLayout) {
                    dataGridValue = tempLayout.find(v => v.i === `space-pane-${d.name}`);
                }
            }
            let temp = d.element();
            if (temp)
                return (
                    <div key={`space-pane-${d.name}`} data-grid={dataGridValue || { x: 0, y: 2, w: 6, h: 2 }}>
                        {temp}
                    </div>
                )
        }).filter(x => x);
        return (
            <div className="react-grid-layout-context" style={{ minHeight: 848 }}>
                <section className="content">
                    <ResponsiveGridLayout
                        className="layout"
                        draggableHandle=".drag-me-header"
                        layout={this.state.loadedLayout}
                        measureBeforeMount={false}
                        onLayoutChange={(currentLayout, allLayouts) => {
                            let currentLayoutKey = Object.keys(allLayouts).filter(x => allLayouts[x] === currentLayout)[0];
                            let updated = { ...this.state.loadedLayout, ...allLayouts }
                            localStorage.setItem('space-pane', JSON.stringify(updated));
                            this.setState({ currentLayoutKey, loadedLayout: updated })
                            // this.storeSelectedViewSetup(selectedViewSetup, allLayouts);
                        }}
                        onDragStart={() => {
                            onDragState({ dragging: true })
                        }}
                        onDragStop={() => {
                            onDragState({ dragging: false })
                        }}
                        onResizeStart={() => {
                            onDragState({ dragging: true })
                        }}
                        onResizeStop={() => {
                            onDragState({ dragging: false })
                        }}
                        onDrop={(layout, item) => {
                            // console.log(layout);
                            // console.log(item)=
                            onDragState({ dragging: false })
                        }}
                        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}>
                        {spaces}
                    </ResponsiveGridLayout>
                </section>
            </div >
        )
    }
}