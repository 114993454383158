import { SSL_OP_NO_TLSv1_1 } from 'constants';
import React from 'react';
import { TitlesService } from '../../../title-service';
import { FormGroup } from '../../FormGroup';

export class SplitModifier extends React.Component {
    reader = null;
    constructor(props) {
        super(props);
        this.state = {
            ratios: [1, 1],
            split: 2
        };
    }
    render() {
        const { selected, progressions, enabled } = this.props;
        let extra = {}
        if (!enabled) {
            extra.disabled = true;
        }
        return (
            <div className="col-md-6" style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }} >
                <div className="container">
                    <div className="row" style={{ alignItems: 'center' }} title={TitlesService('Split-Add-Description')}>
                        <div className="col-md-2">
                            <FormGroup>
                                <label>Split</label>
                                <select className="form-control" value={this.state.split} {...extra} onChange={(val) => {
                                    let new_value = parseInt(val.currentTarget.value, 10);
                                    let ratios = [...this.state.ratios];
                                    let ratios_length = ratios.length;
                                    if (ratios.length > new_value) {
                                        ratios = ratios.slice(0, new_value);
                                    }
                                    else if (ratios.length < new_value) {
                                        for (let i = 0; i < new_value - ratios_length; i++) {
                                            ratios.push(1);
                                        }
                                    }
                                    if (val && val.currentTarget) {
                                        this.setState({
                                            split: new_value,
                                            ratios
                                        })
                                    }
                                }}>
                                    <option>Select</option>
                                    {([1, 2, 3, 5, 7]).map(v => {
                                        return <option value={v}>{v}</option>
                                    })}
                                </select>
                            </FormGroup>
                        </div>
                        {this.state.ratios.map((ratio, index) => {
                            return (
                                <div key={`ratio-${index}`} className="col-md-1">
                                    <FormGroup>
                                        <label>{index + 1}</label>
                                        <select className="form-control" value={ratio} {...extra} onChange={(val) => {
                                            if (val && val.currentTarget) {
                                                let ratios = [...this.state.ratios];
                                                ratios.splice(index, 1, parseInt(val.currentTarget.value, 10));
                                                this.setState({
                                                    ratios
                                                })
                                            }
                                        }}>
                                            <option>Select</option>
                                            {([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]).map(v => {
                                                return <option value={v}>{v}</option>
                                            })}
                                        </select>
                                    </FormGroup>
                                </div>)
                        })}
                        <div className="col-md-1" style={{ display: this.state.split && this.state.ratios.length === this.state.split ? 'block' : 'none' }}>
                            <buton title={TitlesService('Split-Add')} {...extra} className={enabled ? "btn btn-success" : "btn btn-default"} onClick={() => {
                                if (this.props.onSplitAdd && enabled) {
                                    this.props.onSplitAdd({
                                        ratios: this.state.ratios,
                                        split: this.state.split
                                    })
                                }
                            }}><i className="fa fa-plus" /></buton>
                        </div>
                    </div>
                </div >
            </div>
        )
    }
}